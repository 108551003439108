import { TFunction, Trans } from '@getpopsure/i18n-react';
import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import { CoverageId, QuotePlan } from 'models/expat';
import { ReactNode } from 'react';

import brainRedIcon from '../../../../../expat/components/Quote/view/assets/brain-red.svg';
import doctorIcon from '../../../../../expat/components/Quote/view/assets/doctor.svg';
import doctorRedIcon from '../../../../../expat/components/Quote/view/assets/doctor-red.svg';
import eyeIcon from '../../../../../expat/components/Quote/view/assets/eye.svg';
import globeIcon from '../../../../../expat/components/Quote/view/assets/globe.svg';
import heartPlusRedIcon from '../../../../../expat/components/Quote/view/assets/heart-plus-red.svg';
import medicationRedIcon from '../../../../../expat/components/Quote/view/assets/medication-red.svg';
import phoneIcon from '../../../../../expat/components/Quote/view/assets/phone.svg';
import phoneHeartIcon from '../../../../../expat/components/Quote/view/assets/phone-heart.svg';
import pillsIcon from '../../../../../expat/components/Quote/view/assets/pills.svg';
import planeIcon from '../../../../../expat/components/Quote/view/assets/plane-departure.svg';
import pregnancyIcon from '../../../../../expat/components/Quote/view/assets/pregnancy.svg';
import shieldIcon from '../../../../../expat/components/Quote/view/assets/shield.svg';
import shotRedIcon from '../../../../../expat/components/Quote/view/assets/shot-red.svg';
import therapeuticIcon from '../../../../../expat/components/Quote/view/assets/therapeutic.svg';
import toothIcon from '../../../../../expat/components/Quote/view/assets/tooth.svg';
import toothPlusIcon from '../../../../../expat/components/Quote/view/assets/tooth-plus.svg';
import toothRedIcon from '../../../../../expat/components/Quote/view/assets/tooth-red.svg';
import fastClaimsIcon from '../../../../assets/fast.svg';
import fiveYearIcon from '../../../../assets/five.svg';
import styles from './style.module.scss';

export const coverage = (
  t: TFunction,
  region: string,
  isPolicyHolder: boolean
): Array<{
  title: string;
  description: string;
  icon: { src: string; alt: string };
}> => [
  {
    title: t(
      'expatEu.qnr.quote.coverage.maximumCover.title',
      '5-year maximum coverage'
    ),
    description: isPolicyHolder
      ? t('expatEu.qnr.quote.coverage.maximumCover.description', {
          defaultValue:
            'Get settled in {{ region }} with coverage for up to 5 years from the date of your arrival.',
          region,
        })
      : t('expatEu.qnr.dependentsQuote.coverage.maximumCover.description', {
          defaultValue:
            'Get settled in {{ region }} with coverage for up to 5 years from the date of their arrival.',
          region,
        }),
    icon: {
      src: fiveYearIcon,
      alt: t(
        'expatEu.qnr.quote.coverage.maximumCover.iconAlt',
        'shield with number 5'
      ),
    },
  },
  {
    title: t(
      'expatEu.qnr.quote.coverage.fastClaims.title',
      'Fast & easy digital claims'
    ),
    description: isPolicyHolder
      ? t(
          'expatEu.qnr.quote.coverage.fastClaims.description',
          'File and manage claims online: just take a photo and submit it in your Feather account.'
        )
      : t(
          'expatEu.qnr.dependentsQuote.coverage.fastClaims.description',
          'File and manage claims online: just take a photo and submit it in their Feather account.'
        ),
    icon: {
      src: fastClaimsIcon,
      alt: t(
        'expatEu.qnr.quote.coverage.fastClaims.iconAlt',
        'a ticking timer'
      ),
    },
  },
];

export const coverageByPlan = (
  plan: QuotePlan,
  t: TFunction
): Array<{
  title: string;
  icon: { src: string; alt: string };
  description: string;
  coveredInBasic: boolean;
  id?: CoverageId;
}> => [
  {
    title: t(
      'expatEu.qnr.quote.coverageByPlan.doctorVisits.title',
      'Doctor visits & hospitalization'
    ),
    description: t(
      'expatEu.qnr.quote.coverageByPlan.doctorVisits.description',
      'Coverage for illnesses and emergencies that arise after taking out the policy. COVID-19 treatment is covered.'
    ),
    icon: {
      src: doctorIcon,
      alt: t(
        'expatEu.qnr.quote.coverageByPlan.doctorVisits.iconAlt',
        'doctor in a scrub'
      ),
    },
    coveredInBasic: true,
    id: 'DOCTOR_VISIT',
  },
  {
    title: t(
      'expatEu.qnr.quote.coverageByPlan.prescriptionDrugs.title',
      'Prescription drugs'
    ),
    description: t(
      'expatEu.qnr.quote.coverageByPlan.prescriptionDrugs.description',
      'Covered if related to an emergency or a condition that is diagnosed after the policy is purchased.'
    ),
    icon: {
      src: pillsIcon,
      alt: t(
        'expatEu.qnr.quote.coverageByPlan.prescriptionDrugs.iconAlt',
        'drug pills for medicine'
      ),
    },
    coveredInBasic: true,
  },
  {
    title: t(
      'expatEu.qnr.quote.coverageByPlan.repatriation.title',
      'Repatriation costs'
    ),
    description: t(
      'expatEu.qnr.quote.coverageByPlan.repatriation.description',
      'Full medical transport costs to home country are covered in the event of severe accident or illness.'
    ),
    icon: {
      src: planeIcon,
      alt: t(
        'expatEu.qnr.quote.coverageByPlan.repatriation.iconAlt',
        'departing airplane'
      ),
    },
    coveredInBasic: true,
    id: 'REPATRIATION',
  },
  {
    title: t(
      'expatEu.qnr.quote.coverageByPlan.euCoverage.title',
      'Coverage across the EU & Schengen area'
    ),
    description: t(
      'expatEu.qnr.quote.coverageByPlan.euCoverage.description',
      'Additional coverage in your home country for 6 weeks per year after 1 year on the policy.'
    ),
    icon: {
      src: globeIcon,
      alt: t(
        'expatEu.qnr.quote.coverageByPlan.euCoverage.iconAlt',
        'world globe showing europe'
      ),
    },
    coveredInBasic: true,
    id: 'EU_COVERAGE',
  },
  {
    title: t(
      'expatEu.qnr.quote.coverageByPlan.therapeuticMessage.title',
      'Therapeutic massage'
    ),
    description: t(
      'expatEu.qnr.quote.coverageByPlan.therapeuticMessage.description',
      'Covered if related to an emergency or a condition that is diagnosed after the policy is purchased.'
    ),
    icon: {
      src: therapeuticIcon,
      alt: t(
        'expatEu.qnr.quote.coverageByPlan.therapeuticMessage.iconAlt',
        'therapeutic massage'
      ),
    },
    coveredInBasic: true,
    id: 'THERAPEUTIC_MASSAGE',
  },
  {
    title: t(
      'expatEu.qnr.quote.coverageByPlan.dentalPain.title',
      'Dental pain relief'
    ),
    description: t('expatEu.qnr.quote.coverageByPlan.dentalPain.description', {
      defaultValue:
        'Pain relief treatments covered up to {{value}}, including simple fillings if prescribed by a dentist.',
      value: englishFormattedEuroCurrency(plan === 'PREMIUM' ? 1000 : 500, 0),
    }),
    icon: {
      src: toothIcon,
      alt: t(
        'expatEu.qnr.quote.coverageByPlan.dentalPain.iconAlt',
        'white tooth'
      ),
    },
    coveredInBasic: true,
    id: 'DENTAL_PAIN',
  },
  {
    title: t(
      'expatEu.qnr.quote.coverageByPlan.visualAids.title',
      'Visual aids'
    ),
    description: t('expatEu.qnr.quote.coverageByPlan.visualAids.description', {
      defaultValue:
        'Up to {{value}} for eyeglasses and contact lenses in cases of significant eyesight changes (-0.5 diopters).',
      value: englishFormattedEuroCurrency(200, 0),
    }),
    icon: {
      src: eyeIcon,
      alt: t('expatEu.qnr.quote.coverageByPlan.visualAids.iconAlt', 'open eye'),
    },
    coveredInBasic: false,
  },
  {
    title: t(
      'expatEu.qnr.quote.coverageByPlan.pregnancy.title',
      'Pregnancy exams and delivery'
    ),
    description: t(
      'expatEu.qnr.quote.coverageByPlan.pregnancy.description',
      'Covered if pregnancy began more than six months after the policy start date.'
    ),
    icon: {
      src: pregnancyIcon,
      alt: t(
        'expatEu.qnr.quote.coverageByPlan.pregnancy.iconAlt',
        'pregnancy baby sonar image'
      ),
    },
    coveredInBasic: false,
  },
  {
    title: t(
      'expatEu.qnr.quote.coverageByPlan.dentalCheckUps.title',
      'Dental check-ups & cleanings'
    ),
    description: t(
      'expatEu.qnr.quote.coverageByPlan.dentalCheckUps.description',
      {
        defaultValue:
          '{{value1}} for check-ups and {{value2}} for cleanings per year. Coverage starts after six months on the policy.',
        value1: englishFormattedEuroCurrency(200, true),
        value2: englishFormattedEuroCurrency(100, true),
      }
    ),
    icon: {
      src: toothPlusIcon,
      alt: t(
        'expatEu.qnr.quote.coverageByPlan.dentalCheckUps.iconAlt',
        'white tooth with plus sign'
      ),
    },
    coveredInBasic: false,
    id: 'DENTAL_CHECKUP',
  },
  {
    title: t(
      'expatEu.qnr.quote.coverageByPlan.cancerScreening.title',
      'Cancer screening'
    ),
    description: t(
      'expatEu.qnr.quote.coverageByPlan.cancerScreening.description',
      {
        defaultValue:
          'After 6 months, up to {{value}} per year for cancer screenings, 100% coverage per year for one skin cancer check.',
        value: englishFormattedEuroCurrency(300, true),
      }
    ),
    icon: {
      src: shieldIcon,
      alt: t(
        'expatEu.qnr.quote.coverageByPlan.cancerScreening.iconAlt',
        'shield with a plus sign'
      ),
    },
    coveredInBasic: false,
    id: 'CANCER_SCREENING',
  },
  {
    title: t(
      'expatEu.qnr.quote.coverageByPlan.videoAppointments.title',
      'Video appointments'
    ),
    description: t(
      'expatEu.qnr.quote.coverageByPlan.videoAppointments.description',
      '2 video calls with a doctor per year from a choice of doctors provided by our service.'
    ),
    icon: {
      src: phoneHeartIcon,
      alt: t(
        'expatEu.qnr.quote.coverageByPlan.videoAppointments.iconAlt',
        'smartphone with a heart and plus sign'
      ),
    },
    coveredInBasic: false,
  },
  {
    title: t(
      'expatEu.qnr.quote.coverageByPlan.fitnessApps.title',
      'Fitness/health apps'
    ),
    description: t('expatEu.qnr.quote.coverageByPlan.fitnessApps.description', {
      defaultValue:
        'Use of fitness/health applications covered up to {{value}} per month.',
      value: englishFormattedEuroCurrency(5, 0),
    }),
    icon: {
      src: phoneIcon,
      alt: t(
        'expatEu.qnr.quote.coverageByPlan.fitnessApps.iconAlt',
        'smartphone with a heart on screen'
      ),
    },
    coveredInBasic: false,
  },
];

export const coverageModalContent = (
  t: TFunction,
  region: string
): Record<CoverageId, { title: string; content: ReactNode }> => {
  const maxCoverageCancerScreening = englishFormattedEuroCurrency(300, true);
  const basicCoverageDentalPain = englishFormattedEuroCurrency(500, true);
  const premiumCoverageDentalPain = englishFormattedEuroCurrency(1000, true);
  const dentalCheckupsCoverage = englishFormattedEuroCurrency(200, true);
  const dentalCleaningsCoverage = englishFormattedEuroCurrency(100, true);
  const dentureCleaningsCoverage = englishFormattedEuroCurrency(1250, true);
  const maxRepatriationCoverage = englishFormattedEuroCurrency(10000, true);
  const maxTherapeuticMassageCoverage = englishFormattedEuroCurrency(300, true);

  return {
    DOCTOR_VISIT: {
      title: t(
        'expatEu.qnr.quote.modalCoverage.doctorVisits.title',
        'Doctor visits & hospitalization'
      ),
      content: (
        <Trans i18nKey="expatEu.qnr.quote.modalCoverage.doctorVisits.description">
          <div className={`ta-left mt16 ${styles['modal-content-container']}`}>
            <p className="p-p">Pre-existing conditions are not covered.</p>
            <p className="p-p mt24">COVID-19 treatment is covered.</p>
            <p className="p-p">COVID-19 vaccination is not covered.</p>
            <h4 className="p-h4 mt24">Outpatient medical treatment</h4>
            <p className="p-p">
              Visits to a doctor related to an illness or emergency that arises
              after taking out the policy.
            </p>
            <h4 className="p-h4 mt24">Inpatient treatment</h4>
            <p className="p-p">
              On a general (not private) ward (room containing several beds),
              excluding optional benefits (treatment by a private doctor).
            </p>
            <ul className={`p-p ${styles.list}`}>
              <li>Ambulance transportation for inpatient treatment</li>
              <li>Surgery</li>
              <li>Medication and bandages</li>
              <li>Radiation, light and other physical treatments</li>
            </ul>
          </div>
        </Trans>
      ),
    },
    REPATRIATION: {
      title: t(
        'expatEu.qnr.quote.modalCoverage.repatriation.title',
        'Repatriation costs'
      ),
      content: (
        <Trans
          i18nKey="expatEu.qnr.quote.modalCoverage.repatriation.content"
          value={{ region }}
        >
          <div className={`ta-left mt16 ${styles['modal-content-container']}`}>
            <p className="p-p">
              Repatriation where it makes medical sense up to 100%.
            </p>
            <p className="p-p mt24">
              Return of body to home country or burial in {{ region }}, up to{' '}
              {{ maxRepatriationCoverage }}.
            </p>
            <p className="p-p mt24">
              Follow-up cover, after the insurance cover has ended, until the
              patient is able to travel again for a maximum period of three
              months.
            </p>
          </div>
        </Trans>
      ),
    },
    EU_COVERAGE: {
      title: t(
        'expatEu.qnr.quote.modalCoverage.euCoverage.title',
        'Coverage across the EU'
      ),
      content: (
        <Trans
          i18nKey="expatEu.qnr.quote.modalCoverage.euCoverage.content"
          values={{
            region,
          }}
        >
          <div className={`ta-left mt16 ${styles['modal-content-container']}`}>
            <h4 className="p-h4">Where am I covered?</h4>
            <p className="p-p">
              With the Feather expat health insurance plan, you’re covered
              anywhere within the EU and the Schengen Area, as well as Andorra,
              Monaco, San Marino, and the Vatican City. So if intra-European
              travel is on your agenda this year, you’ll benefit from the same
              level of coverage you have in {{ region }}.
            </p>
            <p className="p-p mt24">
              You’re also covered in your home country — defined in the policy
              as the country you were resident in before moving to {{ region }}{' '}
              — for up to six weeks a year. This additional coverage is included
              once you have had an active expat policy for a minimum of one
              year. If you moved to {{ region }} from a country outside those
              listed above, you can still be reimbursed for emergency medical
              expenses while visiting.
            </p>
            <h4 className="p-h4 mt24">Where am I not covered?</h4>
            <p className="p-p">
              If you’re trying to venture further afield, or to somewhere other
              than your home country, coverage will be more limited. So if
              you’re hoping to go deep sea diving in Egypt or planning on
              spending next year’s Carnival in Brazil, you’ll need to look into
              other travel insurance options.
            </p>
            <p className="p-p mt24">
              You should be particularly careful if you’re planning European
              travel in non-EU and non-Schengen countries. While Croatia is in
              the EU, for example, neighbouring countries like Serbia and
              Albania are neither in the Schengen area nor in the EU. We would
              therefore recommend carefully researching your travel plans and
              purchasing supplemental coverage if necessary.
            </p>
          </div>
        </Trans>
      ),
    },
    THERAPEUTIC_MASSAGE: {
      title: t(
        'expatEu.qnr.quote.modalCoverage.therapeuticMassage.title',
        'Therapeutic massage'
      ),
      content: (
        <Trans i18nKey="expatEu.qnr.quote.modalCoverage.therapeuticMassage.content">
          <div className={`ta-left mt16 ${styles['modal-content-container']}`}>
            <p className="p-p">
              Covered if related to an emergency or a condition that is
              diagnosed after the policy is purchased and is prescribed by a
              doctor.
            </p>
            <p className="p-p mt24">
              Basic plan: up to {{ maxTherapeuticMassageCoverage }}
            </p>
            <p className="p-p">Premium plan: 100%</p>
          </div>
        </Trans>
      ),
    },
    DENTAL_PAIN: {
      title: t(
        'expatEu.qnr.quote.modalCoverage.dentalPain.title',
        'Dental pain relief'
      ),
      content: (
        <Trans i18nKey="expatEu.qnr.quote.modalCoverage.dentalPain.content">
          <div className={`ta-left mt16 ${styles['modal-content-container']}`}>
            <p className="p-p">
              Pain-relieving restorative dentistry including simple fillings and
              repairs of existing dentures as long as this treatment is
              performed or prescribed by a dentist.
            </p>
            <p className="p-p mt24">
              In accordance with the fee schedules explained in the terms and
              conditions.
            </p>
            <p className="p-p mt24">
              Basic plan: up to {{ basicCoverageDentalPain }}
            </p>
            <p className="p-p">
              {' '}
              Premium plan: up to {{ premiumCoverageDentalPain }}
            </p>
          </div>
        </Trans>
      ),
    },
    DENTAL_CHECKUP: {
      title: t(
        'expatEu.qnr.quote.modalCoverage.dentalCheckUps.title',
        'Dental check-ups & cleanings'
      ),
      content: (
        <Trans i18nKey="expatEu.qnr.quote.modalCoverage.dentalCheckUps.content">
          <div className={`ta-left mt16 ${styles['modal-content-container']}`}>
            <p className="p-p">
              Coverage starts after six months on the policy.
            </p>
            <p className="p-p mt24">
              In accordance with the fee schedules explained in the terms and
              conditions.
            </p>
            <p className="p-p mt24">
              {{ dentalCheckupsCoverage }} for check-ups per year
            </p>
            <p className="p-p">
              {' '}
              {{ dentalCleaningsCoverage }} for dental cleanings per year
            </p>
            <p className="p-p mt24">
              Dentures at 80% of the reimbursable invoice amount per insurance
              year, up to {{ dentureCleaningsCoverage }}.
            </p>
          </div>
        </Trans>
      ),
    },
    CANCER_SCREENING: {
      title: t(
        'expatEu.qnr.quote.modalCoverage.cancerScreening.title',
        'Cancer screening'
      ),
      content: (
        <Trans
          i18nKey="expatEu.qnr.quote.modalCoverage.cancerScreening.content"
          maxCoverageCancerScreening={maxCoverageCancerScreening}
          region={region}
        >
          <div className={`ta-left mt16 ${styles['modal-content-container']}`}>
            <p className="p-p">Covered after a six months waiting period.</p>
            <p className="p-p mt24">
              <span className="fw-bold">
                One skin cancer screening per year:
              </span>{' '}
              covered 100%
              <br />
              <span className="fw-bold">
                Screenings for early detection:
              </span>{' '}
              up to {{ maxCoverageCancerScreening }}
            </p>
            <p className="p-p mt24">
              Screenings should follow the recommended guidelines for screenings
              in {{ region }}, for example women from 50 are recommended to have
              a mammogram every 2 years.
            </p>
          </div>
        </Trans>
      ),
    },
  };
};

export const notCovered = (
  plan: QuotePlan,
  t: TFunction
): Array<{
  title: string;
  description: string;
  icon: { src: string; alt: string };
}> => [
  {
    title: t(
      'expatEu.qnr.quote.notCovered.preExistingConditions.title',
      'Pre-existing conditions'
    ),
    description: t(
      'expatEu.qnr.quote.notCovered.preExistingConditions.description',
      'Any treatments related to pre-existing conditions are not covered.'
    ),
    icon: {
      src: medicationRedIcon,
      alt: t(
        'expatEu.qnr.quote.notCovered.preExistingConditions.iconAlt',
        'red medication'
      ),
    },
  },
  {
    title: t(
      'expatEu.qnr.quote.notCovered.routineCheckUps.title',
      'Check-ups without symptoms'
    ),
    description: t(
      'expatEu.qnr.quote.notCovered.routineCheckUps.description',
      'Routine check-ups and any new conditions discovered during check-ups are not covered.'
    ),
    icon: {
      src: heartPlusRedIcon,
      alt: t(
        'expatEu.qnr.quote.notCovered.routineCheckUps.iconAlt',
        'red heart plus'
      ),
    },
  },
  {
    title: t(
      'expatEu.qnr.quote.notCovered.regularVaccination.title',
      'Regular vaccinations'
    ),
    description:
      plan === 'BASIC'
        ? t(
            'expatEu.qnr.quote.notCovered.noRegularVaccination.description',
            'Vaccinations are not covered in the basic plan.'
          )
        : t(
            'expatEu.qnr.quote.notCovered.regularVaccination.description',
            'Only travel vaccinations are covered in the premium plan.'
          ),
    icon: {
      src: shotRedIcon,
      alt: t(
        'expatEu.qnr.quote.notCovered.regularVaccination.iconAlt',
        'red vaccination shot'
      ),
    },
  },
  {
    title: t(
      'expatEu.qnr.quote.notCovered.regularDentalTreatments.title',
      'Regular dental treatments'
    ),
    description: t(
      'expatEu.qnr.quote.notCovered.regularDentalTreatments.description',
      'Dentures and major dental treatments are not covered.'
    ),
    icon: {
      src: toothRedIcon,
      alt: t(
        'expatEu.qnr.quote.notCovered.regularDentalTreatments.iconAlt',
        'red tooth'
      ),
    },
  },
  {
    title: t('expatEu.qnr.quote.notCovered.gynecology.title', 'Gynecology'),
    description: t(
      'expatEu.qnr.quote.notCovered.gynecology.description',
      'Birth control and gynecology check-ups are not covered.'
    ),
    icon: {
      src: doctorRedIcon,
      alt: t(
        'expatEu.qnr.quote.notCovered.gynecology.iconAlt',
        'red doctor with scrub'
      ),
    },
  },
  {
    title: t(
      'expatEu.qnr.quote.notCovered.mentalHealthTherapy.title',
      'Mental health therapy'
    ),
    description: t(
      'expatEu.qnr.quote.notCovered.mentalHealthTherapy.description',
      'Therapy visits are not covered.'
    ),
    icon: {
      src: brainRedIcon,
      alt: t(
        'expatEu.qnr.quote.notCovered.mentalHealthTherapy.iconAlt',
        'red brain with a heart'
      ),
    },
  },
];

export const dependentPrices = (t: TFunction) => [
  {
    age: t('expatEu.qnr.quote.dependentsPrice.age.under18', 'Under 18'),
    basic: englishFormattedEuroCurrency(72, true),
    premium: englishFormattedEuroCurrency(96, true),
  },
  {
    age: t('expatEu.qnr.quote.dependentsPrice.age.18to64', '18 - 64'),
    basic: englishFormattedEuroCurrency(72, true),
    premium: englishFormattedEuroCurrency(134, true),
  },
  {
    age: t('expatEu.qnr.quote.dependentsPrice.age.65to74', '65 - 74'),
    basic: englishFormattedEuroCurrency(258, true),
    premium: englishFormattedEuroCurrency(408, true),
  },
];
