import {
  getRoutes,
  RemoveAnswerType,
  Rule,
  stateManagerHelper,
} from '@getpopsure/qnr-framework';
import {
  removeGenericQuestionnaireAnswer,
  storeGenericQuestionnaireAnswer,
} from 'actions/genericQuestionnaire';
import routes from 'constants/routes';
import dayjs from 'dayjs';
import { useQueryParamValue } from 'hooks/useQueryParamValue';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  generatePath,
  Redirect,
  Route,
  Switch,
  useHistory,
} from 'react-router-dom';
import { AppState } from 'reducers';
import { paramsSetUrl } from 'shared/util/paramsSetUrl';
import { SignupQuestionnaire } from 'SignupQuestionnaire';

import { PetHealth } from './models';
import { breedsList } from './models/breedList';
import { PetHealthSignupTally } from './PetHealthSignupTally';
import {
  getTranslatedQuestionnaire,
  PetHealthComponents,
} from './questionnaire';

const rules: Rule<PetHealth>[] = [
  {
    id: 'intro',
    if: {
      op: 'always',
    },
    then: {
      goTo: 'species',
    },
  },
  {
    id: 'species',
    if: {
      op: 'equals',
      variable: { type: 'static', value: 'DOG' },
    },
    then: {
      goTo: 'isPureBreed',
    },
    else: {
      goTo: 'dateOfBirth',
    },
  },
  {
    id: 'isPureBreed',
    if: {
      op: 'equals',
      variable: { type: 'static', value: 'YES' },
    },
    then: {
      goTo: 'breed',
    },
    else: {
      goTo: 'shoulderHeight',
    },
  },
  {
    id: 'breed',
    if: {
      op: 'equals',
      variable: { type: 'static', value: '' },
    },
    then: {
      goTo: 'shoulderHeight',
    },
    else: {
      goTo: 'dateOfBirth',
    },
  },
  {
    id: 'breed',
    if: (breedName) => {
      return breedsList.map((breed) => breed.en).includes(breedName as string);
    },
    then: {
      goTo: 'dateOfBirth',
    },
    else: {
      goTo: 'shoulderHeight',
    },
  },
  {
    id: 'dateOfBirth',
    if: (dateOfBirth) => {
      return dayjs().diff(dayjs(dateOfBirth as string), 'weeks') >= 8;
    },
    then: {
      goTo: 'quote',
    },
    else: {
      goTo: 'tooYoungBlocker',
    },
  },
  {
    id: 'dateOfBirth',
    if: (dateOfBirth) => {
      return dayjs().diff(dayjs(dateOfBirth as string), 'years') < 9;
    },
    then: {
      goTo: 'quote',
    },
    else: {
      goTo: 'ageBlocker',
    },
  },
  {
    id: 'quote',
    if: {
      op: 'always',
    },
    then: {
      goTo: 'redirect',
    },
  },
];

const removeAnswersLogic: Partial<
  Record<keyof PetHealth, RemoveAnswerType<PetHealth>>
> = {
  isPureBreed: {
    op: 'always',
    questions: ['breed'],
  },
};

const getRedirectLocation = (
  questionnaireAnswers: Partial<PetHealth>,
  quote: PetHealth['quote']
) => {
  return paramsSetUrl(`https://tally.so/r/wveYO4`, [
    { key: 'species', value: questionnaireAnswers.species ?? '' },
    { key: 'breed', value: questionnaireAnswers.breed ?? '' },
    {
      key: 'shoulderHeight',
      value: questionnaireAnswers.shoulderHeight ?? '',
    },
    { key: 'raceGroup', value: questionnaireAnswers.raceGroup ?? '' },
    { key: 'dob', value: questionnaireAnswers.dateOfBirth ?? '' },
    {
      key: 'isDeductible',
      value: quote?.isDeductible ? '1' : '0',
    },
    {
      key: 'plan',
      value: quote?.plan ?? '',
    },
    {
      key: 'price',
      value: quote?.price ? String(quote?.price) : '',
    },
    {
      key: 'today',
      value: dayjs().format('YYYY-MM-DD'),
    },
  ]);
};

export const SignupPage = () => {
  const questionnaireAnswers =
    useSelector((state: AppState) => state.genericQuestionnaire.petHealth) ||
    {};
  const [tallySrc, setTallySrc] = useState('');
  const dispatch = useDispatch();
  const history = useHistory();
  const species = useQueryParamValue('species')?.toUpperCase();

  if (species === 'CAT' || species === 'DOG') {
    dispatch(
      storeGenericQuestionnaireAnswer('petHealth', {
        intro: true,
        species,
      })
    );

    setTimeout(() => {
      history.push(
        generatePath(routes.policies.petHealth.questionnaire.path, {
          groupId: 'pre-quote',
          questionId: species === 'CAT' ? 'date-of-birth' : 'is-pure-breed',
        })
      );
    }, 10);
  }

  const onAnswer = <QuestionId extends keyof PetHealth>(
    questionId: QuestionId,
    answer: PetHealth[QuestionId]
  ) => {
    dispatch(
      storeGenericQuestionnaireAnswer('petHealth', { [questionId]: answer })
    );

    const answersToRemove = stateManagerHelper(
      removeAnswersLogic,
      questionnaire.components,
      questionnaireAnswers,
      questionnaire.rules
    ).getAnswersToRemove(questionId, answer);

    dispatch(removeGenericQuestionnaireAnswer('petHealth', answersToRemove));

    if (questionId === 'breed') {
      const raceGroup = breedsList.find((breed) => breed.en === answer);
      if (raceGroup) {
        dispatch(
          storeGenericQuestionnaireAnswer('petHealth', {
            raceGroup: String(raceGroup.OP4),
          })
        );
      }
    } else if (questionId === 'shoulderHeight') {
      const raceGroup = answer === 'SMALLER_EQUAL_44_CM' ? '1' : '2';
      dispatch(
        storeGenericQuestionnaireAnswer('petHealth', {
          raceGroup,
        })
      );
    }

    if (questionId === 'quote') {
      setTallySrc(
        getRedirectLocation(questionnaireAnswers, answer as PetHealth['quote'])
      );

      // redirect to tally
      setTimeout(() => {
        history.push(routes.policies.petHealth.tally.path);
      }, 1500);
    }
  };

  const questions = getTranslatedQuestionnaire();

  const questionnaire = {
    components: questions,
    routes: getRoutes(questions, routes.policies.petHealth.path),
    rules,
  };

  return (
    <Switch>
      <Route strict={false} path={routes.policies.petHealth.tally.path}>
        {tallySrc ? (
          <PetHealthSignupTally src={tallySrc} />
        ) : (
          <Redirect to={routes.policies.petHealth.questionnaire.path} />
        )}
      </Route>
      <Route path={routes.policies.petHealth.questionnaire.path}>
        <SignupQuestionnaire
          questionnaireAnswers={questionnaireAnswers}
          questionnaire={questionnaire}
          onAnswer={onAnswer}
          configuration={{
            components: PetHealthComponents,
          }}
          basePath={routes.policies.petHealth.path}
          questionId="intro"
          featureName="PetHealth"
          share={{
            verticalId: 'petHealth',
          }}
        />
      </Route>
    </Switch>
  );
};
