import { useTranslation } from '@getpopsure/i18n-react';
import classNames from 'classnames';
import { TFunction } from 'shared/i18n';
import englishFormattedEuroCurrency from 'shared/util/priceFormatter';

import styles from './style.module.scss';

const MINIMUM_FRACTION_DIGIT = 0;

const replacementLimitData = (t: TFunction) => [
  {
    coverageTime: t(
      'dental.qnr.preQuote.quote.toothReplacement.advanced.table.oneYear',
      '1 year'
    ),
    noneMissingLimit: englishFormattedEuroCurrency(
      1500,
      MINIMUM_FRACTION_DIGIT
    ),
    twoMissingLimit: englishFormattedEuroCurrency(250, MINIMUM_FRACTION_DIGIT),
    threeOrMoreMissingLimit: englishFormattedEuroCurrency(
      125,
      MINIMUM_FRACTION_DIGIT
    ),
  },
  {
    coverageTime: t(
      'dental.qnr.preQuote.quote.toothReplacement.advanced.table.twoYear',
      '2 years'
    ),
    noneMissingLimit: englishFormattedEuroCurrency(
      1500,
      MINIMUM_FRACTION_DIGIT
    ),
    twoMissingLimit: englishFormattedEuroCurrency(250, MINIMUM_FRACTION_DIGIT),
    threeOrMoreMissingLimit: englishFormattedEuroCurrency(
      125,
      MINIMUM_FRACTION_DIGIT
    ),
  },
  {
    coverageTime: t(
      'dental.qnr.preQuote.quote.toothReplacement.advanced.table.threeYear',
      '3 years'
    ),
    noneMissingLimit: englishFormattedEuroCurrency(
      1500,
      MINIMUM_FRACTION_DIGIT
    ),
    twoMissingLimit: englishFormattedEuroCurrency(250, MINIMUM_FRACTION_DIGIT),
    threeOrMoreMissingLimit: englishFormattedEuroCurrency(
      125,
      MINIMUM_FRACTION_DIGIT
    ),
  },
  {
    coverageTime: t(
      'dental.qnr.preQuote.quote.toothReplacement.advanced.table.fourYear',
      '4 years'
    ),
    noneMissingLimit: englishFormattedEuroCurrency(
      1500,
      MINIMUM_FRACTION_DIGIT
    ),
    twoMissingLimit: englishFormattedEuroCurrency(5250, MINIMUM_FRACTION_DIGIT),
    threeOrMoreMissingLimit: englishFormattedEuroCurrency(
      5265,
      MINIMUM_FRACTION_DIGIT
    ),
  },
  {
    coverageTime: t(
      'dental.qnr.preQuote.quote.toothReplacement.advanced.table.fiveYear',
      '5+ years'
    ),
    noneMissingLimit: t(
      'dental.qnr.preQuote.quote.toothReplacement.advanced.table.nonMissingLimit',
      'No limits'
    ),
    twoMissingLimit: t(
      'dental.qnr.preQuote.quote.toothReplacement.advanced.table.twoMissingLimit',
      'No limits'
    ),
    threeOrMoreMissingLimit: t(
      'dental.qnr.preQuote.quote.toothReplacement.advanced.table.threeOrMoreMissingLimit',
      'No limits'
    ),
  },
];

export const ToothReplacementModal = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.wrapper}>
      <p className="p-p">
        {t(
          'dental.qnr.preQuote.quote.toothReplacement.advanced.modal.intro',
          'The advanced plan will top up the public insurance coverage and protect you against high costs for tooth replacement procedures.'
        )}
      </p>
      <h4 className="p-h4 mt16">
        {t(
          'dental.qnr.preQuote.quote.toothReplacement.advanced.modal.missingTeeth.title',
          'How many missing teeth do you currently have?'
        )}
      </h4>
      <p className="p-p mt8">
        {t(
          'dental.qnr.preQuote.quote.toothReplacement.advanced.modal.missingTeeth.description',
          'If you lose teeth after signing up, this does not affect your limits.'
        )}
      </p>
      <table className="w100 mt8">
        <thead>
          <tr>
            <th className={classNames('p-h4', styles.tableHeader)}>
              {t(
                'dental.qnr.preQuote.quote.toothReplacement.advanced.table.coveredFor',
                'Years on policy'
              )}
            </th>
            <th className={classNames('p-h4', styles.tableHeader)}>
              <div className="p-h4">
                {t(
                  'dental.qnr.preQuote.quote.toothReplacement.advanced.table.noTeethMissing',
                  '0-1 tooth missing'
                )}
              </div>
            </th>
            <th className={classNames('p-h4', styles.tableHeader)}>
              <div className="p-h4">
                {t(
                  'dental.qnr.preQuote.quote.toothReplacement.advanced.table.twoTeethMissing',
                  '2 teeth missing'
                )}
              </div>
            </th>
            <th className={classNames('p-h4', styles.tableHeader)}>
              <div className="p-h4">
                {t(
                  'dental.qnr.preQuote.quote.toothReplacement.advanced.table.threeTeethMissing',
                  '3 teeth missing'
                )}
              </div>
            </th>
          </tr>
        </thead>
        {replacementLimitData(t).map((data) => {
          const {
            coverageTime,
            noneMissingLimit,
            twoMissingLimit,
            threeOrMoreMissingLimit,
          } = data;
          return (
            <tbody key={coverageTime}>
              <tr>
                <td className={styles.tableData}>{coverageTime}</td>
                <td className={classNames('ta-center', styles.tableData)}>
                  {noneMissingLimit}
                </td>
                <td className={classNames('ta-center', styles.tableData)}>
                  {twoMissingLimit}
                </td>
                <td className={classNames('ta-center', styles.tableData)}>
                  {threeOrMoreMissingLimit}
                </td>
              </tr>
            </tbody>
          );
        })}
      </table>
      <h4 className="p-h4 mt16">
        {t(
          'dental.qnr.preQuote.quote.toothReplacement.advanced.modal.whatCounts.title',
          'What counts as missing teeth?'
        )}
      </h4>
      <p className="p-p mt8">
        {t(
          'dental.qnr.preQuote.quote.toothReplacement.advanced.modal.whatCounts.description',
          'Any adult teeth that have not been replaced through an implant or another tooth replacement procedure. Wisdom teeth are not considered as missing teeth.'
        )}
      </p>
      <h4 className="p-h4 mt16">
        {t(
          'dental.qnr.preQuote.quote.toothReplacement.advanced.modal.carryOver.title',
          'Do limits carry over?'
        )}
      </h4>
      <p className="p-p mt8">
        {t(
          'dental.qnr.preQuote.quote.toothReplacement.advanced.modal.carryOver.description',
          'Yes, any unused yearly limit will be carried over to the next year.\n\nThese limits do not apply in case of reconstructive work after an accident.'
        )}
      </p>
    </div>
  );
};
