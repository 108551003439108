import dayjs from 'dayjs';
import { PriceInfo } from 'features/paymentScreen/paymentScreen.models';
import { ReferrerCodeVerify } from 'features/referralEngine';
import { InsuranceTypes } from 'models/insurances/types';
import { getTitleMapping } from 'models/insurances/types/mapping';
import { useSafeTranslation } from 'shared/i18n';
import { formatCurrencyWithSpace } from 'shared/util/formatCurrencyWithSpace';

import { Cell } from '../Cell/Cell.view';
import styles from './style.module.scss';

const DATE_FORMAT = 'DD MMM YYYY';

export type PolicyDetailsProps = {
  startDate: string;
  priceInfo: PriceInfo;
  planId?: string;
  insuranceType: InsuranceTypes;
};

export const PolicyDetails = ({
  startDate,
  planId,
  insuranceType,
  priceInfo: { totalMonthlyPrice },
}: PolicyDetailsProps) => {
  const { t } = useSafeTranslation();

  return (
    <div className="p24 br8 bg-white bs-xs">
      <div className="p-h2 mb16">
        {t('paymentScreen.policyDetails.title', 'Policy details')}
      </div>

      <Cell
        title={t('paymentScreen.policyDetails.insuranceType', 'Insurance')}
        value={getTitleMapping(t)[insuranceType]}
      />

      {planId ? (
        <Cell
          title={t('paymentScreen.policyDetails.planId', 'Plan')}
          value={planId}
        />
      ) : null}

      <Cell
        title={t('paymentScreen.policyDetails.startDate', 'Start date')}
        value={dayjs(startDate).format(DATE_FORMAT)}
      />

      <div className={`bg-grey-200 mt32 mb32 ${styles.divider}`} />

      <div className="d-flex">
        <div className="p-h3">
          {t('paymentScreen.policyDetails.total', 'Total per month')}
        </div>
        <div className="p-h3 ml-auto">
          {formatCurrencyWithSpace(totalMonthlyPrice, 2)}
        </div>
      </div>

      <ReferrerCodeVerify />
    </div>
  );
};
