import { TFunction } from '@getpopsure/i18n-react';
import { BikeClaimsSubmitted as ClaimSubmitted } from 'features/bikeClaims/components/ClaimSubmitted';
import { BikeClaimsGettingStarted as GettingStarted } from 'features/bikeClaims/components/GettingStarted';
import { BikeClaimsPayoutDetails as PayoutDetails } from 'features/bikeClaims/components/PayoutDetails';
import { BikeClaimsProcessing as Processing } from 'features/bikeClaims/components/Processing';
import {
  BikeClaims,
  BikeClaimsMetaData,
  QuestionId,
  Questionnaire,
} from 'features/bikeClaims/models';

import { BikeClaimsFrameNumber as FrameNumber } from '../components/FrameNumber';
import { FrameNumberBlocker } from '../components/FrameNumberBlocker';
import { BikeClaimsPurchaseReceipts as PurchaseReceipts } from '../components/PurchaseReceipts';
import { PurchaseReceiptsBlocker } from '../components/PurchaseReceiptsBlocker';
import { Review } from '../components/Review';
import { BikeClaimsUploadDocuments as UploadDocuments } from '../components/UploadDocuments';
import { retrieveBikeClaimTypesMapping } from '../utils';

export const translatedQuestionnaire = (
  t: TFunction,
  metaData: BikeClaimsMetaData
): Questionnaire => ({
  intro: {
    required: true,
    type: 'CUSTOM',
    component: GettingStarted,
    metaData: {
      policyId: metaData.policyId,
    },
    retrieveAnswerObject: (
      answer: BikeClaims['intro']
    ): Partial<BikeClaims> => ({
      intro: answer,
    }),
    retrieveNextPageId: (
      _,
      questionnaireAnswers?: Partial<BikeClaims>
    ): QuestionId => {
      if (!questionnaireAnswers?.frameNumber) {
        return 'frameNumber';
      }

      const hasNoCollectedPurchaseReceipts =
        !questionnaireAnswers?.purchaseReceipts ||
        questionnaireAnswers.purchaseReceipts.length < 1;

      if (hasNoCollectedPurchaseReceipts) {
        return 'purchaseReceipts';
      }

      return 'claimType';
    },
    groupId: 'questionnaire',
    questionId: 'intro',
    requiresAuth: true,
  },

  blockerFrameNumber: {
    required: false,
    type: 'CUSTOM',
    component: FrameNumberBlocker,
    retrieveAnswerObject: (
      answer: BikeClaims['blockerFrameNumber']
    ): Partial<BikeClaims> => ({
      blockerFrameNumber: answer,
    }),
    groupId: 'questionnaire',
    questionId: 'blockerFrameNumber',
    requiresAuth: true,
  },

  blockerPurchaseReceipts: {
    required: false,
    type: 'CUSTOM',
    component: PurchaseReceiptsBlocker,
    retrieveAnswerObject: (
      answer: BikeClaims['blockerPurchaseReceipts']
    ): Partial<BikeClaims> => ({
      blockerPurchaseReceipts: answer,
    }),
    groupId: 'questionnaire',
    questionId: 'blockerPurchaseReceipts',
    requiresAuth: true,
  },

  frameNumber: {
    metaData: { questionnaireId: metaData.questionnaireId },
    required: true,
    type: 'CUSTOM',
    component: FrameNumber,
    retrieveAnswerObject: (
      answer: BikeClaims['frameNumber']
    ): Partial<BikeClaims> => ({
      frameNumber: answer,
    }),
    retrieveNextPageId: (
      _,
      questionnaireAnswers?: Partial<BikeClaims>
    ): QuestionId => {
      const hasNoCollectedPurchaseReceipts =
        !questionnaireAnswers?.purchaseReceipts ||
        questionnaireAnswers.purchaseReceipts.length < 1;

      if (hasNoCollectedPurchaseReceipts) {
        return 'purchaseReceipts';
      }
      return 'claimType';
    },
    groupId: 'questionnaire',
    questionId: 'frameNumber',
    requiresAuth: true,
  },

  purchaseReceipts: {
    metaData: { questionnaireId: metaData.questionnaireId },
    required: true,
    type: 'CUSTOM',
    component: PurchaseReceipts,
    retrieveAnswerObject: (
      answer: BikeClaims['purchaseReceipts']
    ): Partial<BikeClaims> => ({
      purchaseReceipts: answer,
    }),
    retrieveNextPageId: (): QuestionId => 'claimType',
    groupId: 'questionnaire',
    questionId: 'purchaseReceipts',
    requiresAuth: true,
  },

  claimType: {
    required: true,
    type: 'RADIO',
    props: {
      question: t(
        'claims.bike.questionnaire.claimType.question',
        'What happened to your bike?'
      ),
      mapValue: retrieveBikeClaimTypesMapping(
        metaData.planId,
        metaData.policyStartDate,
        t
      ),
    },
    retrieveAnswerObject: (
      answer: BikeClaims['claimType']
    ): Partial<BikeClaims> => ({
      claimType: answer,
    }),
    retrieveNextPageId: (): QuestionId => 'details',
    groupId: 'questionnaire',
    questionId: 'claimType',
    requiresAuth: true,
  },

  details: {
    required: true,
    type: 'TEXT',
    props: {
      question: t(
        'claims.bike.questionnaire.details.question',
        'Describe what happened'
      ),
      placeholder: t(
        'claims.bike.questionnaire.details.placeholder',
        'Description'
      ),
      additionalInfo: {
        title: t(
          'claims.bike.questionnaire.details.additionalInfo.title',
          'Describing the event'
        ),
        description: t(
          'claims.bike.questionnaire.details.additionalInfo.description',
          'Help us understand what happened to your bike. In the event of theft or accident, remember to include a detailed list of the stolen / damaged items, where it happened, and what caused it.'
        ),
      },
    },
    retrieveAnswerObject: (
      answer: BikeClaims['details']
    ): Partial<BikeClaims> => ({
      details: answer,
    }),
    retrieveNextPageId: (): QuestionId => 'documents',
    groupId: 'questionnaire',
    questionId: 'details',
    requiresAuth: true,
  },

  documents: {
    required: true,
    type: 'CUSTOM',
    component: UploadDocuments,
    metaData: { policyId: metaData.policyId },
    retrieveAnswerObject: (
      answer: BikeClaims['documents']
    ): Partial<BikeClaims> => ({
      documents: answer,
    }),
    retrieveNextPageId: () => 'payoutDetails',
    groupId: 'questionnaire',
    questionId: 'documents',
    requiresAuth: true,
  },

  payoutDetails: {
    required: true,
    type: 'CUSTOM',
    component: PayoutDetails,
    retrieveAnswerObject: (
      answer: BikeClaims['payoutDetails']
    ): Partial<BikeClaims> => ({
      payoutDetails: answer,
    }),
    retrieveNextPageId: () => 'processing',
    groupId: 'questionnaire',
    questionId: 'payoutDetails',
    requiresAuth: true,
  },

  review: {
    required: true,
    type: 'CUSTOM',
    component: Review,
    metaData: { policyId: metaData.policyId },
    retrieveAnswerObject: (
      answer: BikeClaims['review']
    ): Partial<BikeClaims> => ({
      review: answer,
    }),
    retrieveNextPageId: () => 'processing',
    groupId: 'questionnaire',
    questionId: 'review',
    requiresAuth: true,
  },

  processing: {
    required: true,
    type: 'CUSTOM',
    component: Processing,
    retrieveAnswerObject: (
      answer: BikeClaims['processing']
    ): Partial<BikeClaims> => ({
      processing: answer,
    }),
    retrieveNextPageId: (): QuestionId => 'submitted',
    groupId: 'questionnaire',
    questionId: 'processing',
    requiresAuth: true,
  },

  submitted: {
    required: true,
    type: 'CUSTOM',
    component: ClaimSubmitted,
    groupId: 'questionnaire',
    questionId: 'submitted',
    requiresAuth: true,
  },
});
