import { TFunction } from '@getpopsure/i18n-react';
import { z } from 'zod';

export type LegalSubmitClaims = {
  description: string;
  phoneConsultation: boolean;
  phoneNumber: string;
  processing: boolean;
  submitted: boolean;
};

const expectedOutcomeType = [
  'UNDERSTANDING_LEGAL_OPTIONS',
  'PREVIOUS_CONSULTATION',
  'OTHER',
] as const;

export type ExpectedOutcome = typeof expectedOutcomeType[number];

export const expectedOutcome = (
  t: TFunction
): Record<ExpectedOutcome, string> =>
  ({
    UNDERSTANDING_LEGAL_OPTIONS: t(
      'legal.qnr.claims.expectedOutcome.legalOptions',
      'Understanding my legal options'
    ),
    PREVIOUS_CONSULTATION: t(
      'legal.qnr.claims.expectedOutcome.consultation',
      'I have questions about a previous consultation'
    ),
    OTHER: t('legal.qnr.claims.expectedOutcome.otherOption', 'Other'),
  } as const);

const timeDateType = [
  'Morning_800_1200',
  'Noon_1200_1400',
  'Afternoon_1400_1800',
] as const;

type TimeDateType = typeof timeDateType[number];

export const timeDate = (t: TFunction): Record<TimeDateType, string> => ({
  Morning_800_1200: t(
    'legal.qnr.claims.timeDate.morning',
    'Morning: 8:00-12:00'
  ),
  Noon_1200_1400: t('legal.qnr.claims.timeDate.noon', 'Noon: 12:00-14:00'),
  Afternoon_1400_1800: t(
    'legal.qnr.claims.timeDate.afternoon',
    'Afternoon: 14:00-18:00'
  ),
});

export type TimeDate = typeof timeDateType[number];

export type LegalBookConsultation = {
  expectedOutcome: ExpectedOutcome;
  description: string;
  phoneNumber: string;
  timeDate: TimeDate;
  processing: string;
  submitted: boolean;
};

export const ZSubmitLegalBookConsultation = z.object({
  expectedOutcome: z.enum(expectedOutcomeType),
  description: z.string(),
  phoneNumber: z.string(),
  timeDate: z.enum(timeDateType),
});

export const ZSubmitClaimInfo = z.object({
  description: z.string(),
  phoneConsultation: z.boolean(),
  phoneNumber: z.string(),
});

const claimType = ['BOOK_A_CONSULTATION', 'LEGAL_CLAIM'] as const;
export type LegalClaimType = typeof claimType[number];
export type LegalClaimsAndConsultations = {
  claimType: LegalClaimType;
};
