import { paramsSetUrl } from '@getpopsure/public-utility';
import {
  getRoutes,
  RemoveAnswerType,
  Rule,
  stateManagerHelper,
} from '@getpopsure/qnr-framework';
import {
  flushGenericQuestionnaire,
  removeGenericQuestionnaireAnswer,
  storeGenericQuestionnaireAnswer,
} from 'actions/genericQuestionnaire';
import routes from 'constants/routes';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { AppState } from 'reducers';
import { SignupQuestionnaire } from 'SignupQuestionnaire';

import { TravelHealthV1 } from './models';
import {
  getTranslatedQuestionnaire,
  TravelHealthV1Components,
} from './questionnaire';

const rules: Rule<TravelHealthV1>[] = [
  {
    id: 'cover',
    if: {
      op: 'equals',
      variable: { type: 'static', value: 'MYSELF_AND_OTHERS' },
    },
    then: {
      goTo: 'numberOfCovered',
    },
    else: {
      goTo: 'dateOfBirth',
    },
  },
  {
    id: 'numberOfCovered',
    if: {
      op: 'greaterThan',
      variable: { type: 'static', value: 8 },
    },
    then: {
      goTo: 'numberOfCoveredBlocker',
    },
  },
  {
    id: 'dateOfBirth',
    if: {
      op: 'dateDiff',
      variable: {
        type: 'year',
        value: 65,
      },
    },
    then: {
      goTo: 'ageBlocker',
    },
  },
  {
    id: 'dateOfBirth',
    if: {
      op: 'dateDiff',
      variable: {
        type: 'year',
        value: 18,
      },
    },
    then: {
      goTo: 'isLivingInGermany',
    },
    else: {
      goTo: 'ageBlocker',
    },
  },
  {
    id: 'isLivingInGermany',
    if: {
      op: 'equals',
      variable: {
        type: 'static',
        value: false,
      },
    },
    then: {
      goTo: 'germanyBlocker',
    },
  },
  {
    id: 'name',
    if: (_value, answers) => {
      return answers.cover === 'MYSELF_AND_OTHERS';
    },
    then: {
      goTo: 'othersCovered',
    },
  },
];

const removeReview: RemoveAnswerType<TravelHealthV1> = {
  op: 'always',
  questions: ['review'],
};

const removeAnswersLogic: Partial<
  Record<keyof TravelHealthV1, RemoveAnswerType<TravelHealthV1>>
> = {
  cover: { op: 'always', questions: ['othersCovered', 'review'] },
  numberOfCovered: { op: 'always', questions: ['othersCovered'] },
  isLivingInGermany: removeReview,
  quote: removeReview,
  name: removeReview,
  dateOfBirth: removeReview,
  startDate: removeReview,
};

const getTallySrc = (questionnaireAnswers: Partial<TravelHealthV1>) => {
  const baseParams = [
    { key: 'firstName', value: questionnaireAnswers.name?.firstName ?? '' },
    { key: 'lastName', value: questionnaireAnswers.name?.lastName ?? '' },
    { key: 'dateOfBirth', value: questionnaireAnswers.dateOfBirth ?? '' },
    { key: 'startDate', value: questionnaireAnswers.startDate ?? '' },
    {
      key: 'numberOfCovered',
      value: questionnaireAnswers.numberOfCovered
        ? String(questionnaireAnswers.numberOfCovered)
        : '',
    },
    { key: 'cover', value: questionnaireAnswers.cover ?? 'MYSELF' },
    {
      key: 'price',
      value: questionnaireAnswers.quote?.price
        ? String(questionnaireAnswers.quote.price)
        : '',
    },
    {
      key: 'monthlyPrice',
      value: questionnaireAnswers.quote?.price
        ? String(Math.ceil((questionnaireAnswers.quote.price / 12) * 100) / 100)
        : '',
    },
    {
      key: 'plan',
      value: questionnaireAnswers.quote?.plan ?? 'BASIC',
    },
    {
      key: 'today',
      value: dayjs().format('YYYY-MM-DD'),
    },
  ];

  const otherCoveredParams = questionnaireAnswers.othersCovered
    ? questionnaireAnswers.othersCovered.flatMap((covered, index) => [
        { key: `coveredFirstName${index + 1}`, value: covered.firstName },
        { key: `coveredLastName${index + 1}`, value: covered.lastName },
        { key: `coveredDob${index + 1}`, value: covered.dateOfBirth },
      ])
    : [];

  return paramsSetUrl(
    `https://tally.so/r/wALNNN`,
    baseParams.concat(otherCoveredParams)
  );
};

export const SignupPage = () => {
  const questionnaireAnswers =
    useSelector(
      (state: AppState) => state.genericQuestionnaire.travelHealthV1
    ) || {};
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(flushGenericQuestionnaire('travelHealthV1'));
    };
  }, [dispatch]);

  const onAnswer = <QuestionId extends keyof TravelHealthV1>(
    questionId: QuestionId,
    answer: TravelHealthV1[QuestionId],
    additionalData?: Partial<TravelHealthV1>
  ) => {
    dispatch(
      storeGenericQuestionnaireAnswer('travelHealthV1', {
        [questionId]: answer,
      })
    );

    const answersToRemove = stateManagerHelper(
      removeAnswersLogic,
      questionnaire.components,
      questionnaireAnswers,
      questionnaire.rules
    ).getAnswersToRemove(questionId, answer);

    dispatch(
      removeGenericQuestionnaireAnswer('travelHealthV1', answersToRemove)
    );

    if (questionId === 'quote' && additionalData) {
      dispatch(
        storeGenericQuestionnaireAnswer('travelHealthV1', additionalData)
      );
    }
  };

  const questions = getTranslatedQuestionnaire(
    questionnaireAnswers.cover,
    getTallySrc(questionnaireAnswers)
  );

  const questionnaire = {
    components: questions,
    routes: getRoutes(questions, routes.policies.travelHealth.path),
    rules,
  };

  return (
    <Switch>
      <Route path={routes.policies.travelHealth.questionnaire.path}>
        <SignupQuestionnaire
          questionnaireAnswers={questionnaireAnswers}
          questionnaire={questionnaire}
          onAnswer={onAnswer}
          configuration={{
            components: TravelHealthV1Components,
          }}
          basePath={routes.policies.travelHealth.path}
          questionId="intro"
          featureName="TravelHealthV1"
        />
      </Route>
    </Switch>
  );
};
