import { updateBikeClaimsFrameNumber } from 'features/bikeClaims/actions';
import { BikeClaims, BikeClaimsDispatch } from 'features/bikeClaims/models';
import { useRequestStatus } from 'hooks/useRequestStatus';
import { CustomComponentProps } from 'models/questionnaireFramework';
import { FormEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSafeTranslation } from 'shared/i18n';

import { BikeClaimsFrameNumberView } from './FrameNumber.view';

export const BikeClaimsFrameNumber = ({
  value,
  onSubmitValue,
  routes,
  metaData: { questionnaireId, policyId },
}: CustomComponentProps<BikeClaims>) => {
  const { t } = useSafeTranslation();
  const dispatch = useDispatch<BikeClaimsDispatch>();

  const { loading, error } = useRequestStatus('UPDATE_QUESTIONNAIRE');

  const [frameNumber, setFrameNumber] = useState<string>(
    (value as string) ?? ''
  );

  const validForSubmission = Boolean(frameNumber && frameNumber.length > 0);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    if (!validForSubmission) {
      throw new Error(
        `[Bike Claims] Frame number is missing for submission for policy id: ${policyId}`
      );
    }

    const responseStatus = await dispatch(
      updateBikeClaimsFrameNumber({
        questionnaireId,
        frameNumber,
      })
    );

    if (responseStatus === 'SUCCESS') {
      onSubmitValue(frameNumber);
    }
  };

  return (
    <BikeClaimsFrameNumberView
      handleSubmit={handleSubmit}
      t={t}
      validForSubmission={validForSubmission}
      setFrameNumber={setFrameNumber}
      frameNumber={frameNumber}
      blockerRoute={routes.blockerFrameNumber.path}
      loading={loading}
      error={error?.message}
    />
  );
};
