import { ValidVerticalUrlParams } from 'models/insurances/types';
import { useSafeTranslation } from 'shared/i18n';
import { isMobileApp } from 'shared/util/isMobileApp';

type ImprovementBoxProps = {
  vertical: ValidVerticalUrlParams | 'private';
};

export const ImprovementBox = ({ vertical }: ImprovementBoxProps) => {
  const { t } = useSafeTranslation();
  return (
    <a
      className="mt24 mb24 p-a c-pointer fw-bold tc-primary-500"
      href={
        isMobileApp
          ? `https://tally.so/r/wbxYE1?vertical=${vertical}`
          : `https://tally.so/r/mO5bXY?vertical=${vertical}`
      }
    >
      {t('components.improvementBox.title', 'Anything we can improve?')}
    </a>
  );
};
