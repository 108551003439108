import { FC } from 'react';

import { ClaimAddMissingInfo } from '../modules/ClaimAddMissingInfo';
import { GenericTask } from '../modules/GenericTask';
import { LiabilityAddressUpdate } from '../modules/LiabilityAddressUpdate';
import { PrivateHealthAddressUpdate } from '../modules/PrivateHealthAddressUpdate/PrivateHealthAddressUpdate';
import { PrivateHealthPassportInfoUpdate } from '../modules/PrivateHealthPassportInfoUpdate';
import { PrivateHealthRelocationDateUpdate } from '../modules/PrivateHealthRelocationDateUpdate';
import { PrivateHealthTaxIdUpdate } from '../modules/PrivateHealthTaxIdUpdate';
import { UpdateBikeFrameNumber } from '../modules/UpdateBikeFrameNumber';
import { Task, TaskEngineOptions, TaskType } from '../taskEngine.models';

export const getMappedComponents = (
  tasks: Task[],
  options?: TaskEngineOptions
): Array<JSX.Element> => {
  const componentDictionary: Record<
    TaskType,
    FC<{ task: Task; options?: TaskEngineOptions }>
  > = {
    BIKE_FRAME_NUMBER_UPDATE: UpdateBikeFrameNumber,
    CLAIM_MISSING_INFO_REDIRECT: ClaimAddMissingInfo,
    LIABILITY_ADDRESS_UPDATE: LiabilityAddressUpdate,
    PRIVATE_HEALTH_TAX_ID_UPDATE: PrivateHealthTaxIdUpdate,
    PRIVATE_HEALTH_RELOCATION_DATE_UPDATE: PrivateHealthRelocationDateUpdate,
    PRIVATE_HEALTH_ADDRESS_UPDATE: PrivateHealthAddressUpdate,
    PRIVATE_HEALTH_PASSPORT_INFO_UPDATE: PrivateHealthPassportInfoUpdate,
    GENERIC: GenericTask,
  };

  const componentsArray: Array<JSX.Element | null> = tasks.map((task) => {
    const Component = componentDictionary[task.description.type] || null;

    /**
     * Fall back to null if the task is not implemented yet.
     */
    if (!Component) return null;
    return <Component key={task.id} task={task} options={options} />;
  });

  /**
   * Filter all null values from the mapped components array.
   */
  return componentsArray.filter((e): e is JSX.Element => e !== null);
};
