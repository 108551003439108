import {
  fetchMailingPreferences,
  updateMailingPreferences,
} from 'actions/mailingPreferences';
import LoadingSpinner from 'components/loadingSpinner';
import { useRequestStatus } from 'hooks/useRequestStatus';
import { UpdateMailingPreference } from 'models/mailingPreferences';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'reducers';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { getNewsletterSubscription } from 'selectors/mailingPreferences';

import MailingPreferencesView from './MailingPreferences.view';

export const MailingPreferences = () => {
  const newsletterSubscription = useSelector(getNewsletterSubscription);
  const { error, loading } = useRequestStatus('ACCOUNT');
  const dispatch = useDispatch<ThunkDispatch<void, AppState, AnyAction>>();

  const updatePreferences = (
    options: UpdateMailingPreference
  ): Promise<void> => {
    return dispatch(updateMailingPreferences(options));
  };

  useEffect(() => {
    dispatch(fetchMailingPreferences());
  }, [dispatch]);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <MailingPreferencesView
      error={error}
      loading={loading}
      updateMailingPreferences={updatePreferences}
      newsletterSubscription={newsletterSubscription}
    />
  );
};
