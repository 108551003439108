import { allLocales, detectLocale, init } from '@getpopsure/i18n-react';
import * as Sentry from '@sentry/browser';
import { SeverityLevel } from '@sentry/browser';
import { cookieDomainOptions, getI18nOptions, getTranslations } from 'locales';
import { isMobileApp } from 'shared/util/isMobileApp';
import { isStagingOrDev } from 'shared/util/isStagingOrDev';

export const initI18n = async () => {
  const translations = await getTranslations();

  return init({
    phrase: {
      projectId: 'd65143b60b56ebec0b18595d2957fc8b',
      accountId: 'a3bede45c3de7842738aad10ad418b4c',
      allowInContextEditor: isStagingOrDev,
      inContextEditorOptions: {
        autoLowercase: false,
      },
    },
    supportedLngs: isMobileApp
      ? ['en']
      : [...allLocales, ...(isStagingOrDev ? ['xx', 'cimode'] : [])],
    fallbackLng: 'en',
    resources: translations,
    saveMissing: true,
    lng: detectLocale({
      allowDebugLanguages: isStagingOrDev,
      ...cookieDomainOptions,
    }),
    missingKeyHandler: (_lng, _ns, key) => {
      const { translationSource } = getI18nOptions();

      if (translationSource !== 'CODE') {
        return;
      }

      if (isStagingOrDev) {
        // eslint-disable-next-line no-console
        console.info(`[Translation Log] Missing key: ${key}`);
      }

      if (process.env.NODE_ENV !== 'development') {
        const severityLevel: SeverityLevel = 'error';
        Sentry.captureMessage(
          `[Translation Sentry] Missing key: ${key}`,
          severityLevel
        );
      }
    },
  });
};
