import { Address, allCountryCodes, Country } from '@getpopsure/public-models';
import { CheckoutInfo } from 'features/checkout/models';
import { Name } from 'models/user';
import { ActionResponse } from 'shared/models/types';
import { ZAddressSchema, ZNameSchema } from 'shared/models/validations';
import { centimetersToFeet, kgToLbs } from 'shared/util/converters';
import { BLOCKER, SCREEN } from 'SignupQuestionnaire';
import { z } from 'zod';

import { getJobCollar } from '../constants/profession';

export type CollarClassification = 'A' | 'B' | 'C' | 'X';

const OCCUPATIONS = [
  'EMPLOYED',
  'SELF_EMPLOYED',
  'STUDENT',
  'UNEMPLOYED',
  'OTHER',
] as const;

export type Occupation = typeof OCCUPATIONS[number];

const DEGREES = [
  'NO_DEGREE',
  'HIGH_SCHOOL',
  'UNIVERSITY_SHORT_TERM',
  'UNIVERSITY_LONG_TERM',
] as const;

export type Degree = typeof DEGREES[number];

export const JOB_COLLARS = ['A', 'B', 'C', 'X'] as const;

const ProfessionSchema = z.object({
  label: z.string(),
  value: z.string(),
});

export const ZQuestionnaireAnswersRequiredForCalculatorSchema = z.object({
  dateOfBirth: z.string(),
  profession: ProfessionSchema,
  occupation: z.enum(OCCUPATIONS),
  monthlyNetIncome: z.number(),
  height: z.number(),
  weight: z.number(),
});

export type QuestionnaireType = 'DISABILITY' | 'DISABILITY_SCREENED_OUT';

export const ZQuoteDto = z.object({
  dateOfBirth: z.string(),
  jobCollar: z.enum(JOB_COLLARS),
  monthlyPayout: z.number(),
  heightInCm: z.number(),
  weightInKg: z.number(),
});

export type QuoteDto = z.TypeOf<typeof ZQuoteDto>;

export const ZQuote = z.object({
  price: z.number(),
  id: z.string(),
});

export type Quote = z.TypeOf<typeof ZQuote>;

export type CheckoutPolicyDetails = unknown;

// TODO: define the required  quote schema
export type CreateQuoteReturnType = {
  status: ActionResponse;
};

export type Profession = z.infer<typeof ProfessionSchema>;

export type DisabilityV2 = {
  intro: SCREEN;
  dateOfBirth: string;
  ageYoungerThan18Blocker: boolean;
  ageOlderThan50Blocker: boolean;

  occupation: Occupation;
  occupationBlocker: BLOCKER;
  profession: Profession;

  monthlyNetIncome: number;
  monthlyNetIncomeBlocker: BLOCKER;

  degree: Degree;
  height: number;
  weight: number;

  monthlyPayout: number;
  quote: Quote;
  tallyForm: SCREEN;

  signupIntro: never;
  name: Name;
  email: string;
  citizenship: Country[];
  employer: string;
  startDate: string;
  stayAbroad: boolean;
  stayAbroadDetails: string;
  address: Address;

  // Insurance history
  hasPreviousInsurance: boolean;
  previousInsuranceDetails: string;

  hasActiveOrPendingInsurance: boolean;
  activeOrPendingInsuranceDetails: string;

  dynamicPremiums: boolean;

  // Medical History
  isSmoker: boolean;

  // Degree of disability
  hasDegreeOfDisability: boolean;
  degreeOfDisabilityHealthIssuesDetails: string;
  degreeOfDisabilityPercentage: string;
  degreeOfDisabilityDuration: string;

  // Physical Examination
  hasReceivedPhysicalExam: boolean;

  // Prescribed medication
  hasPrescribedMedication: boolean;
  prescribedMedicationDetails: string;
  prescribedMedicationDosage: string;
  prescribedMedicationDuration: string;
  prescribedMedicationReason: string;

  // Sick leave > 14 days
  hasConsecutiveSickLeave: boolean;
  sickLeaveReason: string;
  sickLeaveDuration: string;
  hasSickLeaveClearance: boolean;

  hasReceivedSubstanceAbuseTreatment: boolean;

  // Nervous or musculoskeletal issues
  hasTreatmentForNervousOrMusculoskeletalIssues: boolean;
  nervousOrMusculoskeletalIssuesDetails: string;
  nervousOrMusculoskeletalIssuesTreatmentDuration: string;
  isCuredFromNervousOrMusculoskeletalTreatment: boolean;

  hasTreatmentForHeartTumorKidneyDiabetes: boolean;

  // Medical aids
  hasUsedOrPrescribedMedicalAids: boolean;
  medicalAidsDetails: string;
  medicalAidPrescriptionReason: string;
  isMedicalAidStillUsed: boolean;
  // TODO: add validation for date (in the past)
  medicalAidPrescriptionStartDate: string;

  // Psychotherapy treatment details
  hasReceivedPsychoTherapy: boolean;
  psychoTherapyDetails: string;
  isStillReceivingPsychoTherapy: boolean;
  isCuredFromPsychoTherapy: boolean;
  psychoTherapyEndDate: string;

  hasReceivedHivTreatment: boolean;

  // Hospitalization
  hasBeenHospitalized: boolean;
  hospitalizationReason: string;
  hospitalizationDuration: string;
  isIllnessCured: boolean;

  // Extreme sports
  participatesInRockClimbing: boolean;
  rockClimbingDetails: string;

  participatesInScubaDiving: boolean;
  scubaDivingDetails: string;

  participatesInBoulderingAndMountainSports: boolean;
  participatesInExtremeMountainSports: boolean;

  participatesInMartialArts: boolean;
  participatesInCompetitiveMartialArts: boolean;

  participatesInHorseRiding: boolean;
  participatesInEquestrianSports: boolean;

  participatesInCompetitiveRacing: boolean;

  participatesInAviationSports: boolean;

  postSignupBlocker: BLOCKER;

  review: boolean;

  hasDownloadedDocs: boolean;
  downloadDocuments: boolean;

  hasEligibilityCheck: boolean;

  checkoutInfo: CheckoutInfo<CheckoutPolicyDetails>;
  reviewCheckout: boolean;
  checkout: boolean;

  jobCollar: CollarClassification;
  quoteId: string;
  questionnaireId?: string;
};

const ZCountrySchema = z.object({
  code: z.enum(allCountryCodes),
  name: z.string(),
});

export const ZDisabilityV2Questionnaire = z.object({
  name: ZNameSchema,
  email: z.string(),
  dateOfBirth: z.string(),
  occupation: z.enum(OCCUPATIONS),
  profession: ProfessionSchema,
  monthlyNetIncome: z.number(),
  degree: z.enum(DEGREES),
  height: z.number(),
  weight: z.number(),
  monthlyPayout: z.number(),
  quote: ZQuote,
  citizenship: z.array(ZCountrySchema),
  employer: z.string(),
  startDate: z.string(),
  stayAbroad: z.boolean(),
  stayAbroadDetails: z.string().optional(),
  address: ZAddressSchema,

  hasPreviousInsurance: z.boolean(),
  previousInsuranceDetails: z.string().optional(),

  hasActiveOrPendingInsurance: z.boolean(),
  activeOrPendingInsuranceDetails: z.string().optional(),

  dynamicPremiums: z.boolean(),

  isSmoker: z.boolean(),

  hasDegreeOfDisability: z.boolean(),
  degreeOfDisabilityHealthIssuesDetails: z.string().optional(),
  degreeOfDisabilityPercentage: z.string().optional(),
  degreeOfDisabilityDuration: z.string().optional(),

  hasReceivedPhysicalExam: z.boolean(),

  hasPrescribedMedication: z.boolean().optional(),
  prescribedMedicationDetails: z.string().optional(),
  prescribedMedicationDosage: z.string().optional(),
  prescribedMedicationDuration: z.string().optional(),
  prescribedMedicationReason: z.string().optional(),

  hasConsecutiveSickLeave: z.boolean().optional(),
  sickLeaveReason: z.string().optional(),
  sickLeaveDuration: z.string().optional(),
  hasSickLeaveClearance: z.boolean().optional(),

  hasReceivedSubstanceAbuseTreatment: z.boolean(),

  hasTreatmentForNervousOrMusculoskeletalIssues: z.boolean(),
  nervousOrMusculoskeletalIssuesDetails: z.string().optional(),
  nervousOrMusculoskeletalIssuesTreatmentDuration: z.string().optional(),
  isCuredFromNervousOrMusculoskeletalTreatment: z.boolean().optional(),

  hasTreatmentForHeartTumorKidneyDiabetes: z.boolean().optional(),

  hasUsedOrPrescribedMedicalAids: z.boolean().optional(),
  medicalAidsDetails: z.string().optional(),
  medicalAidPrescriptionReason: z.string().optional(),
  isMedicalAidStillUsed: z.boolean().optional(),
  medicalAidPrescriptionStartDate: z.string().optional(),

  hasReceivedPsychoTherapy: z.boolean(),
  psychoTherapyDetails: z.string().optional(),
  isStillReceivingPsychoTherapy: z.boolean().optional(),
  isCuredFromPsychoTherapy: z.boolean().optional(),
  psychoTherapyEndDate: z.string().optional(),

  hasReceivedHivTreatment: z.boolean(),

  hasBeenHospitalized: z.boolean(),
  hospitalizationReason: z.string().optional(),
  hospitalizationDuration: z.string().optional(),
  isIllnessCured: z.boolean().optional(),

  participatesInRockClimbing: z.boolean(),
  rockClimbingDetails: z.array(z.string()).optional(),

  participatesInScubaDiving: z.boolean(),
  scubaDivingDetails: z.array(z.string()).optional(),

  participatesInBoulderingAndMountainSports: z.boolean(),
  participatesInExtremeMountainSports: z.boolean().optional(),

  participatesInMartialArts: z.boolean(),
  participatesInCompetitiveMartialArts: z.boolean().optional(),

  participatesInHorseRiding: z.boolean(),
  participatesInEquestrianSports: z.boolean().optional(),

  participatesInCompetitiveRacing: z.boolean(),

  participatesInAviationSports: z.boolean(),
});

export const ZSubmittableDisabilityV2Questionnaire =
  ZDisabilityV2Questionnaire.transform((data) => {
    const featherJobCollar = getJobCollar(data.profession.value);
    const squarelifeJobCollar =
      featherJobCollar === 'X' ? 'C' : featherJobCollar;

    return {
      personalInfo: {
        name: data.name,
        email: data.email,
        dateOfBirth: data.dateOfBirth,
        occupation: data.occupation,
        profession: data.profession.value,
        jobCollar: {
          featherJobCollar,
          squarelifeJobCollar,
        },
        monthlyNetIncome: data.monthlyNetIncome,
        degree: data.degree,
        height: {
          inCm: data.height,
          inFt: centimetersToFeet(data.height),
        },
        weight: {
          inKg: data.weight,
          inLbs: kgToLbs(data.weight),
        },
        citizenship: data.citizenship,
        stayAbroad: data.stayAbroad,
        address: data.address,
        extremeSports: {
          participatesInRockClimbing: data.participatesInRockClimbing,
          ...(data.participatesInRockClimbing && {
            rockClimbingDetails: data.rockClimbingDetails,
          }),

          participatesInScubaDiving: data.participatesInScubaDiving,
          ...(data.participatesInScubaDiving && {
            scubaDivingDetails: data.scubaDivingDetails,
          }),

          participatesInBoulderingAndMountainSports:
            data.participatesInBoulderingAndMountainSports,
          ...(data.participatesInBoulderingAndMountainSports && {
            participatesInExtremeMountainSports:
              data.participatesInExtremeMountainSports,
          }),

          participatesInMartialArts: data.participatesInMartialArts,
          ...(data.participatesInMartialArts && {
            participatesInCompetitiveMartialArts:
              data.participatesInCompetitiveMartialArts,
          }),

          participatesInHorseRiding: data.participatesInHorseRiding,
          ...(data.participatesInHorseRiding && {
            participatesInEquestrianSports: data.participatesInEquestrianSports,
          }),

          participatesInCompetitiveRacing: data.participatesInCompetitiveRacing,

          participatesInAviationSports: data.participatesInAviationSports,
        },
      },
      insuranceHistory: {
        hasPreviousInsurance: data.hasPreviousInsurance,
        ...(data.hasPreviousInsurance && {
          previousInsuranceDetails: data.previousInsuranceDetails,
        }),

        hasActiveOrPendingInsurance: data.hasActiveOrPendingInsurance,
        ...(data.hasActiveOrPendingInsurance && {
          activeOrPendingInsuranceDetails: data.activeOrPendingInsuranceDetails,
        }),
        dynamicPremiums: data.dynamicPremiums,
      },
      medicalHistory: {
        isSmoker: data.isSmoker,

        hasDegreeOfDisability: data.hasDegreeOfDisability,
        ...(data.hasDegreeOfDisability && {
          degreeOfDisabilityHealthIssuesDetails:
            data.degreeOfDisabilityHealthIssuesDetails,
          degreeOfDisabilityPercentage: data.degreeOfDisabilityPercentage,
          degreeOfDisabilityDuration: data.degreeOfDisabilityDuration,
        }),

        hasReceivedPhysicalExam: data.hasReceivedPhysicalExam,

        hasPrescribedMedication: data.hasPrescribedMedication,
        ...(data.hasPrescribedMedication && {
          prescribedMedicationDetails: data.prescribedMedicationDetails,
          prescribedMedicationDosage: data.prescribedMedicationDosage,
          prescribedMedicationDuration: data.prescribedMedicationDuration,
          prescribedMedicationReason: data.prescribedMedicationReason,
        }),

        hasConsecutiveSickLeave: data.hasConsecutiveSickLeave,

        ...(data.hasConsecutiveSickLeave && {
          sickLeaveReason: data.sickLeaveReason,
          sickLeaveDuration: data.sickLeaveDuration,
          hasSickLeaveClearance: data.hasSickLeaveClearance,
        }),

        hasReceivedSubstanceAbuseTreatment:
          data.hasReceivedSubstanceAbuseTreatment,
        hasTreatmentForNervousOrMusculoskeletalIssues:
          data.hasTreatmentForNervousOrMusculoskeletalIssues,
        ...(data.hasTreatmentForNervousOrMusculoskeletalIssues && {
          nervousOrMusculoskeletalIssuesDetails:
            data.nervousOrMusculoskeletalIssuesDetails,
          nervousOrMusculoskeletalIssuesTreatmentDuration:
            data.nervousOrMusculoskeletalIssuesTreatmentDuration,
          isCuredFromNervousOrMusculoskeletalTreatment:
            data.isCuredFromNervousOrMusculoskeletalTreatment,
        }),

        hasTreatmentForHeartTumorKidneyDiabetes:
          data.hasTreatmentForHeartTumorKidneyDiabetes,

        hasUsedOrPrescribedMedicalAids: data.hasUsedOrPrescribedMedicalAids,
        ...(data.hasUsedOrPrescribedMedicalAids && {
          medicalAidsDetails: data.medicalAidsDetails,
          medicalAidPrescriptionReason: data.medicalAidPrescriptionReason,
          isMedicalAidStillUsed: data.isMedicalAidStillUsed,
          medicalAidPrescriptionStartDate: data.medicalAidPrescriptionStartDate,
        }),

        hasReceivedPsychoTherapy: data.hasReceivedPsychoTherapy,
        ...(data.hasReceivedPsychoTherapy && {
          psychoTherapyDetails: data.psychoTherapyDetails,
          isStillReceivingPsychoTherapy: data.isStillReceivingPsychoTherapy,
          isCuredFromPsychoTherapy: data.isCuredFromPsychoTherapy,
          psychoTherapyEndDate: data.psychoTherapyEndDate,
        }),

        hasReceivedHivTreatment: data.hasReceivedHivTreatment,

        hasBeenHospitalized: data.hasBeenHospitalized,
        ...(data.hasBeenHospitalized && {
          hospitalizationReason: data.hospitalizationReason,
          hospitalizationDuration: data.hospitalizationDuration,
          isIllnessCured: data.isIllnessCured,
        }),
      },
      tariffInfo: {
        price: data.quote.price,
        quoteId: data.quote.id,
        monthlyPayout: data.monthlyPayout,
        employer: data.employer,
        startDate: data.startDate,
        plan: 'featherBu',
      },
    };
  });

export type DisabilityV2Questionnaire = z.infer<
  typeof ZDisabilityV2Questionnaire
>;
export type SubmittableDisabilityV2Questionnaire = z.infer<
  typeof ZSubmittableDisabilityV2Questionnaire
>;

export type DisabilityV2GroupIds = 'preQuote' | 'signup';

type QuestionId = keyof DisabilityV2Questionnaire;

export const questionOrderPreQuote: QuestionId[] = [
  'dateOfBirth',
  'occupation',
  'profession',
  'degree',
  'height',
  'weight',
];

export const questionOrderPersonalInfo: QuestionId[] = [
  'name',
  'email',
  'citizenship',
  'stayAbroad',
  'stayAbroadDetails',
  'address',
];

export const questionOrderInsuranceHistory: QuestionId[] = [
  'hasPreviousInsurance',
  'previousInsuranceDetails',
  'hasActiveOrPendingInsurance',
  'activeOrPendingInsuranceDetails',
  'dynamicPremiums',
];

export const questionOrderMedicalHistory: QuestionId[] = [
  'isSmoker',
  'hasDegreeOfDisability',
  'degreeOfDisabilityHealthIssuesDetails',
  'degreeOfDisabilityPercentage',
  'degreeOfDisabilityDuration',
  'hasReceivedPhysicalExam',
  'hasPrescribedMedication',
  'prescribedMedicationDetails',
  'prescribedMedicationDosage',
  'prescribedMedicationDuration',
  'prescribedMedicationReason',
  'hasConsecutiveSickLeave',
  'sickLeaveReason',
  'sickLeaveDuration',
  'hasSickLeaveClearance',
  'hasReceivedSubstanceAbuseTreatment',
  'hasTreatmentForNervousOrMusculoskeletalIssues',
  'nervousOrMusculoskeletalIssuesDetails',
  'nervousOrMusculoskeletalIssuesTreatmentDuration',
  'isCuredFromNervousOrMusculoskeletalTreatment',
  'hasTreatmentForHeartTumorKidneyDiabetes',
  'hasUsedOrPrescribedMedicalAids',
  'medicalAidsDetails',
  'medicalAidPrescriptionReason',
  'isMedicalAidStillUsed',
  'medicalAidPrescriptionStartDate',
  'hasReceivedPsychoTherapy',
  'psychoTherapyDetails',
  'isStillReceivingPsychoTherapy',
  'isCuredFromPsychoTherapy',
  'psychoTherapyEndDate',
  'hasReceivedHivTreatment',
  'hasBeenHospitalized',
  'hospitalizationReason',
  'hospitalizationDuration',
  'isIllnessCured',
];

export const questionOrderExtremeSports: QuestionId[] = [
  'participatesInRockClimbing',
  'rockClimbingDetails',
  'participatesInScubaDiving',
  'scubaDivingDetails',
  'participatesInBoulderingAndMountainSports',
  'participatesInExtremeMountainSports',
  'participatesInMartialArts',
  'participatesInCompetitiveMartialArts',
  'participatesInHorseRiding',
  'participatesInEquestrianSports',
  'participatesInCompetitiveRacing',
  'participatesInAviationSports',
];

export const questionOrderTariffInfo: QuestionId[] = [
  'startDate',
  'employer',
  'monthlyPayout',
];

export const questionOrder = {
  personalInfo: [
    ...questionOrderPreQuote,
    ...questionOrderPersonalInfo,
    ...questionOrderExtremeSports,
  ],
  insuranceHistory: questionOrderInsuranceHistory,
  medicalHistory: questionOrderMedicalHistory,
  tariffInfo: questionOrderTariffInfo,
};

export const TitleSchema = z.object({
  tariffInfo: z.record(z.string()),
  personalInfo: z.record(z.string()),
  insuranceHistory: z.record(z.string()),
  medicalHistory: z.record(z.string()),
});

export const AnswersLabelSchema = z.object({
  personalInfo: z.record(z.record(z.string())),
});

export const LabelsSchema = z.object({
  title: TitleSchema,
  answers: AnswersLabelSchema,
});

export type QuestionnaireTitles = z.infer<typeof TitleSchema>;
export type QuestionnaireLabels = z.infer<typeof LabelsSchema>;

const QuestionOrderSchema = z.object({
  tariffInfo: z.array(z.string()),
  personalInfo: z.array(z.string()),
  insuranceHistory: z.array(z.string()),
  medicalHistory: z.array(z.string()),
});

export const MetadataSchema = z.object({
  labels: LabelsSchema,
  questionOrder: QuestionOrderSchema,
  source: z.record(z.string(), z.unknown()).optional(),
});

export type MetaData = z.infer<typeof MetadataSchema>;

const degreesMap = {
  NO_DEGREE: 'No degree',
  HIGH_SCHOOL: 'High school',
  UNIVERSITY_SHORT_TERM: 'University short term',
  UNIVERSITY_LONG_TERM: 'University long term',
};

const occupationMap = {
  EMPLOYED: 'Employed',
  SELF_EMPLOYED: 'Self-employed\\ Freelancer',
  STUDENT: 'Student',
  UNEMPLOYED: 'Unemployed',
  OTHER: 'Other',
};

export const labelAnswers = {
  personalInfo: {
    degree: degreesMap,
    occupation: occupationMap,
    rockClimbingDetails: {
      OVER_4000_METERS_ALTITUDE: 'I go over 4000m of altitude',
      NO_RECOMMENDED_SAFETY_EQUIPMENT:
        'I do not use recommended safety equipment',
      ICE_OR_GLACIER_CLIMBING: 'I climb ice or glaciers',
      NONE_OF_THE_ABOVE: 'None of the above',
    },
    scubaDivingDetails: {
      DEEPER_THAN_50_METERS: 'I go deeper than 50m',
      CAVE_OR_ICE_DIVING: 'I go diving in caves or ice',
      REBREATHER_OR_SOLO_DIVING: 'I go diving with a rebreather or solo diving',
      NONE_OF_THE_ABOVE: 'None of the above',
    },
  },
  medicalHistory: {},
  insuranceHistory: {},
  tariffInfo: {},
};
