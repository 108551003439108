import { TFunction } from '@getpopsure/i18n-react';
import { calendly } from '@getpopsure/private-constants';
import { SignupQuestionnaireType } from 'SignupQuestionnaire';

import { GetStarted } from '../components/GetStarted/GetStarted';
import { InfoScreen } from '../components/InfoScreen/InfoScreen';
import { Quote } from '../components/quote';
import {
  InvestmentStrategyMap,
  InvestmentSustainabilityMap,
  Pension,
  PensionGroupIds,
} from '../models';

export const PensionComponents = {
  GET_STARTED: GetStarted,
  INFO_SCREEN: InfoScreen,
  QUOTE: Quote,
};

export type PensionQuestionnaire = SignupQuestionnaireType<
  Pension,
  PensionGroupIds,
  typeof PensionComponents
>;

export const getTranslatedQuestionnaire = (
  t: TFunction
): PensionQuestionnaire => [
  {
    id: 'quote',
    required: true,
    type: 'QUOTE',
    props: {},
    screen: {
      layout: 'Standalone',
    },
    groupId: 'signup',
  },
  {
    id: 'intro',
    groupId: 'signup',
    type: 'GET_STARTED',
    required: true,
    props: {},
    screen: {
      layout: 'Standalone',
    },
  },
  // Investment strategy
  {
    id: 'investmentSustainability',
    groupId: 'signup',
    type: 'RADIO',
    required: true,
    screen: {
      question: t(
        'pension.qnr.investmentSustainability.title',
        'How sustainable is your investment approach?'
      ),
      additionalInfo: {
        title: t(
          'pension.qnr.investmentSustainability.additionalInfo.title',
          'What are the ESG best practices?'
        ),
        description: t(
          'pension.qnr.investmentSustainability.additionalInfo.description',
          'ESG stands for environmental, social, and governance. We use these practices to guide your investment strategy, evaluating companies on their environmental, social, and governance responsibility to determine their suitability for investment.'
        ),
      },
    },
    props: {
      mapValue: InvestmentSustainabilityMap(t),
    },
    validations: [
      {
        op: 'Custom',
        fn: (answer: unknown) => {
          return answer !== 'GREEN_INVESTING';
        },
        msg: () => {
          return {
            type: 'Error',
            msg: t(
              'pension.qnr.investmentStrategy.errorMessage',
              "We can't offer a 100% green product with low fees. If you're interested in a 100% green product, [please schedule a call]({{calendlyUrl}}).",
              {
                calendlyUrl: calendly.pensionInsurance,
              }
            ),
          };
        },
      },
    ],
  },
  {
    id: 'investmentStrategy',
    groupId: 'signup',
    type: 'RADIO',
    required: true,
    screen: {
      question: t(
        'pension.qnr.investmentStrategy.title',
        'Which investment strategy would you like to choose?'
      ),
      additionalInfo: {
        title: t(
          'pension.qnr.investmentStrategy.additionalInfo.title',
          'Can I change my investment strategy later?'
        ),
        description: t(
          'pension.qnr.investmentStrategy.additionalInfo.description',
          'Yes, you can change your investment strategy at any time. [This article]({{articleUrl}}) explains in detail how we determine our investment strategy',
          {
            // TODO: update the article URL
            articleUrl: 'https://feather-insurance.com/todo',
          }
        ),
      },
    },
    props: {
      mapValue: InvestmentStrategyMap(t),
    },
  },
  {
    id: 'savingForRetirement',
    groupId: 'signup',
    type: 'BOOLEAN',
    required: true,
    screen: {
      question: t(
        'pension.qnr.savingForRetirement.title',
        'Do you want to have private pension insurance to save for retirement?'
      ),
    },
    props: {},
  },
  {
    id: 'privatePensionInfo',
    groupId: 'signup',
    type: 'INFO_SCREEN',
    screen: {
      layout: 'Standalone',
    },
    props: {
      title: t(
        'pension.qnr.privatePensionInfo.title',
        'We recommend private pension insurance only if you plan to use it for your retirement.'
      ),
    },
  },
  {
    id: 'privatePensionLongerThan5Years',
    groupId: 'signup',
    required: true,
    type: 'BOOLEAN',
    screen: {
      question: t(
        'pension.qnr.privatePensionLongerThan5Years.title',
        'Do you plan to keep private pension insurance more than 5 years?'
      ),
    },
    props: {},
  },
  {
    id: 'privatePensionLongerThan5YearsInfo',
    groupId: 'signup',
    type: 'INFO_SCREEN',
    screen: {
      layout: 'Standalone',
    },
    props: {
      title: t(
        'pension.qnr.privatePensionLongerThan5YearsInfo.title',
        'We recommend private pension insurance only if you plan to keep for more than 5 years. '
      ),
    },
  },
  {
    id: 'hasReviewedInvestmentStrategyInfo',
    required: true,
    type: 'REVIEW',
    groupId: 'signup',
    props: {
      requestType: 'REVIEW_SUBMIT_INFO',
      reviewValues: [
        {
          title: t(
            'pension.qnr.hasReviewedInvestmentStrategyInfo.investmentSustainability.title',
            'Investment approach'
          ),
          value: {
            type: 'String',
            key: 'investmentSustainability',
            valueMap: InvestmentSustainabilityMap(t),
          },
          path: 'investmentSustainability',
        },
        {
          title: t(
            'pension.qnr.hasReviewedInvestmentStrategyInfo.investmentStrategy.title',
            'Investment strategy'
          ),
          value: {
            type: 'String',
            key: 'investmentStrategy',
            valueMap: {
              ALL_IN: 'All-in',
              AGGRESSIVE: 'Aggressive',
            },
          },
          path: 'investmentStrategy',
        },
        {
          title: t(
            'pension.qnr.hasReviewedInvestmentStrategyInfo.savingForRetirement.title',
            'Use this product for retirement?'
          ),
          value: { type: 'Boolean', key: 'savingForRetirement' },
          path: 'savingForRetirement',
        },
        {
          title: t(
            'pension.qnr.hasReviewedInvestmentStrategyInfo.privatePensionInfo.title',
            'Confirm you wish to continue?'
          ),
          value: {
            type: 'Boolean',
            key: 'privatePensionInfo',
            valueMap: {
              true: 'I confirm',
              false: 'I do not confirm',
            },
          },
          path: 'privatePensionInfo',
        },
        {
          title: t(
            'pension.qnr.hasReviewedInvestmentStrategyInfo.privatePensionLongerThan5Years.title',
            'Keep for more than 5 years?'
          ),
          value: { type: 'Boolean', key: 'privatePensionLongerThan5Years' },
          path: 'privatePensionLongerThan5Years',
        },
        {
          title: t(
            'pension.qnr.hasReviewedInvestmentStrategyInfo.privatePensionLongerThan5YearsInfo.title',
            'Confirm you wish to continue?'
          ),
          value: {
            type: 'Boolean',
            key: 'privatePensionLongerThan5YearsInfo',
            valueMap: {
              true: 'I confirm',
              false: 'I do not confirm',
            },
          },
          path: 'privatePensionLongerThan5YearsInfo',
        },
      ],
      verticalId: 'pension',
      insuranceType: 'PENSION',
    },
    screen: {
      layout: 'Standalone',
    },
  },
];
