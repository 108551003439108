import { Region } from '@getpopsure/public-models';
import { InsuranceTypes } from 'models/insurances/types';
import { FC, useState } from 'react';

import { PolicyModal } from '../../components/PolicyModal';

export interface UsePolicyModalReturn {
  isModalOpen: boolean;
  openModal: (type: InsuranceTypes, region?: Region) => void;
  closeModal: () => void;
  PolicyModal: FC;
}

export const usePolicyModal = (): UsePolicyModalReturn => {
  const [activeInsuranceType, setActiveInsuranceType] =
    useState<InsuranceTypes | null>(null);
  const [activeRegion, setActiveRegion] = useState<Region | null>(null);
  const isModalOpen = Boolean(activeInsuranceType);

  const openModal = (type: InsuranceTypes, region?: Region) => {
    setActiveInsuranceType(type);
    setActiveRegion(region || null);
  };

  const closeModal = () => {
    setActiveInsuranceType(null);
  };

  const ModalComponent: React.FC = () =>
    !activeInsuranceType ? null : (
      <PolicyModal
        isOpen={isModalOpen}
        onClose={closeModal}
        insuranceType={activeInsuranceType}
        region={activeRegion}
      />
    );

  return {
    isModalOpen,
    openModal,
    closeModal,
    PolicyModal: ModalComponent,
  };
};
