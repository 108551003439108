import { Card } from '@popsure/dirty-swan';
import classNames from 'classnames';

import styles from './TaskCard.module.scss';

export interface TaskCardProps {
  onClick: () => void;
  title: string;
  subtitle: string;
}

export const TaskCard = (props: TaskCardProps) => {
  const { title, subtitle, onClick } = props;

  return (
    <Card
      actionIcon={null}
      classNames={{
        wrapper: 'bg-primary-100 ',
        description: classNames(styles.description, 'tc-grey-600'),
        icon: classNames(styles.icon, 'mr8'),
      }}
      description={subtitle}
      dropShadow={false}
      icon={
        <img
          alt=""
          src="https://assets.cdn.feather-insurance.com/assets/images/arrowRightCirclePurple.svg"
        />
      }
      onClick={onClick}
      title={title}
      titleVariant="medium"
    />
  );
};
