import { InsuranceTypes } from 'models/insurances/types';
import { TFunction } from 'shared/i18n';

import { StatusBadgeColorClass } from '../components/StatusBadge/StatusBadge';
import amexIcon from '../icons/amex.png';
import cartesbancairesIcon from '../icons/cartesbancaires.png';
import mastercardIcon from '../icons/mastercard.png';
import paypalIcon from '../icons/paypal.png';
import sepaIcon from '../icons/sepa.png';
import unionIcon from '../icons/union.png';
import visaIcon from '../icons/visa.png';
import {
  Billing,
  BillingStatus,
  BillingType,
  DisputeInvoice,
  PaymentBrands,
  PaymentType,
} from '../models';

export const mapBillingType: Record<BillingType, string> = {
  PARTIAL_PAYMENT: 'Partial charge',
  MONTHLY_PAYMENT: 'Monthly payment',
  BALANCE_CORRECTION: 'Balance correction',
  DISPUTE_FEE: 'Disputed payment',
};

export const mapBillingStatus = (
  t: TFunction
): Record<BillingStatus, string> => ({
  SUCCEEDED: t('billingHistory.billingStatus.succeeded', 'Succeeded'),
  CANCELED: t('billingHistory.billingStatus.canceled', 'Canceled'),
  PROCESSING: t('billingHistory.billingStatus.processing', 'Processing'),
  REQUIRES_ACTION: t(
    'billingHistory.billingStatus.requiresActionFailed',
    'Failed'
  ),
  DISPUTED: t('billingHistory.billingStatus.disputed', 'Disputed'),
  DISPUTED_AND_OUTSTANDING: t(
    'billingHistory.billingStatus.disputedOutstanding',
    'Disputed and outstanding'
  ),
  DISPUTE_LOST: t('billingHistory.billingStatus.disputeLost', 'Dispute lost'),
  REQUIRES_PAYMENT_METHOD: t(
    'billingHistory.billingStatus.requiresPaymentMethodFailed',
    'Failed'
  ),
  REFUNDED: t('billingHistory.billingStatus.refunded', 'Refunded'),
  PARTIALLY_REFUNDED: t(
    'billingHistory.billingStatus.partiallyrefunded',
    'Partially refunded'
  ),
  DRAFT: t('billingHistory.billingStatus.draft', 'Requires Action'),
});

const cardPaymentBrandIcons: Record<PaymentBrands, string> = {
  mastercard: mastercardIcon,
  visa: visaIcon,
  cartes_bancaires: cartesbancairesIcon,
  amex: amexIcon,
  unionpay: unionIcon,
};

const nonCardPaymentBrandIcons: Record<Exclude<PaymentType, 'card'>, string> = {
  sepa_debit: sepaIcon,
  paypal: paypalIcon,
};

export const mapStatusBadgeColors: Record<
  BillingStatus,
  StatusBadgeColorClass
> = {
  SUCCEEDED: 'bg-green-100',
  CANCELED: 'bg-pink-100',
  PROCESSING: 'bg-blue-100',
  REQUIRES_ACTION: 'bg-pink-100',
  DISPUTED: 'bg-yellow-100',
  DISPUTED_AND_OUTSTANDING: 'bg-grey-200',
  DISPUTE_LOST: 'bg-grey-200',
  REQUIRES_PAYMENT_METHOD: 'bg-pink-100',
  REFUNDED: 'bg-grey-200',
  PARTIALLY_REFUNDED: 'bg-grey-200',
  DRAFT: 'bg-primary-900',
};

export const mapUserFriendlyInsuranceName = (
  t: TFunction
): Record<InsuranceTypes | 'DISPUTE_FEE', string> => ({
  PRIVATE_HEALTH: t(
    'billingHistory.insuranceName.privateHealth',
    'Private health insurance'
  ),
  HOUSEHOLD: t('billingHistory.insuranceName.household', 'Household insurance'),
  LIABILITY: t('billingHistory.insuranceName.liability', 'Liability insurance'),
  LIFE: t('billingHistory.insuranceName.life', 'Life insurance'),
  LIFE_V2: t('billingHistory.insuranceName.life', 'Life insurance'),
  PUBLIC_HEALTH: t(
    'billingHistory.insuranceName.publicHealth',
    'Public health insurance'
  ),
  INCOMING: t('billingHistory.insuranceName.expat', 'Expat health insurance'),
  EXPAT_V2: t('billingHistory.insuranceName.expat', 'Expat health insurance'),
  BIKE: t('billingHistory.insuranceName.bike', 'Bike insurance'),
  CAR: t('billingHistory.insuranceName.car', 'Car insurance'),
  JOB: t('billingHistory.insuranceName.job', 'Job insurance'),
  LEGAL: t('billingHistory.insuranceName.legal', 'Legal insurance'),
  LEGAL_V2: t('billingHistory.insuranceName.legal', 'Legal insurance'),
  DENTAL: t('billingHistory.insuranceName.dental', 'Dental insurance'),
  DENTAL_V2: t('billingHistory.insuranceName.dental', 'Dental insurance'),
  DOG_LIABILITY: t(
    'billingHistory.insuranceName.dogLiability',
    'Dog liability insurance'
  ),
  DISABILITY: t(
    'billingHistory.insuranceName.disability',
    'Disability insurance'
  ),
  PET_HEALTH: t(
    'billingHistory.insuranceName.petHealth',
    'Pet health insurance'
  ),
  GENERIC: t('billingHistory.insuranceName.generic', 'Generic insurance'),
  TRAVEL: t('billingHistory.insuranceName.travel', 'Travel insurance'),
  COMPANY_LIFE: t('billingHistory.insuranceName.companyLife', 'Life insurance'),
  COMPANY_HEALTH: t(
    'billingHistory.insuranceName.companyHealth',
    'Company health insurance'
  ),
  PENSION: t('billingHistory.insuranceName.pension', 'Pension insurance'),
  BASIS_PENSION: t(
    'billingHistory.insuranceName.basisPension',
    'Basis pension insurance'
  ),
  INCOMING_ES: t(
    'billingHistory.insuranceName.expatSpain',
    'Expat health insurance'
  ),
  TRAVEL_HEALTH_V1: t(
    'billingHistory.insuranceName.TravelHealthV1',
    'TravelHealthV1 insurance'
  ),
  TRAVEL_FR: t('billingHistory.insuranceName.travelFrance', 'Travel insurance'),
  DISPUTE_FEE: t('billingHistory.disputeInvoice.disputeFee', 'Dispute Fee'),
  BIKE_V2: t('billingHistory.insuranceName.bike', 'Bike insurance'),
  INCOMING_EU: t('billingHistory.insuranceName.ExpatEu', 'Expat EU insurance'),
  INCOMING_LT: t(
    'billingHistory.insuranceName.expatLongTerm',
    'Expat long term insurance'
  ),
  DISABILITY_V2: t(
    'billingHistory.insuranceName.DisabilityV2',
    'Disability insurance'
  ),
  COMPANY_PENSION: t(
    'billingHistory.insuranceName.companyPension',
    'Company pension insurance'
  ),
});

export const retrieveRefundStatus = (
  amount: number,
  refundAmount?: number
): 'REFUNDED' | 'PARTIALLY_REFUNDED' | null => {
  if (refundAmount && amount === refundAmount) {
    return 'REFUNDED';
  }

  if (refundAmount && amount > refundAmount) {
    return 'PARTIALLY_REFUNDED';
  }

  return null;
};

export const updateBillingStatusForRefunds = (billing: Billing): Billing => {
  const { paidAmount: amount } = billing;
  const refundAmount = billing.refundDetails?.refundAmount;

  const refundStatus = retrieveRefundStatus(amount, refundAmount);

  if (refundStatus === 'REFUNDED') {
    return { ...billing, status: 'REFUNDED' };
  }

  if (refundStatus === 'PARTIALLY_REFUNDED') {
    return { ...billing, status: 'PARTIALLY_REFUNDED' };
  }

  return billing;
};

export const shouldGenerateReceipt = (billingInfo: Billing): boolean =>
  ['SUCCEEDED', 'REFUNDED', 'PARTIALLY_REFUNDED'].includes(billingInfo.status);

export const getPaymentMethodIcon = ({
  paymentMethodDetails,
}: Billing | DisputeInvoice): string => {
  if (paymentMethodDetails.type === 'card') {
    return cardPaymentBrandIcons[paymentMethodDetails.provider];
  }

  return nonCardPaymentBrandIcons[paymentMethodDetails.type];
};
