import { trackFaqItemOpened } from '@getpopsure/analytics';
import { ChevronDownIcon } from '@popsure/dirty-swan';
import classnames from 'classnames';
import Markdown from 'components/markdown';
import SectionHeader from 'components/sectionHeader';
import React, { useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { useSafeTranslation } from 'shared/i18n';

import styles from './style.module.scss';

export interface FAQInfo {
  question: string;
  answer: string;
  questionId: number;
}

interface Props {
  data: FAQInfo[];
  children?: React.ReactNode;
  title?: string;
  markdownClassName?: string;
}

const FAQSection = ({
  data,
  children,
  title,
  markdownClassName = '',
}: Props) => {
  const { t } = useSafeTranslation();
  const [selectedQuestionId, setSelectedQuestionId] = useState<number | null>(
    null
  );

  const handleClick = (questionId: number, question: string) => {
    if (questionId === selectedQuestionId) {
      setSelectedQuestionId(null);
    } else {
      trackFaqItemOpened({ question });
      setSelectedQuestionId(questionId);
    }
  };

  return (
    <>
      <SectionHeader
        centeredOnMobile
        title={title || t('components.faq.title', 'Frequently asked questions')}
      />
      <div className="mt32">
        {data.map(({ question, answer, questionId }) => (
          <div
            className={`mt24 pb24 ${styles['question-container']}`}
            key={question}
          >
            <button
              className="ds-interactive-component w100 d-flex ai-center jc-between"
              onClick={() => handleClick(questionId, question)}
              type="button"
            >
              <h4 className="p-h4 tc-grey-700 ta-left">{question}</h4>
              <ChevronDownIcon
                className={classnames({
                  [styles['chevron-animation']]:
                    questionId === selectedQuestionId,
                })}
                color="grey-500"
                size={20}
                noMargin
              />
            </button>
            <AnimateHeight
              duration={300}
              height={selectedQuestionId === questionId ? 'auto' : 0}
            >
              <Markdown
                className={`mt24 ${styles.markdown} ${markdownClassName}`}
                paragraphClassName="p-p tc-grey-600"
              >
                {answer}
              </Markdown>
            </AnimateHeight>
          </div>
        ))}
      </div>
      {children && <div className="mt32">{children}</div>}
    </>
  );
};

export default FAQSection;
