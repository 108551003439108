import { Address } from '@getpopsure/public-models';
import {
  StudentChecklistDispatch,
  updateChecklist,
} from 'features/studentChecklist/actions';
import { useRequestStatus } from 'hooks/useRequestStatus';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { EStudentChecklistRequestTypes, StepProps } from '../../models';
import { getChecklistAddress, getQuestionnaireAddress } from '../../selectors';
import { GermanAddressView } from './GermanAddress.view';

export const GermanAddress = (props: StepProps) => {
  const { completed } = props;
  const { policyId } = useParams<{ policyId: string }>();
  const { loading, error } = useRequestStatus(
    EStudentChecklistRequestTypes.UPDATE
  );
  const questionnaireAddress = useSelector(getQuestionnaireAddress(policyId));
  const address = useSelector(getChecklistAddress(policyId));
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useDispatch<StudentChecklistDispatch>();

  /**
   * Component clean-up
   */
  useEffect(() => {
    return () => {
      setOpenModal(false);
    };
  }, []);

  const handleOpenModal = () => setOpenModal(true);

  const handleCloseModal = () => setOpenModal(false);

  const handleSaveAddress = async (newAddress: Address) => {
    const payload = {
      address: {
        ...newAddress,
        additionalInformation: newAddress?.additionalInformation || '',
      },
    };

    const status = await dispatch(updateChecklist(policyId, payload));

    if (status === 'SUCCESS') setOpenModal(false);
  };

  return (
    <GermanAddressView
      address={address}
      completed={completed}
      error={error?.message}
      loading={loading}
      openModal={openModal}
      questionnaireAddress={questionnaireAddress}
      handleSaveAddress={handleSaveAddress}
      onOpenModal={handleOpenModal}
      onCloseModal={handleCloseModal}
    />
  );
};
