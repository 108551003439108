import {
  BottomOrRegularModal,
  Button,
  FileTextIcon,
  InformationBox,
  Input,
} from '@popsure/dirty-swan';
import classNames from 'classnames';
import Markdown from 'components/markdown';
import type {
  DetailedHTMLProps,
  FormEvent,
  InputHTMLAttributes,
  LabelHTMLAttributes,
  TextareaHTMLAttributes,
} from 'react';
import { useState } from 'react';
import { createPortal } from 'react-dom';

import { GenericTaskMetadata } from '../../taskEngine.models';
import { TaskCard } from '../TaskCard';
import styles from './CustomTask.module.scss';
import { type AddressInputProps, AddressInput } from './inputs/AddressInput';
import {
  CurrencyInputTask,
  CurrencyInputTaskProps,
} from './inputs/CurrencyInput';
import { type DateInputProps, DateInput } from './inputs/DateInput';
import { type FileInputProps, FileInput } from './inputs/FileInput';

type ActionAttributes =
  | {
      type: 'INPUT';
      attributes: Omit<
        DetailedHTMLProps<
          InputHTMLAttributes<HTMLInputElement>,
          HTMLInputElement
        >,
        'ref'
      >;
    }
  | {
      type: 'TEXTAREA';
      attributes: DetailedHTMLProps<
        TextareaHTMLAttributes<HTMLTextAreaElement>,
        HTMLTextAreaElement
      >;
    }
  | {
      type: 'DATE';
      attributes: Omit<DateInputProps, 'onValidate'>;
    }
  | {
      type: 'ADDRESS';
      attributes?: AddressInputProps;
    }
  | {
      type: 'CURRENCY';
      attributes: Omit<CurrencyInputTaskProps, 'onValidate'>;
    }
  | {
      type: 'FILE';
      attributes?: FileInputProps;
    }
  | {
      type: 'LABEL';
      attributes: Omit<
        DetailedHTMLProps<
          LabelHTMLAttributes<HTMLLabelElement>,
          HTMLLabelElement
        >,
        'ref'
      >;
    }
  | {
      type: 'LINK';
      attributes: {
        href: string;
      };
    }
  | {
      type: 'DOWNLOAD';
      attributes: {
        file: {
          fileName: string;
          url: string;
        };
      };
    }
  | {
      type: 'SUBMIT';
      attributes: {
        value: string;
      };
    };

export type TaskAction = ActionAttributes & {
  id: string;
};

export interface CustomTaskProps {
  metadata?: GenericTaskMetadata;
  actions?: Array<TaskAction>;
  modal: {
    isOpen: boolean;
    loading?: boolean;
    error?: string | null;

    onOpen(): void;
    onClose(): void;
  };

  onSubmit(value: FormEvent<HTMLFormElement>): void;
}

const EXPLICITLY_VALIDATED_ACTIONS = [
  'ADDRESS',
  'CURRENCY',
  'DATE',
  'FILE',
] as ActionAttributes['type'][];

export const CustomTask = (props: CustomTaskProps) => {
  const { modal, metadata, actions, onSubmit } = props;
  const { onOpen, onClose, isOpen, loading, error } = modal;

  /**
   * This validation applies to actions that use feather custom components, i.e. Address, Currency, etc.
   * Action types not included here are validated by the native HTML validator.
   */
  const initialValidation = (actions || [])
    .filter((action) => EXPLICITLY_VALIDATED_ACTIONS.includes(action.type))
    .reduce((acc: Record<string, boolean>, action) => {
      return {
        ...acc,
        [action.id]: false,
      };
    }, {});
  const [validationObject, setValidationObj] = useState(initialValidation);

  const valid = Object.values(validationObject).every((value) => value);

  const onValidate = (id: string, validInput: boolean) =>
    setValidationObj((prev) => ({
      ...prev,
      [id]: validInput,
    }));

  return (
    <>
      <TaskCard
        onClick={onOpen}
        title={metadata?.title || ''}
        subtitle={metadata?.subtitle || ''}
      />
      {isOpen &&
        createPortal(
          <BottomOrRegularModal
            title={metadata?.title || ''}
            isOpen
            onClose={onClose}
          >
            <div
              className={`w100 wmx8 pt16 pr24 pb24 pl24 ${styles.container}`}
            >
              {metadata?.subtitle && (
                <h2 className="p-h3 tc-grey-500 mb16">{metadata.subtitle}</h2>
              )}
              {metadata?.description && (
                <Markdown className="mb16">{metadata?.description}</Markdown>
              )}

              <form onSubmit={onSubmit}>
                {actions?.map((action) => {
                  switch (action.type) {
                    case 'ADDRESS':
                      return (
                        <div
                          key={action.id}
                          className={styles.userActionWrapper}
                        >
                          <AddressInput
                            {...action.attributes}
                            onValidate={(isValid) =>
                              onValidate(action.id, isValid)
                            }
                          />
                        </div>
                      );

                    case 'TEXTAREA':
                      return (
                        <textarea
                          key={action.id}
                          className={`p-input wmn3 p8 ${styles.userActionWrapper} ${styles.textarea}`}
                          {...action.attributes}
                        />
                      );
                    case 'INPUT':
                      return (
                        <Input
                          key={action.id}
                          className={styles.userActionWrapper}
                          {...action.attributes}
                        />
                      );
                    case 'DATE':
                      return (
                        <div
                          key={action.id}
                          className={styles.userActionWrapper}
                        >
                          <DateInput
                            onValidate={(isValid) =>
                              onValidate(action.id, isValid)
                            }
                            {...action.attributes}
                            yearBoundaries={
                              action.attributes?.yearBoundaries || {
                                min: new Date().getFullYear() - 50,
                                max: new Date().getFullYear() + 50,
                              }
                            }
                            displayCalendar={
                              action.attributes?.displayCalendar || true
                            }
                          />
                        </div>
                      );

                    case 'CURRENCY':
                      return (
                        <CurrencyInputTask
                          key={action.id}
                          className={styles.userActionWrapper}
                          {...action.attributes}
                          onValidate={(isValid) =>
                            onValidate(action.id, isValid)
                          }
                        />
                      );
                    case 'FILE':
                      return (
                        <div
                          className={styles.userActionWrapper}
                          key={action.id}
                        >
                          <FileInput
                            {...action.attributes}
                            onValidate={(isValid) => {
                              onValidate(action.id, isValid);
                            }}
                          />
                        </div>
                      );
                    case 'LABEL':
                      return (
                        <div
                          className={styles.userActionWrapper}
                          key={action.id}
                        >
                          <label
                            {...action.attributes}
                            htmlFor={action.attributes.htmlFor}
                            className={styles.userActionWrapper}
                          />
                        </div>
                      );
                    case 'LINK':
                      return (
                        <div
                          key={action.id}
                          className={styles.userActionWrapper}
                        >
                          <a
                            className={classNames(
                              'p-p p-link tc-purple-500 pb8 d-block',
                              styles.link
                            )}
                            href={action.attributes.href}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {action.attributes.href}
                          </a>
                        </div>
                      );
                    case 'DOWNLOAD':
                      return (
                        <div
                          key={action.id}
                          className={styles.userActionWrapper}
                        >
                          <a
                            className={classNames(
                              'p-p d-block br8 d-flex ai-center p16 mb16',
                              styles.file
                            )}
                            href={action.attributes.file.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <FileTextIcon
                              size={24}
                              className="mr12 tc-grey-500"
                            />
                            {action.attributes.file.fileName}
                          </a>
                        </div>
                      );
                    case 'SUBMIT':
                      return (
                        <Button
                          key={action.id}
                          type="submit"
                          loading={loading}
                          className={styles.userActionWrapper}
                          disabled={!valid}
                        >
                          {action.attributes.value}
                        </Button>
                      );

                    default:
                      return null;
                  }
                })}
                {error && (
                  <InformationBox variant="error" className="mt16">
                    {error}
                  </InformationBox>
                )}
              </form>
            </div>
          </BottomOrRegularModal>,
          document.body
        )}
    </>
  );
};
