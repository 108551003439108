import { insurance } from '@getpopsure/private-constants';
import { FAQInfo } from 'components/faq';
import brokenBikeIcon from 'features/liability/icons/broken-bike.svg';
import brokenGlassIcon from 'features/liability/icons/broken-glass.svg';
import catIcon from 'features/liability/icons/cat.svg';
import eurosIcon from 'features/liability/icons/euros.svg';
import houseIcon from 'features/liability/icons/house.svg';
import houseGreyIcon from 'features/liability/icons/house-grey.svg';
import keyIcon from 'features/liability/icons/key.svg';
import lockIcon from 'features/liability/icons/lock.svg';
import phoneIcon from 'features/liability/icons/phone.svg';
import shieldIcon from 'features/liability/icons/shield.svg';
import { CardInfo } from 'models/bike';
import { InclusiveMember } from 'models/liability';
import { TFunction } from 'shared/i18n';
import englishFormattedEuroCurrency from 'shared/util/priceFormatter';

export const getInclusiveMembersInfo: (t: TFunction) => Array<{
  title: string;
  value: InclusiveMember;
}> = (t) => [
  {
    title: t(
      'page.liability.quote.calculator.inclusivemember.partner',
      'Partner'
    ),
    value: 'PARTNER',
  },
  {
    title: t('page.liability.quote.calculator.inclusivemember.kids', 'Kid(s)'),
    value: 'KIDS',
  },
  {
    title: t(
      'page.liability.quote.calculator.inclusivemember.parents',
      'Parent(s)'
    ),
    value: 'PARENTS',
  },
];

export const getCardContent: (t: TFunction) => CardInfo[] = (t) => [
  {
    title: t(
      'page.liability.quote.infocard.1.title',
      'Feather coverage guarantee'
    ),
    description: t(
      'page.liability.quote.infocard.1.description',
      'We’ll cover any claim that would be covered by other liability insurances.'
    ),
    icon: { src: shieldIcon, alt: 'check shield' },
  },
  {
    title: t(
      'page.liability.quote.infocard.2.title',
      'Insured up to €50 million'
    ),
    description: t(
      'page.liability.quote.infocard.2.description',
      "Full protection for life's biggest accidents like property damage, personal injury, and asset loss."
    ),
    icon: { src: eurosIcon, alt: 'euro coins' },
  },
];

export const getWhatsCoveredContent: (t: TFunction) => CardInfo[] = (t) => [
  {
    title: t(
      'page.liability.quote.whatscovered.1.title',
      'Damage to rented apartments'
    ),
    description: t(
      'page.liability.quote.whatscovered.1.description',
      'You never realize how much damage people can cause until you move out of a rented apartment. Submit your claims online and in English!'
    ),
    icon: { src: houseIcon, alt: 'purple house' },
  },
  {
    title: t('page.liability.quote.whatscovered.2.title', 'Lost keys'),
    description: t(
      'page.liability.quote.whatscovered.2.description',
      'Lost your keys in the "hole of no return" in front of your door? Liability insurance will cover the cost of replacing them!'
    ),
    icon: { src: keyIcon, alt: 'purple key' },
  },
  {
    title: t(
      'page.liability.quote.whatscovered.3.title',
      'Damage caused by small pets'
    ),
    description: t(
      'page.liability.quote.whatscovered.3.description',
      "Did your cat scratch the floor? Did your parrot decide that it was time to destroy the landlord's chair? It's all covered!"
    ),
    icon: { src: catIcon, alt: 'purple cat' },
  },
  {
    title: t(
      'page.liability.quote.whatscovered.4.title',
      'Unintentional damage to others'
    ),
    description: t(
      'page.liability.quote.whatscovered.4.description',
      "Accidentally collided with someone during a football game? You're covered for any medical costs or lost wages if they're unable to work."
    ),
    icon: { src: brokenBikeIcon, alt: 'purple broken bike' },
  },
];

export const getWhatsNotCoveredContent: (t: TFunction) => CardInfo[] = (t) => [
  {
    title: t('page.liability.quote.whatsnotcovered.1.title', 'Broken glass'),
    description: t(
      'page.liability.quote.whatsnotcovered.1.description',
      'If there is a broken window or glass in your apartment, this is unfortunately not covered by liability insurance.'
    ),
    icon: { src: brokenGlassIcon, alt: 'broken glass' },
  },
  {
    title: t(
      'page.liability.quote.whatsnotcovered.2.title',
      'Damage to your own property'
    ),
    description: t(
      'page.liability.quote.whatsnotcovered.2.description',
      'Damage to your own belongings, to belongings in a rental property, or to your own home is not covered.'
    ),
    icon: { src: houseGreyIcon, alt: 'grey house' },
  },
  {
    title: t('page.liability.quote.whatsnotcovered.3.title', 'Locksmith'),
    description: t(
      'page.liability.quote.whatsnotcovered.3.description',
      "If you need to get a locksmith to break into your apartment after losing your key, you'll only be covered for the key, not the break-in."
    ),
    icon: { src: lockIcon, alt: 'grey lock' },
  },
  {
    title: t(
      'page.liability.quote.whatsnotcovered.4.title',
      'Fines and penalties'
    ),
    description: t(
      'page.liability.quote.whatsnotcovered.4.description',
      'Any fines and penalties from damages caused by not following the law like crossing the street on a red light is not covered.'
    ),
    icon: { src: phoneIcon, alt: 'grey phone' },
  },
];

export const getFaqContent: (t: TFunction) => FAQInfo[] = (t) => [
  {
    question: t(
      'page.liability.quote.faq.1.question',
      'Am I eligible to get personal liability insurance in Germany?'
    ),
    answer: t(
      'page.liability.quote.faq.1.answer',
      "Yes, you're eligible for personal liability insurance as long you live in Germany. You can also take the coverage with you worldwide."
    ),
    questionId: 0,
  },
  {
    question: t(
      'page.liability.quote.faq.2.question',
      'Is liability insurance mandatory in Germany?'
    ),
    answer: t(
      'page.liability.quote.faq.2.answer',
      "- Liability insurance isn't required by law, but it is sometimes required by landlords when applying for an apartment.\n\n- Additionally, 85% of Germans have some form of personal liability insurance."
    ),
    questionId: 1,
  },
  {
    question: t(
      'page.liability.quote.faq.3.question',
      'Is liability insurance in Germany worth it?'
    ),
    answer: t(
      'page.liability.quote.faq.3.answer',
      "- Yes, liability insurance in Germany is worth it if you want peace of mind knowing that any damage you might cause to someone else's person or property would be covered in full.\n\n- Because so many people have liability insurance, it only costs about as much as a coffee per month."
    ),
    questionId: 2,
  },
  {
    question: t(
      'page.liability.quote.faq.4.question',
      'How much is liability insurance in Germany?'
    ),
    answer: t('page.liability.quote.faq.4.answer', {
      defaultValue:
        'Liability insurance in Germany starts at just {{price}} a month. This covers up to €50 million in property damage, personal injury, and asset loss costs.',
      price: englishFormattedEuroCurrency(insurance.liability.price, 2),
    }),
    questionId: 3,
  },
  {
    question: t(
      'page.liability.quote.faq.5.question',
      "What's covered by personal liability insurance in Germany?"
    ),
    answer: t(
      'page.liability.quote.faq.5.answer',
      "In Germany, personal liability insurance covers many forms of statutory liability, including damage to private property like another person's flat or room, damage caused by your bicycle, damage caused by your pet, the loss of keys, and more."
    ),
    questionId: 4,
  },
  {
    question: t(
      'page.liability.quote.faq.6.question',
      "What's not covered by personal liability insurance in Germany"
    ),
    answer: t(
      'page.liability.quote.faq.6.answer',
      'Personal liability insurance does not cover personal injury resulting from the transfer of illnesses of the insured persons (e.g. sexually transmitted disease), intentional damage, damage caused by hunting as well as from unauthorised possession of weapons, damage caused by asbestos, or damage because of claims for fulfilment of contracts.'
    ),
    questionId: 5,
  },
  {
    question: t(
      'page.liability.quote.faq.7.question',
      'Are there any coverage restrictions?'
    ),
    answer: t('page.liability.quote.faq.7.answer', {
      defaultValue:
        "Yes, there are some cases where the insurance company may be limited in its coverage. These include a {{limit1}} limit  on damages to a borrowed motor vehicle due to misfuelling and a {{limit2}} limit on damages to an employer or colleague's property.",
      limit1: englishFormattedEuroCurrency(3000, 0),
      limit2: englishFormattedEuroCurrency(5000, 0),
    }),
    questionId: 6,
  },
  {
    question: t(
      'page.liability.quote.faq.8.question',
      'How do I file a claim if I have personal liability insurance in Germany?'
    ),
    answer: t(
      'page.liability.quote.faq.8.answer',
      'You simply submit all appropriate documents or images (photos, police reports, hospital bills, invoices, etc.) to your Feather account. After that, we will process your claim.'
    ),
    questionId: 7,
  },
  {
    question: t(
      'page.liability.quote.faq.9.question',
      "What's the difference between third-party liability insurance and personal liability insurance?"
    ),
    answer: t(
      'page.liability.quote.faq.9.answer',
      'Both of these refer to the same type of liability insurance offered on this page.'
    ),
    questionId: 8,
  },
  {
    question: t(
      'page.liability.quote.faq.10.question',
      'Does liability insurance in Germany provide worldwide coverage?'
    ),
    answer: t(
      'page.liability.quote.faq.10.answer',
      'Yes, you have worldwide coverage.'
    ),
    questionId: 9,
  },
  {
    question: t(
      'page.liability.quote.faq.11.question',
      'Does liability insurance in Germany cover my spouse or partner and my family?'
    ),
    answer: t(
      'page.liability.quote.faq.11.answer',
      'Yes, most German liability insurance policies cover the person signing the contract and their family and household members.'
    ),
    questionId: 10,
  },
  {
    question: t(
      'page.liability.quote.faq.12.question',
      'Are there waiting periods for using my liability insurance?'
    ),
    answer: t(
      'page.liability.quote.faq.12.answer',
      'No. As long as you have a valid claim, you can use your liability insurance to cover it right away. Just keep in mind that any instances which occurred before taking out the policy will not be covered.'
    ),
    questionId: 11,
  },
  {
    question: t(
      'page.liability.quote.faq.13.question',
      'Can I use my liability insurance as soon as I sign up?'
    ),
    answer: t(
      'page.liability.quote.faq.13.answer',
      'Yes. Immediately after getting signed up, your Liability insurance becomes active and ready to protect you against any accident or fault.'
    ),
    questionId: 12,
  },
  {
    question: t(
      'page.liability.quote.faq.14.question',
      'How do I cancel my liability insurance?'
    ),
    answer: t(
      'page.liability.quote.faq.14.answer',
      'There are no minimum period or cancellation requirements. Simply sign in to your account and click "cancel". Your policy will then expire on your next billing date.'
    ),
    questionId: 13,
  },
];
