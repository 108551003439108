/**
 * TODO (RV): move to studentChecklistV2 folder
 */
import * as Sentry from '@sentry/react';
import { mergePolicyDetail } from 'actions/policies';
import { setRequestErrored, setRequestInProcess } from 'actions/request';
import { PoliciesAction, RequestAction } from 'constants/actions';
import {
  EStudentChecklistRequestTypes,
  StudentChecklistRequest,
} from 'features/studentChecklist/models';
import { APIResponseError } from 'models/error';
import { AppState } from 'reducers';
import { ThunkDispatch } from 'redux-thunk';
import endpoint from 'shared/api';
import { ActionResponse } from 'shared/models/types';

import { isStudentChecklistCompleted } from '../utils/isChecklistCompleted';

export type StudentChecklistDispatch = ThunkDispatch<
  AppState,
  unknown,
  RequestAction | PoliciesAction
>;

export const updateAndCompleteStudentChecklist = async (
  userPolicyId: string,
  updatedChecklist: Partial<StudentChecklistRequest>
) => {
  try {
    const updateChecklistResponse = await endpoint.updateStudentChecklist(
      userPolicyId,
      updatedChecklist
    );

    const completed = isStudentChecklistCompleted(updateChecklistResponse.data);

    if (completed) {
      await endpoint.completeStudentChecklist(userPolicyId);
    }
  } catch (err) {
    const error = err as APIResponseError;
    const errorMessage = error.response?.data?.message;
    Sentry.captureException(
      `[STUDENT_CHECKLIST] Failed to update student checklist with policy id ${userPolicyId}: ${errorMessage}`
    );
    throw new Error(errorMessage);
  }
};

export const completeStudentChecklist = async (userPolicyId: string) => {
  try {
    await endpoint.completeStudentChecklist(userPolicyId);
  } catch (err) {
    const error = err as APIResponseError;
    const errorMessage = error.response?.data?.message;
    Sentry.captureException(
      `[STUDENT_CHECKLIST] Failed to update student checklist with policy id ${userPolicyId}: ${errorMessage}`
    );
    throw new Error(errorMessage);
  }
};

function updatePolicyDetails(userPolicyId: string) {
  return async (dispatch: StudentChecklistDispatch) => {
    const { data } = await endpoint.getPolicyDetail(userPolicyId);
    dispatch(mergePolicyDetail(data));
  };
}

export const uploadStudentChecklistDocument =
  (
    userPolicyId: string,
    documentName: keyof StudentChecklistRequest,
    file: File
  ) =>
  async (dispatch: StudentChecklistDispatch) => {
    const requestType = EStudentChecklistRequestTypes.UPLOAD_DOCUMENT;
    dispatch(setRequestInProcess(true, requestType));

    try {
      const {
        data: { id, presignedUrl },
      } = await endpoint.createStudentChecklistUpload(userPolicyId, file.name);
      const updatedChecklist: StudentChecklistRequest = {
        [documentName]: id,
      };
      await endpoint.uploadStudentChecklistDocument(presignedUrl, file);
      await updateAndCompleteStudentChecklist(userPolicyId, updatedChecklist);
      await dispatch(updatePolicyDetails(userPolicyId));
      dispatch(setRequestInProcess(false, requestType));
      return 'SUCCESS';
    } catch (error) {
      dispatch(setRequestErrored(error as APIResponseError, requestType));
      return 'ERROR';
    }
  };

export function updateChecklist(
  userPolicyId: string,
  checklist: StudentChecklistRequest
) {
  return async (
    dispatch: StudentChecklistDispatch
  ): Promise<ActionResponse> => {
    dispatch(setRequestInProcess(true, EStudentChecklistRequestTypes.UPDATE));
    try {
      await updateAndCompleteStudentChecklist(userPolicyId, checklist);
      await dispatch(updatePolicyDetails(userPolicyId));
      dispatch(
        setRequestInProcess(false, EStudentChecklistRequestTypes.UPDATE)
      );
      return 'SUCCESS';
    } catch (error) {
      dispatch(
        setRequestErrored(
          error as APIResponseError,
          EStudentChecklistRequestTypes.UPDATE
        )
      );
      return 'ERROR';
    }
  };
}

export function completeChecklist(userPolicyId: string) {
  return async (
    dispatch: StudentChecklistDispatch
  ): Promise<ActionResponse> => {
    dispatch(setRequestInProcess(true, EStudentChecklistRequestTypes.COMPLETE));
    try {
      await completeStudentChecklist(userPolicyId);
      await dispatch(updatePolicyDetails(userPolicyId));
      dispatch(
        setRequestInProcess(false, EStudentChecklistRequestTypes.COMPLETE)
      );
      return 'SUCCESS';
    } catch (error) {
      dispatch(
        setRequestErrored(
          error as APIResponseError,
          EStudentChecklistRequestTypes.COMPLETE
        )
      );
      return 'ERROR';
    }
  };
}
