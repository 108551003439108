import { MailingListPreferencesActions } from 'constants/actions';
import { MailingListPreferences } from 'models/mailingPreferences';
import { Reducer } from 'redux';

export const defaultState: MailingListPreferences = {};

const mailingListPreferences: Reducer<
  MailingListPreferences,
  MailingListPreferencesActions
> = (state = defaultState, action) => {
  switch (action.type) {
    case 'STORE_MAILING_PREFERENCES':
      return {
        ...state,
        ...action.mailingListPreferences,
      };
    default:
      return state;
  }
};

export default mailingListPreferences;
