import { toast } from '@popsure/dirty-swan';
import LoadingSpinner from 'components/loadingSpinner';
import routes from 'constants/routes';
import { PaymentMethodSelectorThunkDispatch } from 'features/paymentMethodSelector/paymentMethodSelector.thunks';
import { getReferralCode } from 'features/paymentScreen/paymentScreen.selectors';
import {
  finalize,
  getCheckout,
  onSuccessfulPayment,
} from 'features/paymentScreen/paymentScreen.thunks';
import { clearReferrerCode } from 'features/referralEngine/actions';
import { useQueryParamValue } from 'hooks/useQueryParamValue';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useHistory, useParams } from 'react-router';
import { useSafeTranslation } from 'shared/i18n';

/**
 * The customer lands here after confirming a new payment on
 * a payment provider's page.
 */
const ConfirmPaymentRedirectGateway = () => {
  const { t } = useSafeTranslation();

  const { checkoutId, localeId }: { checkoutId: string; localeId: string } =
    useParams();
  const redirectStatus = useQueryParamValue('redirect_status');
  const paymentMethodId = useQueryParamValue('paymentMethodId');
  const referralCode = useSelector(getReferralCode);

  const dispatch = useDispatch<PaymentMethodSelectorThunkDispatch>();

  const history = useHistory();
  const isPaymentSuccessful = redirectStatus === 'succeeded';

  useEffect(() => {
    const processPostConfirmPayment = async () => {
      if (!paymentMethodId) {
        throw Error('[Generic checkout] Missing payment method id');
      }

      const checkoutInfo = await dispatch(
        getCheckout({ checkoutId: checkoutId ?? '' })
      );
      try {
        await dispatch(
          finalize({
            checkoutId,
            paymentMethodId,
            paymentSucceeded: isPaymentSuccessful,
          })
        );
      } catch (e) {
        toast(
          t(
            'paymentScreen.errors.errorConfirmingCheckout',
            'The checkout was not successful'
          ),
          {
            type: 'error',
            description: t(
              'paymentScreen.errors.errorConfirmingCheckoutDescription',
              'Please, try again with a different payment method or contact customer support.'
            ),
            duration: 5000,
          }
        );

        const baseUrl = localeId
          ? generatePath(routes.paymentScreenRegionalised.pay.path, {
              checkoutId,
              localeId,
            })
          : generatePath(routes.paymentScreen.pay.path, {
              checkoutId,
            });

        history.replace(`${baseUrl}?paymentMethodId=${paymentMethodId}`);
      }

      if (isPaymentSuccessful) {
        const { policyDetails, id, monthlyPriceInCents } =
          checkoutInfo.policies[0];
        await dispatch(
          onSuccessfulPayment({
            history,
            referralCode,
            t,
            verticalId: policyDetails.type,
            policyId: id,
            isDuplicatePaymentAttempt: false,
            policyMonthlyPrice: monthlyPriceInCents / 100,
            formId: checkoutInfo.policyInfo.formId,
            localeId,
          })
        );
        await dispatch(clearReferrerCode());
      }
    };
    processPostConfirmPayment();
  }, [
    checkoutId,
    dispatch,
    history,
    localeId,
    paymentMethodId,
    redirectStatus,
    referralCode,
    isPaymentSuccessful,
    t,
  ]);

  return <LoadingSpinner />;
};

export default ConfirmPaymentRedirectGateway;
