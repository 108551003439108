import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import { InsuranceTypes } from 'models/insurances/types';
import { Policy, PolicyAttributes } from 'models/policies';
import { UserWithBrokerMandate } from 'models/user';

import { getPoliciesListAction, PolicyAction } from '../getPoliciesListAction';
import { getPolicyStatus, PolicyStatusOrNull } from '../getPolicyStatus';
import { isPolicyExpired } from '../isPolicyExpired';

export type FormattedPolicyListItem = {
  action: PolicyAction;
  attributes: PolicyAttributes;
  content: string[];
  expired: boolean;
  id: string;
  providerName?: string;
  status: PolicyStatusOrNull;
  type: InsuranceTypes;
};

const groupOrder: Record<InsuranceTypes, number> = {
  PUBLIC_HEALTH: 1,
  PRIVATE_HEALTH: 2,
  INCOMING: 3,
  EXPAT_V2: 3,
  DENTAL: 4,
  DENTAL_V2: 4,
  LIABILITY: 5,
  HOUSEHOLD: 6,
  BIKE: 7,
  LIFE: 8,
  LIFE_V2: 8,
  DISABILITY: 9,
  LEGAL: 10,
  LEGAL_V2: 10,
  CAR: 11,
  JOB: 11,
  DOG_LIABILITY: 11,
  PET_HEALTH: 11,
  TRAVEL: 11,
  GENERIC: 11,
  COMPANY_LIFE: 11,
  COMPANY_HEALTH: 11,
  PENSION: 11,
  BASIS_PENSION: 11,
  INCOMING_ES: 11,
  TRAVEL_HEALTH_V1: 11,
  TRAVEL_FR: 11,
  BIKE_V2: 11,
  INCOMING_EU: 11,
  INCOMING_LT: 11,
  DISABILITY_V2: 11,
  COMPANY_PENSION: 11,
};

export const needToSignBrokerMandate = (
  policies?: Policy[],
  user?: UserWithBrokerMandate
) => {
  if (!user) {
    return false;
  }

  if (!policies) {
    return false;
  }

  // TODO: signedBrokerMandate state should distinguish policy type
  const filteredPolicies = policies.filter((policy) => {
    return policy.type === 'PUBLIC_HEALTH';
  });

  if (filteredPolicies.length === 0) {
    return false;
  }

  return policies.length > 0 && user.signedBrokerMandate === false;
};

const getPolicyMetadata = ({ attributes, type }: Policy): string[] => {
  const data: string[] = [];

  if (type === 'BIKE') {
    const model = attributes?.model ? `${attributes.model}, ` : '';
    const brand = attributes?.brand || '';

    data.push(`${model}${brand}`);
  }

  if (type === 'HOUSEHOLD') {
    const address = attributes?.householdAttributes?.personalInfo?.address;
    const street = address?.street || '';
    const houseNumber = address?.houseNumber || '';

    data.push(`${street}${street && houseNumber && ', '}${houseNumber}`);
  }

  if (attributes?.price) {
    const pricePeriodicityDivision =
      attributes?.billingType === 'YEAR' ? 12 : 1;

    const price = englishFormattedEuroCurrency(
      Number(attributes.price) / pricePeriodicityDivision
    );

    data.push(`${price}/mo`);
  }

  return data;
};

const formatPoliciesList = (
  policies: Policy[] = [],
  user?: UserWithBrokerMandate
): FormattedPolicyListItem[] => {
  const needToSignBroker = needToSignBrokerMandate(policies, user);

  return policies
    .map((policy) => {
      const { attributes, id, providerName, type } = policy;
      const moreThanOnePolicyOfType = policies.find(
        (item) => item.type === type && item.id !== id
      );
      const insuredPersonName = attributes.insuredPerson?.name;
      const policyWithVisibleHolderName = !['HOUSEHOLD', 'BIKE'].includes(type);
      const policyHolderNameMatch =
        user?.firstName === insuredPersonName?.firstName &&
        user?.lastName === insuredPersonName?.lastName;

      const insuredPersonNameString =
        insuredPersonName && policyWithVisibleHolderName
          ? `${insuredPersonName?.firstName} ${insuredPersonName?.lastName}`
          : '';

      const action = getPoliciesListAction(policy, needToSignBroker);

      return {
        action,
        attributes,
        id,
        type,
        providerName,
        expired: isPolicyExpired(policy),
        status:
          action && action !== 'DETAIL'
            ? 'ACTION_NEEDED'
            : getPolicyStatus(policy),
        content: [
          // if policy holder name does not match the user account name
          // or there is more than one policy of the same type
          ...(moreThanOnePolicyOfType || !policyHolderNameMatch
            ? [insuredPersonNameString]
            : []),
          ...getPolicyMetadata(policy),
        ],
      };
    })
    .sort(({ status }) => (status === 'ACTION_NEEDED' ? 1 : -1))
    .sort((a, b) => (groupOrder?.[a.type] > groupOrder?.[b.type] ? 1 : -1));
};

export { formatPoliciesList, getPolicyMetadata };
