import { QuestionnaireFormProps } from '@getpopsure/qnr-framework';
import classNames from 'classnames';
import { Pension, Quote as QuoteType } from 'features/pension/models';
import { imageTypeMapping } from 'models/insurances/types/mapping';
import { useSafeTranslation } from 'shared/i18n';

import { Calculator } from '../calculator';
import styles from './Quote.module.scss';

export const Quote = ({
  onSubmitValue,
  value,
  ...props
}: QuestionnaireFormProps<Pension, QuoteType>) => {
  const { t } = useSafeTranslation();
  return (
    <div>
      <header
        className={classNames(
          'p-body d-flex ai-center fd-column mt40',
          styles.title
        )}
      >
        <img
          src={imageTypeMapping.PENSION}
          className={classNames(styles.headerImg, 'mxauto')}
          alt={t(
            'pension.qnr.quote.header.image.alt',
            'A palm tree and a sunbed'
          )}
        />
        <h1 className="p-h1 p--serif">
          {t('pension.qnr.quote.title', 'Calculate your pension')}
        </h1>
      </header>
      <Calculator onSubmitValue={onSubmitValue} value={value} {...props} />
    </div>
  );
};
