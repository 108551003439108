import { Button, Card } from '@popsure/dirty-swan';
import englishFormattedEuroCurrency from 'shared/util/priceFormatter';

import styles from './PriceCard.module.scss';

export const PriceCard = ({
  title,
  subTitle,
  perText,
  price,
  onSelect,
  buttonText = 'Select',
}: {
  title: string;
  price: number;
  onSelect?: () => void;
  subTitle?: string;
  perText?: string;
  buttonText?: string;
}) => (
  <Card
    title={title}
    description={subTitle}
    classNames={{
      title: 'ta-center',
      description: 'ta-center',
      wrapper: `jc-between ${styles.cardWrapper}`,
    }}
  >
    <div className="mt8 ta-center tc-purple-500">
      <p className="p--serif p-h1--xl tc-purple-500">
        {englishFormattedEuroCurrency(price, 0)}
      </p>
      <p className="p-h4 tc-purple-500">{perText}</p>
    </div>
    {onSelect && (
      <Button
        className="mt16 w100 c-pointer"
        data-cy={`policies-expat-button-quote-${title}`}
        onClick={onSelect}
      >
        {buttonText}
      </Button>
    )}
  </Card>
);
