import { ExtendedQuestionType } from './models';

export const getFormForType = (type: ExtendedQuestionType) => {
  switch (type) {
    case 'BLOCKER':
      return {
        title: { type: 'input' },
        description: { type: 'input' },
        additionalInfo: { type: 'text' },
        paragraphClassName: { type: 'input' },
        iconType: {
          type: 'select',
          options: ['PERSON', 'SHIELD', 'CALENDAR', 'NONE'],
        },
        buttonProps: { type: 'input' },
      };

    case 'INTRO':
      return {
        title: { type: 'input' },
        subTitle: { type: 'input' },
      };

    case 'INPUT':
      return {
        label: { type: 'input' },
        placeholder: { type: 'input' },
      };

    case 'CURRENCY':
      return {
        label: { type: 'input' },
        placeholder: { type: 'input' },
      };

    case 'NUMBER':
      return {
        placeholder: { type: 'input' },
      };

    case 'TEXT':
      return {
        placeholder: { type: 'text' },
        maxLength: { type: 'number' },
      };

    case 'DATE':
      return {
        yearRange: {
          type: 'object',
          properties: {
            min: {
              type: 'number',
            },
            max: {
              type: 'number',
            },
          },
        },
        placeholders: {
          type: 'object',
          properties: {
            day: {
              type: 'input',
            },
            month: {
              type: 'input',
            },
            year: {
              type: 'input',
            },
          },
        },
      };

    case 'ADDRESS':
      return {
        placeholders: {
          type: 'object',
          properties: {
            manualAddressEntry: {
              type: 'input',
            },
            street: {
              type: 'input',
            },
            houseNumber: {
              type: 'input',
            },
            additionalInformation: {
              type: 'input',
            },
            postcode: {
              type: 'input',
            },
            city: {
              type: 'input',
            },
          },
        },
        manualAddressEntryTexts: {
          type: 'object',
          properties: {
            preText: {
              type: 'input',
            },
            cta: {
              type: 'input',
            },
          },
        },
      };

    case 'RADIO': {
      return {
        mapValue: { type: 'radioOrCheckboxInput' },
      };
    }

    case 'CHECKBOX': {
      return {
        mapValue: { type: 'radioOrCheckboxInput' },
      };
    }

    case 'QUOTEPAGE':
      return {
        config: {
          type: 'object',
          properties: {
            title: {
              type: 'input',
            },
            headerImage: {
              type: 'object',
              properties: {
                src: {
                  type: 'imageSelect',
                  imageType: 'verticalImage',
                },
                alt: {
                  type: 'input',
                },
              },
            },
            priceHeader: {
              type: 'object',
              properties: {
                title: {
                  type: 'input',
                },
                subTitle: {
                  type: 'input',
                },
              },
            },
            actions: {
              type: 'object',
              properties: {
                items: {
                  type: 'array',
                  properties: {
                    type: {
                      type: 'input',
                      hardCoded: true,
                    },
                    text: { type: 'text' },
                    mapValues: {
                      type: 'object',
                      properties: {
                        0: { type: 'input' },
                        1: { type: 'input' },
                      },
                    },
                  },
                  static: true,
                  rules: [
                    {
                      ifProperty: 'type',
                      equals: 'SubHeader',
                      hideProperty: 'mapValues',
                    },
                    {
                      ifProperty: 'type',
                      equals: 'Description',
                      hideProperty: 'mapValues',
                    },
                    {
                      ifProperty: 'type',
                      equals: 'Toggle',
                      hideProperty: 'text',
                    },
                  ],
                },
                valuesMap: {
                  type: 'object',
                  properties: { 0: { type: 'input' }, 1: { type: 'input' } },
                },
                submitMap: {
                  type: 'array',
                  static: true,
                  properties: {
                    id: { type: 'input' },
                    price: { type: 'number' },
                    plan: {
                      type: 'select',
                      options: ['NON_DEDUCTIBLE', 'DEDUCTIBLE'],
                    },
                    isDeductible: { type: 'boolean' },
                    deductibleAmount: { type: 'number' },
                  },
                },
                button: {
                  type: 'object',
                  properties: { text: { type: 'input' } },
                },
              },
            },
            coveredSection: {
              type: 'object',
              properties: {
                title: { type: 'input' },
                layout: {
                  type: 'object',
                  properties: {
                    compact: { type: 'boolean' },
                    cards: { type: 'boolean' },
                  },
                },
                items: {
                  type: 'array',
                  properties: {
                    title: { type: 'input' },
                    icon: {
                      type: 'object',
                      properties: {
                        src: {
                          type: 'imageSelect',
                          imageType: 'illustration',
                        },
                        alt: { type: 'input' },
                      },
                    },
                    description: { type: 'text' },
                  },
                },
              },
            },
            notCoveredSection: {
              type: 'object',
              properties: {
                title: { type: 'input' },
                items: {
                  type: 'array',
                  properties: {
                    title: { type: 'input' },
                    icon: {
                      type: 'object',
                      properties: {
                        src: {
                          type: 'imageSelect',
                          imageType: 'illustration',
                        },
                        alt: { type: 'input' },
                      },
                    },
                    description: { type: 'text' },
                  },
                },
              },
            },
            ctaBox: {
              type: 'object',
              properties: {
                header: { type: 'input' },
                title: { type: 'input' },
                subTitle: { type: 'input' },
                buttonText: { type: 'input' },
              },
            },
            addons: {
              type: 'array',
              properties: {
                id: { type: 'input' },
                title: { type: 'input' },
                description: { type: 'text' },
                price: { type: 'number' },
                link: { type: 'input' },
              },
            },
            reviewBadge: { type: 'boolean' },
            faq: {
              type: 'object',
              properties: {
                title: { type: 'input' },
                data: {
                  type: 'array',
                  properties: {
                    questionId: { type: 'number' },
                    question: { type: 'input' },
                    answer: { type: 'text' },
                  },
                },
                footer: { type: 'input' },
              },
            },
            moreQuestionsSection: {
              type: 'object',
              properties: {
                bookACallGACategory: { type: 'input' },
                title: { type: 'input' },
                bookACallUrl: { type: 'input' },
                secondaryActionLink: { type: 'input' },
                secondaryAction: { type: 'input' },
              },
            },
          },
        },
      };

    default:
      return {};
  }
};
