import { capitalizeName } from '@getpopsure/public-utility';
import routes from 'constants/routes';
import dayjs from 'dayjs';
import { getPoliciesListAction } from 'features/policiesDashboard/utils/getPoliciesListAction';
import { getPolicyStatus } from 'features/policiesDashboard/utils/getPolicyStatus';
import { PolicyCoverageItem } from 'features/policySingle/sections/PolicyCoverage/PolicyCoverage';
import {
  bannerColorMapping,
  getTitleMapping,
} from 'models/insurances/types/mapping';
import { Policy } from 'models/policies';
import { generatePath } from 'react-router';
import { TFunction } from 'shared/i18n/utils';

import type { PolicySingleModal } from '../../components/modals';
import {
  CancelPolicyModal,
  DelinquencyBlockerCancelPolicyModal,
  DelinquencyBlockerCreateClaimModal,
} from '../../components/modals';
import { DATE_FORMAT } from '../../constants';
import {
  BillingType,
  GetPolicyConfigArgs,
  PolicyConfig,
  PolicySingleSection,
} from '../../PolicySingle.models';
import { getPolicyDocuments } from '../../sections/PolicyDocuments/utils/getPolicyDocuments';
import { getDentalButtons } from './getDentalButtons';

export const getDentalConfig = (args: GetPolicyConfigArgs): PolicyConfig => {
  const [policy, t] = args;
  const { price, insuredPerson, startDate, planId } = policy.attributes;

  const action = getPoliciesListAction(policy);
  const policyStatus =
    action && action !== 'DETAIL'
      ? 'ACTION_NEEDED'
      : getPolicyStatus(policy, true);
  const capitalizedName = insuredPerson?.name
    ? capitalizeName(insuredPerson.name)
    : '';
  const subtitle = capitalizedName || '';

  const header: PolicyConfig['header'] = {
    title: getTitleMapping(t).DENTAL,
    subtitle,
    backgroundColor: bannerColorMapping.DENTAL,
    insuranceType: policy.type,
    policyStatus,
    startDate,
  };

  if (price) {
    header.price = {
      amount: parseFloat(price),
      billingType: policy.attributes.billingType as BillingType,
    };
  }

  const sections: PolicySingleSection[] = [
    {
      id: 'POLICY_DETAILS',
      props: {
        policyType: policy.type,
        info: [
          {
            id: 'insuredPerson',
            title: t(
              'myPolicies.policyDetails.insuredPerson',
              'Insured person'
            ),
            value: insuredPerson && capitalizeName(insuredPerson.name),
          },
          {
            id: 'startDate',
            title: t('myPolicies.policyDetails.startDate', 'Start date'),
            value: startDate && dayjs(startDate).format(DATE_FORMAT),
          },
          {
            id: 'planId',
            title: t('myPolicies.policyDetails.dental.plan.title', 'Plan'),
            value:
              planId === 'ADVANCED'
                ? t('myPolicies.policyDetails.dental.plan.advanced', 'Advanced')
                : t('myPolicies.policyDetails.dental.plan.basic', 'Basic'),
          },
        ],
        buttons: getDentalButtons(policy),
      },
    },
    {
      id: 'POLICY_COVERAGE',
      props: {
        entries: getPolicyCoverage(policy, t),
      },
    },
    {
      id: 'POLICY_CLAIMS',
      props: {
        claims: policy.claims,
        route: generatePath(routes.claims.dental.path, {
          policyId: policy.id,
        }),
        insuranceType: 'DENTAL',
        activeUntil:
          policy.status === 'ACTIVE'
            ? undefined
            : policy.attributes.activeUntil,
        policyStatus: policy.status,
      },
    },
    {
      id: 'POLICY_DOCUMENTS',
      props: {
        documents: getPolicyDocuments(policy),
      },
    },
  ];

  const modals: PolicySingleModal[] = [
    {
      id: 'CANCEL_POLICY',
      component: CancelPolicyModal,
    },
    {
      id: 'DELINQUENT_BLOCKER_CANCEL_POLICY',
      component: DelinquencyBlockerCancelPolicyModal,
    },
    {
      id: 'DELINQUENT_BLOCKER_CREATE_CLAIM',
      component: DelinquencyBlockerCreateClaimModal,
    },
  ];

  return { header, modals, sections };
};

const getPolicyCoverage = (
  policy: Policy,
  t: TFunction
): PolicyCoverageItem[] => {
  if (policy.attributes?.planId === 'ADVANCED') {
    return [
      {
        id: 'advanced-dental-cleanings',
        title: t(
          'myPolicies.policyDetails.dental.covered.dentalCleanings.title',
          'Professional dental cleanings'
        ),
        covered: true,
      },
      {
        id: 'advanced-quality-fillings',
        title: t(
          'myPolicies.policyDetails.dental.covered.qualityFillings.title',
          'High quality fillings'
        ),
        covered: true,
      },
      {
        id: 'advanced-root-canal',
        title: t(
          'myPolicies.policyDetails.dental.covered.rootCanal.title',
          'Root canal and periodontitis treatment'
        ),
        covered: true,
      },
      {
        id: 'advanced-tooth-replacement',
        title: t(
          'myPolicies.policyDetails.dental.covered.toothReplacement.title',
          'Tooth replacement procedures'
        ),
        covered: true,
      },
      {
        id: 'advanced-teeth-whitening',
        title: t(
          'myPolicies.policyDetails.dental.covered.teethWhitening.title',
          'Teeth whitening'
        ),
        covered: true,
      },
      {
        id: 'advanced-cosmetic-procedures',
        title: t(
          'myPolicies.policyDetails.dental.covered.cosmeticProcedures.title',
          'Purely cosmetic procedures'
        ),
        covered: false,
      },
      {
        id: 'advanced-wisdom-teeth',
        title: t(
          'myPolicies.policyDetails.dental.covered.wisdomTeeth.title',
          'Wisdom teeth removal'
        ),
        covered: false,
      },
    ];
  }

  if (dayjs(policy.createdAt).isBefore(dayjs('2023-08-01'))) {
    return [
      {
        id: 'basic-dental-cleanings',
        title: t(
          'myPolicies.policyDetails.dental.covered.dentalCleanings.title',
          'Professional dental cleanings'
        ),
        covered: true,
      },
      {
        id: 'basic-quality-fillings',
        title: t(
          'myPolicies.policyDetails.dental.covered.qualityFillings.title',
          'High quality fillings'
        ),
        description: t(
          'myPolicies.policyDetails.dental.covered.qualityFillings.description',
          'Limits apply for the first 2 years'
        ),
        covered: true,
      },
      {
        id: 'basic-root-canal',
        title: t(
          'myPolicies.policyDetails.dental.covered.rootCanal.title',
          'Root canal and periodontitis treatment'
        ),
        description: t(
          'myPolicies.policyDetails.dental.covered.rootCanal.description',
          'Limits apply for the first 2 years'
        ),
        covered: true,
      },
      {
        id: 'basic-tooth-replacement',
        title: t(
          'myPolicies.policyDetails.dental.covered.toothReplacement.title',
          'Tooth replacement procedures'
        ),
        covered: false,
      },
      {
        id: 'basic-cosmetic-procedures',
        title: t(
          'myPolicies.policyDetails.dental.covered.cosmeticProcedures.title',
          'Purely cosmetic procedures'
        ),
        covered: false,
      },
      {
        id: 'basic-wisdom-teeth',
        title: t(
          'myPolicies.policyDetails.dental.covered.wisdomTeeth.title',
          'Wisdom teeth removal'
        ),
        covered: false,
      },
    ];
  }
  return [
    {
      id: 'basic-dental-cleanings',
      title: t(
        'myPolicies.policyDetails.dental.covered.originalDentalCleanings.title',
        'Professional dental cleanings (up to €150 / year)'
      ),
      covered: true,
    },
    {
      id: 'basic-quality-fillings',
      title: t(
        'myPolicies.policyDetails.dental.covered.qualityFillings.title',
        'High quality fillings'
      ),
      description: t(
        'myPolicies.policyDetails.dental.covered.qualityFillings.description',
        'Limits apply for the first 2 years'
      ),
      covered: true,
    },
    {
      id: 'basic-root-canal',
      title: t(
        'myPolicies.policyDetails.dental.covered.rootCanal.title',
        'Root canal and periodontitis treatment'
      ),
      description: t(
        'myPolicies.policyDetails.dental.covered.rootCanal.description',
        'Limits apply for the first 2 years'
      ),
      covered: true,
    },
    {
      id: 'basic-tooth-replacement',
      title: t(
        'myPolicies.policyDetails.dental.covered.toothReplacement.title',
        'Tooth replacement procedures'
      ),
      covered: false,
    },
    {
      id: 'basic-cosmetic-procedures',
      title: t(
        'myPolicies.policyDetails.dental.covered.cosmeticProcedures.title',
        'Purely cosmetic procedures'
      ),
      covered: false,
    },
    {
      id: 'basic-wisdom-teeth',
      title: t(
        'myPolicies.policyDetails.dental.covered.wisdomTeeth.title',
        'Wisdom teeth removal'
      ),
      covered: false,
    },
  ];
};
