import { BottomOrRegularModal, InfoCard } from '@popsure/dirty-swan';
import classNames from 'classnames';
import { CoverageId, QuotePlan } from 'models/expat';
import { useState } from 'react';
import { useSafeTranslation } from 'shared/i18n';

import { coverageByPlan, coverageModalContent } from '../content';
import styles from './style.module.scss';

type CardState = 'actionable' | 'static' | 'muted';

export const isCoveredInPlan = (
  coveredInBasic: boolean,
  selectedPlan: QuotePlan
): boolean => selectedPlan === 'PREMIUM' || coveredInBasic === true;

export const retrieveCardState = (
  coveredInBasic: boolean,
  selectedPlan: QuotePlan,
  id?: CoverageId
): CardState => {
  if (isCoveredInPlan(coveredInBasic, selectedPlan) === true) {
    if (id !== undefined) {
      return 'actionable';
    }
    return 'static';
  }

  return 'muted';
};

const ComparisonTable = ({
  selectedPlan,
  setSelectedPlan,
  region,
}: {
  selectedPlan: QuotePlan;
  setSelectedPlan: (plan: QuotePlan) => void;
  region: string;
}) => {
  const [coverage, setCoverage] = useState<CoverageId | undefined>();
  const { t } = useSafeTranslation();

  return (
    <div className={styles.container}>
      <BottomOrRegularModal
        title={coverage ? coverageModalContent(t, region)[coverage]?.title : ''}
        isOpen={coverage !== undefined}
        onClose={() => setCoverage(undefined)}
      >
        {coverage && coverageModalContent(t, region)[coverage].content}
      </BottomOrRegularModal>

      <div className={styles.segmentedContainer}>
        <div
          className={`${styles.selectedBackground} ${
            styles[
              `selectedBackground--position-${selectedPlan === 'BASIC' ? 0 : 1}`
            ]
          }`}
        />
        <button
          className={`ds-interactive-component ${styles.item} ${
            selectedPlan === 'BASIC' ? styles['item--selected'] : ''
          }`}
          onClick={() => setSelectedPlan('BASIC')}
          type="button"
        >
          Basic
        </button>
        <button
          className={`ds-interactive-component ${styles.item} ${
            selectedPlan === 'PREMIUM' ? styles['item--selected'] : ''
          }`}
          onClick={() => setSelectedPlan('PREMIUM')}
          type="button"
        >
          Premium
        </button>
      </div>

      <div className={`mt16 ${styles['card-container']}`}>
        {coverageByPlan(selectedPlan, t).map(
          ({ title, icon, description, coveredInBasic, id }) => (
            <div key={title}>
              <InfoCard
                onClick={() => {
                  if (
                    retrieveCardState(coveredInBasic, selectedPlan, id) ===
                      'actionable' &&
                    id !== undefined
                  ) {
                    setCoverage(id);
                  }
                }}
                className={classNames('mt32 ml16 wmx4', styles['card-height'])}
                title={title}
                topIcon={icon}
                state={retrieveCardState(coveredInBasic, selectedPlan, id)}
                rightIcon={
                  retrieveCardState(coveredInBasic, selectedPlan, id) ===
                  'actionable'
                    ? 'info'
                    : undefined
                }
              >
                <div
                  className={`p-p ta-center tc-grey-600 ${styles['info-description']}`}
                >
                  {description}
                </div>
              </InfoCard>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default ComparisonTable;
