import { captureMessage } from '@sentry/browser';
import { InsuranceTypes } from 'models/insurances/types';
import { useEffect } from 'react';
import endpoint from 'shared/api';

export const useCheckoutStarted = (
  insuranceType: InsuranceTypes,
  email?: string
) => {
  useEffect(() => {
    if (!email) {
      return;
    }

    endpoint
      .setCheckoutStarted(insuranceType, email)
      .then(({ status }) => {
        if (status !== 201) {
          captureMessage(
            '[CheckoutStarted]: Checkout returned a non success status'
          );
        }
      })
      .catch((error) => {
        if (error) {
          captureMessage('[CheckoutStarted]: Checkout starting failed');
        }
      });
  }, [email, insuranceType]);
};
