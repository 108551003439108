/* eslint-disable react/no-array-index-key */
import { illustrations, Input, TrashIcon } from '@popsure/dirty-swan';
import { imageTypeMapping } from 'models/insurances/types/mapping';
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { Field } from './models';
import styles from './style.module.scss';

const inputClass = 'ws8';
const labelClass = 'p-p tc-grey-600 wmn1';

type FieldProps = {
  value: unknown;
  onChange: (value: unknown) => void;
  // There seems to be a bug in eslint, where eslint complains that the type is not used
  // although the type is used later on the code.
  // We will need to better understand where the issue comes from
  // and how it can be solved in the future.
  // eslint-disable-next-line react/no-unused-prop-types
  name?: string;
};

type FieldObject = Record<string | number, unknown>;

export const getFieldForType = (field: Field) => {
  switch (field.type) {
    case 'input':
      return ({ value, onChange, name }: FieldProps) => (
        <Input
          type="text"
          defaultValue={value as string}
          onBlur={(e) => onChange(e.target.value)}
          disabled={field.hardCoded}
          className={`${inputClass} ${styles.input} mt8`}
          id={name}
        />
      );

    case 'number':
      return ({ value, onChange, name }: FieldProps) => (
        <Input
          type="text"
          defaultValue={value as string}
          onBlur={(e) => onChange(Number(e.target.value))}
          className={`${inputClass} ${styles.input} mt8`}
          id={name}
        />
      );

    case 'text':
      return ({ value, onChange, name }: FieldProps) => (
        <textarea
          defaultValue={value as string}
          onBlur={(e) => onChange(e.target.value)}
          className={`mb8 p-input ${styles.textArea} mt8`}
          rows={8}
          id={name}
        />
      );

    case 'select':
      return ({ value, onChange }: FieldProps) => (
        <select
          onChange={(e) => {
            onChange(e.target.value);
          }}
          value={value as string}
          className="mt8"
        >
          {field.options.map((option) => {
            return (
              <option key={option} value={option}>
                {option}
              </option>
            );
          })}
        </select>
      );

    case 'object':
      return ({ value, onChange }: FieldProps) => {
        return (
          <div className={`d-flex fd-column gap16 ml24 ${styles.objectInput}`}>
            {Object.entries(field.properties).map(([key, element], idx) => {
              const Component = getFieldForType(element);
              return (
                <div key={`${element.type}-${idx}`}>
                  <label className={`${labelClass}`}>
                    <p>{key}</p>
                    <Component
                      value={(value as Record<string, unknown>)[key]}
                      onChange={(val) =>
                        onChange({
                          ...(value as object),
                          [key]: val,
                        })
                      }
                    />
                  </label>
                </div>
              );
            })}
          </div>
        );
      };

    case 'array':
      return ({ value, onChange }: FieldProps) => {
        const [formData, setFormData] = useState<FieldObject[]>(
          value as FieldObject[]
        );

        const handleUpdateArray = (
          i: number,
          updatedObject: FieldObject
        ): void => {
          const updatedArray = formData.map((item, index) => {
            if (i === index) {
              return updatedObject;
            }
            return item;
          });

          onChange(updatedArray);
          setFormData(updatedArray);
        };

        const handleAddItem = (): void => {
          const lastItem = formData[formData.length - 1];
          const updatedArray = [...formData, { ...lastItem }];

          onChange(updatedArray);
          setFormData(updatedArray);
        };

        const handleRemoveItem = (index: number): void => {
          const updatedArray = formData.filter((_, i) => i !== index);

          onChange(updatedArray);
          setFormData(updatedArray);
        };

        const handleFilterObject = (
          currentObject: FieldObject,
          currentField: Field
        ): FieldObject => {
          if (currentField.type === 'array' && currentField.rules) {
            const propertiesToExclude: Array<string | number> =
              currentField.rules
                .map((rule) => {
                  if (currentObject[rule.ifProperty] === rule.equals) {
                    return rule.hideProperty;
                  }
                  return null;
                })
                .filter((key): key is string | number => key !== null);

            return Object.keys(currentObject)
              .filter((key) => !propertiesToExclude.includes(key))
              .reduce((updatedObject, key) => {
                updatedObject[key] = currentObject[key];
                return updatedObject;
              }, {} as FieldObject);
          }
          return currentObject;
        };

        const handleFilterProperties = (
          filteredObject: FieldObject,
          currentField: Field
        ) => {
          if (currentField.type === 'array') {
            if (currentField.rules) {
              return Object.keys(currentField.properties).reduce(
                (filteredProperties, key) => {
                  if (filteredObject[key]) {
                    return {
                      ...filteredProperties,
                      [key]: currentField.properties[key],
                    };
                  }
                  return filteredProperties;
                },
                {}
              );
            }
            return currentField.properties;
          }
        };

        return (
          <form>
            {formData.map((currentObject, index) => {
              /* We need to handle cases where not every object in the array has the same properties.
                  In that case, we filter out non-existing properties using the "rules" defined at the field level. */
              const filteredObject = handleFilterObject(currentObject, field);
              const filteredProperties = handleFilterProperties(
                filteredObject,
                field
              );

              const isFirstItem = formData.indexOf(currentObject) === 0;
              const isLastItem = index === formData.length - 1;

              const Component = getFieldForType({
                type: 'object',
                properties: filteredProperties ?? {},
              });

              const objectKey = `unique-key-${index}`;

              return (
                <section key={objectKey}>
                  {!isFirstItem && <hr className="mt16 ml24" />}
                  <div className="d-flex fd-column gap16 jc-start mt16 ml24">
                    <Component
                      value={filteredObject}
                      onChange={(object) => {
                        handleUpdateArray(index, object as FieldObject);
                      }}
                    />
                    {!field.static && !isFirstItem && (
                      <button
                        type="button"
                        onClick={() => handleRemoveItem(index)}
                        className="p-a fw-bold bg-transparent"
                      >
                        Remove item
                      </button>
                    )}
                    {!field.static && isLastItem && (
                      <button
                        type="button"
                        onClick={handleAddItem}
                        className="p-a fw-bold bg-transparent"
                      >
                        Add item below
                      </button>
                    )}
                  </div>
                </section>
              );
            })}
          </form>
        );
      };

    case 'boolean':
      return ({ value, onChange }: FieldProps) => {
        type Options = [boolean | string, string][];

        const uniqueId = uuidv4();

        const options: Options = [
          [true, 'Yes'],
          [false, 'No'],
        ];

        return (
          <div className="d-flex gap32">
            {options.map(([mappableValue, label]) => (
              <div className="mt8" key={`${uniqueId}_${label}`}>
                <input
                  type="radio"
                  id={`${uniqueId}_${label}`}
                  className="p-radio"
                  value={label}
                  onChange={() => onChange(mappableValue)}
                  checked={mappableValue === value}
                />
                <label htmlFor={`${uniqueId}_${label}`}>{label}</label>
              </div>
            ))}
          </div>
        );
      };

    case 'imageSelect':
      return ({ value, onChange }: FieldProps) => {
        const imagesMapping = {
          verticalImage: imageTypeMapping as Record<string, string>,
          illustration: illustrations as Record<string, string>,
        };

        const currentImages = imagesMapping[field.imageType];

        const fetchImageOptions = () => {
          return Object.keys(currentImages).filter(
            (key) => !key.includes('V2')
          );
        };

        const imageOptions = fetchImageOptions();

        const fetchImageName = (imageSrc: string) => {
          return Object.keys(currentImages).find(
            (name) => currentImages[name] === imageSrc
          );
        };

        const handleSelectImage = (selectedValue: string) => {
          return currentImages[selectedValue];
        };

        return (
          <select
            onChange={(e) => {
              const selectedImage = handleSelectImage(e.target.value);
              onChange(selectedImage);
            }}
            value={fetchImageName(value as string)}
            className="mt8"
          >
            {imageOptions.map((option, index) => {
              return (
                <option key={`unique-key-${index}`} value={option}>
                  {option}
                </option>
              );
            })}
          </select>
        );
      };

    case 'radioOrCheckboxInput':
      return ({ value, onChange }: FieldProps) => {
        type Options = Record<string, string>;

        const [options, setOptions] = useState<Options>(value as Options);

        const handleUpdateValue = (currentKey: string, newValue: string) => {
          const updatedOptions = { ...options, [currentKey]: newValue };

          onChange(updatedOptions);
          setOptions(updatedOptions);
        };

        /* To keep the UX simple, the qnr builder will only allow updating the values of mapValue and not the keys.
          We keep the keys of mapValue as numbers from 1 onwards. */

        const handleAddInput = () => {
          const newKey = Object.keys(options).length + 1;
          const updatedOptions = { ...options, [newKey]: '' };

          onChange(updatedOptions);
          setOptions(updatedOptions);
        };

        const handleRemoveInput = (key: string) => {
          const updatedOptions = Object.keys(options)
            .filter((currentKey) => key !== currentKey)
            .reduce(
              (newOptions, currentKey, index) => ({
                ...newOptions,
                [index + 1]: options[currentKey],
              }),
              {}
            );

          onChange(updatedOptions);
          setOptions(updatedOptions);
        };

        return (
          <div>
            <form>
              {Object.keys(options).map((optionKey) => {
                return (
                  <div
                    key={optionKey}
                    className="gap8 d-flex ai-center jc-between"
                  >
                    <Input
                      type="text"
                      defaultValue={(value as Options)[optionKey]}
                      onBlur={(e) =>
                        handleUpdateValue(optionKey, e.target.value)
                      }
                      className={`${styles.input} ws5 mt8`}
                    />
                    <div>
                      <button
                        type="button"
                        className="c-pointer tc-grey-500 bg-transparent"
                        onClick={() => handleRemoveInput(optionKey)}
                      >
                        <TrashIcon />
                      </button>
                    </div>
                  </div>
                );
              })}
            </form>
            <button
              className="p-a fw-bold bg-transparent my16"
              type="button"
              onClick={() => handleAddInput()}
            >
              Add new item
            </button>
          </div>
        );
      };
  }
};
