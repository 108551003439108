import {
  AgreementButton,
  BillingAddress,
  PaymentForm,
  PaymentMethodRadio,
  PaymentSelect,
} from 'features/paymentMethodSelector/components';
import { StripeElementForm } from 'features/paymentMethodSelector/components/StripeElementForm/StripeElementForm';
import {
  PaymentOption,
  PaymentOptionSetupData,
  StripeElementSetupData,
} from 'features/paymentMethodSelector/paymentMethodSelector.models';
import { PaymentMethod, PaymentType } from 'models/paymentMethods';
import { useEffect, useState } from 'react';
import AnimateHeight from 'react-animate-height';

type PaymentInfoProps = {
  handlePaymentOptionUpdate: (paymentOption: PaymentOption) => void;
  onStripePaymentMethodTypeChange: (type: string) => void;
  isLoading: boolean;
  isUsingStripeElement: boolean;
  accountHolderEmail?: string;
  forceMakeDefault: boolean;
  hideDropdown: boolean;
  defaultPaymentTypeValue?: PaymentType;
  dropdownOptions: {
    paymentOptions: PaymentMethod[];
    defaultPaymentOption?: PaymentMethod;
    overridePaymentOption?: PaymentMethod;
  };
};

const PaymentMethodSelectorView = ({
  handlePaymentOptionUpdate,
  onStripePaymentMethodTypeChange,
  accountHolderEmail,
  isLoading,
  isUsingStripeElement,
  forceMakeDefault,
  hideDropdown,
  defaultPaymentTypeValue,
  dropdownOptions: {
    defaultPaymentOption,
    overridePaymentOption,
    paymentOptions,
  },
}: PaymentInfoProps) => {
  const [setupData, onSetupDataChange] =
    useState<PaymentOptionSetupData | null>(null);

  const [stripeSetupData, onStripeSetupDataChange] =
    useState<StripeElementSetupData | null>(null);

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<
    PaymentMethod | undefined
  >(undefined);

  const [paymentType, setPaymentType] = useState<PaymentType | undefined>(
    undefined
  );

  const [addNewPaymentMethod, setAddNewPaymentMethod] = useState<boolean>(
    hideDropdown === true || paymentOptions.length === 0
  );

  const handlePaymentTypeChange = (value: PaymentType) => {
    setPaymentType(value);

    if (!hideDropdown) {
      const visiblePaymentMethods = hideDropdown
        ? []
        : paymentOptions.filter((item) => item.type === value);

      if (defaultPaymentOption?.type === value) {
        setSelectedPaymentMethod(defaultPaymentOption);
      } else if (visiblePaymentMethods.length > 0) {
        setSelectedPaymentMethod(visiblePaymentMethods[0]);
      }

      if (selectedPaymentMethod) {
        setAddNewPaymentMethod(false);
      } else {
        setAddNewPaymentMethod(true);
      }
    }
  };

  useEffect(() => {
    setPaymentType(
      hideDropdown
        ? defaultPaymentTypeValue
        : overridePaymentOption?.type ||
            defaultPaymentOption?.type ||
            defaultPaymentTypeValue
    );

    setSelectedPaymentMethod(
      hideDropdown ? undefined : overridePaymentOption || defaultPaymentOption
    );
  }, [
    hideDropdown,
    defaultPaymentTypeValue,
    overridePaymentOption,
    defaultPaymentOption,
  ]);

  useEffect(() => {
    if (isUsingStripeElement) return;

    if (addNewPaymentMethod) {
      if (setupData) {
        handlePaymentOptionUpdate({
          type: 'READY_FOR_SETUP',
          setupData,
        });
      } else {
        handlePaymentOptionUpdate({
          type: 'NOT_READY_FOR_SETUP',
        });
      }
    } else if (selectedPaymentMethod) {
      handlePaymentOptionUpdate({
        type: 'EXISTING_PAYMENT_METHOD',
        paymentMethod: selectedPaymentMethod,
      });
    }
  }, [
    isUsingStripeElement,
    addNewPaymentMethod,
    setupData,
    selectedPaymentMethod,
    handlePaymentOptionUpdate,
  ]);

  useEffect(() => {
    if (!isUsingStripeElement) return;

    if (addNewPaymentMethod) {
      if (stripeSetupData) {
        handlePaymentOptionUpdate({
          type: 'READY_FOR_SETUP_WITH_REDIRECT',
          setupData: stripeSetupData,
        });
      } else {
        handlePaymentOptionUpdate({
          type: 'NOT_READY_FOR_SETUP',
        });
      }
    } else if (selectedPaymentMethod) {
      handlePaymentOptionUpdate({
        type: 'EXISTING_PAYMENT_METHOD',
        paymentMethod: selectedPaymentMethod,
      });
    }
  }, [
    addNewPaymentMethod,
    stripeSetupData,
    selectedPaymentMethod,
    isUsingStripeElement,
    handlePaymentOptionUpdate,
  ]);

  if (!accountHolderEmail || isLoading) {
    return <div className="ds-spinner ds-spinner__m mx-auto my24" />;
  }

  return (
    <div>
      {!isUsingStripeElement && (
        <PaymentMethodRadio
          value={paymentType}
          onChange={handlePaymentTypeChange}
        />
      )}

      {(isUsingStripeElement || paymentType) && (
        <div className="mt16">
          {!hideDropdown && (
            <PaymentSelect
              selectedPaymentOption={selectedPaymentMethod}
              isUsingStripeElement={isUsingStripeElement}
              onChange={setSelectedPaymentMethod}
              setAddNewPayment={setAddNewPaymentMethod}
              paymentOptions={paymentOptions}
              paymentType={paymentType}
            />
          )}

          {selectedPaymentMethod?.type === 'SEPA' &&
            selectedPaymentMethod?.address && (
              <BillingAddress address={selectedPaymentMethod.address} />
            )}

          <AnimateHeight
            duration={300}
            height={addNewPaymentMethod === true ? 'auto' : 0}
          >
            {isUsingStripeElement ? (
              <StripeElementForm
                forceMakeDefault={forceMakeDefault}
                accountHolderEmail={accountHolderEmail}
                onSetupDataChange={onStripeSetupDataChange}
                onPaymentMethodTypeChange={onStripePaymentMethodTypeChange}
              />
            ) : (
              <>
                <PaymentForm
                  forceMakeDefault={forceMakeDefault}
                  accountHolderEmail={accountHolderEmail}
                  paymentType={paymentType}
                  onSetupDataChange={onSetupDataChange}
                  selectedPaymentMethod={selectedPaymentMethod}
                />
                {paymentType && <AgreementButton paymentType={paymentType} />}
              </>
            )}
          </AnimateHeight>
        </div>
      )}
    </div>
  );
};

export default PaymentMethodSelectorView;
