import { AxiosInstance } from 'axios';
import { InsuranceTypes } from 'models/insurances/types';
import { Policy } from 'models/policies';

import {
  Checkout,
  CheckoutInfo,
  CheckoutInfoRequest,
  CheckoutResult,
} from './paymentScreen.models';

type ProcessAndConfirmPayload = {
  paymentMethodId: string;
  localeId?: string;
};

type FinalizePayload = {
  paymentMethodId: string;
  paymentSucceeded: boolean;
};

export function startCheckout(
  network: AxiosInstance,
  data: CheckoutInfoRequest
) {
  return network.post<CheckoutInfo>('/checkout/generic/checkouts', data);
}

export function linkToStripe(network: AxiosInstance) {
  return network.post<unknown>('/account/link_to_stripe');
}

export function processCheckout(
  network: AxiosInstance,
  checkoutId: string,
  payload: ProcessAndConfirmPayload
) {
  return network.post<CheckoutResult>(
    `/checkout/generic/checkouts/${checkoutId}/process`,
    payload
  );
}

export function confirmCheckout(
  network: AxiosInstance,
  checkoutId: string,
  payload: ProcessAndConfirmPayload
) {
  return network.post<CheckoutResult>(
    `/checkout/generic/checkouts/${checkoutId}/confirm`,
    payload
  );
}

export function createGenericPolicyAfterPayment(
  network: AxiosInstance,
  data: {
    id: string;
    formId: string;
    insuranceType: InsuranceTypes;
    source?: Record<string, string>;
    referralCode?: string;
    region?: string;
    providerId?: string;
    price?: number;
  }
) {
  return network.post<Policy>('/generic_signups/policies', data);
}

export function finalizeCheckout(
  network: AxiosInstance,
  checkoutId: string,
  payload: FinalizePayload
) {
  return network.post<CheckoutResult>(
    `/checkout/generic/checkouts/${checkoutId}/finalize`,
    payload
  );
}

export function getCheckoutInfo(network: AxiosInstance, checkoutId: string) {
  return network.get<Checkout>(`/checkout/generic/checkouts/${checkoutId}`);
}
