import { trackStructuredEvent } from '@getpopsure/analytics';
import { Button } from '@popsure/dirty-swan';
import classNames from 'classnames';
import { updateChecklist } from 'features/studentChecklist/actions';
import {
  EStudentChecklistRequestTypes,
  StepProps,
} from 'features/studentChecklist/models';
import { getStudentChecklist } from 'features/studentChecklist/selectors';
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AppState } from 'reducers';
import { getRequestError, getRequestInProcess } from 'selectors/requests';
import { useSafeTranslation } from 'shared/i18n';

import style from './BankAccount.module.scss';
import { BankAccountModal } from './components';

export const BankAccount = ({ completed, submittedChecklist }: StepProps) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const { policyId } = useParams<{ policyId: string }>();
  const initialIban = useSelector(getStudentChecklist(policyId))?.iban;
  const dispatch = useDispatch();
  const loading = useSelector((state: AppState) =>
    getRequestInProcess(state, EStudentChecklistRequestTypes.UPDATE)
  );
  const error = useSelector((state: AppState) =>
    getRequestError(state, EStudentChecklistRequestTypes.UPDATE)
  );
  const { t } = useSafeTranslation();

  /**
   * Component clean-up
   */
  useEffect(() => {
    return () => {
      setOpenModal(false);
    };
  }, []);

  const toggleModal = () => setOpenModal((modal) => !modal);

  const handleSubmit = async (iban: string) => {
    trackStructuredEvent({
      category: 'STUDENT_CHECKLIST',
      action: 'SUBMITTED_BANK_ACCOUNT',
    });
    await dispatch(updateChecklist(policyId, { iban }));
    toggleModal();
  };

  if (submittedChecklist) return null;

  /**
   * Student Checklist does not allow edition of submitted data
   */
  if (completed) return null;

  return (
    <>
      <p className="p-p p-p--small tc-grey-500">
        {t(
          'studentChecklist.bankAccount.description',
          "If you're not in Germany yet, you can provide it when you arrive."
        )}
      </p>
      <Button
        data-testid="bank-account"
        className={classNames('w100 wmx3 mt8', style.button)}
        onClick={toggleModal}
      >
        {t('studentChecklist.bankAccount.button.title', 'Enter IBAN')}
      </Button>

      {openModal &&
        createPortal(
          <BankAccountModal
            toggleModal={toggleModal}
            onSubmit={handleSubmit}
            initialIban={initialIban}
            loading={loading}
            error={error?.message}
          />,
          document.body
        )}
    </>
  );
};
