import { Trans, useSafeTranslation } from 'shared/i18n';

import familyIcon from '../../../../assets/family.svg';
import { dependentPrices } from '../content';
import styles from './style.module.scss';

const DependentPrices = () => {
  const { t } = useSafeTranslation();

  return (
    <section className="p-container d-flex fd-column ai-center jc-center">
      <img src={familyIcon} alt="family icon" width={117} />
      <h2 className="p--serif p-h1 mt16 ta-center">
        {t(
          'expatEu.qnr.quote.dependentsPrice.title',
          'Want to cover loved ones?'
        )}
      </h2>
      <p className="p-p ta-center mt8">
        <Trans key="expatEu.qnr.quote.dependentsPrice.description">
          Each dependent can choose the plan that best fits their needs.
          <br />
          The price of their coverage will be adjusted based on their age.
        </Trans>
      </p>
      <div className={`${styles.tableContainer} mt32 mb96 w100 p32`}>
        <table className={`${styles.table} w100`}>
          <thead>
            <tr>
              <th className={`p-h4 ta-left ${styles.tableHeader}`}>
                {t('expatEu.qnr.quote.dependentsPrice.age.title', 'Age')}
              </th>
              <th className={`p-h4 ${styles.tableHeader}`}>
                <div className="p-h4">
                  {t('expatEu.qnr.quote.dependentsPrice.basic.title', 'Basic')}
                </div>
              </th>
              <th className={`p-h4 ${styles.tableHeader}`}>
                <div className="p-h4">
                  {t(
                    'expatEu.qnr.quote.dependentsPrice.premium.title',
                    'Premium'
                  )}
                </div>
              </th>
            </tr>
          </thead>
          {dependentPrices(t).map((data) => {
            const { age, basic, premium } = data;
            return (
              <tbody key={age}>
                <tr>
                  <td className="p-p tc-grey-700 py16">{age}</td>
                  <td className="p-h3 tc-purple-500 ta-center py16">{basic}</td>
                  <td className="p-h3 tc-purple-500 ta-center py16">
                    {premium}
                  </td>
                </tr>
              </tbody>
            );
          })}
        </table>
      </div>
    </section>
  );
};

export default DependentPrices;
