import { useQuery } from '@tanstack/react-query';
import { useFlag } from '@unleash/proxy-client-react';
import { fetchAccountInfo } from 'actions/user';
import { REGION_INSURANCE_TYPE_MAP } from 'features/policiesDashboard/constants';
import { formatPoliciesList } from 'features/policiesDashboard/utils/formatPoliciesList';
import {
  getAdditionalCoverage,
  getAdditionalCoverageMetadata,
} from 'features/policiesDashboard/utils/getAdditionalCoverage';
import { getAdditionalCoverageSuggestions } from 'features/policyRecommendations/api/additionalCoverage.api';
import { getShowReferralGenerator } from 'features/referralEngine';
import { useRegion } from 'hooks/useRegion';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAccountInfo } from 'selectors/user';
import { useSafeTranslation } from 'shared/i18n';

import { PoliciesDashboardView } from './PoliciesDashboard.view';
import { useGetPolicies } from './useGetPolicies';

export const PoliciesDashboard = () => {
  const { t } = useSafeTranslation();
  const dispatch = useDispatch();
  const user = useSelector(getAccountInfo);

  const { policies, isLoading, error } = useGetPolicies();

  const getFormattedPolicies = useCallback(
    () => formatPoliciesList(policies, user),
    [policies, user]
  );
  const { region } = useRegion();
  const isAdditionalCoverageV2 = useFlag('app_additional_coverage_v2');
  const regionFilter = REGION_INSURANCE_TYPE_MAP[region] ?? [];

  const showReferralGenerator = getShowReferralGenerator(policies);

  const additionalCoverageItemsFilteredByRegionV2 = getAdditionalCoverage(
    policies,
    t
  ).filter((coverage) => regionFilter.includes(coverage.type));
  const { data: additionalCoverageList } = useQuery({
    queryKey: ['additionalCoverage'],
    queryFn: getAdditionalCoverageSuggestions,
    initialData: [],
  });
  const additionalCoverageItemsFilteredByRegion = getAdditionalCoverageMetadata(
    additionalCoverageList,
    t
  ).filter((coverage) => regionFilter.includes(coverage.type));

  useEffect(() => {
    dispatch(fetchAccountInfo());
  }, [dispatch]);

  return (
    <PoliciesDashboardView
      additionalCoverageItems={
        isAdditionalCoverageV2
          ? additionalCoverageItemsFilteredByRegionV2
          : additionalCoverageItemsFilteredByRegion
      }
      error={error?.message}
      isLoading={!policies?.length && isLoading}
      formattedPolicies={getFormattedPolicies()}
      region={region}
      showReferralGenerator={showReferralGenerator}
    />
  );
};
