import { BottomOrRegularModal, Input } from '@popsure/dirty-swan';
import classNames from 'classnames';
import { APIResponseError } from 'models/error';
import { FormEvent, useState } from 'react';
import { Trans, useSafeTranslation } from 'shared/i18n';

import styles from './style.module.scss';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  email: string;
  loading?: boolean;
  error?: APIResponseError;
  handleSendNewCode: () => void;
  handleSignin: (loginCode: string) => void;
}

const LoginModalView = ({
  email,
  isOpen,
  handleClose,
  loading,
  error,
  handleSendNewCode,
  handleSignin,
}: Props) => {
  const { t } = useSafeTranslation();

  const [loginCode, setLoginCode] = useState('');

  const valid = loginCode.length > 0;

  const invalidLoginCode = error?.response?.status === 422;
  const errorMessage = invalidLoginCode
    ? t(
        'components.loginmodal.errors.invalidlogincode',
        'The code you entered was not recognized. Please try again or receive a new code'
      )
    : error?.message;

  return (
    <BottomOrRegularModal
      title={t('components.loginmodal.title', 'Sign in to Feather')}
      isOpen={isOpen}
      onClose={() => {
        setLoginCode('');
        handleClose();
      }}
    >
      <form
        className={`p-p pt16 p24 ${styles.container}`}
        onSubmit={(e: FormEvent) => {
          e.preventDefault();
          handleSignin(loginCode);
        }}
      >
        <p>
          {t(
            'components.loginmodal.description.1',
            'Looks like there’s already an account for that email address.'
          )}
        </p>
        <p className="mt16">
          <Trans i18nKey="components.loginmodal.description.2" email={email}>
            We’ve sent a code to <span className="fw-bold">{{ email }}</span>.
          </Trans>
        </p>
        <p>
          {t(
            'components.loginmodal.description.3',
            'Please enter the code below to log in.'
          )}
        </p>
        <button
          className={`p-a p-p c-pointer ${styles['send-code-button']}`}
          onClick={(e: FormEvent) => {
            e.preventDefault();
            handleSendNewCode();
          }}
          type="button"
        >
          {t('components.loginmodal.newcode.buttoncaption', 'Send new code')}
        </button>
        <Input
          className="mt16"
          value={loginCode}
          type="text"
          placeholder={t(
            'components.loginmodal.logincode.placeholder',
            'Temporary login code'
          )}
          onChange={(e) => {
            setLoginCode(e.target.value);
          }}
          required
          error={errorMessage}
          data-cy="account-signin-login-code-input"
        />
        <button
          className={classNames(
            `p-btn--primary mt24 wmn3 ${styles['signin-button']}`,
            {
              'p-btn--loading': loading,
            }
          )}
          type="submit"
          disabled={!valid}
          data-cy="account-signin-continue-button"
        >
          {t('components.loginmodal.signin.buttoncaption', 'Sign in')}
        </button>
      </form>
    </BottomOrRegularModal>
  );
};

export default LoginModalView;
