import { capitalizeName } from '@getpopsure/public-utility';
import {
  BottomOrRegularModal,
  Button,
  MultiDropzone,
  PlusIcon,
  toast,
} from '@popsure/dirty-swan';
import { useFlag } from '@unleash/proxy-client-react';
import type { ClaimActionDispatch } from 'actions/claims';
import { updateClaimDocuments } from 'actions/claims';
import classNames from 'classnames';
import { NavButton } from 'components/NavButton';
import routes from 'constants/routes';
import dayjs from 'dayjs';
import documentBoxIcon from 'features/claimsV2/icons/document-box.svg';
import {
  getClaimStatusInformation,
  getClaimTypeMapping,
} from 'features/claimsV2/utils';
import { TaskEngine } from 'features/taskEngine';
import { TaskError } from 'features/taskEngine/components/TaskError';
import { useRequestStatus } from 'hooks/useRequestStatus';
import { useUploadFiles } from 'hooks/useUploadFiles';
import {
  Claim,
  claimStatusesClassMapping,
  getClaimStatusesTextMapping,
} from 'models/claims';
import {
  getTitleMapping,
  imageTypeMapping,
} from 'models/insurances/types/mapping';
import { useLayoutEffect, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { TFunction } from 'shared/i18n';

import { StatusBadge } from '../statusBadge';
import { ClaimDescription } from './ClaimDescriptions';
import styles from './ClaimDetail.module.scss';
import { ClaimLegalDescription } from './ClaimLegalDescriptions';
import arrowRightIcon from './icons/arrowRight.svg';
import checkedIcon from './icons/checked.svg';
import chevronIcon from './icons/chevron.svg';
import documentIcon from './icons/document.svg';
import uploadIcon from './icons/upload.svg';

const DocumentCell = ({
  title,
  downloadUrl,
}: {
  title: string;
  downloadUrl: string;
}) => (
  <a
    href={downloadUrl}
    className={`mt16 p16 w100 ${styles.document}`}
    target="_blank"
    rel="noopener noreferrer"
    key={downloadUrl}
  >
    <div className="d-flex">
      <img src={documentIcon} alt={title} />
      <div className={`p-p ${styles.documentName}`}>{title}</div>
    </div>
  </a>
);

type ClaimDetailViewProps = {
  claim?: Claim;
  t: TFunction;
};

export const ClaimDetailView = ({ claim, t }: ClaimDetailViewProps) => {
  const [isDocumentOpen, setIsDocumentOpen] = useState(true);
  const [isClaimSubmittedOpen, setIsClaimSubmittedOpen] = useState(true);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const { pathname } = useLocation();
  const { loading, error: uploadError } = useRequestStatus(
    'UPDATE_CLAIM_DOCUMENTS'
  );

  const isLegalClaimsV2 = useFlag('app_legal_claims_v2');
  /**
   * We are using the admin_panel_claims_issues to handle both
   * issues and tasks on claims
   */
  const isTaskEngineClaims = useFlag('admin_panel_claims_issues');

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const dispatch = useDispatch<ClaimActionDispatch>();

  if (!claim) {
    throw new Error(
      '[CLAIMS] Trying to continue without Claim details or Policy detail'
    );
  }

  const {
    id,
    claimDate,
    claimTypes,
    documents,
    claimNumber,
    status: claimStatus,
    internalStatus,
    insuranceType,
    insuredPerson,
  } = claim;

  /**
   * Show documents when an expat claim is submitted to the provider,
   * and it's been less than 7 days since it was created
   */
  const updateExpirationDate = dayjs(claimDate).add(7, 'days');
  const isIncomingClaim =
    insuranceType === 'INCOMING' && internalStatus === 'SUBMITTED_TO_PROVIDER';
  const isIncomingClaimCanSubmitDocuments =
    isIncomingClaim && dayjs().isBefore(updateExpirationDate);
  const isIncomingClaimUpdateExpired =
    isIncomingClaim && dayjs().isAfter(updateExpirationDate);

  let claimInformation = getClaimStatusInformation(t, claim);
  const hasClaimTypes = claimTypes && claimTypes.length > 0;

  /**
   * Hide the claim information when the isTaskEngineClaims flag is enabled.
   *
   * We'll remove the information from the getClaimStatusInformation function
   * once we clean up the feature flag.
   */
  if (isTaskEngineClaims && claimStatus === 'ACTION_NEEDED') {
    claimInformation = undefined;
  }

  const hasConsultationBooking =
    isLegalClaimsV2 &&
    hasClaimTypes &&
    claimTypes.find(
      (type) =>
        type.claimType === 'BOOK_A_CONSULTATION' && insuranceType === 'LEGAL'
    );

  const claimDisplayTitle = hasClaimTypes
    ? getClaimTypeMapping(t, {
        insuranceType,
        claimType: claimTypes[0].claimType,
      })
    : null;

  const {
    uploadFile,
    uploadedFiles,
    loading: uploadLoading,
    removeFile: onRemoveFile,
    isSubmitValid,
    resetUploadedFiles,
  } = useUploadFiles({
    initialValue: [],
  });

  const onSubmit = async () => {
    if (!isSubmitValid) return;
    if (claimStatus !== 'ACTION_NEEDED' && !isIncomingClaimCanSubmitDocuments)
      return;

    const tokens = uploadedFiles.flatMap(({ token }) =>
      token !== undefined ? [token] : []
    );
    const { status } = await dispatch(updateClaimDocuments(id, tokens));
    if (status === 'SUCCESS') {
      setIsUploadModalOpen(false);
      resetUploadedFiles();
      toast(
        t(
          'claims.detail.expat.uploadDocuments.success',
          'Documents uploaded successfully'
        ),
        { type: 'success' }
      );
    }
  };

  const submittedDocuments = (documents ?? []).filter(
    ({ documentType }) => documentType === 'UPLOADED_BY_USER'
  );

  const providedDocuments = (documents ?? []).filter(
    ({ documentType }) => documentType !== 'UPLOADED_BY_USER'
  );

  const showDocuments = !['LEGAL'].includes(insuranceType);
  const showClaimNumber = !['LEGAL'].includes(insuranceType);

  return (
    <div className="p-body" data-testid="claim-detail-container">
      <BottomOrRegularModal
        isOpen={isUploadModalOpen}
        onClose={() => setIsUploadModalOpen(false)}
        title={t(
          'claims.detail.additionalInfo.modal.title',
          'Upload documents'
        )}
      >
        <div className="px24 pb24 p-p tc-grey-900 mt16">
          {t(
            'claims.detail.additionalInfo.modal.description',
            'We accept both scanned documents and photos of documents. Make sure that the text is readable before submitting. You can upload multiple files.'
          )}
          <div className="mt16">
            <MultiDropzone
              onFileSelect={uploadFile}
              uploadedFiles={uploadedFiles}
              uploading={uploadLoading}
              onRemoveFile={onRemoveFile}
            />
          </div>
          <AnimateHeight duration={300} height={uploadError ? 'auto' : 0}>
            <div className="p-notice p-notice--warning p-p mt16">
              {t(
                'policy.bike.frameNumberUploadDocument.error',
                'Something went wrong and we were unable to upload your documents. Please try again.'
              )}
            </div>
          </AnimateHeight>
          <Button
            type="button"
            className="ws3 mt16"
            disabled={!isSubmitValid}
            onClick={onSubmit}
            loading={loading}
          >
            {t('claims.detail.additionalInfo.modal.button', 'Send to Feather')}
          </Button>
        </div>
      </BottomOrRegularModal>
      <div className="p-body d-flex fd-column wmx10 ml-auto mr-auto">
        <NavButton
          tall={false}
          title={t('claims.detail.navButton.title', 'Claims')}
          path={routes.claims.path}
        />
        <div
          className={classNames(
            'ds-card ds-card--no-dropshadow p32 gap24 d-flex',
            styles.sectionContainer
          )}
        >
          <img
            className={classNames('br8', styles.icon, styles.img)}
            src={imageTypeMapping[insuranceType] ?? documentBoxIcon}
            alt="insurance icon"
          />
          <div
            className={classNames(
              'd-flex fd-column jc-center r-gap8',
              styles.infoContainer
            )}
          >
            <div className={styles.info}>
              <div className="p-h2 mr16">
                {claimDisplayTitle ? (
                  <>
                    <h1 className="p-h1">{claimDisplayTitle}</h1>
                    {claimTypes.length > 1 && <> +{claimTypes.length - 1}</>}
                  </>
                ) : (
                  <h1 className="p-h1">
                    {getTitleMapping(t)[insuranceType]}{' '}
                    {hasConsultationBooking
                      ? t(
                          'claims.claimDetail.title.post.consultation',
                          'consultation'
                        )
                      : t('claims.claimDetail.title.post.claim', 'claim')}
                  </h1>
                )}
              </div>
            </div>
            <div className={styles.info}>
              <div className="p-p--small tc-grey-600 fw-bold mr16">
                {dayjs(claimDate).format('DD MMM YYYY')}
              </div>
              {insuredPerson && (
                <div className="p-p--small tc-grey-600 fw-normal">
                  {capitalizeName(insuredPerson)}
                </div>
              )}
            </div>
          </div>
          <div
            className={classNames(
              'd-flex jc-between r-gap16',
              styles.statusContainer
            )}
          >
            <StatusBadge
              badgeColor={claimStatusesClassMapping[claimStatus]}
              title={getClaimStatusesTextMapping(t)[claimStatus]}
            />
            <div className="p-p--small tc-grey-600">
              {showClaimNumber && claimNumber ? `#${claimNumber}` : ''}
            </div>
          </div>
        </div>
        <TaskEngine
          options={{
            filter: (task) =>
              task.description.type === 'GENERIC' &&
              task.description.attributes?.claimId === claim.id,
          }}
          render={({ tasks, error }) => {
            if (error) return <TaskError error={error} className="mt24" />;

            if (tasks.length === 0) return null;

            return <div className="mt24 d-flex fd-column gap16">{tasks}</div>;
          }}
        />
        {claimInformation &&
          !(
            insuranceType === 'LEGAL' &&
            ['PROCESSING', 'SUBMITTED'].includes(claimStatus)
          ) && (
            <div className="ds-card ds-card--no-dropshadow mt24 p24 gap24 ">
              <div className={classNames('d-flex fd-row ai-start')}>
                <div className={classNames('ai-center mr16', styles.row)}>
                  <img src={arrowRightIcon} alt="arrow right" />
                </div>
                <div className="d-flex fd-column w90">
                  <div className={classNames('p-h3', styles.row)}>
                    {claimInformation.title}
                  </div>
                  {claimInformation.description &&
                    insuranceType !== 'PRIVATE_HEALTH' && (
                      <div
                        className={classNames('p-p tc-grey-600', styles.row)}
                      >
                        {claimInformation.description}{' '}
                        {isIncomingClaimCanSubmitDocuments &&
                          t(
                            'claims.detail.expat.canUploadDocuments',
                            'You can add more files to this claim if needed until {{date}}',
                            {
                              date: updateExpirationDate.format('DD MMM YYYY'),
                            }
                          )}
                        {isIncomingClaimUpdateExpired &&
                          t(
                            'claims.detail.expat.updateExpired',
                            'If you wish to add more details to this claim, reach out to us.'
                          )}
                      </div>
                    )}
                  {providedDocuments.length > 0 && (
                    <div className={classNames('mt16')}>
                      {providedDocuments.map(({ filename, publicUrl }) => (
                        <DocumentCell
                          title={filename}
                          downloadUrl={publicUrl}
                          key={publicUrl}
                        />
                      ))}
                    </div>
                  )}
                  {!isTaskEngineClaims && claimStatus === 'ACTION_NEEDED' && (
                    <div className="mt16">
                      <button
                        className="p-btn--primary d-flex gap8 ai-center mt16"
                        onClick={() => setIsUploadModalOpen(true)}
                        type="button"
                      >
                        <img src={uploadIcon} alt="upload" />
                        {t(
                          'claims.detail.additionalInfo.button',
                          'Upload documents'
                        )}
                      </button>
                    </div>
                  )}
                  {isIncomingClaimCanSubmitDocuments && (
                    <div>
                      <Button
                        variant="filledColor"
                        className="d-inline-flex ai-center mt8"
                        onClick={() => setIsUploadModalOpen(true)}
                        type="button"
                      >
                        <PlusIcon size={24} noMargin className="mr8" />
                        {t(
                          'claims.detail.expat.addDocuments.button',
                          'Add documents'
                        )}
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        {showDocuments && (
          <div className="ds-card ds-card--no-dropshadow mt24 p24 gap24">
            <button
              type="button"
              className="bg-white c-pointer d-flex df-row ai-start w100"
              onClick={() => setIsDocumentOpen(!isDocumentOpen)}
            >
              <span className={classNames('ai-center mr16', styles.row)}>
                <img src={checkedIcon} alt="Checked" />
              </span>
              <span className={classNames('p-h3 w100 jc-between', styles.row)}>
                {t(
                  'claims.detail.documentsProvided.title',
                  'Documents provided'
                )}
                <img
                  className={
                    isDocumentOpen ? styles.chevronOpen : styles.chevronClosed
                  }
                  src={chevronIcon}
                  alt="expand / collapse card"
                />
              </span>
            </button>
            <div className="d-flex fd-column w90 ml40">
              <AnimateHeight
                duration={300}
                height={isDocumentOpen ? 'auto' : 0}
              >
                {submittedDocuments.length > 0 && (
                  <div className={classNames('mt16')}>
                    {submittedDocuments.map(({ filename, publicUrl }) => (
                      <DocumentCell
                        title={filename}
                        downloadUrl={publicUrl}
                        key={publicUrl}
                      />
                    ))}
                  </div>
                )}
              </AnimateHeight>
            </div>
          </div>
        )}
        {insuranceType === 'LEGAL' ? (
          <ClaimLegalDescription
            claim={claim}
            t={t}
            isClaimSubmittedOpen={isClaimSubmittedOpen}
            setIsClaimSubmittedOpen={setIsClaimSubmittedOpen}
          />
        ) : (
          <ClaimDescription
            claim={claim}
            t={t}
            isClaimSubmittedOpen={isClaimSubmittedOpen}
            setIsClaimSubmittedOpen={setIsClaimSubmittedOpen}
          />
        )}
      </div>
    </div>
  );
};
