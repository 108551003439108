import dayjs from 'dayjs';

export const isInRightOfWithdrawalPeriod = ({
  nowDate,
  startDate,
}: {
  nowDate: string | number;
  startDate?: string;
}) => {
  if (!startDate) {
    return false;
  }

  return dayjs(nowDate).diff(startDate, 'days') <= 14;
};
