import {
  capitalizeName,
  englishFormattedEuroCurrency,
} from '@getpopsure/public-utility';
import classNames from 'classnames';
import dayjs from 'dayjs';
import Cell from 'features/checkout/components/Cell';
import { CheckoutPolicyBaseDetails } from 'features/checkout/models';
import { getCheckoutInfo } from 'features/checkout/selectors';
import {
  CheckoutPolicyDetails,
  LegalGuardian,
  mapPlanId,
} from 'features/expatLongTerm/models';
import { ReferrerCodeVerify } from 'features/referralEngine';
import { useSelector } from 'react-redux';
import { useSafeTranslation } from 'shared/i18n';

import styles from './PolicyDetails.module.scss';

const DATE_FORMAT = 'DD MMM YYYY';

type ExpatLongTermPolicyDetails = CheckoutPolicyBaseDetails &
  CheckoutPolicyDetails;

export const PolicyDetails = ({
  additionalData,
}: {
  additionalData?: Record<string, unknown>;
}) => {
  const { t } = useSafeTranslation();

  const checkoutInfo = useSelector(
    getCheckoutInfo<CheckoutPolicyDetails>('INCOMING_LT')
  );

  if (!checkoutInfo) {
    return <></>;
  }

  const { mainPolicy, totalMonthlyPrice } = checkoutInfo;

  const getLegalGuardian = (
    policyDetails: ExpatLongTermPolicyDetails
  ): LegalGuardian | null => {
    if (policyDetails.isMainPolicyTheLegalGuardian)
      return mainPolicy.policyHolder;

    if (policyDetails.legalGuardian) return policyDetails.legalGuardian;

    return null;
  };

  if (additionalData && additionalData.type === 'Dependent') {
    // do something dependent specific
    if (!checkoutInfo.subPolicies || checkoutInfo.subPolicies.length === 0) {
      // then bailout
      return <></>;
    }

    return (
      <div className={classNames(styles.container, 'br8 p24 bg-white')}>
        <div className="p-h2 mb16">
          {t(
            'expatLongTerm.qnr.checkout.policyDetails.title',
            'Policy details'
          )}
        </div>
        {checkoutInfo.subPolicies.map(
          ({ policyHolder, startDate, id, policyDetails }) => {
            const legalGuardian = getLegalGuardian(policyDetails);
            return (
              <div key={id}>
                <Cell
                  title={t(
                    'expatLongTerm.qnr.checkout.policyDetails.plan',
                    'Plan'
                  )}
                  value={mapPlanId[mainPolicy.policyDetails.planId]}
                />
                <Cell
                  title={t(
                    'expatLongTerm.qnr.checkout.policyDetails.name',
                    'Name'
                  )}
                  value={capitalizeName(policyHolder.name) ?? ''}
                />
                <Cell
                  title={t(
                    'expatLongTerm.qnr.checkout.policydetails.requestedStartDate',
                    'Requested start date'
                  )}
                  value={dayjs(startDate).format(DATE_FORMAT)}
                  additionalInfo={{
                    title: t(
                      'expatLongTerm.qnr.checkout.policydetails.requestedStartDate.additionalInfo.title',
                      'Requested start date'
                    ),
                    description: t(
                      'expatLongTerm.qnr.checkout.policydetails.requestedStartDate.additionalInfo.description',
                      'Your coverage should begin on {{coverageDate}}, but only after you have received the official confirmation.',
                      {
                        coverageDate: dayjs(startDate).format(DATE_FORMAT),
                      }
                    ),
                  }}
                />
                <Cell
                  title={t(
                    'expatLongTerm.qnr.checkout.policyDetails.dateOfBirth',
                    'Date of Birth'
                  )}
                  value={dayjs(policyHolder.dateOfBirth).format(DATE_FORMAT)}
                />
                <div>
                  {legalGuardian && (
                    <>
                      <div className="p-h3 mt24">
                        {t(
                          'expatLongTerm.qnr.checkout.policyDetails.subTitle',
                          'Parent / Legal guardian'
                        )}
                      </div>
                      <Cell
                        title={t(
                          'expatLongTerm.qnr.checkout.policyDetails.name',
                          'Name'
                        )}
                        value={capitalizeName(legalGuardian.name) ?? ''}
                      />
                      <Cell
                        title={t(
                          'expatLongTerm.qnr.checkout.policyDetails.dateOfBirth',
                          'Date of Birth'
                        )}
                        value={dayjs(legalGuardian.dateOfBirth).format(
                          DATE_FORMAT
                        )}
                      />
                      <Cell
                        title={t(
                          'expatLongTerm.qnr.checkout.policyDetails.email',
                          'Email'
                        )}
                        value={legalGuardian.email}
                      />
                    </>
                  )}
                </div>
              </div>
            );
          }
        )}
        <div
          className={`mt24 p-h3 d-flex jc-between pt16 ${styles['price-container']}`}
        >
          <div>
            {t(
              'expatLongTerm.qnr.checkout.policyDetails.totalPerMonth',
              'Total per month'
            )}
          </div>
          {totalMonthlyPrice && (
            <div>{englishFormattedEuroCurrency(totalMonthlyPrice)}</div>
          )}
        </div>
        <ReferrerCodeVerify />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className="p-h2 mb16">
        {t('expatLongTerm.qnr.checkout.policyDetails.title', 'Policy details')}
      </div>
      <Cell
        title={t('expatLongTerm.qnr.checkout.policyDetails.name', 'Name')}
        value={capitalizeName(mainPolicy.policyHolder.name) ?? ''}
      />
      <Cell
        title={t('expatLongTerm.qnr.checkout.policyDetails.plan', 'Plan')}
        value={mapPlanId[mainPolicy.policyDetails.planId]}
      />
      <Cell
        title={t(
          'expatLongTerm.qnr.checkout.policydetails.requestedStartDate',
          'Requested start date'
        )}
        value={dayjs(mainPolicy.startDate).format(DATE_FORMAT)}
        additionalInfo={{
          title: t(
            'expatLongTerm.qnr.checkout.policydetails.requestedStartDate.additionalInfo.title',
            'Requested start date'
          ),
          description: t(
            'expatLongTerm.qnr.checkout.policydetails.requestedStartDate.additionalInfo.description',
            'Your coverage should begin on {{coverageDate}}, but only after you have received the official confirmation.',
            {
              coverageDate: dayjs(mainPolicy.startDate).format(DATE_FORMAT),
            }
          ),
        }}
      />
      <Cell
        title={t(
          'expatLongTerm.qnr.checkout.policyDetails.dateOfBirth',
          'Date of Birth'
        )}
        value={dayjs(mainPolicy.policyHolder.dateOfBirth).format(DATE_FORMAT)}
      />
      <Cell
        title={t('expatLongTerm.qnr.checkout.policyDetails.email', 'Email')}
        value={mainPolicy.policyHolder.email}
      />
      {mainPolicy.policyDetails.legalGuardian && (
        <>
          <div className="p-h3 mt24">
            {t(
              'expatLongTerm.qnr.checkout.policyDetails.legalGuardian.subTitle',
              'Parent / Legal guardian'
            )}
          </div>
          <Cell
            title={t(
              'expatLongTerm.qnr.checkout.policyDetails.legalGuardian.name',
              'Name'
            )}
            value={
              capitalizeName(mainPolicy.policyDetails.legalGuardian.name) ?? ''
            }
          />
          <Cell
            title={t(
              'expatLongTerm.qnr.checkout.policyDetails.legalGuardian.dateOfBirth',
              'Date of Birth'
            )}
            value={dayjs(
              mainPolicy.policyDetails.legalGuardian.dateOfBirth
            ).format(DATE_FORMAT)}
          />
          <Cell
            title={t(
              'expatLongTerm.qnr.checkout.policyDetails.legalGuardian.email',
              'Email'
            )}
            value={mainPolicy.policyDetails.legalGuardian.email}
          />
        </>
      )}
      <div className={`bg-grey-200 mt32 mb32 ${styles.divider}`} />
      {mainPolicy.monthlyPrice && (
        <div className="d-flex">
          <div className="p-h3">
            {t(
              'expatLongTerm.qnr.checkout.policyDetails.total',
              'Total per month'
            )}
          </div>
          <div className="p-h3 ml-auto">
            {englishFormattedEuroCurrency(totalMonthlyPrice)}
          </div>
        </div>
      )}
      <ReferrerCodeVerify />
    </div>
  );
};
