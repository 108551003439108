import bank from 'features/paymentMethodSelector/icons/bank.svg';
import card from 'features/paymentMethodSelector/icons/card.svg';
import { PaymentType } from 'models/paymentMethods';
import { useSafeTranslation } from 'shared/i18n';

import styles from './style.module.scss';

export interface PaymentMethodRadioProps {
  value?: PaymentType;
  onChange: (option: PaymentType) => void;
}

export const PaymentMethodRadio = ({
  value,
  onChange,
}: PaymentMethodRadioProps) => {
  const { t } = useSafeTranslation();

  return (
    <div className="p-label-container d-flex jc-between">
      <input
        id="sepa"
        className="p-radio"
        type="radio"
        value="SEPA"
        onChange={() => {
          onChange('SEPA');
        }}
        name="payment-method-radio"
        checked={value === 'SEPA'}
      />
      <label
        htmlFor="sepa"
        className={`p-label p-label--bordered mr8 pr16 w100 ${styles['radio-label']}`}
        data-cy="payment-info-button-sepa"
      >
        <img className="mr8" src={bank} alt="sepa" />
        <div className="p-p">
          {t('components.paymentMethodSelector.bank', 'Bank')}
        </div>
      </label>
      <input
        id="card"
        className="p-radio"
        type="radio"
        value="CARD"
        name="payment-method-radio"
        onChange={() => {
          onChange('CARD');
        }}
        checked={value === 'CARD'}
      />
      <label
        htmlFor="card"
        className={`p-label p-label--bordered  pr16 w100 ${styles['radio-label']}`}
        data-cy="payment-info-button-card"
      >
        <img className="mr8" src={card} alt="card" />
        <div className="p-p">
          {t('components.paymentMethodSelector.card', 'Card')}
        </div>
      </label>
    </div>
  );
};
