import { hasAutomatedCancelProcess } from 'models/insurances/types/mapping';

import { ViewProps } from '../../cancelModal/types';
import { CancelAutomatedModal } from './cancelAutomatedModal';
import { CancelDentalModal } from './cancelDentalModal';
import { CancelExpatEuModal } from './cancelExpatEuModal';
import { CancelExpatHealthModal } from './cancelExpatHealthModal';
import { CancelExpatSpainModal } from './cancelExpatSpainModal';
import { CancelManuallyModal } from './cancelManuallyModal';
import { CancelPublicHealthModal } from './cancelPublicHealthModal';

const CancelModal = (props: ViewProps) => {
  const {
    type,
    activeUntil,
    onCloseModal,
    isOpen,
    onMarkPolicyDroppedOut,
    policyStatus,
    providerName,
    policyNumber,
  } = props;

  if (hasAutomatedCancelProcess[type]) {
    // Expat health
    if (type === 'INCOMING') {
      return <CancelExpatHealthModal {...props} />;
    }

    if (type === 'DENTAL') {
      return <CancelDentalModal {...props} />;
    }

    if (type === 'INCOMING_ES') {
      return <CancelExpatSpainModal {...props} />;
    }

    if (type === 'INCOMING_EU') {
      return <CancelExpatEuModal {...props} />;
    }

    // Any other policy with automated cancelation
    return <CancelAutomatedModal {...props} />;
  }

  // Policy is public health (and has to be manually canceled)
  if (type === 'PUBLIC_HEALTH') {
    return (
      <CancelPublicHealthModal
        onCloseModal={onCloseModal}
        isOpen={isOpen}
        onMarkPolicyDroppedOut={onMarkPolicyDroppedOut}
        policyStatus={policyStatus}
        providerName={providerName}
      />
    );
  }

  // Any other policy that has to be manually canceled
  return (
    <CancelManuallyModal
      activeUntil={activeUntil}
      onCloseModal={onCloseModal}
      isOpen={isOpen}
      policyNumber={policyNumber}
    />
  );
};

export default CancelModal;
