import { setRequestErrored, setRequestInProcess } from 'actions/request';
import { APIResponseError } from 'models/error';
import {
  MailingListPreferences,
  UpdateMailingPreference,
} from 'models/mailingPreferences';
import { AppState } from 'reducers';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import endpoint from 'shared/api';

export const storeMailingPreferences = (
  mailingListPreferences: MailingListPreferences
) => ({
  type: 'STORE_MAILING_PREFERENCES',
  mailingListPreferences,
});

export const fetchMailingPreferences =
  () => async (dispatch: ThunkDispatch<void, AppState, AnyAction>) => {
    const requestType = 'ACCOUNT';
    dispatch(setRequestInProcess(true, requestType));

    try {
      const {
        data: { mailingListOptIns },
      } = await endpoint.getMailingPreferences();

      dispatch(storeMailingPreferences(mailingListOptIns));
      dispatch(setRequestInProcess(false, requestType));
    } catch (error) {
      dispatch(setRequestErrored(error as APIResponseError, requestType));
    }
  };

export const updateMailingPreferences =
  (options: UpdateMailingPreference) =>
  async (dispatch: ThunkDispatch<void, AppState, AnyAction>) => {
    const requestType = 'UPDATE_MAILING_PREFERENCES';
    dispatch(setRequestInProcess(true, requestType));

    try {
      const {
        data: { mailingListOptIns },
      } = await endpoint.updateMailingPreferences(options);

      dispatch(storeMailingPreferences(mailingListOptIns));
      dispatch(setRequestInProcess(false, requestType));
    } catch (error) {
      dispatch(setRequestErrored(error as APIResponseError, requestType));
    }
  };
