import { website } from '@getpopsure/private-constants';
import { getCurrentClaim } from 'actions/claims';
import { ErrorWithAction } from 'components/ErrorWithAction';
import TimedLoadSpinner from 'components/timedLoadSpinner';
import { useRequestStatus } from 'hooks/useRequestStatus';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getCurrentClaim as getCurrentClaimState } from 'selectors/claims';
import { useSafeTranslation } from 'shared/i18n';

import { ClaimDetailView } from './ClaimDetail.view';

export const ClaimDetail = () => {
  const { t } = useSafeTranslation();
  const claim = useSelector(getCurrentClaimState);
  const { claimId }: { claimId: string } = useParams();

  const dispatch = useDispatch();
  const { loading, error } = useRequestStatus('GET_CURRENT_CLAIM');

  useEffect(() => {
    dispatch(getCurrentClaim(claimId));
  }, [dispatch, claimId]);

  if (loading || (!claim && !error)) {
    return <TimedLoadSpinner />;
  }

  if (error) {
    return (
      <ErrorWithAction
        title={t('claims.detail.error.title', 'Something went wrong')}
        description={t(
          'claims.detail.error.description',
          'Sorry, there was a problem loading your claim. If the problem persists, please contact support.'
        )}
        cta={{
          title: t('claims.detail.error.button.title', 'Contact support'),
          onClick: () => {
            window.location.href = website.support;
            return null;
          },
        }}
      />
    );
  }

  return <ClaimDetailView claim={claim} t={t} />;
};
