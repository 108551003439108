import * as Sentry from '@sentry/react';
import { setRequestErrored, setRequestInProcess } from 'actions/request';
import { ClaimsAction, RequestAction } from 'constants/actions';
import { RequestType } from 'constants/requestTypes';
import { ClaimSubmitInfo } from 'models/claims';
import { APIResponseError } from 'models/error';
import { AppState } from 'reducers';
import { ThunkDispatch } from 'redux-thunk';
import endpoint from 'shared/api';
import { ActionResponse } from 'shared/models/types';

import {
  LegalBookConsultation,
  LegalSubmitClaims,
  ZSubmitClaimInfo,
  ZSubmitLegalBookConsultation,
} from './models';

export const submitClaim =
  (policyId: string, answers: Partial<LegalSubmitClaims>) =>
  async (
    dispatch: ThunkDispatch<AppState, unknown, RequestAction | ClaimsAction>
  ): Promise<ActionResponse> => {
    const requestType: RequestType = 'CREATE_CLAIM';
    try {
      if (!policyId) {
        throw new Error(
          '[Legal Submit Claims] Policy ID is missing for submitting claim.'
        );
      }

      const validationResult = ZSubmitClaimInfo.safeParse(answers);

      if (!validationResult.success) {
        throw new Error(
          `[LEGAL - Submit Claims] Questionnaire answer validation errors: ${validationResult.error.toString()}`
        );
      }

      const validatedData = validationResult.data;

      const claimInfo: ClaimSubmitInfo = {
        claimTypes: [{ claimType: 'LEGAL_CLAIM' }],
        userPolicyId: policyId,
        description: validatedData.description,
        phoneConsultation: validatedData.phoneConsultation,
        phoneNumber: validatedData.phoneNumber,
      };

      dispatch(setRequestInProcess(true, requestType));

      await endpoint.submitClaim({ type: 'LEGAL', ...claimInfo });
      dispatch(setRequestInProcess(false, requestType));
      return 'SUCCESS';
    } catch (error) {
      Sentry.captureException(error);
      dispatch(setRequestErrored(error as APIResponseError, requestType));
      return 'ERROR';
    }
  };

export const submitBookConsultation =
  (policyId: string, answers: Partial<LegalBookConsultation>) =>
  async (
    dispatch: ThunkDispatch<AppState, unknown, RequestAction | ClaimsAction>
  ): Promise<ActionResponse> => {
    const requestType: RequestType = 'CREATE_CLAIM';

    try {
      if (!policyId) {
        throw new Error(
          '[Legal Book Consultation] Policy ID is missing for submitting claim.'
        );
      }

      const validationResult = ZSubmitLegalBookConsultation.safeParse(answers);

      if (!validationResult.success) {
        throw new Error(
          `[LEGAL - Book Consultation] Questionnaire answer validation errors: ${validationResult.error.toString()}`
        );
      }

      const validatedData = validationResult.data;

      const claimInfo: ClaimSubmitInfo = {
        claimTypes: [{ claimType: 'BOOK_A_CONSULTATION' }],
        userPolicyId: policyId,
        expectedOutcome: validatedData.expectedOutcome,
        description: validatedData.description,
        phoneNumber: validatedData.phoneNumber,
        timeDate: validatedData.timeDate,
      };

      dispatch(setRequestInProcess(true, requestType));

      await endpoint.submitClaim({ type: 'LEGAL', ...claimInfo });
      dispatch(setRequestInProcess(false, requestType));
      return 'SUCCESS';
    } catch (error) {
      Sentry.captureException(error);
      dispatch(setRequestErrored(error as APIResponseError, requestType));
      return 'ERROR';
    }
  };
