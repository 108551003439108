import { BikeClaims } from 'features/bikeClaims/models';
import { CustomComponentProps } from 'models/questionnaireFramework';
import { FormEvent, useState } from 'react';
import { useSafeTranslation } from 'shared/i18n';
import { PayoutDetails } from 'shared/models/types';

import { BikeClaimsPayoutDetailsView } from './PayoutDetails.view';

const MIN_AMOUNT = 0.01;

export const BikeClaimsPayoutDetails = ({
  value,
  onSubmitValue,
}: CustomComponentProps<BikeClaims>) => {
  const { t } = useSafeTranslation();

  const [payoutDetails, setPayoutDetails] = useState<PayoutDetails>(
    (value as PayoutDetails) ?? { amount: '', iban: '' }
  );

  const isSubmissionValid =
    Boolean(payoutDetails.amount) &&
    Number(payoutDetails.amount) >= MIN_AMOUNT &&
    Boolean(payoutDetails.iban);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    onSubmitValue(payoutDetails);
  };

  return (
    <BikeClaimsPayoutDetailsView
      handleSubmit={handleSubmit}
      t={t}
      payoutDetails={payoutDetails}
      setPayoutDetails={setPayoutDetails}
      validForSubmission={isSubmissionValid}
      minAmount={MIN_AMOUNT}
    />
  );
};
