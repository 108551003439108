import { PoliciesAction } from 'constants/actions';
import { Policy } from 'models/policies';
import { Reducer } from 'redux';

export interface PoliciesState {
  items?: { [id: string]: Policy };
}

export const defaultState = {};

const policies: Reducer<PoliciesState, PoliciesAction> = (
  state = defaultState,
  action
): PoliciesState => {
  switch (action.type) {
    case 'ME_MERGE_POLICIES':
      let flattenItems: { [id: string]: Policy } = {};
      action.items.forEach((item) => {
        flattenItems = { ...flattenItems, [item.id]: item };
      });
      return { ...state, items: flattenItems };
    case 'ME_MERGE_POLICY_DETAIL':
      return {
        ...state,
        items: { ...state.items, [action.item.id]: action.item },
      };
    case 'ME_MERGE_POLICY_ATTR': {
      if (!state.items || !state.items[action.policyId]) return state;

      return {
        ...state,
        items: {
          ...state.items,
          [action.policyId]: {
            ...state.items[action.policyId],
            attributes: {
              ...(state.items[action.policyId]?.attributes || {}),
              ...action.attr,
            },
          },
        },
      };
    }

    case 'SHOW_HEALTH_CARD_CONFIRMATION': {
      if (!state.items || !state.items[action.policyId]) return state;

      return {
        ...state,
        items: {
          ...state.items,
          [action.policyId]: {
            ...state.items[action.policyId],
            attributes: {
              ...(state.items[action.policyId]?.attributes || {}),
              showHealthCardConfirmation: true,
            },
          },
        },
      };
    }
    default:
      return state;
  }
};

export default policies;
