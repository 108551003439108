import { fetchPolicies } from 'actions/policies';
import TimedLoadSpinner from 'components/timedLoadSpinner';
import { useRequestStatus } from 'hooks/useRequestStatus';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMyPolicies } from 'selectors/policies';
import { useSafeTranslation } from 'shared/i18n';

import { CreateClaimView } from './CreateClaim.view';

export const CreateClaim = () => {
  const { t } = useSafeTranslation();
  const policies = useSelector(getMyPolicies) ?? [];
  const hasFetchedPolicies = useRef(false);

  const dispatch = useDispatch();
  const { loading } = useRequestStatus('LOAD_MY_POLICIES');

  const showLoadingSpinner = loading || !hasFetchedPolicies.current;

  useEffect(() => {
    if (!hasFetchedPolicies.current) {
      dispatch(fetchPolicies());
      hasFetchedPolicies.current = true;
    }
  }, [dispatch, showLoadingSpinner]);

  if (showLoadingSpinner) {
    return <TimedLoadSpinner delayInMilliseconds={200} />;
  }

  return <CreateClaimView policies={policies} t={t} />;
};
