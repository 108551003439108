import {
  Alpha2CountryCode,
  associatedCountryFlagForCountryCode,
  Region,
} from '@getpopsure/public-models';
import { Header } from '@getpopsure/qnr-framework';
import { AutoSuggestInput, Button, illustrations } from '@popsure/dirty-swan';
import { useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { useSafeTranslation } from 'shared/i18n';

import styles from './RegionSelector.module.scss';

const noop = () => {};

interface RegionSelectorProps {
  onSubmit: (
    selectedRegion: string,
    setError: (error: string | null) => void
  ) => void;
  regions: Partial<Record<Region, string>>;
  selectedRegion?: string;
}

export const RegionSelector = ({
  onSubmit,
  regions,
  selectedRegion: defaultSelectedRegion = '',
}: RegionSelectorProps) => {
  const allSuggestions = Object.entries(regions).map(([shortCode, region]) => ({
    value: region,
    leftIcon: associatedCountryFlagForCountryCode(
      shortCode.toUpperCase() as Alpha2CountryCode
    ),
  }));

  const { t } = useSafeTranslation();
  const [error, setError] = useState<string | null>(null);
  const [selectedRegion, setSelectedRegion] = useState(defaultSelectedRegion);
  const [suggestions, setSuggestions] = useState(allSuggestions);

  const handleSubmit = () => onSubmit(selectedRegion, setError);

  return (
    <Header onSubmit={handleSubmit}>
      <div className={styles.container}>
        <div className="p-body">
          <img src={illustrations.worldwide} alt="world wide" width={80} />
          <h1 className="p-h1 mt8">
            {t(
              'components.regionSelector.title',
              'Which country do you want to be insured in?'
            )}
          </h1>
          <div className="mt16 w100 wmx6">
            <AutoSuggestInput
              placeholder={t(
                'components.regionSelector.placeholder',
                'Country'
              )}
              currentOption={selectedRegion ?? ''}
              suggestions={suggestions}
              onChange={(value) => {
                setError(null);
                setSelectedRegion(value);
              }}
              handleSuggestionSelected={(value) => {
                setSelectedRegion(value.value);
              }}
              handleSuggestionFetchRequest={({ value: val }) => {
                const filteredOptions = allSuggestions.filter(({ value }) =>
                  value.toLowerCase().startsWith(val.toLowerCase())
                );
                if (val.length > 1) {
                  setSuggestions(filteredOptions);
                } else {
                  setSuggestions(allSuggestions);
                }
              }}
              handleSuggestionClearRequest={noop}
              wrapText
            />
          </div>
          <AnimateHeight duration={300} height={error ? 'auto' : 0}>
            <div className="p-notice p-notice--warning mt24 p-p wmx6">
              {error}
            </div>
          </AnimateHeight>
          <div className={`d-flex f-wrap mt24 ${styles.buttonContainer}`}>
            <Button
              className="w100"
              disabled={!selectedRegion}
              onClick={handleSubmit}
              data-cy="button-continue"
            >
              {t('components.regionSelector.submitButton', 'Continue')}
            </Button>
          </div>
        </div>
      </div>
    </Header>
  );
};
