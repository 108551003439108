import { Card, illustrations } from '@popsure/dirty-swan';
import routes from 'constants/routes';
import { flushBikeClaimsAnswers } from 'features/bikeClaims/actions';
import { BikeClaims } from 'features/bikeClaims/models';
import { CustomComponentProps } from 'models/questionnaireFramework';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useSafeTranslation } from 'shared/i18n';
import { ClaimSubmitted } from 'shared/pages/ClaimSubmitted';

export const BikeClaimsSubmitted = ({
  questionnaireAnswers: { claimType },
}: CustomComponentProps<BikeClaims>) => {
  const { t } = useSafeTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const handleUnmount = () => {
      dispatch(flushBikeClaimsAnswers());
    };

    return handleUnmount;
  }, [dispatch]);

  const stolenOrFullyDamaged =
    claimType === 'STOLEN' || claimType === 'FULLY_DAMAGED';

  const redirectToBikeSignupFlow = () => {
    history.push(routes.policies.bike.path);
  };

  return (
    <ClaimSubmitted vertical="bike">
      {stolenOrFullyDamaged && (
        <Card
          actionIcon={null}
          classNames={{ buttonWrapper: 'mt24 wmx8' }}
          description={t(
            'claims.bike.claimSubmitted.card.description',
            'Your replacement bike will need a new policy, you can sign up for it now!'
          )}
          icon={
            <img
              alt={t('claims.bike.claimSubmitted.card.imgAlt', 'purple bike')}
              src={illustrations.bikeRiding}
              width={48}
              className="mr24"
            />
          }
          onClick={redirectToBikeSignupFlow}
          title={t(
            'claims.bike.claimSubmitted.card.title',
            'Cover your new bike'
          )}
        />
      )}
    </ClaimSubmitted>
  );
};
