import { TFunction, useTranslation } from '@getpopsure/i18n-react';
import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import { Radio } from '@popsure/dirty-swan';
import classNames from 'classnames';
import { ReactNode } from 'react';

import {
  DentalQuotePlan,
  Plan,
  planSummaryMapping,
  QuoteCustomization,
} from '../../../models';
import checkIcon from './icons/check.svg';
import styles from './style.module.scss';

type SelectorProps = {
  selectedPlan: Plan;
  setQuoteCustomization: React.Dispatch<
    React.SetStateAction<QuoteCustomization>
  >;
  planInfoMapping: DentalQuotePlan;
};

export const Selector = ({
  selectedPlan,
  setQuoteCustomization,
  planInfoMapping,
}: SelectorProps) => {
  const { t } = useTranslation();

  const plan = selectedPlan ?? 'BASIC';

  return (
    <Radio
      options={getRadioOptions(t, planInfoMapping)}
      onChange={(value) =>
        setQuoteCustomization((prevState) => ({
          ...prevState,
          plan: value,
        }))
      }
      value={plan}
      wide
      classNames={{
        container: classNames(
          'd-flex jc-center gap24 fd-column',
          styles.radioContainer
        ),
        label: classNames(
          'd-flex jc-start ai-start fd-column gap8',
          styles.radioLabel
        ),
        option: classNames('ws4', styles.radioOption),
      }}
    />
  );
};

type QuotePlanRadio = Record<Plan, { title: ReactNode; icon: () => ReactNode }>;

export const getRadioOptions = (
  t: TFunction,
  planMapping: DentalQuotePlan
): QuotePlanRadio => {
  return Object.keys(planMapping).reduce<QuotePlanRadio>(
    (options, currentPlan) => {
      return {
        ...options,
        [currentPlan]: {
          icon: () => (
            <img src={planMapping[currentPlan as Plan].icon} alt="" />
          ),
          title: (
            <>
              <p className="p-h2 ta-center">
                {planMapping[currentPlan as Plan].name}
              </p>
              <p className="p--serif p-p--small tc-primary-500 ta-center">
                <span className="p-h1 tc-primary-500">
                  {englishFormattedEuroCurrency(
                    planMapping[currentPlan as Plan].price,
                    true
                  )}
                </span>
                {t('dental.qnr.preQuote.quote.selector.perMonth', '/mo')}
              </p>
              <ul className="mt16 d-flex gap8 fd-column">
                {planSummaryMapping(t)[currentPlan as Plan].map((item) => (
                  <li className="d-flex gap8 ai-start" key={item}>
                    <img
                      src={checkIcon}
                      alt={t(
                        'dental.qnr.preQuote.quote.selector.icon.alt',
                        'Icon of a checkmark'
                      )}
                    />{' '}
                    <span className="p-p tc-grey-600">{item}</span>
                  </li>
                ))}
              </ul>
            </>
          ),
        },
      };
    },
    {} as QuotePlanRadio
  );
};
