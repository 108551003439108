import { TFunction } from '@getpopsure/i18n-react';
import {
  CalendarIcon,
  ClockTimeIcon,
  DentalPlusIcon,
  DentalToothTeethIcon,
  DentalTreatmentToothIcon,
  LoaderIcon,
  SmileIcon,
  ToothFillingCrackedIcon,
} from '@popsure/dirty-swan';

import { AdditionalInfoType } from '../../types';

export const dental = (t: TFunction): AdditionalInfoType => ({
  price: 10.9,
  description: t(
    'policyModal.dental.description',
    'Supplementary coverage on top of public health insurance. Since public insurance only covers basic dental needs, many people in Germany take additional coverage for dental cleanings and treatments.'
  ),
  accordions: [
    {
      title: t('policyModal.dental.title1', "What's covered"),
      items: [
        {
          id: 'covered-1',
          icon: <DentalToothTeethIcon size={24} />,
          question: t(
            'policyModal.dental.covered1.question',
            'Professional dental cleanings'
          ),
          answer: t(
            'policyModal.dental.covered1.answer',
            'Professional cleanings are covered. Without the insurance they usually cost between €85 and €125, and public insurance only covers a small portion of it, if anything at all.'
          ),
        },
        {
          id: 'covered-2',
          icon: <DentalTreatmentToothIcon size={24} />,
          question: t(
            'policyModal.dental.covered2.question',
            'Dental treatments'
          ),
          answer: t(
            'policyModal.dental.covered2.answer',
            'E.g. root canal treatments, mouth guards, pain relief treatments, fluoride for strengthening enamel, sensitivity treatments, and fissure sealant for reducing tooth decay.'
          ),
        },
        {
          id: 'covered-3',
          icon: <ToothFillingCrackedIcon size={24} />,
          question: t(
            'policyModal.dental.covered3.question',
            'High-quality fillings'
          ),
          answer: t(
            'policyModal.dental.covered3.answer',
            'Public insurance will only cover amalgam fillings, which has a metallic silver color. This insurance covers high-quality fillings, giving them a color match.'
          ),
        },
        {
          id: 'covered-4',
          icon: <SmileIcon size={24} />,
          question: t(
            'policyModal.dental.covered4.question',
            'Tooth & jaw alignment (under 21)'
          ),
          answer: t(
            'policyModal.dental.covered4.answer',
            'For treatments that start before you turn 21, you have access to braces and aligners. Limits apply in the first years of the policy.'
          ),
        },
      ],
    },
    {
      title: t('policyModal.dental.title2', 'Add-ons'),
      items: [
        {
          id: 'addons-1',
          icon: <DentalPlusIcon size={24} />,
          question: t(
            'policyModal.dental.addons1.question',
            'Tooth replacement'
          ),
          answer: t(
            'policyModal.dental.addons1.answer',
            'Our Advanced dental insurance will also add benefits for the most expensive operations like getting crowns, veneers, onlays, inlays, and bridges.\n\nNot only do you receive benefits for the procedure itself, but also for any pre- or post-operations that are required, including bone reconstruction.'
          ),
        },
        {
          id: 'addons-2',
          icon: <LoaderIcon size={24} />,
          question: t('policyModal.dental.addons2.question', 'Teeth whitening'),
          answer: t(
            'policyModal.dental.addons2.answer',
            'Coverage up to €200 every 2 years on the Advanced plan.'
          ),
        },
      ],
    },
    {
      title: t('policyModal.dental.title3', 'What’s not covered'),
      items: [
        {
          id: 'not-covered-1',
          icon: <ToothFillingCrackedIcon size={24} />,
          question: t(
            'policyModal.dental.notCovered1.question',
            'Pre-existing conditions'
          ),
          answer: t(
            'policyModal.dental.notCovered1.answer',
            "Dental insurance does not cover pre-existing or ongoing treatments, issues, or complaints. It may also be difficult to get dental insurance if you're already missing many teeth."
          ),
        },
      ],
    },
    {
      title: t('policyModal.dental.title4', 'Details'),
      items: [
        {
          id: 'details-1',
          icon: <ClockTimeIcon size={24} />,
          question: t(
            'policyModal.dental.details1.question',
            'No waiting periods'
          ),
          answer: t(
            'policyModal.dental.details1.answer',
            'It takes about 1-3 business days to process your dental application, then after that you can use the policy right away. Just keep in mind that any procedures for which you had an appointment or planned before applying for dental insurance won’t be covered.'
          ),
        },
        {
          id: 'details-2',
          icon: <CalendarIcon size={24} />,
          question: t(
            'policyModal.dental.details2.question',
            '1 year contract, then monthly cancellation'
          ),
          answer: t(
            'policyModal.dental.details2.answer',
            'Our dental insurance starts off with a one-year contract period. After your first year on the contract you can cancel on a monthly basis. If you leave Germany in the first year, you can cancel the insurance.'
          ),
        },
      ],
    },
  ],
  buttonText: t('policyModal.dental.buttonText', 'Continue to quote'),
});
