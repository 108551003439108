const sessionKey = 'qnr_build';

export const writeSession = (data: unknown) => {
  localStorage.setItem(sessionKey, JSON.stringify(data));
};

export const readSession = (): unknown => {
  try {
    const session = localStorage.getItem(sessionKey);
    return JSON.parse(session as string);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.warn(err);
    return {};
  }
};

export const exportData = () => {
  const href = URL.createObjectURL(
    new Blob([JSON.stringify(readSession(), null, 2)], {
      type: 'application/json',
    })
  );
  const el = document.createElement('a');
  el.href = href;
  el.download = 'qnr-builder.json';
  document.body.appendChild(el);
  el.click();
  document.body.removeChild(el);
  URL.revokeObjectURL(href);
};
