import { Bike as Questionnaire } from 'models/genericQuestionnaire';
import { Name } from 'models/user';

export const VINTAGE_BIKE_DAYS_LIMIT = 180;
export const MIN_ELIGIBLE_AGE = 18;
export const MAX_ELIGIBLE_PRICE = 10_000;

export type QuestionId = keyof Questionnaire;
type QuestionnaireTypeObject<K extends QuestionId> = {
  questionId: K;
  value: Questionnaire[K];
};
export type QuestionnaireValueTypeMapping = {
  [K in QuestionId]: QuestionnaireTypeObject<K>;
};

export type Plan = 'BASIC' | 'COMFORT';
export type Type = 'BIKE' | 'E_BIKE';
export type NotifyType = 'VINTAGE' | 'CARBON';
export type BlockerId =
  | 'not-living-in-germany'
  | 'purchase-date-over-six-months'
  | 'carbon-frame'
  | 'over-max-covered-price';

export const questionOrder: QuestionId[] = [
  'livingInGermany',
  'purchaseDate',
  'carbonFrame',
  'purchasePrice',
  'type',
  'quote',
  'account',
  'email',
  'name',
  'dateOfBirth',
  'bikeDetails',
  'frameNumber',
  'uploadedFiles',
  'hasReviewed',
  'hasDownloadedDocs',
  'checkoutInfo',
];

export const skippableQuestions: QuestionId[] = [
  'frameNumber',
  'uploadedFiles',
  'hasReviewed',
];

export const filteredQuestionOrder: QuestionId[] = questionOrder.filter(
  (q) => !skippableQuestions.includes(q)
);

export const mapBikeType: { [K in Type]: string } = {
  BIKE: 'Bicycle',
  E_BIKE: 'E-bike',
};

export type QuestionnaireType =
  | 'BIKE_PRE_QUOTE'
  | 'BIKE_POST_QUOTE'
  | 'BIKE_SCREENED_OUT';

export interface EmailNotificationQuestionnaire {
  email: string;
  notificationType: NotifyType;
}

export interface PreQuoteQuestionnaire {
  bikeInfo: {
    livingInGermany: boolean;
    purchaseDate: string;
    carbonFrame: boolean;
    purchasePrice: number;
    type: Type;
  };
}

export interface PostQuoteQuestionnaire {
  personalInfo: {
    email: string;
    name: Name;
    dateOfBirth: string;
  };
  bikeInfo: {
    purchaseDate: string;
    carbonFrame: boolean;
    purchasePrice: number;
    type: Type;
    brand: string;
    model: string;
    frameNumber?: string;
  };
}

export interface Quote {
  id: string;
  planId: Plan;
  price: number;
  providerId: string;
}

export interface Quotes {
  questionnaireId: string;
  options: Quote[];
  selectedQuote?: Quote;
}

export const quoteContentMapping: {
  [k in Plan]: { id: number; name: string };
} = {
  BASIC: {
    id: 0,
    name: 'Basic',
  },
  COMFORT: {
    id: 1,
    name: 'Comfort',
  },
};

export interface CardInfo {
  title: string;
  description: string | React.ReactNode;
  icon: { src: string; alt: string };
}

export interface Price {
  basic: number;
  comfort: number;
}

export interface BikeDetails {
  brand: string;
  model: string;
}

export const questionReviewLabelMapping: Partial<{
  [K in QuestionId]: string;
}> = {
  purchaseDate: 'Purchase date',
  purchasePrice: 'Bike’s price',
  type: 'Bike type',
  quote: 'Plan',
  name: 'Name',
  dateOfBirth: 'Date of birth',
  frameNumber: 'Frame number',
  bikeDetails: 'Brand and model',
  uploadedFiles: 'Purchase receipt',
};

export const reviewQuestions: QuestionId[] = [
  'purchaseDate',
  'purchasePrice',
  'type',
  'quote',
  'name',
  'dateOfBirth',
  'bikeDetails',
  'frameNumber',
  'uploadedFiles',
];

export interface CheckoutInfo {
  policyDetails: {
    bikePurchaseDate: string;
    brand: string;
    dateOfBirth: string;
    email: string;
    frameNumber: string | null;
    model: string;
    name: Name;
    price: number;
    startDate: string;
  };
}

export interface QuestionnaireLabels {
  labels: {
    title: {
      personalInfo: {
        [K in keyof Partial<Questionnaire & 'brand' & 'model'>]: string;
      };
      bikeInfo: {
        [K in keyof Partial<Questionnaire & 'brand' & 'model'>]: string;
      };
    };
    answers: {
      bikeInfo: {
        type: {
          [K in Type]: string;
        };
      };
    };
  };
  questionOrder: {
    personalInfo: Array<QuestionId | 'brand' | 'model'>;
    bikeInfo: Array<QuestionId | 'brand' | 'model'>;
  };
}

export const questionsMapping: {
  [K in QuestionId | 'brand' | 'model']: string | null;
} = {
  livingInGermany: 'Are you currently living in Germany?',
  purchaseDate: 'When did you buy your bike?',
  carbonFrame: 'Does your bike have a carbon frame?',
  purchasePrice: 'How much did you buy your bike for?',
  type: 'What kind of bike is it?',

  quote: null,

  account: null,
  email: "What's your email address?",
  name: "What's your name?",
  dateOfBirth: "What's your date of birth?",
  frameNumber: 'Have your bike’s frame number?',
  bikeDetails: null,
  brand: 'What’s the brand?',
  model: 'What’s the model?',
  uploadedFiles: 'Have your purchase receipt with you?',
  hasReviewed: null,

  hasDownloadedDocs: null,

  checkoutInfo: null,
};

export interface PolicyDetails {
  bikePurchaseDate: string;
  brand: string;
  model: string;
  frameNumber: string;
}
