import {
  getRoutes,
  QuestionnaireQuestions,
  RemoveAnswerType,
  Rule,
  stateManagerHelper,
} from '@getpopsure/qnr-framework';
import {
  removeGenericQuestionnaireAnswer,
  storeGenericQuestionnaireAnswer,
} from 'actions/genericQuestionnaire';
import routes from 'constants/routes';
import { UserWithBrokerMandate } from 'models/user';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { AppState } from 'reducers';
import { getAccountInfo } from 'selectors/user';
import { useSafeTranslation } from 'shared/i18n';
import { SignupQuestionnaire } from 'SignupQuestionnaire';

import {
  Pension,
  questionGroupsMapping,
  questionnaireIdMapping,
} from './models';
import { getTranslatedQuestionnaire, PensionComponents } from './questionnaire';

const rules: Rule<Pension>[] = [
  {
    id: 'savingForRetirement',
    if: {
      op: 'equals',
      variable: {
        type: 'static',
        value: false,
      },
    },
    then: {
      goTo: 'privatePensionInfo',
    },
  },
  {
    id: 'privatePensionLongerThan5Years',
    if: {
      op: 'equals',
      variable: {
        type: 'static',
        value: false,
      },
    },
    then: {
      goTo: 'privatePensionLongerThan5YearsInfo',
    },
  },
];

const removeReview: RemoveAnswerType<Pension> = {
  op: 'always',
  questions: ['review'],
};

const removeAnswersLogic: Partial<
  Record<keyof Pension, RemoveAnswerType<Pension>>
> = {
  quote: removeReview,
  name: removeReview,
  email: removeReview,
  address: removeReview,
  review: {
    op: 'always',
    questions: ['hasDownloadedDocs'],
  },
};

const prefillNameAndBirthDate = <
  Questionnaire extends QuestionnaireQuestions,
  Key extends keyof Questionnaire
>(
  questionnaireAnswers: Partial<Questionnaire>,
  questionId: Key,
  answer: Questionnaire[Key],
  account?: UserWithBrokerMandate
) => {
  if (
    questionId === 'email' &&
    account &&
    account.email === answer &&
    questionnaireAnswers.email !== answer
  ) {
    let toUpdate: Partial<Pension> = {};
    const { firstName, lastName } = account;

    if (firstName && lastName) {
      toUpdate = { ...toUpdate, name: { firstName, lastName } };
    }
    return toUpdate;
  }

  return null;
};

export const SignupPage = () => {
  const questionnaireAnswers =
    useSelector((state: AppState) => state.genericQuestionnaire.pension) || {};
  const dispatch = useDispatch();
  const account = useSelector(getAccountInfo);

  const onAnswer = <QuestionId extends keyof Pension>(
    questionId: QuestionId,
    answer: Pension[QuestionId]
  ) => {
    dispatch(
      storeGenericQuestionnaireAnswer('pension', { [questionId]: answer })
    );

    const answersToRemove = stateManagerHelper(
      removeAnswersLogic,
      questionnaire.components,
      questionnaireAnswers,
      questionnaire.rules
    ).getAnswersToRemove(questionId, answer);

    dispatch(removeGenericQuestionnaireAnswer('pension', answersToRemove));

    const toUpdate = prefillNameAndBirthDate(
      questionnaireAnswers,
      questionId,
      answer,
      account
    );
    if (toUpdate) {
      dispatch(storeGenericQuestionnaireAnswer('pension', toUpdate));
    }
  };

  const { t } = useSafeTranslation();
  const questions = getTranslatedQuestionnaire(t);

  const questionnaire = {
    components: questions,
    routes: getRoutes(questions, routes.policies.pension.path),
    rules,
  };

  return (
    <Switch>
      <Route path={routes.policies.pension.questionnaire.path}>
        <SignupQuestionnaire
          questionnaireAnswers={questionnaireAnswers}
          questionnaire={questionnaire}
          onAnswer={onAnswer}
          configuration={{
            components: PensionComponents,
            progressBar: {
              questionnaireIdMapping,
              questionGroupsMapping,
            },
          }}
          basePath={routes.policies.pension.path}
          questionId="intro"
          featureName="Pension"
        />
      </Route>
    </Switch>
  );
};
