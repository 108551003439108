import { trackBookACallClicked } from '@getpopsure/analytics';
import { calendly, faq, insurance } from '@getpopsure/private-constants';
import { QuestionnaireFormProps } from '@getpopsure/qnr-framework';
import { InfoCard } from '@popsure/dirty-swan';
import classNames from 'classnames';
import Markdown from 'components/markdown';
import blueCalendarIcon from 'features/expat/icons/calendar-blue.svg';
import { contentMapping, QuotePlan } from 'features/expat/models';
import {
  ExpatEu,
  isValidRegion,
  regionToNameMapping,
} from 'features/expatEu/models';
import featherLogoIcon from 'icons/feather-icon.svg';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { getUserId } from 'selectors/user';
import { Trans, useSafeTranslation } from 'shared/i18n';

import policyIcon from '../../../expat/components/Quote/view/assets/policy.svg';
import quoteHeader from '../../../expat/components/Quote/view/assets/quote-icon.svg';
import styles from './Quote.module.scss';
import Chip from './view/chip';
import ComparisonTable from './view/comparisonTable';
import { coverage, notCovered } from './view/content';
import DependentPrices from './view/dependentPrices';

export const Quote = ({
  onSubmitValue,
  questionnaireAnswers,
}: QuestionnaireFormProps<ExpatEu>) => {
  const [selectedPlan, setSelectedPlan] = useState<QuotePlan>('PREMIUM');
  const { t } = useSafeTranslation();
  const userId = useSelector(getUserId);

  const { region, quoteOptions } = questionnaireAnswers;

  if (!region || !isValidRegion(region)) {
    return null;
  }

  // TODO: Move this country-handling logic where it's centralized, translatable and reusable (f.e. i18n library)
  const regionName =
    region === 'nl' ? 'the Netherlands' : regionToNameMapping[region];

  const chips = (quoteOptions ?? [])
    .sort(({ planId: a }, { planId: b }) => {
      return contentMapping[a].id < contentMapping[b].id ? -1 : 1;
    })
    .map((quoteOption) => (
      <Chip
        key={quoteOption.planId}
        name={contentMapping[quoteOption.planId].name}
        price={quoteOption.price}
        onSelect={() => {
          onSubmitValue(quoteOption);
        }}
      />
    ));

  const handleBookACall = () => {
    trackBookACallClicked({
      user_id: userId ?? null,
      insurance_type: 'INCOMING_EU',
      region,
      medium: 'quote_screen',
    });
    window.Calendly.initPopupWidget({ url: calendly.expatInsuranceEu });
  };

  const isPolicyHolder = Boolean(questionnaireAnswers.isPolicyHolder);

  return (
    <div className={`w100 ${styles.container}`}>
      <div className="p-body">
        <section className={classNames('pt32', styles['section-top'])}>
          <img src={quoteHeader} alt="quote header" />
          <h1 className={`p-h1 mt24 p--serif ${styles.header}`}>
            {!isPolicyHolder
              ? t('expatEu.qnr.dependentsQuote.title', 'Here are their options')
              : t('expatEu.qnr.quote.title', 'Here are your options')}
          </h1>
          <div className={`mt8 wmx9 ${styles['chip-container']}`}>{chips}</div>
          <div className={`mt48 ${styles['coverage-card-container']}`}>
            {coverage(t, regionName, isPolicyHolder).map(
              ({ title, icon, description }) => (
                <InfoCard
                  key={title}
                  className="wmx4 mt32 ml16"
                  title={title}
                  topIcon={icon}
                  state="static"
                >
                  <div className="p-p tc-grey-600 ta-center">
                    <Markdown
                      paragraphClassName="tc-grey-600"
                      openLinksInNewTab={true}
                    >
                      {description}
                    </Markdown>
                  </div>
                </InfoCard>
              )
            )}
          </div>
          <div className={`w100 mt40 ${styles['additional-info']}`}>
            <img src={blueCalendarIcon} alt="calendar" />
            <div className="ml16">
              <div className="p-h4">
                {t(
                  'expatEu.qnr.quote.waitingPeriod.title',
                  '31 day waiting period'
                )}
              </div>
              <div className="p-p mt8">
                {isPolicyHolder ? (
                  <Trans i18nKey="expatEu.qnr.quote.waitingPeriod.content">
                    There is a 31-day waiting period from coverage start date to
                    use the benefits. However, this waiting period does not
                    apply to accidents or medical interventions required to
                    prevent an acute danger to your life.{' '}
                    <a
                      className="p-a fw-bold c-pointer"
                      target="_blank"
                      rel="noopener noreferrer"
                      href={faq.expatEuWaitingPeriod}
                    >
                      Learn more
                    </a>
                  </Trans>
                ) : (
                  <Trans i18nKey="expatEu.qnr.dependentsQuote.waitingPeriod.content">
                    There is a 31-day waiting period from coverage start date to
                    use the benefits. However, this waiting period does not
                    apply to accidents or medical interventions required to
                    prevent an acute danger to their life.{' '}
                    <a
                      className="p-a c-pointer"
                      target="_blank"
                      rel="noopener noreferrer"
                      href={faq.expatEuWaitingPeriod}
                    >
                      Learn more
                    </a>
                  </Trans>
                )}
              </div>
            </div>
          </div>
        </section>
        <section className={`mt80 ${styles['section-middle']}`}>
          <h2 className="p-h1 ta-center p--serif">
            {t('expatEu.qnr.quote.included.title', "What's included")}
          </h2>
          <div className={`mt16 ${styles['policy-statement-container']}`}>
            <div className="p-p ta-center wmx7">
              {t(
                'expatEu.qnr.quote.included.content',
                'For a closer look, you can read the'
              )}{' '}
            </div>
            <img src={policyIcon} alt="policy icon" />{' '}
            <a
              className="p-a c-pointer"
              href={insurance.expathHealth.policyDetail}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('expatEu.qnr.quote.included.linkText', 'Policy statement')}
            </a>
          </div>
          <div className="mt40">
            <ComparisonTable
              selectedPlan={selectedPlan}
              setSelectedPlan={setSelectedPlan}
              region={regionName}
            />
          </div>
        </section>
        <section className={styles['section-middle']}>
          <h2 className="p-h1 ta-center p--serif">
            {t('expatEu.qnr.quote.notIncluded.title', "What's not covered")}
          </h2>
          <div className="p-p mt16 ta-center">
            {t(
              'expatEu.qnr.quote.notIncluded.content',
              'What our customers find useful to know about'
            )}
          </div>
          <div className={`mt16 ${styles['not-covered-card-container']}`}>
            {notCovered(selectedPlan, t).map(({ title, description, icon }) => (
              <InfoCard
                key={title}
                className="mt32 ml16 wmx4"
                title={title}
                topIcon={icon}
                state="static"
              >
                <div
                  className={`p-p ta-center tc-grey-600 ${styles['info-description']}`}
                >
                  {description}
                </div>
              </InfoCard>
            ))}
          </div>
        </section>
      </div>
      {isPolicyHolder && <DependentPrices />}
      <section className={`${styles['section-bottom']}`}>
        <div className={`p-body ${styles['section-bottom-container']}`}>
          <img
            src={featherLogoIcon}
            alt="logo"
            className={styles['logo-icon']}
          />
          <div className="p--serif p-h1 ta-center mt24">
            {t('expatEu.qnr.quote.moreQuestions.title', 'Have more questions?')}
          </div>
          <div className="p-p ta-center wmx8 mt24">
            {t(
              'expatEu.qnr.quote.moreQuestions.content',
              'Reach out to us for more information or check out our website to get answers to common questions about expat health insurance.'
            )}
          </div>
          <div className={`mt24 wmn3 ${styles['statement-links-container']}`}>
            <button
              type="button"
              className="p-a ds-interactive-component"
              onClick={handleBookACall}
            >
              {t('expatEu.qnr.quote.moreQuestions.bookCall', 'Book a call')}
            </button>
            <div className={styles.divider} />
            <a
              className="p-a"
              href={faq.expatEuCollection}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('expatEu.qnr.quote.moreQuestions.faq', 'Visit our FAQ')}
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};
