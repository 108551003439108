import { trackTaskEngineCardClicked } from '@getpopsure/analytics';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateTask } from 'features/taskEngine/api/taskEngine.api';
import { CustomTask } from 'features/taskEngine/components/CustomTask';
import { updateTaskMutationErrorHandler } from 'features/taskEngine/utils/updateTaskMutationErrorHandler';
import { APIResponseError } from 'models/error';
import { FormEvent, useState } from 'react';
import { useSafeTranslation } from 'shared/i18n';

import {
  BikeFrameNumberUpdateTaskSchema,
  Task,
  TaskEngineOptions,
} from '../../taskEngine.models';

export const UpdateBikeFrameNumber = ({
  task,
  options,
}: {
  task: Task;
  options?: TaskEngineOptions;
}) => {
  const queryClient = useQueryClient();
  const { t } = useSafeTranslation();
  const [showModal, setShowModal] = useState(false);

  const mutation = useMutation({
    mutationFn: (frameNumber: string) => {
      return updateTask({
        taskId: task.id,
        payload: { frameNumber },
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['tasks'] });
    },
  });

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const frameNumber = formData.get('frame-number');

    if (typeof frameNumber !== 'string') {
      throw new Error('[Bike] Frame number missing for update');
    }

    mutation.mutate(frameNumber, {
      onSuccess: () => setShowModal(false),
      onError: (error) => updateTaskMutationErrorHandler(error),
    });
  };

  const parsedTask = BikeFrameNumberUpdateTaskSchema.safeParse(task);

  if (!parsedTask.success) {
    return null;
  }

  const {
    description: {
      type,
      attributes: { brand, model },
    },
    id,
  } = parsedTask.data;

  const openModal = () => {
    trackTaskEngineCardClicked({
      task_type: type,
      task_id: id,
    });

    if (options?.onTaskClick) {
      options.onTaskClick(task);
    }

    setShowModal(true);
  };

  const closeModal = () => setShowModal(false);

  const subtitle = `${t(
    'taskEngine.bikeFrameNumber.subtitle',
    'Bike'
  )} · ${brand} ${model}`;

  return (
    <CustomTask
      metadata={{
        title: t(
          'taskEngine.bikeFrameNumber.title',
          'Provide your frame number'
        ),
        subtitle,
      }}
      modal={{
        isOpen: showModal,
        loading: mutation.isLoading,
        error: mutation.isError
          ? (mutation.error as APIResponseError)?.response?.data?.message
          : null,
        onOpen: openModal,
        onClose: closeModal,
      }}
      onSubmit={handleSubmit}
      actions={[
        {
          id: '1',
          type: 'INPUT',
          attributes: {
            name: 'frame-number',
            type: 'text',
            placeholder: t(
              'taskEngine.bikeFrameNumber.modal.input.placeholder',
              'Frame number'
            ),
            required: true,
          },
        },
        {
          id: '2',
          type: 'SUBMIT',
          attributes: {
            value: t('taskEngine.bikeFrameNumber.modal.button.title', 'Save'),
          },
        },
      ]}
    />
  );
};
