import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { Policy } from 'models/policies';
import endpoint from 'shared/api';

export const getMyPolicies = async (): Promise<Policy[]> => {
  const { data } = await endpoint.getMyPolicies();
  return data;
};

export const useGetPolicies = () => {
  const {
    isLoading,
    data: policies,
    error,
  } = useQuery<Policy[], AxiosError>({
    queryKey: ['getMyPolicies'],
    queryFn: getMyPolicies,
    enabled: true,
  });
  return {
    error,
    isLoading,
    policies,
  };
};
