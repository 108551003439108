import { trackTaskEngineCardClicked } from '@getpopsure/analytics';
import * as Sentry from '@sentry/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateTask } from 'features/taskEngine/api/taskEngine.api';
import { type UploadedFile } from 'features/taskEngine/hooks/useFileUpload';
import { updateTaskMutationErrorHandler } from 'features/taskEngine/utils/updateTaskMutationErrorHandler';
import { APIResponseError } from 'models/error';
import { FormEvent, useContext, useState } from 'react';

import { CustomTask, TaskAction } from '../../components/CustomTask';
import { TASK_ENGINE_SENTRY_CATEGORY } from '../../constants';
import {
  type UploadTaskDataType,
  GenericTaskSchema,
  Task,
  TaskEngineOptions,
} from '../../taskEngine.models';
import { TaskEngineContext } from '../../utils/taskEngine.context';

type MutationPayloadType = Record<string, unknown> | UploadTaskDataType;

export const GenericTask = ({
  task,
  options,
}: {
  task: Task;
  options?: TaskEngineOptions;
}) => {
  const queryClient = useQueryClient();
  const [openModal, setOpenModal] = useState(false);
  const {
    files: { uploadedFiles, resetUploadedFiles },
  } = useContext(TaskEngineContext);

  const mutation = useMutation({
    mutationFn: (data: MutationPayloadType) => {
      return updateTask({
        taskId: task.id,
        payload: { ...data },
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['tasks'] });
    },
  });

  const parsedTask = GenericTaskSchema.safeParse(task);

  if (!parsedTask.success) {
    Sentry.captureMessage(
      `${TASK_ENGINE_SENTRY_CATEGORY} Failed to parse generic task`,
      {
        level: 'error',
        extra: {
          task,
          validationErrors: parsedTask.error,
        },
      }
    );
    return null;
  }

  const {
    id: taskId,
    description: { type, metadata, actions },
  } = parsedTask.data;

  const onOpen = () => {
    trackTaskEngineCardClicked({
      task_type: type,
      task_id: taskId,
    });

    if (options?.onTaskClick) {
      options.onTaskClick(task);
    }

    setOpenModal(true);
  };

  const onClose = () => setOpenModal(false);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let data;

    // filter FILE action type
    const fileAction = actions.filter(
      (action: TaskAction) => action.type === 'FILE'
    );

    const formData = new FormData(e.currentTarget).entries();
    if (fileAction.length > 0) {
      data = {
        files: uploadedFiles.map((uploadedFile: UploadedFile) => {
          return { fileName: uploadedFile.name, blobName: uploadedFile.id };
        }),
      };
    }

    data = {
      ...data,
      ...Object.fromEntries(formData),
    };

    mutation.mutate(data, {
      onSuccess: () => {
        resetUploadedFiles();
        setOpenModal(false);
      },
      onError: (error) => updateTaskMutationErrorHandler(error),
    });
  };

  return (
    <CustomTask
      modal={{
        isOpen: openModal,
        onOpen,
        onClose,
        loading: mutation.isLoading,
        error: mutation.isError
          ? (mutation.error as APIResponseError).response?.data?.message
          : null,
      }}
      actions={actions}
      onSubmit={handleSubmit}
      metadata={metadata}
    />
  );
};
