import { changeLanguage, language, Locale } from '@getpopsure/i18n-react';
import { ChevronDownIcon } from '@popsure/dirty-swan';
import { useFlag, useFlagsStatus } from '@unleash/proxy-client-react';
import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { useLocales } from 'shared/i18n';

import styles from './LanguageSwitcher.module.scss';

export const localeToLongNameLookup: Record<Locale, string> = {
  en: 'English',
  de: 'Deutsch',
  es: 'Español',
  fr: 'Français',
};

export const LanguageSwitcher = ({
  condensed = true,
  type = 'inline',
  onUpdate,
}: {
  condensed?: boolean;
  type?: 'inline' | 'dropdown';
  onUpdate?: (locale: Locale) => void;
}) => {
  const { allowedLocales: allLocales, hasNonEnglishLocale } = useLocales();
  const showLanguageSelector = hasNonEnglishLocale;
  const { flagsReady } = useFlagsStatus();
  const isSpanishLanguageEnabled = useFlag('app_language_es');

  const allowedLocales = allLocales.filter((locale) =>
    flagsReady && isSpanishLanguageEnabled ? true : locale !== 'es'
  );

  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [selectedLocale, setSelectedLocale] = useState(language());

  const buttonContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!allowedLocales.includes(selectedLocale as Locale)) {
      const fallbackLocale = 'en';
      setSelectedLocale(fallbackLocale);
      changeLanguage(fallbackLocale);
    }
  }, [selectedLocale, allowedLocales]);

  useEffect(() => {
    const listener = (event: Event) => {
      if (
        !buttonContainerRef.current ||
        buttonContainerRef.current.contains(event.target as Node)
      ) {
        return;
      }
      setMenuIsOpen(false);
    };
    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [buttonContainerRef]);

  const onClick =
    (localeId: Locale) => (e: React.MouseEvent<HTMLButtonElement>) => {
      e?.preventDefault();
      setMenuIsOpen(false);
      changeLanguage(localeId);
      if (onUpdate) {
        onUpdate(localeId);
      }
    };

  if (type === 'inline') {
    return (
      <div>
        {allowedLocales.map((locale, index) => (
          <span key={locale}>
            <button
              onClick={onClick(locale)}
              type="button"
              className={classNames(
                'ds-interactive-component',
                locale === selectedLocale
                  ? styles.currentInlineButton
                  : styles.inlineButton
              )}
            >
              {locale.toUpperCase()}
            </button>
            {allowedLocales.length - 1 !== index && (
              <span className={styles.separator}>/</span>
            )}
          </span>
        ))}
      </div>
    );
  }

  if (!showLanguageSelector) {
    return null;
  }

  return (
    <div
      ref={buttonContainerRef}
      style={{
        position: 'relative',
      }}
    >
      <button
        className={`d-flex jc-between ai-center c-pointer br8 ${
          styles.dropdownButton
        } ${condensed ? styles.condensedButton : styles.normalButton}`}
        onClick={() => setMenuIsOpen(!menuIsOpen)}
        type="button"
      >
        {condensed
          ? (selectedLocale || allowedLocales[0]).toUpperCase()
          : localeToLongNameLookup[
              allowedLocales.find((locale) => locale === selectedLocale) ||
                allowedLocales[0]
            ]}
        <ChevronDownIcon />
      </button>
      {menuIsOpen && (
        <div className={styles.dropdownContainer}>
          {allowedLocales.map((locale) => (
            <button
              key={locale}
              onClick={onClick(locale)}
              type="button"
              className={locale === selectedLocale ? styles.currentLocale : ''}
            >
              {localeToLongNameLookup[locale]}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};
