import classNames from 'classnames';
import {
  expectedOutcome as expectedOutcomeLookup,
  timeDate as timeDateLookup,
} from 'features/legalClaims/models';
import { Claim } from 'models/claims';
import AnimateHeight from 'react-animate-height';
import { TFunction } from 'shared/i18n';

import styles from './ClaimDetail.module.scss';
import checkedIcon from './icons/checked.svg';
import chevronIcon from './icons/chevron.svg';

export const ClaimLegalDescription = ({
  claim,
  t,
  isClaimSubmittedOpen,
  setIsClaimSubmittedOpen,
}: {
  claim: Claim;
  t: TFunction;
  isClaimSubmittedOpen: boolean;
  setIsClaimSubmittedOpen: (open: boolean) => void;
}) => {
  const {
    claimTypes,
    claimNumber,
    timeDate,
    expectedOutcome,
    contacts: phoneNumber,
    details,
    phoneConsultation,
  } = claim;

  const noClaimTypes = claimTypes.length === 0;
  const isSubmittedClaim = claimTypes.every(
    (type) => type.claimType !== 'BOOK_A_CONSULTATION'
  );

  return (
    <div className="ds-card ds-card--no-dropshadow mt24 p24 gap24">
      <button
        type="button"
        className="bg-white c-pointer d-flex df-row ai-start w100"
        onClick={() => setIsClaimSubmittedOpen(!isClaimSubmittedOpen)}
      >
        <span className={classNames('ai-center mr16', styles.row)}>
          <img src={checkedIcon} alt="Checked" />
        </span>
        <span className={classNames('p-h3 w100 jc-between', styles.row)}>
          {noClaimTypes || isSubmittedClaim
            ? t('claims.detail.legal.claimsSubmitted.title', 'Claims submitted')
            : t(
                'claims.detail.legal.requestSubmitted.title',
                'Request submitted'
              )}
          <img
            className={
              isClaimSubmittedOpen ? styles.chevronOpen : styles.chevronClosed
            }
            src={chevronIcon}
            alt="expand / collapse card"
          />
        </span>
      </button>
      <div className="d-flex fd-column w100">
        <AnimateHeight
          duration={300}
          height={isClaimSubmittedOpen ? 'auto' : 0}
        >
          {(noClaimTypes || isSubmittedClaim) && claimNumber && (
            <div className="d-flex d-row mt24 ai-center">
              <div className="w30 mr24 p-p tc-grey-500">
                {t('claims.detail.legal.claimNumber', 'Claim number')}
              </div>
              <div className="w70 p-p">{claimNumber}</div>
            </div>
          )}
          {expectedOutcome && (
            <div className="d-flex d-row mt24 ai-center">
              <div className="w30 mr24 p-p tc-grey-500">
                {t(
                  'claims.detail.legal.expectedOutcome',
                  'Reason for consultation'
                )}
              </div>
              <div className="w70 p-p">
                {expectedOutcomeLookup(t)[expectedOutcome]}
              </div>
            </div>
          )}
          <div className="d-flex d-row mt24 ai-center">
            <div className="w30 mr24 p-p tc-grey-500">
              {t('claims.detail.legal.eventDescription', 'What happened?')}
            </div>
            <div className="w70 p-p">{details}</div>
          </div>
          {phoneNumber && (
            <div className="d-flex d-row mt24 ai-center">
              <div className="w30 mr24 p-p tc-grey-500">
                {t('claims.detail.legal.phoneNumber', 'Phone number')}
              </div>
              <div className="w70 p-p">
                {phoneNumber.startsWith('+') ? phoneNumber : `+${phoneNumber}`}
              </div>
            </div>
          )}
          {timeDate && (
            <div className="d-flex d-row mt24 ai-center">
              <div className="w30 mr24 p-p tc-grey-500">
                {t('claims.detail.legal.timeDate', 'Preferred time slot')}
              </div>
              <div className="w70 p-p">{timeDateLookup(t)[timeDate]}</div>
            </div>
          )}
          {phoneConsultation !== null && (
            <div className="d-flex d-row mt24 ai-center">
              <div className="w30 mr24 p-p tc-grey-500">
                {t(
                  'claims.detail.legal.phoneConsultation.title',
                  'Had a phone consultation?'
                )}
              </div>
              <div className="w70 p-p">
                {phoneConsultation
                  ? t('claims.detail.legal.phoneConsultation.yes', 'Yes')
                  : t('claims.detail.legal.phoneConsultation.no', 'No')}
              </div>
            </div>
          )}
        </AnimateHeight>
      </div>
    </div>
  );
};
