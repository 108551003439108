/* eslint-disable import/no-cycle */
import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import * as Sentry from '@sentry/react';
import dayjs from 'dayjs';
import {
  isChecklistCompleted,
  StepType,
} from 'features/studentChecklist/models';
import { getChecklistStepsStatus } from 'features/studentChecklist/selectors';
import { mapBikeType } from 'models/bike';
import {
  getStatusMapping,
  MappedStatus,
} from 'models/insurances/status/mapping';
import { InsuranceTypes } from 'models/insurances/types';
import { getInclusiveMembersMapping } from 'models/liability';
import { Policy, PolicyDocument } from 'models/policies';
import { AppState } from 'reducers';
import { createSelector } from 'reselect';
import { getHealthCardSteps } from 'selectors/healthCard';
import { getNeedToSignBrokerMandate } from 'selectors/user';
import { TFunction } from 'shared/i18n';
import { capitalizeName } from 'shared/util/capitalizeName';

import { getMyPolicies } from './getMyPolicies';
import { getPolicyDetail } from './getPolicyDetail';

interface Info {
  title: string;
  value: unknown;
  category?: 'DURATION' | 'BENEFICIARIES';
}

export { getMyPolicies, getPolicyDetail };

const DATE_FORMAT = 'DD MMM YYYY';

/**
 * Retrieve the last active step of the public health checklist
 */
export const getCurrentStep = (policy?: Policy): StepType => {
  const { currentSteps } = getChecklistStepsStatus(policy);
  const stepsIds = currentSteps.reduce(
    (acc: Array<StepType>, current) =>
      current.active ? acc.concat(current.id) : acc,
    []
  );
  return stepsIds[stepsIds.length - 1];
};

export const getCancelApplicationAllowed = (policy?: Policy): boolean => {
  const step = getCurrentStep(policy);
  return (
    (policy &&
      policy.attributes?.studentChecklist &&
      [
        'LETTER_OF_ACCEPTANCE',
        'CONFIRMATION_OF_COVERAGE',
        'PROOF_OF_ENROLLMENT',
        'BANK_ACCOUNT',
        'IBAN',
        'SUBMIT_APPLICATION',
        'GERMAN_ADDRESS',
      ].includes(step)) ||
    false
  );
};

export const getPolicyNeedsAction = (policy: Policy) => {
  const currentStep = getCurrentStep(policy);
  return [
    'LETTER_OF_ACCEPTANCE',
    'CONFIRMATION_OF_COVERAGE',
    'PROOF_OF_ENROLLMENT',
    'BANK_ACCOUNT',
    'IBAN',
    'SUBMIT_APPLICATION',
    'GERMAN_ADDRESS',
  ].includes(currentStep);
};

export const getDocumentsForPolicy = createSelector(
  getPolicyDetail,
  (policy) => {
    if (!policy) {
      Sentry.captureException('Trying to access unknown policy ID');
      return;
    }

    const {
      documents,
      attributes: { studentChecklist },
      userUploads,
    } = policy;

    let allAvailableDocuments: PolicyDocument[] = [...documents];

    if (studentChecklist) {
      const { proofOfHealthInsuranceCoverageUrl } = studentChecklist;

      if (proofOfHealthInsuranceCoverageUrl) {
        allAvailableDocuments.push({
          title: 'Proof of health insurance coverage',
          downloadURL: proofOfHealthInsuranceCoverageUrl,
        });
      }
    }

    if (userUploads && userUploads.length > 0) {
      allAvailableDocuments = allAvailableDocuments.concat(userUploads);
    }

    return allAvailableDocuments;
  }
);

export const getStatusForPolicy = (t: TFunction) =>
  createSelector(
    getPolicyDetail,
    (state: AppState) => state.user.accountInfo,
    (state: AppState) => getNeedToSignBrokerMandate(state),
    (policy, accountInfo, needToSignBrokerMandate) => {
      if (!policy || !accountInfo) {
        Sentry.captureException(
          'Trying to access unknown policy ID or account info isn’t available'
        );
        return;
      }

      const policyNeedsAction = getPolicyNeedsAction(policy);
      const { status, type } = policy;

      if (type === 'PUBLIC_HEALTH' && needToSignBrokerMandate === true) {
        return {
          text: t(
            'models.status.publicHealth.requiresSignature.text',
            'Requires signature'
          ),
          type: 'inactive',
        };
      }

      const {
        attributes: { studentChecklist },
      } = policy;

      const isStudentWithCompletedChecklist =
        studentChecklist &&
        (status === 'STUDENT_DOCS_UPLOADED_BY_US' ||
          status === 'APPLICATION_SENT') &&
        isChecklistCompleted(studentChecklist);

      const isCanceled = status === 'DROPPED_OUT' || status === 'CANCELED';

      if (isStudentWithCompletedChecklist && !isCanceled) {
        /**
         * If this is a student with a completed checklist we set the status to 'Application in review'
         * as they don't have any action to do anymore
         */
        return {
          text: t(
            'models.status.student.applicationInReview.text',
            'Application in review'
          ),
          type: 'pending',
          tooltip: t(
            'models.status.student.applicationInReview.tooltip',
            'You’re all set for now. We’re reviewing your application and we’ll get back to you soon.'
          ),
        };
      }

      if (policyNeedsAction && !isCanceled) {
        return {
          text: t('models.status.policyNeedsAction.text', 'Needs action'),
          type: 'pending',
        };
      }

      if (type === 'LIFE' && status === 'APPLICATION_SENT') {
        return {
          type: 'active',
          text: t('models.status.life.active.text', 'Approved'),
        };
      }

      return getStatusMapping(t)[status];
    }
  );

export const getInfosForPolicyDetail = (t: TFunction) =>
  createSelector(getPolicyDetail, (policy) => {
    if (!policy) {
      Sentry.captureException('Trying to access unknown policy ID');
      return;
    }
    const toReturn: Array<Info | Info[]> = [];

    if (policy.attributes.policyNumber) {
      toReturn.push({
        title: t('myPolicies.policyDetails.policyNumber', 'Policy number'),
        value: policy.attributes.policyNumber,
      });
    }

    if (policy.attributes.startDate) {
      toReturn.push({
        title: t('myPolicies.policyDetails.startDate', 'Start date'),
        value: dayjs(policy.attributes.startDate).format(DATE_FORMAT),
      });
    }

    if (policy.attributes.coverage) {
      toReturn.push({
        title: t('myPolicies.policyDetails.coverage', 'Insured sum'),
        value: englishFormattedEuroCurrency(policy.attributes.coverage, true),
      });
    }

    if (policy.attributes.iban) {
      toReturn.push({
        title: t('myPolicies.policyDetails.iban', 'IBAN'),
        value: policy.attributes.iban,
      });
    }

    if (policy.attributes.partnerName) {
      toReturn.push({
        title: t('myPolicies.policyDetails.partnerName', 'Partner Name'),
        value: policy.attributes.partnerName,
      });
    }

    if (
      policy.attributes.SVNR &&
      (policy.type === 'PUBLIC_HEALTH' || policy.type === 'PRIVATE_HEALTH')
    ) {
      toReturn.push({
        title: t('myPolicies.policyDetails.svnr', 'SVNR'),
        value: policy.attributes.SVNR,
      });
    }

    if (policy.attributes.insuredSum) {
      toReturn.push({
        title: t('myPolicies.policyDetails.coverage', 'Insured sum'),
        value: englishFormattedEuroCurrency(policy.attributes.insuredSum, true),
      });
    }

    if (
      policy.attributes.duration &&
      policy.attributes.endDate &&
      policy.attributes.startDate
    ) {
      toReturn.push({
        title: t('myPolicies.policyDetails.duration', 'Duration'),
        value: {
          duration: policy.attributes.duration,
          endDate: policy.attributes.endDate,
          startDate: policy.attributes.startDate,
        },
        category: 'DURATION',
      });
    }

    if (policy.attributes.endDate) {
      toReturn.push({
        title: t('myPolicies.policyDetails.endDate', 'End date'),
        value: dayjs(policy.attributes.endDate).format(DATE_FORMAT),
      });
    }

    if (policy.attributes.insuredPerson) {
      toReturn.push({
        title: t('myPolicies.policyDetails.insuredPerson', 'Insured person'),
        value: capitalizeName(policy.attributes.insuredPerson.name),
      });
    }

    if (
      policy.attributes.policyHolder?.name?.firstName &&
      policy.attributes.policyHolder?.name?.lastName
    ) {
      toReturn.push({
        title: t('myPolicies.policyDetails.policyHolder', 'Policy Holder'),
        value: capitalizeName(policy.attributes.policyHolder.name),
      });
    }

    if (policy.attributes.beneficiaries) {
      toReturn.push({
        title: t('myPolicies.policyDetails.beneficiaries', 'Beneficiaries'),
        value: policy.attributes.beneficiaries.map((beneficiary) => ({
          ...beneficiary,
          coverage: policy.attributes.coverage
            ? policy.attributes.coverage *
              (beneficiary.payoutPercentage ?? 0 / 100)
            : undefined,
        })),
        category: 'BENEFICIARIES',
      });
    }

    if (policy.type === 'HOUSEHOLD') {
      if (!policy.attributes.householdAttributes?.personalInfo) {
        return toReturn;
      }

      const { personalInfo, householdInfo } =
        policy.attributes.householdAttributes;

      if (personalInfo.bikeInsuredSum) {
        toReturn.push({
          title: t(
            'myPolicies.policyDetails.household.bikeInsuredSum',
            'Bike insured sum'
          ),
          value: englishFormattedEuroCurrency(
            personalInfo.bikeInsuredSum,
            true
          ),
        });
      }

      const { city, postcode, street, houseNumber } = personalInfo.address;
      toReturn.push({
        title: t('myPolicies.policyDetails.household.address', 'Address'),
        value: `${street} ${houseNumber}`,
      });
      toReturn.push({
        title: '',
        value: `${postcode} ${city}`,
      });

      toReturn.push({
        title: t(
          'myPolicies.policyDetails.household.homeSecurityDevice',
          'Home security device'
        ),
        value: householdInfo.hasSecurityDevice ? 'Yes' : 'No',
      });

      toReturn.push({
        title: t('myPolicies.policyDetails.household.plan.title', 'Plan'),
        value: personalInfo.addOns.includes('EXTENDED_COVERAGE')
          ? t(
              'myPolicies.policyDetails.household.plan.extended',
              'Extended coverage'
            )
          : t(
              'myPolicies.policyDetails.household.plan.basic',
              'Basic coverage'
            ),
      });

      const addonStrings = personalInfo.addOns.flatMap((addon) =>
        addon !== 'EXTENDED_COVERAGE'
          ? [
              addon === 'ADDITIONAL_BIKE_COVERAGE'
                ? t(
                    'myPolicies.policyDetails.household.addon.additionalBikeCoverage',
                    'Additional bike coverage'
                  )
                : t(
                    'myPolicies.policyDetails.household.addon.brokenGlassCoverage',
                    'Broken glass coverage'
                  ),
            ]
          : []
      );

      toReturn.push({
        title: t('myPolicies.policyDetails.household.addons', 'Add-ons'),
        value: addonStrings[0],
      });

      if (addonStrings[1]) {
        toReturn.push({
          title: '',
          value: addonStrings[1],
        });
      }
    }

    if (policy.type === 'LEGAL') {
      const { planId, partner } = policy.attributes;

      if (planId) {
        toReturn.push({
          title: t('myPolicies.policyDetails.legal.plan.title', 'Plan'),
          value:
            planId === 'ADVANCED'
              ? t('myPolicies.policyDetails.legal.plan.advanced', 'Advanced')
              : t('myPolicies.policyDetails.legal.plan.basic', 'Basic'),
        });
      }

      if (partner) {
        toReturn.push({
          title: t(
            'myPolicies.policyDetails.legal.includedSpouse',
            'Included spouse or partner'
          ),
          value: capitalizeName(partner),
        });
      }
    }

    if (policy.type === 'BIKE') {
      const { planId, bikeType, brand, model, frameNumber } = policy.attributes;

      if (planId) {
        toReturn.push({
          title: t('myPolicies.policyDetails.bike.plan.title', 'Plan'),
          value:
            planId === 'COMFORT'
              ? t('myPolicies.policyDetails.bike.plan.comfort', 'Comfort')
              : t('myPolicies.policyDetails.bike.plan.basic', 'Basic'),
        });
      }

      if (bikeType) {
        toReturn.push({
          title: 'Bike type',
          value: mapBikeType[bikeType],
        });
      }

      if (brand) {
        toReturn.push({
          title: 'Brand',
          value: brand,
        });
      }

      if (model) {
        toReturn.push({
          title: 'Model',
          value: model,
        });
      }

      toReturn.push({
        title: 'Frame number',
        value: frameNumber,
      });

      toReturn.push({
        title: 'Purchase receipts',
        value: policy.userUploads,
      });
    }

    if (policy.type === 'LIABILITY') {
      const { inclusiveMembers } = policy.attributes;

      if (inclusiveMembers?.length) {
        toReturn.push({
          title: t(
            'myPolicies.policyDetails.liability.inclusiveMembers',
            'Included household member(s)'
          ),
          value: inclusiveMembers
            .map((iM) => getInclusiveMembersMapping(t)[iM])
            .join(', '),
        });
      }

      if (policy.attributes.address) {
        const { city, postcode, street, houseNumber } =
          policy.attributes.address;
        toReturn.push({
          title: t('myPolicies.policyDetails.liability.address', 'Address'),
          value: `${street} ${houseNumber}`,
        });
        toReturn.push({
          title: '',
          value: `${postcode} ${city}`,
        });
        toReturn.push({
          title: '',
          value: {
            text: t(
              'myPolicies.policyDetails.liability.addressEdit',
              'Edit address'
            ),
            type: 'Button',
          },
        });
      } else {
        toReturn.push({
          title: t('myPolicies.policyDetails.liability.address', 'Address'),
          value: {
            text: t(
              'myPolicies.policyDetails.liability.addressAdd',
              'Add address'
            ),
            type: 'Button',
          },
        });
      }
    }

    if (policy.attributes.policyData) {
      Object.entries(policy.attributes.policyData).forEach(([title, value]) =>
        toReturn.push({
          title,
          value,
        })
      );
    }

    return toReturn;
  });

export const getInfosForPolicyOverview =
  (t: TFunction) => (state: AppState, policyId: string) => {
    const policy = getPolicyDetail(state, policyId);
    const status = getStatusForPolicy(t)(state, policyId);
    if (!policy || !status) {
      Sentry.captureException('Trying to access unknown policy ID');
      return [];
    }

    return [
      {
        title: t('myPolicies.status.label', 'Status'),
        value: status.text,
        type: status.type as MappedStatus['type'],
        tooltip: status.tooltip,
      },
    ];
  };

export const getAdditionalCoverage = (policies: Policy[]): InsuranceTypes[] => {
  const policiesByTypes = policies.map(({ type }) => type);

  const hasNoExpatOrPrivatePolicy =
    !policiesByTypes.includes('INCOMING') &&
    !policiesByTypes.includes('PRIVATE_HEALTH');

  const activeExpatOrPrivatePolicies = policies.filter(
    ({ type, status }) =>
      (type === 'INCOMING' || type === 'PRIVATE_HEALTH') && status === 'ACTIVE'
  );

  const additionalCoverage: InsuranceTypes[] = [];

  if (!policiesByTypes.includes('LIABILITY')) {
    additionalCoverage.push('LIABILITY');
  }

  if (
    !policiesByTypes.includes('DENTAL') &&
    (hasNoExpatOrPrivatePolicy || activeExpatOrPrivatePolicies.length === 0)
  ) {
    additionalCoverage.push('DENTAL');
  }

  if (!policiesByTypes.includes('LEGAL')) {
    additionalCoverage.push('LEGAL');
  }

  if (!policiesByTypes.includes('HOUSEHOLD')) {
    additionalCoverage.push('HOUSEHOLD');
  }

  return additionalCoverage;
};

export const getPolicyChecklists = (policyId: string) =>
  createSelector(
    [
      (state: AppState) => getPolicyDetail(state, policyId),
      getHealthCardSteps(policyId),
    ],
    (details, healthCardChecklist) => {
      const studentChecklist = getChecklistStepsStatus(details);
      const isNotPublicHealth = !details || details.type !== 'PUBLIC_HEALTH';
      const isProviderTk = details && details.providerId === 'TK';

      if (isNotPublicHealth)
        return { withStudentChecklist: false, withHealthCardChecklist: false };

      const hasStudentChecklist = studentChecklist?.currentSteps.length > 0;
      const hasHealthCardChecklist = healthCardChecklist?.steps.length > 0;

      return {
        withStudentChecklist: hasStudentChecklist,
        withHealthCardChecklist: hasHealthCardChecklist && !isProviderTk,
      };
    }
  );
