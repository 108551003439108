import { Button, Toggle } from '@popsure/dirty-swan';
import classNames from 'classnames';
import { NavButton } from 'components/NavButton';
import routes from 'constants/routes';
import { APIResponseError } from 'models/error';
import { UpdateMailingPreference } from 'models/mailingPreferences';
import { useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { useSafeTranslation } from 'shared/i18n';

import styles from './MailingPreferences.module.scss';

interface MailingPreferencesProps {
  newsletterSubscription?: boolean;
  loading: boolean;
  error?: APIResponseError;
  updateMailingPreferences: (options: UpdateMailingPreference) => Promise<void>;
}

const MailingPreferences = ({
  newsletterSubscription,
  updateMailingPreferences,
  loading,
  error,
}: MailingPreferencesProps) => {
  const { t } = useSafeTranslation();
  const [isActive, setIsActive] = useState<boolean>(!!newsletterSubscription);
  const [feedback, setFeedback] = useState<string | null>(null);

  const toggleActive = () => setIsActive((subscribe) => !subscribe);

  const handleOnSubmit = () =>
    updateMailingPreferences({ mailingListId: 'NEWSLETTER', isActive }).then(
      () => {
        if (error) {
          return;
        }

        setTimeout(() => setFeedback(null), 3000);
        setFeedback(
          t(
            'account.communicationPreferences.savingToastMessage',
            '🎉 Changes saved'
          )
        );
      }
    );

  return (
    <div className="p-container wmx8">
      <NavButton
        path={routes.account.path}
        title={t('account.backButton.caption', 'Account')}
      />

      <h1 className="p-h1 mb24">
        {t('account.communicationPreferences.title', 'Communication')}
      </h1>

      <Toggle
        options={{
          NEWSLETTER: {
            title: t(
              'account.communicationPreferences.newsLetterCard.title',
              'Newsletter'
            ),
            description: t(
              'account.communicationPreferences.newsLetterCard.description',
              'Information on new products, special deals, and expat-focused insurance news.'
            ),
          },
        }}
        classNames={{
          container: 'bg-white br8',
          label: classNames(styles.label, 'fd-row-reverse jc-between p24'),
        }}
        bordered={false}
        onChange={toggleActive}
        value={isActive ? ['NEWSLETTER'] : []}
      />

      <p className="p-p--small tc-grey-600 mt16">
        {t(
          'account.communicationPreferences.finePrint',
          'We will also send you important information about your insurance policies, as well as service announcements and terms, privacy and security updates.'
        )}
      </p>

      <div className={`mt16 d-flex ai-center ${styles.footer}`}>
        <Button
          className={styles.button}
          disabled={loading || !!newsletterSubscription === isActive}
          onClick={handleOnSubmit}
          loading={loading}
        >
          {t(
            'account.communicationPreferences.saveButton.caption',
            'Save changes'
          )}
        </Button>

        <p
          className={classNames('p-p ml24 tc-grey-600', styles.feedback, {
            [styles.feedbackActive]: feedback,
          })}
        >
          {feedback}
        </p>
      </div>

      <AnimateHeight duration={300} height={error ? 'auto' : 0}>
        <p className="p-notice--danger mt24 w100">{error}</p>
      </AnimateHeight>
    </div>
  );
};

export default MailingPreferences;
