import { Region } from '@getpopsure/public-models';
import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import { BottomOrRegularModal, Button } from '@popsure/dirty-swan';
import classNames from 'classnames';
import { InsuranceTypes } from 'models/insurances/types';
import {
  getDescriptionMapping,
  getExcerptMapping,
  getTitleMapping,
  imageSrcSetTypeMapping,
  imageTypeMapping,
} from 'models/insurances/types/mapping';
import { useState } from 'react';
import { useHistory } from 'react-router';
import { useSafeTranslation } from 'shared/i18n';

import { AdditionalInfoSection } from './components/AdditionalInfoSection';
import { DetailsSection } from './components/DetailsSection';
import { getConfig } from './config';
import styles from './styles.module.scss';

export interface PolicyModalProps {
  isOpen: boolean;
  onClose: () => void;
  insuranceType: InsuranceTypes;
  region: Region | null;
}

const PolicyModal = ({
  isOpen,
  onClose,
  insuranceType,
  region,
}: PolicyModalProps) => {
  const { t } = useSafeTranslation();
  const history = useHistory();
  const [isTitleVisible, setIsTitleVisible] = useState(false);

  const nonDeIncomingType = region === 'es' ? 'INCOMING_ES' : 'INCOMING_EU';
  const formattedInsuranceType =
    insuranceType !== 'INCOMING' || region === 'de'
      ? insuranceType
      : nonDeIncomingType;

  const src = imageTypeMapping?.[formattedInsuranceType];
  const srcSet = imageSrcSetTypeMapping?.[formattedInsuranceType];
  const title = getTitleMapping(t)?.[formattedInsuranceType];

  const { additionalInfo, url } = getConfig(t, formattedInsuranceType, region);

  const description =
    additionalInfo?.description ||
    getDescriptionMapping(t)?.[formattedInsuranceType] ||
    getExcerptMapping(t)?.[formattedInsuranceType] ||
    '';
  const additionalButtons = additionalInfo?.buttons || [];
  const isInternalRoute = url && !url?.startsWith('http');
  const handleOnClick = () => {
    if (url) {
      window.scrollTo(0, 0);
      history.push({ pathname: url, search: '?utm_source=account' });
    }
  };

  return (
    <BottomOrRegularModal
      isOpen={isOpen}
      onClose={onClose}
      size="large"
      title={
        <div
          className={classNames(styles.title, {
            [styles.titleVisible]: isTitleVisible,
          })}
        >
          {title}
        </div>
      }
      onModalScroll={(scrollTop) => setIsTitleVisible(scrollTop > 140)}
      footer={
        <div className={classNames(styles.modalFooter, 'd-flex gap8')}>
          {url && (
            <>
              {isInternalRoute ? (
                <Button className="w100" onClick={handleOnClick}>
                  {additionalInfo?.buttonText ||
                    t('policyModal.actionButton', 'Get started')}
                </Button>
              ) : (
                <a
                  className="d-flex w100 p-btn p-btn--primary"
                  href={url}
                  rel="noreferrer"
                  target="_blank"
                >
                  {additionalInfo?.buttonText ||
                    t('policyModal.actionButton', 'Get started')}
                </a>
              )}
            </>
          )}
          {additionalButtons?.map(({ text, href }) => (
            <a
              key={href}
              className="p-btn p-btn--secondary-grey w100"
              href={href}
              rel="noreferrer"
            >
              {text}
            </a>
          ))}
        </div>
      }
    >
      <div className={classNames(styles.modalContent, 'p24 pb48 pt0 w100')}>
        <img
          src={src}
          srcSet={srcSet}
          alt=""
          className={classNames('br8 mb16', styles.icon)}
        />

        <h2 className="p-h1 mb8">{title}</h2>
        {!!additionalInfo?.price && (
          <div className="p-p tc-grey-600 mb16">
            {typeof additionalInfo?.price === 'string'
              ? additionalInfo?.price
              : t('policyModal.price', {
                  defaultValue: 'From {{price}}/month',
                  price: englishFormattedEuroCurrency(additionalInfo?.price),
                })}
          </div>
        )}

        {description && <p className="p-p">{description}</p>}

        {additionalInfo?.accordions?.map(({ title: sectionTitle, items }) => (
          <AdditionalInfoSection
            key={sectionTitle}
            items={items}
            title={sectionTitle}
            isBordered
          />
        ))}

        <DetailsSection
          items={additionalInfo?.details}
          title={t('policiesModal.details', 'Details')}
        />
      </div>
    </BottomOrRegularModal>
  );
};

export { PolicyModal };
