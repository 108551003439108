import { calendly, insurance } from '@getpopsure/private-constants';
import { Blocker } from 'components/Blocker';
import { submitScreenedOutQuestionnaire } from 'features/disabilityV2/actions';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSafeTranslation } from 'shared/i18n';

export const EligibilityCheck = () => {
  const dispatch = useDispatch();
  const { t } = useSafeTranslation();

  const submitSQuestionnaire = async () =>
    dispatch(submitScreenedOutQuestionnaire());

  useEffect(() => {
    submitSQuestionnaire();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Blocker
      title={t(
        'disabilityV2.qnr.eligibilityCheck.title',
        'Sorry, you are not eligible for disability insurance'
      )}
      description={t(
        'disabilityV2.qnr.eligibilityCheck.description',
        "You aren't eligible for occupational disability insurance. We are working hard to make sure everyone can be covered. \n\n Still, **we might have some other options for you.**"
      )}
      iconType="SHIELD"
      buttonProps={[
        {
          type: 'href',
          href: insurance.pension.landingPage,
          caption: t(
            'disabilityV2.qnr.postQuoteBlocker.moreInformation.caption',
            'Get more information'
          ),
        },
        {
          type: 'calendly',
          trackingCategory: 'DISABILITY_INSURANCE',
          caption: t(
            'disabilityV2.qnr.postQuoteBlocker.bookACallButton.caption',
            'Book a call with our experts'
          ),

          calendlyLink: `${calendly.base}/pension-insurance-consultation`,
        },
      ]}
    />
  );
};
