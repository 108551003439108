import { QuestionnaireFormProps } from '@getpopsure/qnr-framework';
import { useWindowSize } from 'hooks/useWindowSize';
import { Helmet } from 'react-helmet';

import { TravelHealthV1 } from '../models';

export const SignupTally = ({
  src,
}: { src: string } & QuestionnaireFormProps<TravelHealthV1>) => {
  const size = useWindowSize();

  return (
    <>
      <Helmet>
        <script async src="https://tally.so/widgets/embed.js" />
      </Helmet>
      <iframe
        width={size.width ?? '100%'}
        height={size.height ?? '100%'}
        data-tally-src={src}
        title="Finish Travel health signup"
      />
    </>
  );
};
