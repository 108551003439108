import { getTrackingObject } from '@getpopsure/tracker';
import { captureMessage } from '@sentry/browser';
import ErrorView from 'components/error';
import LoadingSpinner from 'components/loadingSpinner';
import routes from 'constants/routes';
import { useQueryParamValue } from 'hooks/useQueryParamValue';
import { APIResponseError } from 'models/error';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { formatErrorMessageFromError } from 'selectors/requests';
import endpoint from 'shared/api';
import { trackConversions } from 'shared/trackers';

export const DisabilitySuccessScreen = () => {
  const formId = useQueryParamValue('formId');
  const [error, setError] = useState<string | undefined>(undefined);
  const history = useHistory();

  useEffect(() => {
    if (formId) {
      const generatePolicy = async () => {
        try {
          const {
            data: { id },
          } = await endpoint.createGenericPolicy({
            formId,
            providerId: 'SQUARE_LIFE',
            insuranceType: 'DISABILITY',
            source: getTrackingObject(),
          });

          await trackConversions({
            verticalId: 'DISABILITY',
            policyId: id,
            regionOfPurchase: 'de',
          });

          history.push(routes.me.policies.path);
        } catch (e) {
          const errorMessage = formatErrorMessageFromError(
            e as APIResponseError
          );
          setError(errorMessage);
          captureMessage(errorMessage);
        }
      };
      generatePolicy();
    } else {
      throw new Error('[Disability] Missing formId for generating policy\n');
    }
  }, [formId, history]);

  if (error) {
    return <ErrorView error={error} />;
  }

  return <LoadingSpinner />;
};
