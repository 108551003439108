import { submitClaim } from 'features/dentalClaims/actions';
import {
  DentalClaims,
  DentalClaimsDispatch,
  SubmitInfo,
  ZDentalClaimsSubmissionSchema,
} from 'features/dentalClaims/models';
import { getDentalClaims } from 'features/dentalClaims/selectors';
import { useRequestStatus } from 'hooks/useRequestStatus';
import { CustomComponentProps } from 'models/questionnaireFramework';
import { FormEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSafeTranslation } from 'shared/i18n';
import { PayoutDetails } from 'shared/models/types';

import { DentalClaimsPayoutDetailsView } from './PayoutDetails.view';

const MIN_AMOUNT = 0.01;

export const DentalClaimsPayoutDetails = ({
  value,
  onSubmitValue,
  metaData: { policyId },
  sendQuestionnaireError,
}: CustomComponentProps<DentalClaims>) => {
  const { t } = useSafeTranslation();

  const [payoutDetails, setPayoutDetails] = useState<PayoutDetails>(
    (value as PayoutDetails) ?? { amount: '', iban: '' }
  );

  const { loading, error } = useRequestStatus('CREATE_CLAIM');
  const dispatch = useDispatch<DentalClaimsDispatch>();

  const questionnaire = useSelector(getDentalClaims);

  const isSubmissionValid =
    Boolean(payoutDetails.amount) &&
    Number(payoutDetails.amount) >= MIN_AMOUNT &&
    Boolean(payoutDetails.iban) &&
    !loading;

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    const validation = ZDentalClaimsSubmissionSchema.safeParse({
      ...questionnaire,
      payoutDetails,
    });

    if (!validation.success) {
      sendQuestionnaireError?.({
        type: 'CRITICAL',
        message: `[Dental claims] Validation error for policy number: ${policyId} - ${String(
          validation.error
        )}`,
      });
      return;
    }

    const { claimTypes, uploadedDocumentTokens, otherClaimType } =
      validation.data;

    const submittableClaimTypes = claimTypes.map((type) => ({
      claimType: type,
    }));
    const tokens = uploadedDocumentTokens.flatMap(({ token }) =>
      token !== undefined ? [token] : []
    );

    const submittableInfo: SubmitInfo = {
      type: 'DENTAL',
      userPolicyId: policyId,
      uploadedDocumentTokens: tokens,
      claimTypes: submittableClaimTypes,
      ...payoutDetails,
      ...(otherClaimType ? { otherClaimType } : {}),
    };

    const response = await dispatch(submitClaim(submittableInfo));

    if (response === 'SUCCESS') {
      onSubmitValue(payoutDetails);
    }
  };

  return (
    <DentalClaimsPayoutDetailsView
      handleSubmit={handleSubmit}
      t={t}
      loading={loading}
      error={error?.message}
      payoutDetails={payoutDetails}
      setPayoutDetails={setPayoutDetails}
      validForSubmission={isSubmissionValid}
      minAmount={MIN_AMOUNT}
    />
  );
};
