import { submitBikeClaim } from 'features/bikeClaims/actions';
import {
  BikeClaims,
  BikeClaimsDispatch,
  BikeClaimsSubmitInfo,
  ZBikeClaimsSubmissionSchema,
} from 'features/bikeClaims/models';
import { getBikeClaims } from 'features/bikeClaims/selectors';
import { retrieveBikeClaimQuestionReviewValues } from 'features/bikeClaims/utils';
import { useRequestStatus } from 'hooks/useRequestStatus';
import { CustomComponentProps } from 'models/questionnaireFramework';
import { FormEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSafeTranslation } from 'shared/i18n';

import { ReviewView } from './Review.view';

export const Review = ({
  onSubmitValue,
  metaData: { policyId },
  routes,
}: CustomComponentProps<BikeClaims>) => {
  const { t } = useSafeTranslation();
  const dispatch = useDispatch<BikeClaimsDispatch>();
  const { loading, error } = useRequestStatus('CREATE_CLAIM');
  const questionnaire = useSelector(getBikeClaims);

  if (!questionnaire) {
    throw new Error('[Bike Claims] Questionnaire is undefined for review');
  }

  const questionReviewValues = retrieveBikeClaimQuestionReviewValues(
    questionnaire,
    routes,
    t
  );

  const onSubmitClaim = async (e: FormEvent) => {
    e.preventDefault();

    const validation = ZBikeClaimsSubmissionSchema.safeParse(questionnaire);

    if (!validation.success) {
      throw new Error(
        `[Bike Claims] Validation error for policy number: ${policyId} - ${String(
          validation.error
        )}`
      );
    }

    const {
      claimType,
      documents,
      details,
      payoutDetails: { iban, amount },
    } = validation.data;

    const flattenedTokens = documents.flatMap(({ token }) =>
      token !== undefined ? [token] : []
    );

    const submittableInfo: BikeClaimsSubmitInfo = {
      type: 'BIKE',
      userPolicyId: policyId,
      uploadedDocumentTokens: flattenedTokens,
      claimType,
      details,
      iban,
      amount,
    };

    const response = await dispatch(submitBikeClaim(submittableInfo));

    if (response === 'SUCCESS') {
      onSubmitValue(true);
    }
  };

  return (
    <ReviewView
      questionReviewValues={questionReviewValues}
      t={t}
      loading={loading}
      error={error?.message}
      onSubmit={onSubmitClaim}
    />
  );
};
