import classNames from 'classnames';
import routes from 'constants/routes';
import { Link } from 'react-router-dom';
import { useSafeTranslation } from 'shared/i18n';

import styles from './style.module.scss';

interface Prop {
  className: string;
}

const Buttons = ({ className }: Prop) => {
  const { t } = useSafeTranslation();
  return (
    <div className={className}>
      <Link
        className={classNames('p-btn--primary ws3', styles.btn)}
        type="button"
        to={routes.account.signIn.path}
        data-cy="main-login"
      >
        {t('main.logInButton.caption', 'Log in')}
      </Link>
      <Link
        className={classNames(
          'p-btn--secondary ws3',
          styles.btn,
          styles.btnWhite
        )}
        type="button"
        to={routes.me.policies.list.path}
        data-cy="main-show-policies"
      >
        {t('main.seePoliciesButton.caption', 'See our policies')}
      </Link>
    </div>
  );
};

export default Buttons;
