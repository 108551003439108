import routes from 'constants/routes';
import { ExpectedOutcome, TimeDate } from 'features/legalClaims/models';
import { InsuranceTypes } from 'models/insurances/types';
import { Name } from 'models/user';
import { TFunction } from 'shared/i18n';
import { UploadedFile } from 'shared/models/types';

export const RECENT_CLAIMS_DAYS_LIMIT = 60;
export const PAST_CLAIMS_DISPLAY_LIMIT = 3;

export interface Claim {
  refundAmount: number;
  amount: number;
  contacts: string;
  details: string;
  status: ClaimStatuses;
  claimTypes: ClaimType[];
  documents: Document[];
  claimDate: string;
  id: string;
  insuredPerson: Name;
  insuranceType: InsuranceTypes;
  internalStatus: InternalClaimStatus;
  claimNumber?: string;
  iban?: string;
  dateOfEvent?: string;
  timeDate?: TimeDate;
  expectedOutcome?: ExpectedOutcome;
  userPolicyId: string;
  phoneConsultation?: boolean;
}

export type ClaimStep = 'SELECT_CLAIM_TYPE' | 'CLAIM_DETAIL' | 'PAYOUT_DETAIL';

export const associatedPathForClaimStep: { [K in ClaimStep]: string } = {
  SELECT_CLAIM_TYPE: routes.me.claims.claimTypes.path,
  CLAIM_DETAIL: routes.me.claims.uploadDocuments.path,
  PAYOUT_DETAIL: routes.me.claims.paymentInfo.path,
};

export type ClaimTypes =
  | 'MEDICATION'
  | 'DOCTORS_VISIT'
  | 'EMERGENCY_ROOM'
  | 'HOSPITAL_OVERNIGHT'
  | 'NON_MEDICAL_PROFESSIONAL'
  | 'MENTAL_HEALTH'
  | 'AMBULANCE'
  | 'BOOK_A_CONSULTATION'
  | 'LEGAL_CLAIM'
  | 'VISUAL_AIDS'
  | 'HOSPITAL_TREATMENT'
  | 'PHYSICAL_THERAPY'
  | 'DENTAL'
  | 'COST_PLAN'
  | 'OTHER';

export type ExpatClaimTypes = Exclude<ClaimTypes, 'MENTAL_HEALTH'>;

export interface ClaimType {
  claimType: ClaimTypes;
  additionalInfo?: string;
}

export interface ExpatClaimType {
  claimType: ExpatClaimTypes;
  additionalInfo?: string;
}

interface Document {
  publicUrl: string;
  filename: string;
  documentType: 'UPLOADED_BY_USER' | 'UPLOADED_BY_OPS';
}

/**
 * TODO: [KONG] Keep generic interface for claim submission and pass generic types
 */
export interface ClaimSubmittableInfo {
  claimType: ClaimType;
  uploadedFileTokens: string[];
  uploadedFiles: UploadedFile[];
  details: string;
  iban: string;
  amount?: number;
}

export interface ClaimSubmitInfo {
  contacts?: string;
  details?: string;
  userPolicyId: string;
  uploadTokens?: string[];
  claimTypes?: ClaimType[];
  iban?: string;
  amount?: number;
  claimNumber?: string;
  expectedOutcome?: ExpectedOutcome;
  phoneNumber?: string;
  timeDate?: TimeDate;
  description?: string;
  phoneConsultation?: boolean;
}

export type ClaimStatuses =
  | 'SUBMITTED'
  | 'CANCELLED'
  | 'ACTION_NEEDED'
  | 'PROCESSING'
  | 'PAID_OUT'
  | 'SETTLED'
  | 'DENIED'
  | 'PROCESSED'
  | 'APPROVED'
  | 'WITHDRAWN'
  | 'ON_HOLD';

export type InternalClaimStatus =
  | 'RECEIVED'
  | 'DUPLICATE'
  | 'INCOMPLETE'
  | 'MISSING_INFO_RECEIVED'
  | 'SUBMITTED_TO_PROVIDER'
  | 'FEATHER_PAID_OUT'
  | 'CUSTOMER_PAID_OUT'
  | 'OTHER_PAID_OUT'
  | 'DENIED'
  | 'APPROVED_WITH_OTHER_CLAIMS'
  | 'DENIED_WITH_OTHER_CLAIMS'
  | 'CLOSED'
  | 'MISSING_PAYMENT_INFO'
  | 'PROCESSED_WITH_OTHER_CLAIM'
  | 'APPROVED'
  | 'IN_REVIEW'
  | 'DROPPED_OUT'
  | 'ON_HOLD';

export type StepStatuses = 'completed' | 'uncompleted' | 'error';

// TODO: Retrieve dynamic claim types. Below types are hard-coded for display purposes
export const mapClaimTypes: Record<ClaimTypes, string> = {
  MEDICATION: 'Medication',
  DOCTORS_VISIT: "Doctor's visit",
  EMERGENCY_ROOM: 'Emergency room',
  NON_MEDICAL_PROFESSIONAL: 'Non-medical professional',
  MENTAL_HEALTH: 'Mental health',
  AMBULANCE: 'Ambulance',
  HOSPITAL_OVERNIGHT: 'Hospital overnight',
  BOOK_A_CONSULTATION: 'Book a consultation',
  OTHER: 'Other',
  LEGAL_CLAIM: 'Claim',
  PHYSICAL_THERAPY: 'Physical therapy',
  HOSPITAL_TREATMENT: 'Hospital treatment',
  DENTAL: 'Dental',
  COST_PLAN: 'Cost plan',
  VISUAL_AIDS: 'Visual aids',
};

// First remove MENTAL_HEALTH and claim types from Expat Claim V2 then export rest as mapExpatClaimTypes
const {
  MENTAL_HEALTH,
  PHYSICAL_THERAPY,
  HOSPITAL_TREATMENT,
  DENTAL,
  VISUAL_AIDS,
  ...mapExpatClaimTypes
}: {
  [k in ClaimTypes]: string;
} = mapClaimTypes;

export { mapExpatClaimTypes };

export interface LegalClaimInfo {
  phoneNumber: string;
  detail: {
    situation?: string;
    peopleInvolved?: string;
    wantedOutcome?: string;
  };
}

export const getClaimStatusesTextMapping: (
  t: TFunction
) => Record<ClaimStatuses, string> = (t) => ({
  SUBMITTED: t('claims.claimsCard.status.submitted', 'Submitted'),
  PROCESSING: t('claims.claimsCard.status.processing', 'Processing'),
  REQUIRES_INFO: t('claims.claimsCard.status.requiresInfo', 'Requires info'),
  PAID_OUT: t('claims.claimsCard.status.paidOut', 'Paid out'),
  SENT_OUT: t('claims.claimsCard.status.sentOut', 'Sent out'),
  CLOSED: t('claims.claimsCard.status.closed', 'Closed'),
  ACTION_NEEDED: t('claims.claimsCard.status.actionNeeded', 'Action needed'),
  CANCELLED: t('claims.claimsCard.status.cancelled', 'Cancelled'),
  DENIED: t('claims.claimsCard.status.denied', 'Denied'),
  PROCESSED: t('claims.claimsCard.status.processed', 'Processed'),
  SETTLED: t('claims.claimsCard.status.settled', 'Settled'),
  APPROVED: t('claims.claimsCard.status.approved', 'Approved'),
  WITHDRAWN: t('claims.claimsCard.status.withdrawn', 'Withdrawn'),
  ON_HOLD: t('claims.claimsCard.status.onHold', 'On hold'),
});

export const claimStatusesClassMapping: Record<ClaimStatuses, string> = {
  SUBMITTED: 'bg-grey-200',
  PROCESSING: 'bg-blue-100',
  PAID_OUT: 'bg-spearmint-100',
  CANCELLED: 'bg-grey-200',
  ACTION_NEEDED: 'bg-primary-900 tc-white',
  SETTLED: 'bg-spearmint-100',
  DENIED: 'bg-grey-200',
  PROCESSED: 'bg-grey-200',
  APPROVED: 'bg-spearmint-100',
  WITHDRAWN: 'bg-grey-200',
  ON_HOLD: 'bg-grey-200',
};
