import { AppState } from 'reducers';

export const getMyPolicies = (state: AppState) => {
  const { items } = state.policies;

  if (!items) {
    return undefined;
  }

  return Object.keys(items).map((key) => {
    return items[key];
  });
};
