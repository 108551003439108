import { useTranslation } from '@getpopsure/i18n-react';
import { faq, typeform } from '@getpopsure/private-constants';
import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import { Button } from '@popsure/dirty-swan';
import classNames from 'classnames';
import FAQSection from 'components/faq';
import MoreQuestionsSection from 'components/moreQuestions';
import ReviewBadge from 'components/reviewBadge';
import useIntersectionObserver from 'hooks/useIntersectionObserver';
import {
  bannerColorMapping,
  imageTypeMapping,
} from 'models/insurances/types/mapping';
import { useRef } from 'react';

import {
  DentalQuotePlan,
  Plan,
  QuoteCustomization,
  QuoteOption,
  ToothReplacementCoverage as TooothCoverage,
} from '../../models';
import { ComparisonTable } from './ComparisonTable';
import { faqData } from './faqData';
import { Selector } from './Selector';
import styles from './style.module.scss';
import { ToothReplacementCoverage } from './ToothReplacementCoverage';

type QuoteProps = {
  toothReplacementCoverage?: TooothCoverage;
  plan: Plan;
  setQuoteCustomization: React.Dispatch<
    React.SetStateAction<QuoteCustomization>
  >;
  planInfoMapping: DentalQuotePlan;
  selectedQuote: QuoteOption;
  handleSubmit: () => void;
};

const DentalQuoteView = ({
  toothReplacementCoverage,
  plan,
  planInfoMapping,
  setQuoteCustomization,
  selectedQuote,
  handleSubmit,
}: QuoteProps) => {
  const { t } = useTranslation();

  const coverageSectionRef = useRef<HTMLDivElement>(null);

  const coverageSectionIntersection = useIntersectionObserver(
    coverageSectionRef,
    { rootMargin: '0px 0px -100%' }
  );
  const showPrice = !!coverageSectionIntersection?.isIntersecting;

  return (
    <>
      <div className={classNames('bg-white', styles.quoteContainer)}>
        <header>
          <div
            className={classNames('px16', styles.headerImgWrapper)}
            style={{ backgroundColor: bannerColorMapping.DENTAL_V2 }}
          >
            <img
              src={imageTypeMapping.DENTAL}
              className={styles.headerImg}
              alt={t(
                'dental.qnr.preQuote.quote.header.image.alt',
                'A cup with a toothbrush on top'
              )}
            />
          </div>
          <div
            className={classNames(
              'p-body d-flex fd-column ai-center',
              styles.titleContainer
            )}
          >
            <h1 className="p-h1 p--serif">
              {t('dental.qnr.preQuote.quote.title', 'Choose your plan')}
            </h1>
          </div>
          <div
            className={classNames(
              'p-body d-flex jc-center mt32',
              styles.contentContainer
            )}
          >
            <Selector
              setQuoteCustomization={setQuoteCustomization}
              planInfoMapping={planInfoMapping}
              selectedPlan={plan}
            />
          </div>
        </header>
        <>
          <section
            className={classNames('pt56', styles.comparisonTable)}
            ref={coverageSectionRef}
          >
            <ComparisonTable
              showPrice={showPrice}
              planMapping={planInfoMapping}
            />
          </section>
          <section
            className={classNames(
              'p-body pt72',
              styles.toothReplacementContainer
            )}
          >
            <ToothReplacementCoverage
              toothReplacementCoverage={toothReplacementCoverage ?? '90%'}
              setQuoteCustomization={setQuoteCustomization}
              plan={plan}
            />
          </section>
          <section className={classNames('p-body pt72')}>
            <FAQSection data={faqData(t)} />
          </section>
          <section>
            <ReviewBadge
              className={classNames(
                'w100 d-flex jc-center gap16',
                styles.reviewContainer
              )}
            />
          </section>
          <section>
            <MoreQuestionsSection
              title={t(
                'dental.qnr.preQuote.quote.moreQuestions.title',
                'Have more questions?'
              )}
              description={t(
                'dental.qnr.preQuote.quote.moreQuestions.description',
                'Reach out to us for more information or check out our FAQ for answers to common questions about dental insurance.'
              )}
              bookACallGACategory="DENTAL_INSURANCE"
              openInNewTab
              secondaryAction={t(
                'dental.qnr.preQuote.quote.moreQuestions.faq.label',
                'Visit our FAQs'
              )}
              secondaryActionLink={faq.base}
              bookACallAction={() => {
                window.open(
                  `${typeform.base}/to/f3nNrCtc?typeform-source=feather-insurance.com`
                );
              }}
            />
          </section>
        </>
      </div>
      <footer
        className={classNames(
          'w100 d-flex jc-center ai-center',
          styles.fixedPriceBar
        )}
      >
        <div
          className={classNames(
            'p-body jc-between ai-center ws12',
            styles.desktopSticky
          )}
        >
          <div className="d-flex ai-end gap8">
            <img src={planInfoMapping[plan].icon} alt="icon of a shield" />
            <h3 className="p-h3">{planInfoMapping[plan].name} plan</h3>
          </div>
          <div className="d-flex ai-center gap16">
            <p className="p--serif p-p--small tc-primary-500">
              <span className="p-h2 tc-primary-500">
                {englishFormattedEuroCurrency(selectedQuote.price, true)}
              </span>
              {t('dental.qnr.preQuote.quote.footer.price.perMonth', '/mo')}
            </p>
            <div className={`d-flex ai-center gap16 ${styles.buttonContainer}`}>
              <Button
                onClick={handleSubmit}
                type="button"
                className="ws3"
                data-cy="dental-quote-continue"
              >
                {t(
                  'dental.qnr.preQuote.quote.continueButton.label',
                  'Continue'
                )}
              </Button>
            </div>
          </div>
        </div>
        <div
          className={classNames(
            'p-body ai-center jc-between gap16 w100',
            styles.mobileSticky
          )}
        >
          <div className="d-flex ai-center gap16">
            <img src={planInfoMapping[plan].icon} alt="icon of a shield" />
            <div>
              <h3 className="p-h3">{planInfoMapping[plan].name} plan</h3>
              <p className="p--serif p-p--small tc-primary-500">
                <span className="p-h2 tc-primary-500">
                  {englishFormattedEuroCurrency(selectedQuote.price, true)}
                </span>
                {t('dental.qnr.preQuote.quote.footer.price.perMonth', '/mo')}
              </p>
            </div>
          </div>
          <Button onClick={handleSubmit} type="button" className="ws2">
            {t('dental.qnr.preQuote.quote.continueButton.label', 'Continue')}
          </Button>
        </div>
      </footer>
    </>
  );
};

export default DentalQuoteView;
