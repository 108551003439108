import { insurance } from '@getpopsure/private-constants';
import { Card } from '@popsure/dirty-swan';
import classNames from 'classnames';
import CardWithAnimation from 'components/cardWithAnimation';
import CardWithCheckbox from 'components/cardWithCheckbox';
import { PolicyRecommendations } from 'features/policyRecommendations';
import { checkWhite, contract } from 'icons';
import { dental } from 'models/insurances/assets/img';
import { ValidVerticalUrlParams } from 'models/insurances/types';
import { useRef } from 'react';
import AnimateHeight from 'react-animate-height';
import { Trans, useSafeTranslation } from 'shared/i18n';
import { paramsSetUrl } from 'shared/util/paramsSetUrl';

import feathersSvg from '../../icons/feather-icon.svg';
import styles from './style.module.scss';

interface CheckoutConfirmationBaseProps {
  showFeatherInfoCard?: boolean;
  description?: string;
  newsletterSubscription?: boolean;
  onUpdateMailingPreferences: () => void;
  feedback?: string | null;
  newsletterLoading: boolean;
  newsletterError?: string;
  title?: string;
  showDentalCard?: boolean;
  insuranceType: ValidVerticalUrlParams;
}

const CheckoutConfirmation = (props: CheckoutConfirmationBaseProps) => {
  const {
    description,
    showFeatherInfoCard,
    newsletterSubscription,
    onUpdateMailingPreferences,
    feedback,
    newsletterLoading,
    newsletterError,
    title,
    showDentalCard,
    insuranceType,
  } = props;
  const shouldHideCard = useRef(newsletterSubscription);
  const { t } = useSafeTranslation();

  return (
    <div className={styles.container} data-testid="checkout-confirmation">
      <div className={`p-body wmx6 m-auto ${styles['inner-container']}`}>
        <img src={checkWhite} alt="Check icon" className={styles.image} />
        <h1 className="p-h1 mt24">
          {title ??
            t('myPolicies.signupSuccess.default.title', "You're all set!")}
        </h1>
        <p className="p-p mt8 ta-center">
          {description !== undefined ? (
            description
          ) : (
            <>
              We’ve received your application.
              <br />
              You can access your policy details below.
            </>
          )}
        </p>

        {showFeatherInfoCard && (
          <Card
            title={
              <>
                <img src={feathersSvg} alt="Feather logo" width={22} />
                What’s Feather?
              </>
            }
            dropShadow={false}
            description=" We’re partnered with public insurance providers to help get you
            signed up easily & in English. Our 100% digital sign up is
            completely free to use."
            data-testid="checkout-confirmation-info"
            classNames={{ wrapper: 'mt24', title: 'd-flex ai-center gap16' }}
          />
        )}

        {showDentalCard && (
          <a
            href={paramsSetUrl(insurance.dental.landingPage, [
              {
                key: 'utm_source',
                value: 'in-account-card-public',
              },
              { key: 'utm_campaign', value: 'dental' },
            ])}
            className={styles.cardLink}
          >
            <CardWithAnimation
              title={
                <Trans i18nKey="myPolicies.signupSuccess.dentalCard.title">
                  Complete your coverage with dental insurance
                </Trans>
              }
              leftIcon={{ src: dental, alt: 'dental insurance' }}
              rightIcon="arrow"
              className="mt24"
              data-testid="checkout-dental-info"
            >
              <p className="p-p--small fw-normal">
                <Trans i18nKey="myPolicies.signupSuccess.dentalCard.description">
                  Public insurance only covers about 40% of the cost of
                  professional dental cleanings and restorative treatments,
                  which is why most people get{' '}
                  <span className="fw-bold">comprehensive dental coverage</span>
                  , as low as €10.90/month.
                </Trans>
              </p>
              <div className="mt16 d-flex fd-row ai-center jc-between">
                <div className="p-p--small fw-bold tc-grey-600">
                  {t('myPolicies.signupSuccess.dentalCard.pay', 'You pay')}{' '}
                  <span
                    className={classNames('fw-bold tc-grey-900', styles.price)}
                  >
                    {' '}
                    {t('myPolicies.signupSuccess.dentalCard.currency', '€')}
                  </span>
                </div>
                <div
                  className={classNames(
                    'w80 bg-primary-100 br8',
                    styles.progress
                  )}
                >
                  <p className="bg-primary-500 br8" />
                </div>
              </div>
            </CardWithAnimation>
          </a>
        )}
        <PolicyRecommendations />
        {!shouldHideCard.current && (
          <CardWithCheckbox
            id="newsletter-subscribe"
            title={t(
              'myPolicies.signupSuccess.newsletterCard.title',
              'Send me tips about my policy'
            )}
            icon={{ src: contract, alt: 'Newsletter icon' }}
            description={t(
              'myPolicies.signupSuccess.newsletterCard.description',
              'We prepared emails with practical info to help you make the most out of your policy.'
            )}
            onChange={onUpdateMailingPreferences}
            checked={!!newsletterSubscription}
            loading={newsletterLoading}
          />
        )}

        <a
          className="mt24 p-a c-pointer fw-bold tc-primary-500"
          href={`https://tally.so/r/3ld0do?vertical=${insuranceType}`}
        >
          {t(
            'myPolicies.signupSuccess.improvement.title',
            'Anything we can improve?'
          )}
        </a>

        <p
          className={classNames('mt24', styles.feedback, {
            [styles['feedback-active']]: feedback,
          })}
        >
          {feedback}
        </p>

        <AnimateHeight duration={300} height={newsletterError ? 'auto' : 0}>
          <div className="p-notice p-notice--danger mt16">
            {newsletterError}
          </div>
        </AnimateHeight>
      </div>
    </div>
  );
};

export default CheckoutConfirmation;
