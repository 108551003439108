import { submitClaim } from 'features/householdClaims/actions';
import {
  HouseholdClaims,
  HouseholdClaimsDispatch,
  HouseholdClaimsSubmitInfo,
  ZHouseholdClaimsSubmissionSchema,
} from 'features/householdClaims/models';
import { getHouseholdClaims } from 'features/householdClaims/selectors';
import { useRequestStatus } from 'hooks/useRequestStatus';
import { CustomComponentProps } from 'models/questionnaireFramework';
import { FormEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSafeTranslation } from 'shared/i18n';
import { PayoutDetails } from 'shared/models/types';

import { HouseholdClaimsPayoutDetailsView } from './PayoutDetails.view';

const MIN_AMOUNT = 0.01;

export const HouseholdClaimsPayoutDetails = ({
  value,
  onSubmitValue,
  metaData: { policyId },
}: CustomComponentProps<HouseholdClaims>) => {
  const { t } = useSafeTranslation();

  const [payoutDetails, setPayoutDetails] = useState<PayoutDetails>(
    (value as PayoutDetails) ?? { amount: '', iban: '' }
  );

  const { loading, error } = useRequestStatus('CREATE_CLAIM');
  const dispatch = useDispatch<HouseholdClaimsDispatch>();

  const questionnaire = useSelector(getHouseholdClaims);

  const isSubmissionValid =
    (Boolean(payoutDetails.amount) === false ||
      Number(payoutDetails.amount) >= MIN_AMOUNT) &&
    Boolean(payoutDetails.iban) &&
    !loading;

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    const validation = ZHouseholdClaimsSubmissionSchema.safeParse({
      ...questionnaire,
      payoutDetails: {
        ...payoutDetails,
        amount: Number(payoutDetails.amount),
      },
    });

    if (!validation.success) {
      throw new Error(
        `[Household Claims] Validation error for policy number: ${policyId} - ${String(
          validation.error
        )}`
      );
    }

    const { claimType, uploadedDocumentTokens, details, dateOfEvent } =
      validation.data;

    const tokens = uploadedDocumentTokens.flatMap(({ token }) =>
      token !== undefined ? [token] : []
    );

    const submittableInfo: HouseholdClaimsSubmitInfo = {
      type: 'HOUSEHOLD',
      userPolicyId: policyId,
      uploadedDocumentTokens: tokens,
      claimType,
      details,
      iban: payoutDetails.iban,
      amount: Number(payoutDetails.amount),
      dateOfEvent,
    };

    const response = await dispatch(submitClaim(submittableInfo));

    if (response === 'SUCCESS') {
      onSubmitValue(payoutDetails);
    }
  };

  return (
    <HouseholdClaimsPayoutDetailsView
      handleSubmit={handleSubmit}
      t={t}
      loading={loading}
      error={error?.message}
      payoutDetails={payoutDetails}
      setPayoutDetails={setPayoutDetails}
      validForSubmission={isSubmissionValid}
      minAmount={MIN_AMOUNT}
    />
  );
};
