import { CurrencyInput, Input } from '@popsure/dirty-swan';
import QuestionnaireWrapper from 'components/questionnaireForms/QuestionnaireWrapper';
import { FormEvent } from 'react';
import { TFunction } from 'shared/i18n';
import { PayoutDetails } from 'shared/models/types';

interface Props {
  handleSubmit: (e: FormEvent) => void;
  t: TFunction;
  loading: boolean;
  error?: string;
  validForSubmission: boolean;
  payoutDetails: PayoutDetails;
  setPayoutDetails: (payoutDetails: PayoutDetails) => void;
  minAmount: number;
}

export const DentalClaimsPayoutDetailsView = ({
  handleSubmit,
  t,
  loading,
  error,
  validForSubmission,
  payoutDetails,
  setPayoutDetails,
  minAmount,
}: Props) => (
  <QuestionnaireWrapper
    question={t('claims.dental.payoutDetails.question', 'Payout details')}
    onSubmit={handleSubmit}
    additionalInfo={{
      title: t(
        'claims.dental.payoutDetails.additionalInfo.title',
        'Choosing what bank account gets the reimbursement'
      ),
      description: t(
        'claims.dental.payoutDetails.additionalInfo.description',
        'We recommend paying your invoices out of pocket and then submitting the itemised invoice as a claim. Always add your personal IBAN, that way we can reimburse you directly.'
      ),
      moreInfoLink:
        'https://faq.feather-insurance.com/en/articles/6281846-why-does-feather-want-me-to-pay-for-dental-treatments-with-my-bank-account',
    }}
    validForSubmission={validForSubmission}
    loading={loading}
    error={error}
  >
    <h2 className="p-h4">
      {t('claims.dental.payoutDetails.amount.label', 'Claim amount')}
    </h2>
    <CurrencyInput
      className="mt8"
      value={payoutDetails.amount}
      onChange={(value) =>
        setPayoutDetails({ ...payoutDetails, amount: value })
      }
      placeholder={t(
        'claims.dental.payoutDetails.amount.placeholder',
        'Amount'
      )}
      min={minAmount}
      required
    />
    <h2 className="mt24 p-h4">
      {t('claims.dental.payoutDetails.iban.label', 'Your IBAN')}
    </h2>
    <p className="p-p--small mt8">
      {t(
        'claims.dental.payoutDetails.iban.description',
        'The bank account where you’ll receive the payout if approved.'
      )}
    </p>
    <Input
      className="mt8"
      value={payoutDetails.iban}
      onChange={(e) =>
        setPayoutDetails({ ...payoutDetails, iban: e.target.value })
      }
      placeholder={t('claims.dental.payoutDetails.iban.placeholder', 'IBAN')}
      required
    />
  </QuestionnaireWrapper>
);
