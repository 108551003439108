import routes from 'constants/routes';
import { Route, Switch } from 'react-router';

import { DependentsSignupPage } from './components/DependentsSignupPage';
import { SignupPage } from './components/SignupPage';

const ExpatRoutes = () => {
  return (
    <Switch>
      <Route
        component={DependentsSignupPage}
        path={routes.policies.expat.dependents.path}
      />
      <Route component={SignupPage} path={routes.policies.expat.path} />
    </Switch>
  );
};

export default ExpatRoutes;
