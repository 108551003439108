import { useFlag } from '@unleash/proxy-client-react';
import { fetchAccountInfo } from 'actions/user';
import { fetchPaymentMethods } from 'features/paymentMethods/paymentMethods.actions';
import {
  getDefaultPaymentMethod,
  getPaymentMethods,
} from 'features/paymentMethods/paymentMethods.selectors';
import { PaymentOption } from 'features/paymentMethodSelector/paymentMethodSelector.models';
import { PaymentMethodSelectorThunkDispatch } from 'features/paymentMethodSelector/paymentMethodSelector.thunks';
import { useQueryParamValue } from 'hooks/useQueryParamValue';
import { PaymentMethod } from 'models/paymentMethods';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'reducers';
import { getRequestInProcess } from 'selectors/requests';
import { getAccountInfo } from 'selectors/user';

import PaymentMethodSelectorView from './PaymentMethodSelector.view';

/**
 * This hook is intended to pick a payment method from query params to
 * support a redirect from Stripe Element when setting up i.e. PayPal
 */
const usePaymentOptionFromQuery = (
  paymentOptions: PaymentMethod[]
): PaymentMethod | undefined => {
  const paymentMethodId = useQueryParamValue('paymentMethodId');
  if (!paymentMethodId) return;

  return paymentOptions.find(({ id }) => id === paymentMethodId);
};

export const PaymentMethodSelector = ({
  handlePaymentOptionUpdate,
  hideDropdown = false,
  onStripePaymentMethodTypeChange,
}: {
  handlePaymentOptionUpdate: (paymentOption: PaymentOption) => void;
  hideDropdown?: boolean;
  onStripePaymentMethodTypeChange: (type: string) => void;
}) => {
  const dispatch = useDispatch<PaymentMethodSelectorThunkDispatch>();
  const [isFirstFetch, setIsFirstFetch] = useState<boolean>(true);
  const paymentOptions = useSelector(getPaymentMethods);
  const defaultPaymentOption = useSelector(getDefaultPaymentMethod);
  const overridePaymentOption = usePaymentOptionFromQuery(paymentOptions);
  const isStripeElementFlagEnabled = useFlag('stripe_payment_element');
  const isLoadingPaymentMethods = useSelector((state: AppState) =>
    getRequestInProcess(state, 'GET_PAYMENT_METHODS')
  );
  const forceMakeDefault = !defaultPaymentOption;

  /**
   * Account data is required to fetch current user's email to configure
   * the SepaSetupData.accountHolderEmail property required by Stripe.
   *
   * This is a SEPA/Stripe requirement, and is rather an implementation detail,
   * that's why selector has been moved to this component. In the future we might want
   * to make this editable by the customer.
   */
  const account = useSelector(getAccountInfo);
  const accountHolderEmail = account?.email;

  const isUsingStripeElement =
    isStripeElementFlagEnabled ||
    (typeof accountHolderEmail === 'string' &&
      accountHolderEmail.includes('paypal'));

  const isLoading = !account || isFirstFetch || isLoadingPaymentMethods;

  useEffect(() => {
    if (isFirstFetch) {
      setIsFirstFetch(false);
      dispatch(fetchAccountInfo());
      dispatch(fetchPaymentMethods());
    }
  }, [
    dispatch,
    isFirstFetch,
    isStripeElementFlagEnabled,
    isUsingStripeElement,
  ]);

  return (
    <PaymentMethodSelectorView
      hideDropdown={hideDropdown}
      isLoading={isLoading}
      isUsingStripeElement={isUsingStripeElement}
      forceMakeDefault={forceMakeDefault}
      handlePaymentOptionUpdate={handlePaymentOptionUpdate}
      onStripePaymentMethodTypeChange={onStripePaymentMethodTypeChange}
      accountHolderEmail={account?.email}
      dropdownOptions={{
        paymentOptions,
        defaultPaymentOption,
        overridePaymentOption,
      }}
    />
  );
};
