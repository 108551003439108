import {
  trackCheckoutScreenReached,
  trackPaymentMethodAdded,
} from '@getpopsure/analytics';
import { Region } from '@getpopsure/public-models';
import * as Sentry from '@sentry/react';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import { StripeError } from '@stripe/stripe-js';
import { useFlag, useFlagsStatus } from '@unleash/proxy-client-react';
import { flushGenericQuestionnaire } from 'actions/genericQuestionnaire';
import { fetchAccountInfo } from 'actions/user';
import axios, { AxiosError } from 'axios';
import { ErrorWithAction } from 'components/ErrorWithAction';
import TimedLoadSpinner from 'components/timedLoadSpinner';
import routes from 'constants/routes';
import {
  CheckoutDispatch,
  createCheckoutSubscription,
  onSuccessfulCheckout,
  retrieveCheckoutInfoAndPaymentMethods,
  updateCheckoutInfo,
} from 'features/checkout/actions';
import {
  CheckoutPolicyRequestPayload,
  mapVerticalId,
} from 'features/checkout/models';
import { getCheckoutInfo } from 'features/checkout/selectors';
import View from 'features/checkout/view';
import { getDocuments } from 'features/checkoutDocuments/checkoutDocuments.selectors';
import { usePollCheckoutDocuments } from 'features/checkoutDocuments/hooks';
import { getPaymentMethods } from 'features/paymentMethods/paymentMethods.selectors';
import { PaymentOption } from 'features/paymentMethodSelector/paymentMethodSelector.models';
import { prepareForPayment } from 'features/paymentMethodSelector/paymentMethodSelector.thunks';
import {
  isCustomerFacingError,
  isReadyForPayment,
} from 'features/paymentMethodSelector/paymentMethodSelector.utils';
import { isStripeError } from 'features/paymentScreen/utils/paymentMethod.utils';
import { useRequestStatus } from 'hooks/useRequestStatus';
import { imageTypeMapping } from 'models/insurances/types/mapping';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useHistory, useLocation } from 'react-router';
import { getAccountInfo, getUserId } from 'selectors/user';
import { useSafeTranslation } from 'shared/i18n';
import { ZFutureDateString } from 'shared/models/validations';
import { isMobileApp } from 'shared/util/isMobileApp';

import { getRegionFromType, retrieveCheckoutErrorMessage } from './utils';

interface Props {
  checkoutPolicyRequestPayload: CheckoutPolicyRequestPayload;
  policyDetailsComponent: JSX.Element;
  mainPolicyId?: string;
  changeStartDatePath?: string;
  checkoutIcon?: string;
}

const Checkout = ({
  checkoutPolicyRequestPayload,
  policyDetailsComponent,
  mainPolicyId,
  changeStartDatePath,
  checkoutIcon,
}: Props) => {
  const { t } = useSafeTranslation();
  const history = useHistory();
  const account = useSelector(getAccountInfo);
  const paymentOptions = useSelector(getPaymentMethods);
  const verticalId = checkoutPolicyRequestPayload.type;
  const { questionnaireId } = checkoutPolicyRequestPayload.policyInfo;
  const documents = useSelector(getDocuments(questionnaireId));
  const isPayPalAvailable = useFlag('app_payment_method_paypal');
  const isUsingStripeElement = useFlag('stripe_payment_element');
  const { flagsReady } = useFlagsStatus();
  const location = useLocation();
  const { startPollingDocuments, documentsError, documentsLoading } =
    usePollCheckoutDocuments(verticalId, questionnaireId);

  const [
    backendStartDateValidationHasFailed,
    setBackendStartDateValidationHasFailed,
  ] = useState<boolean | undefined>();

  const hasPayPal = paymentOptions.find((method) => method.type === 'PAYPAL');

  const stripe = useStripe();
  const elements = useElements();
  if (!isMobileApp && (isPayPalAvailable || hasPayPal)) {
    elements?.update({
      paymentMethodTypes: ['sepa_debit', 'card', 'paypal'],
    });
  }
  const [paymentOption, setPaymentOption] = useState<PaymentOption>({
    type: 'NOT_STARTED',
  });
  const [isProcessingPayment, setIsProcessingPayment] =
    useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [paymentTypeSelected, setPaymentTypeSelected] = useState<
    string | undefined
  >();

  const validForSubmission =
    !isProcessingPayment && isReadyForPayment(paymentOption);
  const isBeforePaypalRedirect =
    paymentOption.type === 'READY_FOR_SETUP_WITH_REDIRECT' &&
    paymentTypeSelected === 'paypal';

  const checkoutInfo = useSelector(getCheckoutInfo(verticalId));
  const userId = useSelector(getUserId);

  const { loading: checkoutReviewLoading, error: checkoutReviewError } =
    useRequestStatus('CHECKOUT_REVIEW');
  const { error: checkoutError } = useRequestStatus('CHECKOUT');

  const checkoutErrorMessage = checkoutError
    ? retrieveCheckoutErrorMessage(checkoutError)
    : undefined;

  const dispatch = useDispatch<CheckoutDispatch>();

  useEffect(() => {
    // As the backend rejects this initial request if "startDate" is (now) in the past,
    // we just don't send it over to be able to get the pricing data etc. to be able to render the page.
    // (Backend check: https://github.com/getPopsure/checkout-service/pull/132)
    const {
      policyInfo: { startDate, ...policyInfoWithoutStartDate },
      ...checkoutPolicyRequestPayloadWithoutPolicyInfo
    } = checkoutPolicyRequestPayload;
    dispatch(
      retrieveCheckoutInfoAndPaymentMethods({
        ...checkoutPolicyRequestPayloadWithoutPolicyInfo,
        policyInfo: {
          ...policyInfoWithoutStartDate,
        },
      })
    );

    dispatch(fetchAccountInfo);
    // eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    startPollingDocuments();
  }, [startPollingDocuments]);

  useEffect(() => {
    if (flagsReady && userId) {
      trackCheckoutScreenReached({
        variant: isUsingStripeElement ? 'stripe' : 'custom',
        insurance_type: verticalId,
        user_id: userId ?? null,
        medium: 'checkout_screen',
      });
    }
    // eslint-disable-next-line
  }, [flagsReady, userId]);

  const icon =
    checkoutIcon || imageTypeMapping[verticalId] || imageTypeMapping.GENERIC;

  const showNetworkError = (_error: AxiosError) => {
    setErrorMessage(
      t(
        'checkoutScreen.errors.genericError',
        'We could not process your payment because of an error on our side, please contact support.'
      )
    );
  };

  const showStripeError = (error: StripeError) => {
    setErrorMessage(
      error.message ??
        t(
          'checkoutScreen.errors.genericStripeError',
          'There was an issue with your payment method, please contact support.'
        )
    );
  };

  const handleCheckout = async () => {
    setIsProcessingPayment(true);

    // Since our initial checkout request did not include the start date, do another checkout request, this time not removing it from the payload.
    const updatedCheckoutInfoResult = await dispatch(
      updateCheckoutInfo(checkoutPolicyRequestPayload)
    );

    if (
      updatedCheckoutInfoResult?.error ===
      'BACKEND_START_DATE_VALIDATION_FAILED'
    ) {
      setBackendStartDateValidationHasFailed(true);
    }

    const checkoutId = updatedCheckoutInfoResult?.checkoutInfo?.id;

    const policyId =
      mainPolicyId || updatedCheckoutInfoResult?.checkoutInfo?.mainPolicy.id;

    if (!checkoutId) {
      throw new Error(
        `[Checkout] Checkout id not found for vertical: ${verticalId}`
      );
    }

    if (!isReadyForPayment) {
      throw new Error(
        '[Checkout] Attempting to pay while the component is not ready for payment'
      );
    }

    if (!stripe) {
      throw new Error(
        `[Checkout] Stripe isn't loaded for checkout for id: ${checkoutId}`
      );
    }

    if (!elements) {
      throw new Error(
        '[Checkout] The Stripe elements object was not ready in time for payment'
      );
    }

    let checkoutSubscriptionResult;

    try {
      const paymentMethod = await dispatch(
        prepareForPayment({
          paymentOption,
          stripeClient: stripe,
          elements,
          returnUrl: `${window.location.origin}${generatePath(
            routes.policies.checkoutSetupConfirmation.path
          )}`,
          returnPath: location.pathname,
        })
      );

      trackPaymentMethodAdded({
        variant: isUsingStripeElement ? 'stripe' : 'custom',
        insurance_type: verticalId,
        user_id: userId ?? null,
        medium: 'checkout_screen',
        payment_type: paymentMethod.type,
        is_default_payment: paymentMethod.isDefault,
      });

      checkoutSubscriptionResult = await dispatch(
        createCheckoutSubscription({
          verticalId,
          checkoutId,
          stripe,
          paymentMethod,
          returnPath: `${generatePath(
            routes.policies.checkoutPaymentConfirmation.path
          )}?checkoutId=${checkoutId}&checkoutPath=${
            location.pathname
          }?paymentMethodId=${paymentMethod.id}`,
        })
      );

      setIsProcessingPayment(false);
    } catch (error: unknown) {
      setIsProcessingPayment(false);
      if (axios.isAxiosError(error)) {
        showNetworkError(error);
      } else if (isStripeError(error)) {
        showStripeError(error);

        if (!isCustomerFacingError(error)) {
          // This error should be reported to Sentry very rarely. If this shows up as
          // a false-positive, please update the list of reported error types in isCustomerFacingError,
          // or remove this captureException entirely
          Sentry.captureException(error);
        }

        return;
      }
    }

    if (checkoutSubscriptionResult?.status === 'SUCCESS') {
      try {
        await dispatch(
          onSuccessfulCheckout({
            verticalId,
            policyId,
            localeId: getRegionFromType(
              checkoutInfo?.mainPolicy.policyDetails?.type ??
                checkoutInfo?.subPolicies?.[0].policyDetails.type ??
                'GENERIC',
              (checkoutInfo?.mainPolicy.policyDetails
                ?.regionOfPurchase as Region) ??
                (checkoutInfo?.subPolicies?.[0].policyDetails
                  .regionOfPurchase as Region)
            ),
          })
        );
      } catch (error) {
        setIsProcessingPayment(false);
        if (axios.isAxiosError(error)) {
          showNetworkError(error);
        } else if (isStripeError(error)) {
          showStripeError(error);
          return;
        }

        throw error;
      }
    } else {
      // TODO: We should render an error here
      setIsProcessingPayment(false);
    }
  };

  useEffect(() => {
    if (account?.isDelinquent) {
      history.push(routes.policies.delinquency.path);
    }
  }, [account?.isDelinquent, history]);
  if (
    checkoutReviewLoading ||
    !checkoutInfo ||
    account?.isDelinquent ||
    !elements ||
    documentsLoading
  ) {
    return (
      <TimedLoadSpinner
        title={t('checkout.loading.title', 'Preparing your policy')}
        description={t(
          'checkout.loading.description',
          "We're gathering your documents and policy details so you can review them before purchasing."
        )}
        delayInMilliseconds={0}
      />
    );
  }

  if (checkoutReviewError) {
    const handleResetFlow = async () => {
      const verticalStateId = mapVerticalId[verticalId];

      if (verticalStateId) {
        await dispatch(flushGenericQuestionnaire(verticalStateId));
      }

      Sentry.captureException(
        `Missing checkout info for vertical ${
          checkoutPolicyRequestPayload.type
        } with ids: ${JSON.stringify(checkoutPolicyRequestPayload)}`
      );

      history.push(routes.base.path);
    };

    return (
      <ErrorWithAction
        title={t('checkout.error.title', 'Missing or invalid info')}
        description={t(
          'checkout.error.description',
          'It seems like there is some missing or invalid information provided. Please start again from the beginning.'
        )}
        cta={{
          title: t('checkout.error.cta', 'Start from beginning'),
          onClick: handleResetFlow,
        }}
      />
    );
  }

  if (documentsError) {
    return (
      <ErrorWithAction
        title={t('checkout.documents.error.title', 'Something went wrong')}
        description={t(
          'checkout.documents.error.description',
          "Some of the required documents couldn't be generated.\n\nGoing back to the previous page and trying again should fix the issue."
        )}
        cta={{
          title: t('checkout.documentsError.cta', 'Go back'),
          onClick: () => history.goBack(),
        }}
      />
    );
  }

  let isValidStartDate = !backendStartDateValidationHasFailed;

  if (checkoutPolicyRequestPayload.policyInfo.startDate) {
    const startDateValidationResult = ZFutureDateString.safeParse(
      checkoutPolicyRequestPayload.policyInfo.startDate
    );
    isValidStartDate =
      startDateValidationResult?.success &&
      !backendStartDateValidationHasFailed;
  }

  const onStripePaymentMethodTypeChange = (type: string) => {
    setPaymentTypeSelected(type);
  };

  return (
    <View
      policyInfo={checkoutInfo}
      error={checkoutErrorMessage ?? errorMessage}
      loading={isProcessingPayment}
      icon={icon}
      paymentOption={paymentOption}
      setPaymentOption={setPaymentOption}
      stripe={stripe}
      stripeElements={elements}
      policyDetailsComponent={policyDetailsComponent}
      handleCheckout={handleCheckout}
      isValidStartDate={isValidStartDate}
      changeStartDatePath={changeStartDatePath}
      validForSubmission={validForSubmission}
      onStripePaymentMethodTypeChange={onStripePaymentMethodTypeChange}
      isBeforePaypalRedirect={isBeforePaypalRedirect}
      documents={documents}
    />
  );
};

export default Checkout;
