import { toast } from '@popsure/dirty-swan';
import { setRequestErrored, setRequestInProcess } from 'actions/request';
import { fetchAccountInfo } from 'actions/user';
import axios from 'axios';
import { PoliciesAction, RequestAction } from 'constants/actions';
import routes from 'constants/routes';
import { APIResponseError } from 'models/error';
import { hasAutomatedCancelProcess } from 'models/insurances/types/mapping';
import {
  OptionalPolicyAttributes,
  Policy,
  PolicyCancellationReason,
} from 'models/policies';
import { AppState } from 'reducers';
import { ThunkDispatch } from 'redux-thunk';
import endpoint from 'shared/api';
import browserHistory from 'shared/browserHistory';

type UnknownObject = Record<string, unknown>;

export type MergeRequestAction = RequestAction | PoliciesAction;

export function mergePolicies(items: Policy[]) {
  return {
    type: 'ME_MERGE_POLICIES' as const,
    items,
  };
}

export function mergePolicyDetail(item: Policy) {
  return {
    type: 'ME_MERGE_POLICY_DETAIL' as const,
    item,
  };
}

export function mergePolicyAttr(
  policyId: string,
  attr: OptionalPolicyAttributes
) {
  return {
    type: 'ME_MERGE_POLICY_ATTR' as const,
    policyId,
    attr,
  };
}

export function fetchPolicies() {
  return async (
    dispatch: ThunkDispatch<AppState, UnknownObject, MergeRequestAction>
  ) => {
    const requestType = 'LOAD_MY_POLICIES';

    dispatch(setRequestInProcess(true, requestType));

    try {
      const { data } = await endpoint.getMyPolicies();
      dispatch(mergePolicies(data));
      dispatch(setRequestInProcess(false, requestType));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        dispatch(setRequestErrored(error as APIResponseError, requestType));
      }
    }
  };
}

export function fetchPolicyDetail(policyId: string) {
  return async (
    dispatch: ThunkDispatch<AppState, UnknownObject, MergeRequestAction>
  ) => {
    const requestType = 'LOAD_MY_POLICY_DETAIL';

    dispatch(setRequestInProcess(true, requestType));

    try {
      const { data } = await endpoint.getPolicyDetail(policyId);
      await dispatch(fetchAccountInfo());
      dispatch(mergePolicyDetail(data));
      dispatch(setRequestInProcess(false, requestType));
      if (hasAutomatedCancelProcess[data.type] && data.status !== 'CANCELED') {
        dispatch(fetchPolicyCancellation(policyId));
      }
    } catch (error) {
      dispatch(setRequestErrored(error as APIResponseError, requestType));
    }
  };
}

export function fetchPolicyCancellation(policyId: string) {
  return async (
    dispatch: ThunkDispatch<AppState, UnknownObject, MergeRequestAction>
  ) => {
    const requestType = 'LOAD_MY_POLICY_CANCELLATION';
    dispatch(setRequestInProcess(true, requestType));

    try {
      const { data } = await endpoint.getPolicyCancellation(policyId);
      dispatch(mergePolicyAttr(policyId, data));
      dispatch(setRequestInProcess(false, requestType));
    } catch (error) {
      dispatch(setRequestErrored(error as APIResponseError, requestType));
    }
  };
}

export function cancelPolicy(
  policyId: string,
  reason?: PolicyCancellationReason,
  additionalInfo?: string,
  onSuccessMessage?: string
) {
  return async (
    dispatch: ThunkDispatch<AppState, UnknownObject, RequestAction>
  ) => {
    const requestType = 'CANCEL_POLICY';

    dispatch(setRequestInProcess(true, requestType));

    try {
      await endpoint.cancelPolicy(policyId, reason, additionalInfo);
      browserHistory.goBack();
      dispatch(setRequestInProcess(false, requestType));
      toast(onSuccessMessage ?? 'Your policy was successfully cancelled', {
        type: 'success',
      });
    } catch (error) {
      dispatch(setRequestErrored(error as APIResponseError, requestType));
    }
  };
}

export function markPolicyDroppedOut(policyId: string) {
  return async (
    dispatch: ThunkDispatch<AppState, UnknownObject, RequestAction>
  ) => {
    const requestType = 'MARK_POLICY_DROPPED_OUT';

    dispatch(setRequestInProcess(true, requestType));

    try {
      await endpoint.markPolicyDroppedOut(policyId);
      browserHistory.goBack();
      dispatch(setRequestInProcess(false, requestType));
      toast('Your policy was successfully disabled', { type: 'success' });
    } catch (error) {
      dispatch(setRequestErrored(error as APIResponseError, requestType));
    }
  };
}

export const cancelApplication =
  (policyId: string) =>
  async (dispatch: ThunkDispatch<AppState, UnknownObject, RequestAction>) => {
    const requestType = 'CANCEL_APPLICATION';

    dispatch(setRequestInProcess(true, requestType));

    try {
      await endpoint.markPolicyDroppedOut(policyId);
      browserHistory.push(routes.me.policies.path);
      dispatch(setRequestInProcess(false, requestType));
      toast('Your application was successfully cancelled', { type: 'success' });
    } catch (error) {
      dispatch(setRequestErrored(error as APIResponseError, requestType));
    }
  };
