import { trackConversion } from '@getpopsure/analytics';
import { Region } from '@getpopsure/public-models';
import { flushTrackingObject } from '@getpopsure/tracker';
import { captureException } from '@sentry/react';
import { InsuranceTypes } from 'models/insurances/types';
import endpoint from 'shared/api';
import {
  getFinanceAdsCategory,
  trackFinanceAdsConversion,
} from 'shared/trackers/financeAds';

export const mapVerticalIdToSnowplowVerticalId: Partial<
  Record<InsuranceTypes, InsuranceTypes>
> = {
  DENTAL_V2: 'DENTAL',
  LEGAL_V2: 'LEGAL',
  EXPAT_V2: 'INCOMING',
  LIFE_V2: 'LIFE',
  BIKE_V2: 'BIKE',
};

export const trackConversions = async ({
  verticalId,
  policyId,
  regionOfPurchase,
}: {
  verticalId: InsuranceTypes;
  policyId: string;
  regionOfPurchase: Region;
}) => {
  try {
    const vertical = mapVerticalIdToSnowplowVerticalId[verticalId]
      ? mapVerticalIdToSnowplowVerticalId[verticalId]
      : verticalId;

    trackConversion({
      vertical,
      policy_id: policyId,
      region_of_purchase: regionOfPurchase,
    });

    // financeAds
    const financeAdsCategory = getFinanceAdsCategory(verticalId);
    if (financeAdsCategory) {
      await trackFinanceAdsConversion(endpoint.network, {
        policyId,
        financeAdsCategory,
      });
    }

    flushTrackingObject();
  } catch (err) {
    captureException(err);
  }
};
