import { ErrorWithAction } from 'components/ErrorWithAction';
import TimedLoadSpinner from 'components/timedLoadSpinner';
import routes from 'constants/routes';
import {
  flushBikeClaimsAnswers,
  retrieveBikePolicyDetails,
  storeBikeClaimsAnswers,
} from 'features/bikeClaims/actions';
import { BikeClaimsView } from 'features/bikeClaims/BikeClaims.view';
import type {
  BikeClaims as BikeClaimsModel,
  BikeClaimsDispatch,
  BikeClaimsMetaData,
} from 'features/bikeClaims/models';
import { translatedQuestionnaire } from 'features/bikeClaims/questionnaire';
import { getBikeClaims } from 'features/bikeClaims/selectors';
import { useRequestStatus } from 'hooks/useRequestStatus';
import { Plan } from 'models/bike';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useHistory, useParams } from 'react-router';
import { useSafeTranslation } from 'shared/i18n';

export const BikeClaims = () => {
  const { t } = useSafeTranslation();
  const dispatch = useDispatch<BikeClaimsDispatch>();
  const history = useHistory();

  const answers = useSelector(getBikeClaims) ?? {};
  const { policyId }: { policyId: string } = useParams();

  const { loading: fetchPolicyDetailsLoading } = useRequestStatus(
    'LOAD_MY_POLICY_DETAIL'
  );

  const [fetchPolicyDetailsError, setFetchPolicyDetailsError] =
    useState<boolean>(false);
  const [policyDetails, setPolicyDetails] = useState<{
    policyStartDate: string;
    planId: Plan;
    questionnaireId: string;
  }>({
    policyStartDate: '',
    planId: 'BASIC',
    questionnaireId: '',
  });

  const flushAnswers = useCallback(() => {
    dispatch(flushBikeClaimsAnswers());
  }, [dispatch]);

  useEffect(() => {
    const handleRetrievePolicyDetails = async () => {
      const response = await dispatch(retrieveBikePolicyDetails(policyId));

      if (!response) {
        setFetchPolicyDetailsError(true);
        return;
      }

      setPolicyDetails(response);
    };

    handleRetrievePolicyDetails();

    return flushAnswers;
  }, [policyId, dispatch, flushAnswers]);

  const handleSubmitAnswer = (answer: Partial<BikeClaimsModel>) => {
    dispatch(storeBikeClaimsAnswers(answer));
  };

  if (fetchPolicyDetailsLoading) return <TimedLoadSpinner />;

  if (fetchPolicyDetailsError) {
    const handleResetFlow = async () => {
      const policyDetailsPage = generatePath(routes.me.policies.detail.path, {
        policyId,
      });
      await flushAnswers();
      history.push(policyDetailsPage);
    };

    return (
      <ErrorWithAction
        title={t('claims.bike.mainError.title', 'You are missing some info')}
        description={t(
          'claims.bike.mainError.description',
          'It seems like you are missing some info to submit the claim. Click here to go back to the policy page and try again.'
        )}
        cta={{
          title: t('claims.bike.mainError.cta', 'Go back to policy'),
          onClick: handleResetFlow,
        }}
      />
    );
  }

  const metaData: BikeClaimsMetaData = {
    policyId,
    ...policyDetails,
  };

  return (
    <BikeClaimsView
      questionnaireAnswers={answers}
      questionnaire={translatedQuestionnaire(t, metaData)}
      handleAnswerQuestion={handleSubmitAnswer}
    />
  );
};
