import {
  QuestionnaireFormProps,
  QuestionnaireQuestions,
} from '@getpopsure/qnr-framework';
import GenericReview from 'components/review';
import { RequestType } from 'constants/requestTypes';
import dayjs from 'dayjs';
import { useRequestStatus } from 'hooks/useRequestStatus';
import { InsuranceTypes } from 'models/insurances/types';
import { QuestionReviewValue } from 'models/review';
import { FormEvent } from 'react';
import { useDispatch } from 'react-redux';
import { GenericQuestionnaireState } from 'reducers/genericQuestionnaire';
import {
  CreatePostQuoteAction,
  CreateQuoteDispatch,
} from 'SignupQuestionnaire/actions/account';

type ValueMap = Record<string, string>;

export type QuestionReviewValueType<
  Questionnaire extends QuestionnaireQuestions
> = {
  title: string | null;
  value:
    | { type: 'String'; key: keyof Questionnaire; valueMap?: ValueMap }
    | {
        type: 'Boolean';
        key: keyof Questionnaire;
        valueMap?: { true: string; false: string };
      }
    | { type: 'Strings'; key: keyof Questionnaire; keys: string[] }
    | { type: 'Date'; format: string; key: keyof Questionnaire }
    | {
        type: 'Array';
        pick?: string[];
        key: keyof Questionnaire;
        valueMap?: ValueMap;
        transform?: (value: unknown) => string;
      }
    | {
        type: 'Object';
        key: keyof Questionnaire;
      }
    | null;
  path: string | null;
};

export const prepareReviewValues = <
  Questionnaire extends QuestionnaireQuestions
>(
  answers: Partial<Questionnaire>,
  reviewValues: QuestionReviewValueType<Questionnaire>[]
) => {
  const reviews: QuestionReviewValue[] = [];
  for (const reviewValue of reviewValues) {
    switch (reviewValue.value?.type) {
      case 'String': {
        const map = reviewValue.value.valueMap;
        const value = String(answers[reviewValue.value.key] ?? '');
        reviews.push({
          ...reviewValue,
          value: map ? map[value] : value,
        });
        break;
      }
      case 'Strings': {
        const answer = answers[reviewValue.value.key];
        if (answer && typeof answer === 'object') {
          const value = reviewValue.value.keys.reduce((acc, key) => {
            return `${acc} ${(answer as Record<string, unknown>)[key]}`;
          }, '');
          reviews.push({
            ...reviewValue,
            value,
          });
        }
        break;
      }
      case 'Date': {
        const answer = answers[reviewValue.value.key];
        if (answer) {
          const value = dayjs(answer as string).format(
            reviewValue.value.format
          );
          reviews.push({
            ...reviewValue,
            value,
          });
        }
        break;
      }
      case 'Array': {
        const selectedAnswers = answers[reviewValue.value.key];
        const pick = reviewValue.value.pick ? reviewValue.value.pick[0] : false;
        const { valueMap: map, transform } = reviewValue.value;
        const value = ((selectedAnswers || []) as [])
          .reduce((acc, answer) => {
            return [
              ...acc,
              pick ? (answer as Record<string, never>)[pick] : answer,
            ];
          }, [])
          .map((v) => (transform ? transform(v) : v))
          .map((v) => (map ? map[v] : v))
          .join(', ');
        reviews.push({
          ...reviewValue,
          value,
        });
        break;
      }
      case 'Object': {
        const selectedAnswers = answers[reviewValue.value.key];
        if (
          selectedAnswers !== undefined &&
          selectedAnswers !== null &&
          typeof selectedAnswers === 'object'
        ) {
          const value = Object.entries(
            selectedAnswers as Record<string, unknown>
          )
            .reduce<string[]>((acc, [key, answer]) => {
              return [...acc, `${key}: ${answer}`];
            }, [])
            .join(' / ');
          reviews.push({
            ...reviewValue,
            value,
          });
        }
        break;
      }
      case 'Boolean': {
        const answer = answers[reviewValue.value.key];
        if (answer !== undefined) {
          const map = reviewValue.value.valueMap ?? {
            true: 'Yes',
            false: 'No',
          };
          const value = answer === true ? map.true : map.false;
          reviews.push({
            ...reviewValue,
            value,
          });
        }
        break;
      }
    }
  }
  return reviews;
};

export const Review = <
  Questionnaire extends QuestionnaireQuestions = QuestionnaireQuestions
>({
  onSubmitValue,
  questionnaireAnswers,
  reviewValues,
  createPostQuote,
  verticalId,
  requestType,
  confirmationText,
  region = 'de',
  insuranceType,
}: {
  requestType: RequestType;
  verticalId: keyof GenericQuestionnaireState;
  insuranceType: InsuranceTypes;
  createPostQuote?: () => CreatePostQuoteAction<
    Questionnaire,
    typeof verticalId
  >;
  reviewValues: QuestionReviewValueType<Questionnaire>[];
  region?: string;
  confirmationText?: string;
} & QuestionnaireFormProps<Questionnaire>) => {
  const { loading, error } = useRequestStatus(requestType);

  const dispatch = useDispatch<CreateQuoteDispatch<typeof verticalId>>();

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    if (!createPostQuote) {
      return;
    }

    const { status, result } = await dispatch(createPostQuote());
    if (status === 'SUCCESS') {
      onSubmitValue(true, result);
    }
  };

  const questionReviewValues = prepareReviewValues(
    questionnaireAnswers,
    reviewValues
  );

  return (
    <GenericReview
      title={<h1 className="p-h1 mb8">Here's what we know about you:</h1>}
      questionReviewValues={questionReviewValues}
      loading={loading}
      error={error?.message}
      handleSubmit={handleSubmit}
      confirmationText={confirmationText}
      region={region}
      insuranceType={insuranceType}
    />
  );
};
