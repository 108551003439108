import { website } from '@getpopsure/private-constants';
import { Input } from '@popsure/dirty-swan';
import classnames from 'classnames';
import { APIResponseError } from 'models/error';
import { ChangeEvent, FormEvent, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { emailAddressValidator } from 'shared/util/emailValidator';

import styles from './EmailForm.module.scss';

interface Props {
  email: string;
  loading?: boolean;
  error?: APIResponseError;
  onInputChange(email: string): void;
  onSubmit(): void;
}

const Email = ({ email, loading, error, onInputChange, onSubmit }: Props) => {
  const [termsAccepted, setTermsAccepted] = useState(false);

  const valid = emailAddressValidator(email) && termsAccepted;

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    onInputChange(e.target.value);
  };

  const handleSetTermsAccepted = () => {
    setTermsAccepted((value) => !value);
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    if (valid) {
      onSubmit();
    }
  };
  return (
    <form className={styles.container} onSubmit={handleSubmit}>
      <div className="p-body">
        <h1 className="p-h1">What&apos;s your email address?</h1>
        <p className="p-p mt8">
          With your email address, we can create a Feather account for you to
          access your policies at any time.
        </p>
        <Input
          className="mt16 wmx6"
          type="email"
          value={email}
          placeholder="Email address"
          onChange={handleInputChange}
          required
          data-cy="input-email"
        />
        <div className="mt16 p-label-container">
          <input
            id="terms"
            className="p-checkbox"
            type="checkbox"
            onChange={handleSetTermsAccepted}
          />
          <label
            htmlFor="terms"
            className={`p-label ${styles.label}`}
            data-cy="email-tnc"
          >
            <div className="p-p">
              I have read and understood the{' '}
              <a
                className="p-a"
                target="_blank"
                rel="noopener noreferrer"
                href={website.privacyPolicy}
              >
                privacy policy
              </a>{' '}
              and{' '}
              <a
                className="p-a"
                target="_blank"
                rel="noopener noreferrer"
                href={website.termsAndConditions}
              >
                T&Cs
              </a>
              .
            </div>
          </label>
        </div>
        <AnimateHeight duration={300} height={error ? 'auto' : 0}>
          <div className="p-p--small p-notice--warning mt16">
            {error?.message}
          </div>
        </AnimateHeight>
        <button
          data-cy="email-form-submit"
          className={classnames(
            `p-btn p-btn--primary mt24 wmn3 ${styles.button}`,
            {
              'p-btn--loading': loading,
            }
          )}
          disabled={!valid}
          type="submit"
        >
          Continue
        </button>
      </div>
    </form>
  );
};

export default Email;
