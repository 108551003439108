import { Country, Gender, Region, regions } from '@getpopsure/public-models';
import { CheckoutInfo } from 'features/checkout/models';
import { Name } from 'models/user';
import { TFunction } from 'shared/i18n/utils';
import { ActionResponse } from 'shared/models/types';
import { ZCountrySchema, ZNameSchema } from 'shared/models/validations';
import { BLOCKER, SCREEN } from 'SignupQuestionnaire';
import { z } from 'zod';

// TODO: Move this country-handling logic where it's centralized, translatable and reusable (f.e. i18n library)
// This will also allow us to handle country names that differ based on context ("Netherlands"/"The Netherlands")
export const regionToNameMapping: Record<Region, string> = {
  nl: 'Netherlands',
  se: 'Sweden',
  be: 'Belgium',
  it: 'Italy',
  at: 'Austria',
  mt: 'Malta',
  fi: 'Finland',
  cy: 'Cyprus',
  gr: 'Greece',
  ee: 'Estonia',
  pt: 'Portugal',
  lt: 'Lithuania',
  hr: 'Croatia',
  lv: 'Latvia',
  mc: 'Monaco',
  de: 'Germany',
  es: 'Spain',
  fr: 'France',
};

export const isValidRegion = (region: string): region is Region => {
  return regions
    .filter((reg) => !['de', 'es', 'fr'].includes(reg))
    .includes(region as Region);
};

const Occupations = [
  'UNEMPLOYED',
  'SELF_EMPLOYED',
  'STUDENT',
  'HAVE_A_JOB_OFFER',
  'EMPLOYED',
  'OTHER',
] as const;

export type Occupation = typeof Occupations[number];

const StudentTypes = [
  'RECOGNIZED_UNIVERSITY',
  'LANGUAGE_STUDENT',
  'OTHER',
] as const;

export type StudentType = typeof StudentTypes[number];

export type QuotePlan = 'BASIC' | 'PREMIUM';

export const mapPlanId: Record<QuotePlan, string> = {
  BASIC: 'Basic',
  PREMIUM: 'Premium',
};

export const occupationMapping = (
  t: TFunction
): Record<Occupation, string> => ({
  HAVE_A_JOB_OFFER: t(
    'expatEu.qnr.occupationMapping.jobOffer',
    'I have a job lined up'
  ),
  SELF_EMPLOYED: t(
    'expatEu.qnr.occupationMapping.selfEmployed.title',
    'Self-employed'
  ),
  EMPLOYED: t('expatEu.qnr.occupationMapping.employed', 'Employed'),
  STUDENT: t('expatEu.qnr.occupationMapping.student', 'Student'),
  UNEMPLOYED: t('expatEu.qnr.occupationMapping.unemployed', 'Unemployed'),
  OTHER: t('expatEu.qnr.occupationMapping.otherOption', 'Other'),
});

export const genderMapping = (t: TFunction): Record<Gender, string> => ({
  MALE: t('expatEu.qnr.gender.optionMale', 'Male'),
  FEMALE: t('expatEu.qnr.gender.optionFemale', 'Female'),
  OTHER: t('expatEu.qnr.gender.optionOther', 'Gender non-conforming'),
});

export const planIds = ['BASIC', 'PREMIUM'] as const;

export type Plan = typeof planIds[number];

// TODO: define the quote type
export const ZQuote = z.object({
  id: z.optional(z.string()),
  planId: z.enum(planIds),
  price: z.number(),
});

export type Quote = z.TypeOf<typeof ZQuote>;

export type Quotes = {
  id: string;
  planId: string;
  price: number;
}[];

export type LegalGuardian = {
  name: Name;
  dateOfBirth: string;
  email: string;
};

export type CheckoutPolicyDetails = {
  regionOfPurchase: string;
  legalGuardian?: LegalGuardian;
  isMainPolicyTheLegalGuardian?: boolean;
};

export type CreateQuoteReturnType = {
  status: ActionResponse;
};

export type ExpatEu = {
  region: string;
  intro: SCREEN;

  dateOfBirth: string;
  ageIs75AndAboveBlocker: BLOCKER;

  arrivalDate: string;

  betweenFourAndFiveYearsBlocker: BLOCKER;
  moreThanFiveYearsBlocker: BLOCKER;

  occupation: Occupation;

  regionOfEmployment: 'YES' | 'NO';
  employedInRegionBlocker: BLOCKER;

  quoteOptions: Quote[];
  quote: Quote;

  signupIntro: boolean;

  email: string;
  name: Name;
  gender: Gender;

  citizenship: Country[];
  lastPermanentResidency: Country;

  startDate: string;

  legalGuardianName: Name;
  legalGuardianDateOfBirth: string;
  legalGuardianEmail: string;

  review: boolean;
  hasDownloadedDocs: boolean;
  downloadDocuments: boolean;

  checkoutInfo: CheckoutInfo<CheckoutPolicyDetails>;
  reviewCheckout: boolean;
  checkout: boolean;

  isPolicyHolder: boolean;

  addFamilyMembers: boolean;

  dependent: Partial<ExpatEuDependent>;

  quoteId: string;
  questionnaireId: string;
};

export type ExpatEuDependent = Omit<
  ExpatEu,
  'addFamilyMembers' | 'email' | 'dependent'
> & {
  mainPolicyId: string;
  isMainPolicyTheLegalGuardian: boolean;
};

export const ZSubmittableExpatEuBaseQuestionnaire = z.object({
  region: z.string(),
  dateOfBirth: z.string(),
  occupation: z.enum(Occupations),
  name: ZNameSchema,
  gender: z.enum(['MALE', 'FEMALE', 'OTHER']),
  lastPermanentResidency: ZCountrySchema,
  citizenship: z.array(ZCountrySchema),
  startDate: z.string(),
  arrivalDate: z.string(),
  legalGuardianName: z.optional(ZNameSchema),
  legalGuardianDateOfBirth: z.optional(z.string()),
  legalGuardianEmail: z.optional(z.string()),
});

export const ZSubmittableExpatEuQuestionnaire =
  ZSubmittableExpatEuBaseQuestionnaire.extend({
    email: z.string().email(),
    addFamilyMembers: z.optional(z.boolean()),
  }).transform(
    ({
      name,
      email,
      dateOfBirth,
      gender,
      startDate,
      citizenship,
      lastPermanentResidency,
      occupation,
      arrivalDate,
      legalGuardianName,
      legalGuardianDateOfBirth,
      legalGuardianEmail,
      addFamilyMembers,
    }) => {
      const hasLegalGuardian =
        legalGuardianName !== undefined &&
        legalGuardianDateOfBirth !== undefined &&
        legalGuardianEmail !== undefined;
      return {
        personalInfo: {
          name,
          email,
          dateOfBirth,
          gender,
          occupation,
          lastPermanentResidency,
          arrivalDate,
          citizenship,
        },
        tariffInfo: {
          arrivalDate,
          startDate,
          addFamilyMembers,
          legalGuardian: hasLegalGuardian
            ? {
                name: legalGuardianName,
                dateOfBirth: legalGuardianDateOfBirth,
                email: legalGuardianEmail,
              }
            : undefined,
        },
      };
    }
  );

export const ZSubmittableExpatEuDependentQuestionnaire =
  ZSubmittableExpatEuBaseQuestionnaire.extend({
    isMainPolicyTheLegalGuardian: z.optional(z.boolean()),
    mainPolicyId: z.string(),
  }).transform(
    ({
      name,
      dateOfBirth,
      gender,
      startDate,
      citizenship,
      lastPermanentResidency,
      occupation,
      arrivalDate,
      legalGuardianName,
      legalGuardianDateOfBirth,
      legalGuardianEmail,
      isMainPolicyTheLegalGuardian,
      mainPolicyId,
    }) => {
      const hasLegalGuardian =
        legalGuardianName !== undefined &&
        legalGuardianDateOfBirth !== undefined &&
        legalGuardianEmail !== undefined;
      return {
        personalInfo: {
          name,
          dateOfBirth,
          gender,
          occupation,
          lastPermanentResidency,
          arrivalDate,
          citizenship,
        },
        tariffInfo: {
          arrivalDate,
          startDate,
          isMainPolicyTheLegalGuardian,
          legalGuardian: hasLegalGuardian
            ? {
                name: legalGuardianName,
                dateOfBirth: legalGuardianDateOfBirth,
                email: legalGuardianEmail,
              }
            : undefined,
        },
        mainPolicyId,
      };
    }
  );

export type SubmittableExpatEuQuestionnaire = z.infer<
  typeof ZSubmittableExpatEuQuestionnaire
>;

export type SubmittableExpatEuDependentQuestionnaire = z.infer<
  typeof ZSubmittableExpatEuDependentQuestionnaire
>;

export type QuotesAnswers = {
  region: string;
  dateOfBirth: string;
};

export type ExpatEuGroupIds = 'preQuote' | 'signup';

export type ExpatEuCancellationReasonsId =
  | 'RIGHT_OF_WITHDRAWAL'
  | 'FOUND_A_JOB'
  | 'LEFT_COUNTRY'
  | 'FOUND_ANOTHER_INSURANCE'
  | 'OTHER';
