import LoadingSpinner from 'components/loadingSpinner';
import routes from 'constants/routes';
import { mergeCheckoutInfo } from 'features/paymentScreen/actions';
import { linkToStripe, startCheckout } from 'features/paymentScreen/api';
import { PaymentScreenThunkDispatch } from 'features/paymentScreen/paymentScreen.thunks';
import {
  useFormIdFromQuery,
  useInsuranceTypeFromQuery,
  useMonthlyPriceFromQuery,
  usePlanIdFromQuery,
  useStartDateFromQuery,
} from 'features/paymentScreen/utils/queryParams.utils';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { generatePath, useHistory, useParams } from 'react-router';
import api from 'shared/api';
import { getLocaleAndRegionFromLocaleId } from 'shared/i18n';

const UrlGateway = () => {
  const { localeId } = useParams<{ localeId?: string }>();

  const startDate = useStartDateFromQuery('startDate');
  const insuranceType = useInsuranceTypeFromQuery('insuranceType');
  const planId = usePlanIdFromQuery('planId');
  const formId = useFormIdFromQuery('formId');
  const monthlyPriceInEuros = useMonthlyPriceFromQuery('monthlyPrice');

  const history = useHistory();
  const dispatch = useDispatch<PaymentScreenThunkDispatch>();

  const callInitializeCheckout = async () => {
    await linkToStripe(api.network);

    const { data: checkout } = await startCheckout(api.network, {
      startDate,
      insuranceType,
      monthlyPriceInEuros,
      formId,
      policyDetails: {
        type: insuranceType,
        planId,
      },
      // policyInfo is required by the BE in the process of merging generic and non-generic checkouts. It can be an empty object as a first step.
      // TODO: add required properties to policyInfo and update CheckoutInfoRequest type
      policyInfo: {},
    });

    if (localeId) {
      const { region } = getLocaleAndRegionFromLocaleId(localeId);

      dispatch(
        mergeCheckoutInfo({
          ...checkout,
          region,
        })
      );
      history.replace(
        generatePath(routes.paymentScreenRegionalised.pay.path, {
          localeId,
          checkoutId: checkout.id,
        })
      );
    } else {
      dispatch(mergeCheckoutInfo(checkout));

      history.replace(
        generatePath(routes.paymentScreen.pay.path, {
          checkoutId: checkout.id,
        })
      );
    }
  };

  useEffect(() => {
    callInitializeCheckout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <LoadingSpinner />;
};

export default UrlGateway;
