import { Button, InformationBox, Input } from '@popsure/dirty-swan';
import classNames from 'classnames';
import { NavButton } from 'components/NavButton';
import routes from 'constants/routes';
import type { ViewProps } from 'features/signIn/containers';
import { useGetEmailFromUrl } from 'hooks/useGetEmailFromUrl';
import { useEffect, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { Redirect } from 'react-router-dom';
import { useSafeTranslation } from 'shared/i18n';
import { emailAddressValidator } from 'shared/util/emailValidator';
import { isMobileApp } from 'shared/util/isMobileApp';

import styles from './styles.module.scss';

const SignIn = ({
  isUserSignedIn,
  loading,
  error: errorFromRedux,
  submitSignInEmail,
  signInWithLoginCode,
}: ViewProps) => {
  const { t } = useSafeTranslation();

  const { referrer, emailParam, clearEmailParam, referrerParam } =
    useGetEmailFromUrl();
  const [email, setEmail] = useState<string | null>(null);
  const [loginCode, setLoginCode] = useState<string | null>(null);
  const [emailSubmitted, setEmailSubmitted] = useState(false);
  const [sentNewCode, setSentNewCode] = useState(false);

  const validEmail = emailAddressValidator(email) || false;
  const validForSignIn = validEmail && loginCode && loginCode.length > 0;

  useEffect(() => {
    if (emailParam) {
      setEmail(emailParam);
      submitSignInEmail(emailParam, setEmailSubmitted);
      clearEmailParam();
    }
  }, [emailParam, submitSignInEmail, clearEmailParam]);

  if (isUserSignedIn) {
    return (
      <Redirect
        to={{
          pathname: referrer || routes.me.path,
          search: referrerParam,
        }}
      />
    );
  }

  const handleWrongEmailClick = () => {
    clearEmailParam();
    setEmail(null);
    setEmailSubmitted(false);
  };

  let error = '';

  switch (errorFromRedux) {
    case '422':
      error = t(
        'signIn.errors.invalidCode',
        'Provided login code is either incorrect or expired.'
      );
      break;
    default:
      error = errorFromRedux || '';
  }

  if (emailSubmitted) {
    return (
      <form
        className={`w100 ${styles['check-email-container']}`}
        onSubmit={(e) => {
          e.preventDefault();
          if (!email || !loginCode) {
            throw new Error(
              '[Sign in] Email and login code are required to sign in.'
            );
          }
          setSentNewCode(false);
          signInWithLoginCode(email, loginCode);
        }}
      >
        <div className="p-body">
          <NavButton
            renderInPortal
            onClick={handleWrongEmailClick}
            title={t('mobileNavbar.backToMainPage', 'Back')}
            className={styles.navButton}
          />
          <h1 className="p-h1">
            {t('signIn.checkYourEmail.title', 'Check your email ✉️')}
          </h1>
          <p className="p-p mt8">
            {t('signIn.loginCodeSent.message', {
              defaultValue: 'We’ve sent a login code to {{email}}',
              email,
            })}
          </p>
          <Input
            className="d-block wmx6 mt16"
            placeholder={t('signIn.loginCode.placeholder', 'Login code')}
            onChange={(e) => setLoginCode(e.target.value)}
            value={loginCode ?? ''}
            data-cy="account-signin-login-code-input"
            inputMode="numeric"
          />
          <AnimateHeight duration={300} height={error ? 'auto' : 0}>
            <InformationBox variant="error" className="mt16 wmx6">
              {error}
            </InformationBox>
          </AnimateHeight>
          <AnimateHeight duration={300} height={sentNewCode ? 'auto' : 0}>
            <InformationBox className="mt16 wmx6">
              {t(
                'signIn.newCodeSent.message',
                "We've just sent a new code to your email"
              )}
            </InformationBox>
          </AnimateHeight>
          <div className={`wmx6 mt8 ${styles['button-container']}`}>
            <Button
              data-cy="account-signin-continue-button"
              data-testid="account-signin-continue-button"
              className="wmn3 mt8"
              loading={loading}
              type="submit"
              disabled={!validForSignIn || loading}
            >
              {t('signIn.continueButton.caption', 'Continue')}
            </Button>
            <Button
              className="wmn3 mt8"
              variant="textColor"
              disabled={loading}
              onClick={(e) => {
                e.preventDefault();
                if (!email) {
                  throw new Error('[Sign in] Email is required to sign in.');
                }
                setSentNewCode(true);
                submitSignInEmail(email);
              }}
            >
              {t('signIn.sendNewCodeButton.caption', 'Send new code')}
            </Button>
          </div>
        </div>
      </form>
    );
  }

  return (
    <div>
      <form
        className={`w100 ${styles['signin-container']}`}
        onSubmit={(e) => {
          e.preventDefault();
          if (!email) {
            throw new Error('[Sign in] Email is required to sign in.');
          }
          submitSignInEmail(email, setEmailSubmitted);
        }}
      >
        <div className={`p-body ${styles['inner-container']}`}>
          {isMobileApp && (
            <NavButton
              renderInPortal
              path="/"
              title={t('mobileNavbar.backToMainPage', 'Back')}
            />
          )}
          <h1 className="p-h1">{t('signIn.title', 'Log in')}</h1>
          <div className="p-p mt8">
            {t('signIn.description', 'What’s your email address?')}
          </div>
          <Input
            data-cy="account-signin-email-input"
            className="d-block wmx5 mt16"
            type="email"
            placeholder={t('components.email.placeholder')}
            onChange={(e) => setEmail(e.target.value)}
            value={email ?? ''}
          />
          <AnimateHeight duration={300} height={error ? 'auto' : 0}>
            <InformationBox variant="error" className="mt16 wmx5">
              <span data-cy="account-signin-error-message">{error}</span>
            </InformationBox>
          </AnimateHeight>
          <Button
            data-cy="account-login-code-button"
            data-testid="account-login-code-button"
            className={classNames(styles.loginButton, 'wmn3 wmx5 mt16')}
            loading={loading}
            disabled={!validEmail || loading}
            type="submit"
          >
            {t('signIn.continueButton.caption', 'Continue')}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default SignIn;
