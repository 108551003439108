import {
  getRoutes,
  removeAnswers,
  RemoveAnswerType,
  Rule,
  stateManagerHelper,
} from '@getpopsure/qnr-framework';
import { ClaimsAction } from 'constants/actions';
import routes from 'constants/routes';
import { PhoneInput } from 'features/legalClaims/components/PhoneInput';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useParams, useRouteMatch } from 'react-router';
import { AppState } from 'reducers';
import { TFunction, useSafeTranslation } from 'shared/i18n';
import {
  SignupQuestionnaire,
  SignupQuestionnaireType,
} from 'SignupQuestionnaire';

import { ClaimSubmitted } from '../legalClaims/components/ClaimSubmitted';
import { submitClaim } from './actions';
import { LegalSubmitClaims } from './models';

export type ClaimGroupIds = 'questionnaire';

export const LegalClaimsComponents = {
  PHONE: PhoneInput,
  SUBMITTED: ClaimSubmitted,
} as const;

export type ClaimQuestionnaire = SignupQuestionnaireType<
  LegalSubmitClaims,
  ClaimGroupIds,
  typeof LegalClaimsComponents
>;

export const getTranslatedQuestionnaire = (
  t: TFunction
): ClaimQuestionnaire => [
  {
    id: 'description',
    required: true,
    type: 'TEXT',
    props: {
      placeholder: t(
        'legal.qnr.claims.submitClaim.description.placeholder',
        'E.g. my work contract was unjustly terminated after a dispute. I want to understand my options and potentially if I have a legal case.'
      ),
      maxLength: 10_000,
    },
    screen: {
      question: t(
        'legal.qnr.claims.submitClaim.description.title',
        'Describe your legal matter'
      ),
      additionalInfo: {
        title: t(
          'legal.qnr.claims.submitClaim.description.additionalInfo.title',
          'Describing your legal case'
        ),
        description: t(
          'legal.qnr.claims.submitClaim.description.additionalInfo.description',
          'Help us understand your legal case, including what happened and who is involved.'
        ),
      },
    },
    groupId: 'questionnaire',
  },
  {
    id: 'phoneNumber',
    required: true,
    type: 'PHONE',
    props: {},
    screen: {
      question: t(
        'legal.qnr.claims.submitClaim.phoneNumber.title',
        "What's your phone number?"
      ),
    },
    groupId: 'questionnaire',
  },
  {
    id: 'phoneConsultation',
    required: true,
    type: 'BOOLEAN',
    props: {},
    screen: {
      question: t(
        'legal.qnr.claims.submitClaim.phoneConsultation.title',
        'Have you had a phone consultation about this legal matter?'
      ),
    },
    groupId: 'questionnaire',
  },
  {
    id: 'processing',
    required: true,
    type: 'PROCESSING',
    props: {},
    screen: {
      layout: 'Standalone',
    },
    groupId: 'questionnaire',
  },
  {
    id: 'submitted',
    required: true,
    type: 'SUBMITTED',
    props: {},
    screen: {
      layout: 'Standalone',
    },
    groupId: 'questionnaire',
  },
];

const rules: Rule<LegalSubmitClaims>[] = [];

const removeProcessing: RemoveAnswerType<LegalSubmitClaims> = {
  op: 'always',
  questions: ['processing', 'submitted'],
};

const removeAnswersLogic: Partial<
  Record<keyof LegalSubmitClaims, RemoveAnswerType<LegalSubmitClaims>>
> = {
  description: removeProcessing,
  phoneConsultation: removeProcessing,
};

export const storeLegalClaimsAnswers = (
  answer: Partial<LegalSubmitClaims>
): ClaimsAction => ({
  type: 'STORE_LEGAL_SUBMIT_CLAIM_V2',
  legalSubmitClaimInfo: answer,
});

export const flushLegalClaimsAnswers = (): ClaimsAction => ({
  type: 'FLUSH_LEGAL_SUBMIT_CLAIM_V2',
});

export const SubmitClaim = () => {
  const questionnaireAnswers =
    useSelector((state: AppState) => state.claims.legalSubmitClaimInfoV2) || {};
  const { policyId } = useParams<{ policyId: string }>();
  const { url } = useRouteMatch();
  const dispatch = useDispatch();

  const { t } = useSafeTranslation();

  const flushAnswers = useCallback(() => {
    dispatch(flushLegalClaimsAnswers());
  }, [dispatch]);

  useEffect(() => {
    return flushAnswers;
  }, [policyId, dispatch, flushAnswers]);

  const onAnswer = <QuestionId extends keyof LegalSubmitClaims>(
    questionId: QuestionId,
    answer: LegalSubmitClaims[QuestionId]
  ) => {
    const answersToRemove = stateManagerHelper(
      removeAnswersLogic,
      questionnaire.components,
      questionnaireAnswers,
      questionnaire.rules
    ).getAnswersToRemove(questionId, answer);

    const nextState = removeAnswers(
      {
        ...questionnaireAnswers,
        [questionId]: answer,
      },
      answersToRemove
    );

    dispatch(storeLegalClaimsAnswers(nextState));

    if (
      questionId === 'phoneConsultation' &&
      answer !== null &&
      !questionnaireAnswers.submitted
    ) {
      dispatch(submitClaim(policyId, nextState));
    }
  };

  const questions = getTranslatedQuestionnaire(t);

  const questionnaire = {
    components: questions,
    routes: getRoutes(questions, url),
    rules,
  };

  return (
    <Switch>
      <Route path={routes.claims.legal.submitClaim.questionnaire.path}>
        <SignupQuestionnaire
          questionnaireAnswers={questionnaireAnswers}
          questionnaire={questionnaire}
          onAnswer={onAnswer}
          configuration={{
            components: LegalClaimsComponents,
          }}
          basePath={url}
          questionId="description"
          featureName="LegalClaimSubmitClaim"
        />
      </Route>
    </Switch>
  );
};
