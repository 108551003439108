import { TFunction } from '@getpopsure/i18n-react';
import { Region } from '@getpopsure/public-models';
import { getTrackingObject } from '@getpopsure/tracker';
import { toast } from '@popsure/dirty-swan';
import { captureException, captureMessage } from '@sentry/browser';
import { Stripe } from '@stripe/stripe-js';
import {
  PaymentMethodsAction,
  RequestAction,
  UserAction,
} from 'constants/actions';
import routes from 'constants/routes';
import { History } from 'history';
import { InsuranceTypes } from 'models/insurances/types';
import { PaymentMethod } from 'models/paymentMethods';
import { Policy } from 'models/policies';
import { generatePath } from 'react-router';
import { AppState } from 'reducers';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import api from 'shared/api';
import { getLocaleAndRegionFromLocaleId } from 'shared/i18n';
import { trackConversions } from 'shared/trackers';

import { PaymentScreenAction } from './actions';
import {
  confirmCheckout,
  createGenericPolicyAfterPayment,
  finalizeCheckout,
  getCheckoutInfo,
  processCheckout,
} from './api';
import { Checkout, CheckoutInfo, CheckoutResult } from './paymentScreen.models';

export type PaymentScreenThunkDispatch = ThunkDispatch<
  AppState,
  Record<string, unknown>,
  PaymentScreenAction | RequestAction | UserAction | PaymentMethodsAction
>;

export const pay =
  ({
    checkoutInfo,
    paymentMethod,
    stripeClient,
    localeId,
  }: {
    checkoutInfo: CheckoutInfo;
    paymentMethod: PaymentMethod;
    stripeClient: Stripe;
    localeId?: string;
  }): ThunkAction<
    Promise<CheckoutResult>,
    AppState,
    Record<string, unknown>,
    PaymentScreenAction | RequestAction
  > =>
  async () => {
    const { id: paymentMethodId } = paymentMethod;
    const { id: checkoutId } = checkoutInfo;

    const { data: processResponse } = await processCheckout(
      api.network,
      checkoutId,
      {
        paymentMethodId,
        localeId,
      }
    );

    const { customerActionRequired, clientSecret, confirmationRequired } =
      processResponse;

    if (customerActionRequired) {
      // TODO: the next line assumes there's only one type of action that
      //       can ever be required. This might change when we add more
      //       payment method types, at that point this code needs to be revisited.
      const { error } = await stripeClient.handleCardAction(clientSecret);

      if (error) {
        throw error;
      }
    }

    if (customerActionRequired || confirmationRequired) {
      const { data: confirmResponse } = await confirmCheckout(
        api.network,
        checkoutId,
        {
          paymentMethodId,
        }
      );

      return confirmResponse;
    }

    return processResponse;
  };

export const finalize =
  ({
    checkoutId,
    paymentMethodId,
    paymentSucceeded,
  }: {
    checkoutId: string;
    paymentMethodId: string;
    paymentSucceeded: boolean;
  }): ThunkAction<
    Promise<CheckoutResult>,
    AppState,
    Record<string, unknown>,
    PaymentScreenAction | RequestAction
  > =>
  async () => {
    const { data: processResponse } = await finalizeCheckout(
      api.network,
      checkoutId,
      {
        paymentMethodId,
        paymentSucceeded,
      }
    );

    return processResponse;
  };

export const getCheckout =
  ({
    checkoutId,
  }: {
    checkoutId: string;
  }): ThunkAction<
    Promise<Checkout>,
    AppState,
    Record<string, unknown>,
    RequestAction
  > =>
  async () => {
    const { data: checkoutInfo } = await getCheckoutInfo(
      api.network,
      checkoutId
    );

    return checkoutInfo;
  };

export const createPolicyAfterPayment =
  ({
    referralCode,
    localeId,
    formId,
    policyId,
    verticalId,
    policyMonthlyPrice,
  }: {
    referralCode?: string;
    localeId?: string;
    formId?: string;
    policyId: string;
    verticalId: InsuranceTypes;
    policyMonthlyPrice: number;
  }): ThunkAction<
    Promise<Policy>,
    AppState,
    Record<string, unknown>,
    PaymentScreenAction | RequestAction
  > =>
  async () => {
    const { region } = getLocaleAndRegionFromLocaleId(localeId ?? '');
    const { data: policy } = await createGenericPolicyAfterPayment(
      api.network,
      {
        id: policyId,
        insuranceType: verticalId,
        // TODO: fix the formId logic
        //       - do we really need to track it?
        //       - how to make the types non-optional, even though
        //         some checkout infos will likely not have a formId
        formId: formId ?? `unknown/${policyId}`,
        source: getTrackingObject(),
        referralCode,
        region,
        price: policyMonthlyPrice,
      }
    );

    return policy;
  };

export const onSuccessfulPayment =
  ({
    history,
    referralCode,
    t,
    isDuplicatePaymentAttempt,
    policyId,
    verticalId,
    formId,
    policyMonthlyPrice,
    localeId,
  }: {
    history: History;
    t: TFunction;
    policyId: string;
    verticalId: InsuranceTypes;
    referralCode?: string;
    localeId?: string;
    formId?: string;
    isDuplicatePaymentAttempt: boolean;
    policyMonthlyPrice: number;
  }): ThunkAction<
    Promise<void>,
    AppState,
    Record<string, unknown>,
    PaymentScreenAction
  > =>
  async (dispatch) => {
    const redirectToPolicyPage = (userPolicyId: string) => {
      history.replace(
        generatePath(routes.me.policies.detail.path, {
          policyId: userPolicyId,
        })
      );
    };

    const redirectToAllPoliciesPage = () => {
      history.replace(routes.me.policies.path);
    };

    if (isDuplicatePaymentAttempt) {
      captureMessage(
        '[Generic checkouts] Customer sent a duplicate payment request (see details in extra data)',
        {
          level: 'fatal',
          extra: { policyId, verticalId },
          tags: {
            priority: 'investigate-immediately',
            feature: 'paymentScreen',
          },
        }
      );

      // TODO: make sure this doesn't break conversion tracking in case of a concurrent
      //       request.
      // TODO: Confirm the messaging with Andrey and Marius.
      toast(
        t(
          'paymentScreen.errors.duplicatePolicy',
          "It looks like you've already completed this payment earlier"
        ),
        {
          type: 'error',
          description: t(
            'paymentScreen.errors.duplicatePolicyDescription',
            'Please, reach out to customer support if you cannot access your policy.'
          ),
          duration: 5000,
        }
      );

      return redirectToAllPoliciesPage();
    }

    try {
      const policy = await dispatch(
        createPolicyAfterPayment({
          referralCode,
          policyId,
          verticalId,
          formId,
          policyMonthlyPrice,
          localeId,
        })
      );

      let region: Region = 'de';
      if (localeId) {
        region = getLocaleAndRegionFromLocaleId(localeId).region as Region;
      }

      trackConversions({
        verticalId,
        policyId,
        regionOfPurchase: region,
      });

      return redirectToPolicyPage(policy.id);
    } catch (error: unknown) {
      // TODO: this request should not happen in the frontend, we will move it out
      //       as soon as the policy creation logic will move to the backend.
      //
      //       For now, in case of an error we redirect the customer to the "all policies" page
      //       with a toast, so if you see this error, we need to create a policy manually
      //       and reach out to the customer.
      captureException(error, {
        level: 'fatal',
        extra: { verticalId, policyId },
        tags: {
          priority: 'investigate-immediately',
          feature: 'paymentScreen',
        },
      });

      toast(
        t(
          'paymentScreen.errors.errorCreatingPolicy',
          'We have processed your payment successfully'
        ),
        {
          type: 'error',
          description: t(
            'paymentScreen.errors.errorCreatingPolicyDescription',
            'We have processed your payment successfully, however there was an error and we are looking into it. Please, reach out to customer support if you cannot access the policy in the next few hours'
          ),
        }
      );

      return redirectToAllPoliciesPage();
    }
  };
