import { NavButton } from 'components/NavButton';
import routes from 'constants/routes';
import damagedBikeIcon from 'features/bikeClaims/icons/damaged-bike.svg';
import phonePhotoIcon from 'features/bikeClaims/icons/phone-photo.svg';
import { generatePath, useHistory } from 'react-router';
import { TFunction } from 'shared/i18n';

import {
  GettingStartedCard,
  Props as GettingStartedCardProps,
} from './components/GettingStartedCard';
import styles from './styles.module.scss';

interface Props {
  handleGetStarted: () => void;
  t: TFunction;
  policyId: string;
}

export const BikeClaimsGettingStartedView = ({
  handleGetStarted,
  t,
  policyId,
}: Props) => {
  const { location } = useHistory<{ from?: string }>();
  const cardData: GettingStartedCardProps[] = [
    {
      title: t(
        'claims.bike.gettingStarted.cardInfo.1.title',
        'Record the damage as carefully as possible'
      ),
      description: t(
        'claims.bike.gettingStarted.cardInfo.1.description',
        'We recommend that you record the event thoroughly. Take pictures from different angles, write down what happened, and include the invoices, original receipts, and the police report in an event of theft or accident.'
      ),
      icon: {
        src: phonePhotoIcon,
        alt: t(
          'claims.bike.gettingStarted.cardInfo.1.iconalt',
          'purple phone taking a photo'
        ),
      },
    },

    {
      title: t(
        'claims.bike.gettingStarted.cardInfo.2.title',
        'Replace the affected bike or part(s)'
      ),
      description: t(
        'claims.bike.gettingStarted.cardInfo.2.description',
        'In the event of theft, buy a new bike of the same cost, type, and quality as the original, then submit the receipt for reimbursement. The same goes for any repairs - present the receipts and you will get reimbursed if it is covered.'
      ),
      icon: {
        src: damagedBikeIcon,
        alt: t(
          'claims.bike.gettingStarted.cardInfo.2.iconalt',
          'purple damaged bike'
        ),
      },
    },
  ];

  return (
    <div className={`mb80 ${styles.container}`}>
      <div className="p-body">
        <NavButton
          title={t('claims.bike.gettingStarted.navButton.title', 'Back')}
          path={
            location.state?.from !== 'policy'
              ? routes.claims.create.path
              : generatePath(routes.me.policies.detail.path, { policyId })
          }
        />
        <h1 className="p-h1">
          {t('claims.bike.gettingStarted.title', 'Getting started')}
        </h1>
        <p className="p-p mt8 mb24">
          {t(
            'claims.bike.gettingStarted.description',
            'Here are a few useful tips to consider when making a claim:'
          )}
        </p>
        {cardData.map(({ title, description, icon }) => (
          <GettingStartedCard
            className="mt16"
            title={title}
            icon={icon}
            description={description}
            key={title}
          />
        ))}
        <button
          type="button"
          onClick={handleGetStarted}
          className={`p-btn--primary mt32 wmn3 ${styles.button}`}
          data-cy="button-continue"
        >
          {t('claims.bike.gettingStarted.cta', 'Get started')}
        </button>
      </div>
    </div>
  );
};
