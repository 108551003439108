import { TFunction } from '@getpopsure/i18n-react';
import { Address } from '@getpopsure/public-models';
import { CheckoutInfo } from 'features/checkout/models';
import { Name } from 'models/user';
import { ActionResponse } from 'shared/models/types';
import { ZAddressSchema, ZNameSchema } from 'shared/models/validations';
import { SCREEN } from 'SignupQuestionnaire';
import { z } from 'zod';

export const ZQuote = z.object({
  id: z.string().optional(),
  monthlyPayment: z.number(),
  initialAmount: z.number(),
  age: z.number(),
});

export type Quote = z.TypeOf<typeof ZQuote>;

// TODO: define the checkout policy details
export type CheckoutPolicyDetails = unknown;

// TODO: define the required  quote schema
export type CreateQuoteReturnType = {
  status: ActionResponse;
};

type Review = boolean;

export type Pension = {
  quote: Quote;
  intro: SCREEN;

  // investment strategy
  investmentSustainability: string;
  investmentStrategy: string;
  savingForRetirement: boolean;
  privatePensionInfo: SCREEN;
  privatePensionLongerThan5Years: boolean;
  privatePensionLongerThan5YearsInfo: SCREEN;
  hasReviewedInvestmentStrategyInfo: Review;

  // personal details
  name: Name;
  email: string;
  address: Address;
  startDate: string;

  review: boolean;
  hasDownloadedDocs: boolean;
  downloadDocuments: boolean;

  checkoutInfo: CheckoutInfo<CheckoutPolicyDetails>;
  reviewCheckout: boolean;
  checkout: boolean;

  quoteId: string;
  questionnaireId: string;
};

export const investmentSustainability = [
  'NO_PREFERENCE',
  'FEATHER_ESG',
  'GREEN_INVESTING',
] as const;

export type InvestmentSustainability = typeof investmentSustainability[number];

export const InvestmentSustainabilityMap = (
  t: TFunction
): Record<InvestmentSustainability, string> => ({
  NO_PREFERENCE: t(
    'pension.qnr.investmentSustainiability.option.no_preference',
    'Not have a preference'
  ),
  FEATHER_ESG: t(
    'pension.qnr.investmentSustainability.option.feather_esg',
    'Follow the Feather ESG best practices'
  ),
  GREEN_INVESTING: t(
    'pension.qnr.investmentSustainability.option.green_investing',
    '100% green investment'
  ),
});

export const investmentStrategy = ['AGGRESSIVE', 'ALL_IN'] as const;

export type InvestmentStrategy = typeof investmentStrategy[number];

export const InvestmentStrategyMap = (
  t: TFunction
): Record<InvestmentStrategy, { title: string; description: string }> => ({
  AGGRESSIVE: {
    title: t(
      'pension.qnr.investmentStrategy.option.agressive.title',
      'Aggressive'
    ),
    description: t(
      'pension.qnr.investmentStrategy.option.aggressive.description',
      '75% ETF investment and 25% bonds'
    ),
  },
  ALL_IN: {
    title: t('pension.qnr.investmentStrategy.option.all_in.title', 'All-in'),
    description: t(
      'pension.qnr.investmentStrategy.option.all_in.description',
      '100% ETF investment, 0% bonds'
    ),
  },
});

export const ZSubmittablePensionQuestionnaire = z.object({
  // investement strategy
  investmentSustainability: z.enum(investmentSustainability),
  investmentStrategy: z.enum(investmentStrategy),
  savingForRetirement: z.boolean(),
  privatePensionInfo: z.optional(z.boolean()),
  privatePensionLongerThan5Years: z.boolean(),
  privatePensionLongerThan5YearsInfo: z.optional(z.boolean()),
  hasReviewedInvestmentStrategyInfo: z.boolean(),

  name: ZNameSchema,
  email: z.string(),
  address: ZAddressSchema,
  startDate: z.string(),
});
export type SubmittablePensionQuestionnaire = z.infer<
  typeof ZSubmittablePensionQuestionnaire
>;

export type PensionGroupIds = 'signup';

type QuestionId = keyof SubmittablePensionQuestionnaire;

export const questionOrderInvestmentStrategy: QuestionId[] = [
  'investmentSustainability',
  'investmentStrategy',
  'savingForRetirement',
  'privatePensionInfo',
  'privatePensionLongerThan5Years',
  'privatePensionLongerThan5YearsInfo',
];

export const questionGroupsMapping: Partial<Record<QuestionId, QuestionId[]>> =
  {
    hasReviewedInvestmentStrategyInfo: questionOrderInvestmentStrategy,
  };

export const questionnaireIdMapping: Partial<Record<QuestionId, string>> = {
  hasReviewedInvestmentStrategyInfo: 'Investment strategy',
};

export type ValidationError =
  | 'INITIAL_AMOUNT_ABOVE_LIMIT'
  | 'AGE_ABOVE_LIMIT'
  | 'AGE_BELOW_LIMIT';

export type CalculatorError =
  | ValidationError
  | 'SUBMISSION_ERROR'
  | 'MISSING_VALUES';
