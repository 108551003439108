import { calendly, faq, website } from '@getpopsure/private-constants';
import LoadingSpinner from 'components/loadingSpinner';
import { imageTypeMapping } from 'models/insurances/types/mapping';
import { SignupQuestionnaireType } from 'SignupQuestionnaire';

import AidIcon from '../assets/Aid.svg';
import AidBigIcon from '../assets/AidBig.svg';
import CheckDocIcon from '../assets/CheckDoc.svg';
import DentalIcon from '../assets/Dental.svg';
import DoorIcon from '../assets/Door.svg';
import HeartIcon from '../assets/Heart.svg';
import HeartBlackAndWhiteIcon from '../assets/HeartBlackAndWhite.svg';
import MedicineIcon from '../assets/Medicine.svg';
import MedicineBigIcon from '../assets/MedicineBig.svg';
import PawIcon from '../assets/Paw.svg';
import ShieldIcon from '../assets/Shield.svg';
import VideoCallIcon from '../assets/VideoCall.svg';
import WorldwideIcon from '../assets/Worldwide.svg';
import { QuotePage } from '../components/quotePage';
import { PetHealth, PetHealthIds } from '../models';
import { breedsList } from '../models/breedList';

export const PetHealthComponents = {
  CUSTOM_QUOTE_PAGE: QuotePage,
  REDIRECT: LoadingSpinner,
} as const;

export type PetHealthQuestionnaire = SignupQuestionnaireType<
  PetHealth,
  PetHealthIds,
  typeof PetHealthComponents
>;

export const getTranslatedQuestionnaire = (): PetHealthQuestionnaire => [
  {
    id: 'intro',
    required: true,
    type: 'INTRO',
    groupId: 'preQuote',
    props: {
      title: "Hey! Let's get you a quote",
      subTitle: 'It takes 1-2 minutes.',
    },
    screen: {
      continueButtonText: 'Get started',
    },
  },
  {
    id: 'species',
    type: 'RADIO',
    props: {
      mapValue: {
        CAT: 'Cat',
        DOG: 'Dog',
      },
    },
    screen: {
      question: 'What kind of animal is your pet?',
    },
    groupId: 'preQuote',
  },
  {
    id: 'isPureBreed',
    type: 'RADIO',
    props: {
      mapValue: { NO: 'Mixed breed', YES: 'Purebred' },
    },
    screen: {
      question: 'Is your dog mixed or purebred?',
    },
    groupId: 'preQuote',
  },
  {
    id: 'breed',
    type: 'AUTOSUGGEST',
    props: {
      options: breedsList.map((breed) => breed.en),
      placeholder: 'Dog breed',
    },
    screen: {
      question: 'What breed is your dog?',
      skipDetails: {
        title: "It's not listed",
        skipQuestion: { value: '' },
        path: '',
      },
    },
    groupId: 'preQuote',
  },
  {
    id: 'shoulderHeight',
    type: 'RADIO',
    props: {
      mapValue: {
        SMALLER_EQUAL_44_CM: '0-44 cm',
        OVER_44_CM: 'More than 44 cm',
      },
    },
    screen: {
      question: 'What is the shoulder height of your dog?',
      description: 'For reference, a coffee table is about 45cm high.',
      additionalInfo: {
        title: 'How to measure your dog?',
        description:
          "You can measure the shoulder height while your dog is standing, from its shoulder blade straight down to the ground.\n\nIf your dog hasn't reached full size yet, please estimate the future (final) height.",
      },
    },
    groupId: 'preQuote',
  },
  {
    id: 'dateOfBirth',
    required: true,
    type: 'DATE',
    props: {
      yearRange: {
        min: { op: 'subtract', type: 'years', value: 50 },
        max: { op: 'subtract', type: 'years', value: 0 },
      },
    },
    validations: [
      {
        op: 'dateDiff',
        variable: {
          type: 'day',
          value: 1,
        },
        msg: { type: 'Error', value: 'Date can not be in the future' },
      },
    ],
    screen: {
      question: 'When was your pet born?',
      description:
        "If you don't know the exact birth date, put an approximate date.",
      additionalInfo: {
        title: 'Why is it important?',
        description:
          "Your pet's age affects the cost of the insurance plan. Just like older humans, older pets are more likely to develop age-related diseases and other health issues. Therefore, the monthly premium tends to increase with the pet's age.",
      },
    },
    groupId: 'preQuote',
  },
  {
    id: 'tooYoungBlocker',
    type: 'BLOCKER',
    props: {
      iconType: 'SHIELD',
      title: 'Your pet is still too young',
      description:
        "We can only cover pets older than 8 weeks.\n\nWe don't currently support early sign-ups, but you can come back when your pet is 8 weeks old. You can speak to one of our insurance experts if you have any questions.",
      buttonProps: [
        { type: 'href', href: website.support, caption: 'Contact us' },
      ],
    },
    screen: {
      layout: 'Standalone',
    },
    groupId: 'preQuote',
  },
  {
    id: 'ageBlocker',
    type: 'BLOCKER',
    props: {
      iconType: 'SHIELD',
      title: "Unfortunately, we can't cover your pet",
      description:
        "We currently only cover pets younger than 9 years.\n\nWe're working hard to make our policies cover all use cases, but still have certain limitations. You can speak to one of our insurance experts if you have any questions.",
      buttonProps: [
        { type: 'href', href: website.support, caption: 'Contact us' },
      ],
    },
    screen: {
      layout: 'Standalone',
    },
    groupId: 'preQuote',
  },
  {
    id: 'quote',
    required: true,
    type: 'CUSTOM_QUOTE_PAGE',
    groupId: 'preQuote',
    props: {
      config: {
        title: 'Here is your quote',
        headerImage: {
          src: imageTypeMapping.PET_HEALTH ?? '',
          alt: 'dog',
        },
        reviewBadge: true,
        coveredSection: [
          {
            title: 'Surgery plan',
            layout: {
              compact: true,
              cards: true,
            },
            items: [
              {
                title: 'Surgeries',
                description:
                  'Necessary operations and related expenses such as x-rays and anesthesia.',
                icon: {
                  src: CheckDocIcon,
                  alt: 'Checked document',
                },
              },
              {
                title: 'Post-surgery recovery',
                description:
                  'Your pet will be covered up to 30 days following the operation if they need to heal at the clinic.',
                icon: {
                  src: DoorIcon,
                  alt: 'Door',
                },
              },
              {
                title: 'Worldwide coverage',
                description:
                  'Coverage for uninterrupted trips up to 12 months when you travel with your pet.',
                icon: {
                  src: WorldwideIcon,
                  alt: 'Globe',
                },
              },
            ],
          },
          {
            title: 'Standard plan',
            layout: {
              compact: true,
              cards: true,
            },
            items: [
              {
                title: 'Everything in the surgery plan',
                description:
                  'Standard plan includes the surgeries and related expenses, plus a free choice of vet.',
                icon: {
                  src: ShieldIcon,
                  alt: 'Shield',
                },
              },
              {
                title: 'Video veterinary',
                description:
                  'Arrange a video consultation for your ill pet quickly, from the comfort of home, and without the stress of a trip to the vet.',
                icon: {
                  src: VideoCallIcon,
                  alt: 'Person',
                },
              },
              {
                title: 'Veterinary treatments',
                description:
                  'Up to €800 per year for non-emergency treatments, vet visits, diagnostics, and physiotherapy.',
                icon: {
                  src: HeartIcon,
                  alt: 'Heart',
                },
              },
              {
                title: 'Medication',
                description:
                  'Medications prescribed by your vet are covered up to €800 per year (counts toward the vet treatment limit).',
                icon: {
                  src: MedicineBigIcon,
                  alt: 'Money Income',
                },
              },
              {
                title: 'Preventive treatments',
                description:
                  'Up to €70 per year for professional dental cleanings, vaccinations, flea and tick repellents, and deworming.',
                icon: {
                  src: AidBigIcon,
                  alt: 'Damaged laptop',
                },
              },
              {
                title: 'Dental procedures',
                description:
                  'Up to €150 per year for dental procedures such as fillings, tooth replacement, and extractions.',
                icon: {
                  src: DentalIcon,
                  alt: 'Money Income',
                },
              },
            ],
          },
        ],
        notCoveredSection: {
          title: "What's not covered?",
          items: [
            {
              title: 'Surgeries for pre-existing conditions',
              description:
                'Operations for illnesses, accidents, congenital, genetic or acquired abnormalities of which you were aware prior to the start of the policy cover.',
              icon: {
                src: AidIcon,
                alt: '',
              },
            },
            {
              title: 'Breed standardisation procedures',
              description:
                'Treatments and operations that serve to establish the respective breed standard that are not deemed medically necessary.',
              icon: {
                src: HeartBlackAndWhiteIcon,
                alt: 'Heart',
              },
            },
            {
              title: 'Brachycephalic syndrome treatment',
              description:
                'Treatments and operations required due to brachycephalic syndrome, such as the operation on a soft palate that is too long.',
              icon: {
                src: MedicineIcon,
                alt: 'Medicine pill',
              },
            },
            {
              title: 'Pets must not be 9 years old or older',
              description:
                'Your pet must be at least 8 weeks old and no older than 9 years old on the start date of the pet health insurance.',
              icon: {
                src: PawIcon,
                alt: 'Paw',
              },
            },
          ],
        },
        ctaBox: {
          header: 'Pet health insurance',
          title: '{{value}}',
          subTitle: 'per month',
          buttonText: 'Get covered',
        },
        faq: {
          title: 'Frequently asked questions',
          data: [
            {
              questionId: 1,
              question:
                'What is the difference between surgery and standard insurance coverage?',
              answer:
                'Surgery coverage only covers the costs directly related to an operation.\n\nStandard insurance is pet health insurance that also covers surgeries and the additional costs that are incurred for other treatments for your animal, such as outpatient treatment costs, physiotherapy, preventive services, or certain dental treatments.\n\n- An operation is a veterinary medically required surgical intervention under general or local anesthesia to restore the pet’s state of health.',
            },
            {
              questionId: 2,
              question:
                'What is considered an accident under the pet health insurance policy?',
              answer:
                'An accident is an event that suddenly affects the body of the insured animal from the outside and results in physical damage to the insured animal. Ingestion of foreign/damaging bodies and poisoning (including food poisoning) are also considered accidents.',
            },
            {
              questionId: 3,
              question:
                'What is the cancellation policy for pet health insurance?',
              answer:
                'The initial contract term is for one full year, after which you can request the cancellation anytime. This applies to both surgery coverage and standard health insurance coverage plans.',
            },
            {
              questionId: 4,
              question:
                'Are surgeries already included in the pet health insurance standard coverage?',
              answer:
                'Yes. The standard pet health insurance includes all the benefits of operation costs.',
            },
            {
              questionId: 5,
              question:
                'Are there any age restrictions for coverage for pet health insurance?',
              answer:
                'Yes, when the policy is purchased, your pet must be at least 8 weeks old and no more than 9 years old.',
            },
            {
              questionId: 6,
              question:
                'Can I still purchase pet insurance if my pet has a pre-existing condition?',
              answer:
                'Certain pre-existing conditions restrict you from taking out insurance for your pet if you are aware of them before taking out the policy.',
            },
          ],
          footer: `See our [FAQ page](${faq.base}) for more answers.`,
        },
        moreQuestionsSection: {
          bookACallGACategory: 'PetHealth',
          title: 'Have more questions?',
          bookACallUrl: calendly.petHealthInsurance,
          secondaryActionLink: faq.base,
          secondaryAction: 'Visit our FAQ',
          openInNewTab: true,
        },
      },
    },
    screen: {
      layout: 'Standalone',
    },
  },
  {
    id: 'redirect',
    type: 'REDIRECT',
    props: {},
    screen: {
      layout: 'Standalone',
    },
    groupId: 'preQuote',
  },
];
