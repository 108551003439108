import { trackStructuredEvent } from '@getpopsure/analytics';
import { calendly } from '@getpopsure/private-constants';
import {
  QuestionnaireFormProps,
  QuestionnaireQuestions,
} from '@getpopsure/qnr-framework';
import classNames from 'classnames';
import { Pension } from 'features/pension/models';
import { useState } from 'react';
import { useSafeTranslation } from 'shared/i18n';

import shieldIcon from './icons/shield.svg';
import styles from './InfoScreen.module.scss';

type InfoScreenProps<Questionnaire extends QuestionnaireQuestions> = {
  title: string;
} & QuestionnaireFormProps<Questionnaire, boolean>;

export const InfoScreen = ({
  title,
  onSubmitValue,
}: InfoScreenProps<Pension>) => {
  const [consentGiven, setConsentGiven] = useState(false);
  const { t } = useSafeTranslation();

  const handleSubmit = () => {
    onSubmitValue(true);
  };

  const handleConsent = () => {
    setConsentGiven((value) => !value);
  };

  return (
    <form
      className={`p-container d-flex fd-column ai-start jc-center ${styles.container}`}
      onSubmit={handleSubmit}
    >
      <img src={shieldIcon} alt="shield icon" />
      <h1 className="p-h1 mt16 mb8">{title}</h1>
      <div className="mt16 p-label-container">
        <input
          id="info-consent"
          type="checkbox"
          data-testid="info-consent"
          className="p-checkbox"
          onChange={handleConsent}
        />
        <label
          htmlFor="info-consent"
          className={`p-label p-p ${styles.label}`}
          data-cy="info-consent"
        >
          {t(
            'pension.qnr.infoScreen.checkbox.text',
            'I understand and wish to continue'
          )}
        </label>
      </div>
      <div className={classNames('mt24 d-flex gap16', styles.buttonContainer)}>
        <button
          type="submit"
          className="p-btn--primary wmn3"
          disabled={!consentGiven}
        >
          {t('pension.qnr.infoScreen.button.caption', 'Continue')}
        </button>
        <button
          className="p-btn--secondary wmn3"
          onClick={() => {
            trackStructuredEvent({
              category: 'PENSION',
              action: 'CLICKED_BOOK_AN_APPOINTMENT',
            });
            window.Calendly.initPopupWidget({
              url: calendly.pensionInsurance,
            });
          }}
          type="button"
        >
          {t('pension.qnr.infoScreen.bookCall.caption', 'Book a call')}
        </button>
      </div>
    </form>
  );
};
