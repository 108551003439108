import classNames from 'classnames';
import {
  bannerColorMapping,
  imageTypeMapping,
} from 'models/insurances/types/mapping';
import { useSafeTranslation } from 'shared/i18n';

import styles from './Header.module.scss';

export const QuoteHeader = () => {
  const { t } = useSafeTranslation();

  return (
    <header>
      <div
        className={classNames('px16', styles.headerImgWrapper)}
        style={{ backgroundColor: bannerColorMapping.INCOMING_LT }}
      >
        <img
          src={imageTypeMapping.INCOMING_LT}
          className={styles.headerImg}
          alt=""
        />
      </div>
      <div
        className={classNames(
          'p-body d-flex fd-column ai-center',
          styles.titleContainer
        )}
      >
        <h1 className="p-h1 p--serif">
          {t('expatLongTerm.qnr.preQuote.quote.title', 'Your coverage')}
        </h1>
      </div>
    </header>
  );
};
