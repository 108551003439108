import { Plan } from '@getpopsure/legal-insurance-pricing-engine-v2';
import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import { TableButton, TableTrueFalse } from '@popsure/dirty-swan';
import classNames from 'classnames';
import { useState } from 'react';
import { createPortal } from 'react-dom';
import { capitalizeSingleWord } from 'shared/util/capitalizeSingleWord';

import {
  CellModal,
  DentalQuotePlan,
  QuoteTableModal,
  ValueWithDescription,
} from '../../../models';
import { TableModal } from '../TableModal';
import styles from './style.module.scss';

export const ValueCell = ({
  input,
}: {
  input: ValueWithDescription & CellModal;
}) => {
  const [modal, setModal] = useState<null | QuoteTableModal>();

  const { value, description, cellModal } = input;

  const handleOpenModal = () => {
    setModal(cellModal);
  };

  return (
    <>
      {cellModal ? (
        <>
          <div className="d-flex fd-column ai-center">
            <TableButton onClick={handleOpenModal}>
              {typeof value === 'boolean' ? (
                <TableTrueFalse value={value} />
              ) : (
                <span>{value}</span>
              )}
              {description && (
                <p
                  className={classNames(
                    'ml8 p-p--small tc-grey-500',
                    {
                      [`${styles.booleanDescription}`]:
                        typeof value === 'boolean',
                    },
                    styles.description
                  )}
                >
                  {description}
                </p>
              )}
            </TableButton>
          </div>
          {modal &&
            createPortal(
              <TableModal modal={modal} setModal={setModal} />,
              document.body
            )}
        </>
      ) : (
        <div className="d-flex fd-column ai-center">
          {typeof value === 'boolean' ? (
            <TableTrueFalse value={value} />
          ) : (
            <span>{value}</span>
          )}
          {description && (
            <p
              className={classNames(
                'ml8 p-p--small tc-grey-500',
                {
                  [`${styles.booleanDescription}`]: typeof value === 'boolean',
                },
                styles.description
              )}
            >
              {description}
            </p>
          )}
        </div>
      )}
    </>
  );
};

export const CTACell = ({
  value,
  showPrice,
  planMapping,
}: {
  value: Plan | 'PREMIUM';
  showPrice: boolean;
  planMapping: DentalQuotePlan;
}) => {
  const isPlan = (plan: Plan | 'PREMIUM'): plan is Plan => plan in planMapping;

  return (
    <div className="wmn3">
      {isPlan(value) ? (
        <p className="p-h3">
          {planMapping[value].name}{' '}
          {showPrice && (
            <span className="tc-purple-500 ml4 p--serif">
              {englishFormattedEuroCurrency(planMapping[value].price, true)}
            </span>
          )}
        </p>
      ) : (
        <p className="p-h3">{capitalizeSingleWord(value.toLowerCase())} </p>
      )}
    </div>
  );
};
