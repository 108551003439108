import Session from '@getpopsure/session';
import { fetchPolicies } from 'actions/policies';
import ErrorView from 'components/error';
import LoadingSpinner from 'components/loadingSpinner';
import routes from 'constants/routes';
import { getNextUnansweredQuestionId } from 'features/questionnaireFramework/utils/getNextUnansweredQuestion';
import { getPersonaAndRecommendation } from 'features/recommendationTool/actions';
import { getRedirectToIntroPage } from 'features/recommendationTool/utils/getRedirectToIntroPage';
import { useRegion } from 'hooks/useRegion';
import { useRequestStatus } from 'hooks/useRequestStatus';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, Redirect } from 'react-router-dom';
import { getMyPolicies } from 'selectors/policies';
import {
  getAllQuestionnaireAnswers,
  getCurrentRecommendation,
  getPersonaId,
  getShowIntroPage,
} from 'selectors/recommendationTool';

import { RecommendationType } from '../models/recommendation';
import allQuestionnaires from '../questionnaires';
import { getEnabledVerticalId } from '../utils/groupRecommendationVerticals';
import { QuickAssessmentPage } from './QuickAssessmentPage';

export const OverviewPageContainer = () => {
  const [isFirstFetch, setIsFirstFetch] = useState<boolean>(true);
  const dispatch = useDispatch();
  const { loading: loadingRegion, region } = useRegion();
  const recommendation = useSelector(getCurrentRecommendation);
  const personaId = useSelector(getPersonaId);
  const showIntroPage = useSelector(getShowIntroPage);
  const policies = useSelector(getMyPolicies);
  const policiesList =
    policies && policies.length
      ? [
          ...new Set(
            policies.flatMap(({ status, type }) => {
              const verticalKey = getEnabledVerticalId(
                type as RecommendationType
              );

              if (status !== 'ACTIVE' || !verticalKey) {
                return [];
              }

              return [verticalKey];
            })
          ),
        ]
      : [];

  const questionnaireAnswers = useSelector(getAllQuestionnaireAnswers);
  const unansweredQuickRecommendationQuestions = getNextUnansweredQuestionId(
    allQuestionnaires.quickRecommendation,
    questionnaireAnswers
  );

  const redirectToIntro =
    unansweredQuickRecommendationQuestions &&
    getRedirectToIntroPage({
      showIntroPage,
      personaId,
    });

  const { loading, error } = useRequestStatus('RECOMMENDATION_TOOL');
  const { loading: policiesLoading, error: policiesError } =
    useRequestStatus('LOAD_MY_POLICIES');

  useEffect(() => {
    if (isFirstFetch) {
      setIsFirstFetch(false);
      dispatch(getPersonaAndRecommendation);

      if (Session.isAuthenticated) {
        dispatch(fetchPolicies());
      }
    }
  }, [dispatch, isFirstFetch]);

  if (error) {
    return <ErrorView error={error.message} />;
  }

  if (policiesError) {
    return <ErrorView error={policiesError.message} />;
  }

  if (loading || loadingRegion || policiesLoading || isFirstFetch) {
    return <LoadingSpinner />;
  }

  /**
   * If it's the first time seen the recommendation tool show the intro page
   * If there are still unanswered quick recommendation questions
   */
  if (
    redirectToIntro ||
    unansweredQuickRecommendationQuestions ||
    !recommendation
  ) {
    return (
      <Redirect
        to={generatePath(routes.insuranceTool.intro.path, {
          insuranceType: 'intro',
        })}
      />
    );
  }

  return (
    <QuickAssessmentPage
      policiesList={policiesList}
      recommendation={recommendation}
      region={region}
    />
  );
};
