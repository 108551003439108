import dayjs from 'dayjs';

import { Quote, SubmittablePetHealthQuestionnaire } from '.';

export const dogPriceGroups = [
  {
    groupId: '1',
    ageGroups: {
      0: {
        standard: {
          deductible: 35.05,
          regular: 40.53,
        },
        surgery: {
          deductible: 12.29,
          regular: 14.45,
        },
      },
      1: {
        standard: {
          deductible: 35.05,
          regular: 40.53,
        },
        surgery: {
          deductible: 12.29,
          regular: 14.45,
        },
      },
      3: {
        standard: {
          deductible: 35.25,
          regular: 40.77,
        },
        surgery: {
          deductible: 14.07,
          regular: 16.76,
        },
      },
      5: {
        standard: {
          deductible: 40.47,
          regular: 46.99,
        },
        surgery: {
          deductible: 16.84,
          regular: 20.05,
        },
      },
      7: {
        standard: {
          deductible: 73.55,
          regular: 86.01,
        },
        surgery: {
          deductible: 33.04,
          regular: 39.32,
        },
      },
    },
  },
  {
    groupId: '2',
    ageGroups: {
      0: {
        standard: {
          deductible: 38.98,
          regular: 45.21,
        },
        surgery: {
          deductible: 12.29,
          regular: 14.45,
        },
      },
      1: {
        standard: {
          deductible: 38.98,
          regular: 45.21,
        },
        surgery: {
          deductible: 12.29,
          regular: 14.45,
        },
      },
      3: {
        standard: {
          deductible: 39.21,
          regular: 45.48,
        },
        surgery: {
          deductible: 14.07,
          regular: 16.76,
        },
      },
      5: {
        standard: {
          deductible: 45.13,
          regular: 52.53,
        },
        surgery: {
          deductible: 16.84,
          regular: 20.05,
        },
      },
      7: {
        standard: {
          deductible: 82.45,
          regular: 96.6,
        },
        surgery: {
          deductible: 33.04,
          regular: 39.32,
        },
      },
    },
  },
  {
    groupId: '3',
    ageGroups: {
      0: {
        standard: {
          deductible: 48.79,
          regular: 56.89,
        },
        surgery: {
          deductible: 14.75,
          regular: 17.35,
        },
      },
      1: {
        standard: {
          deductible: 48.79,
          regular: 56.89,
        },
        surgery: {
          deductible: 14.75,
          regular: 17.35,
        },
      },
      3: {
        standard: {
          deductible: 49.09,
          regular: 57.25,
        },
        surgery: {
          deductible: 16.76,
          regular: 19.95,
        },
      },
      5: {
        standard: {
          deductible: 56.79,
          regular: 66.41,
        },
        surgery: {
          deductible: 21.69,
          regular: 25.82,
        },
      },
      7: {
        standard: {
          deductible: 104.75,
          regular: 123.15,
        },
        surgery: {
          deductible: 42.94,
          regular: 51.13,
        },
      },
    },
  },
  {
    groupId: '4',
    ageGroups: {
      0: {
        standard: {
          deductible: 55.32,
          regular: 64.84,
        },
        surgery: {
          deductible: 18.43,
          regular: 21.69,
        },
      },
      1: {
        standard: {
          deductible: 55.32,
          regular: 64.68,
        },
        surgery: {
          deductible: 18.43,
          regular: 21.69,
        },
      },
      3: {
        standard: {
          deductible: 55.68,
          regular: 65.08,
        },
        surgery: {
          deductible: 21.94,
          regular: 26.12,
        },
      },
      5: {
        standard: {
          deductible: 64.55,
          regular: 75.67,
        },
        surgery: {
          deductible: 25.98,
          regular: 30.93,
        },
      },
      7: {
        standard: {
          deductible: 119.62,
          regular: 140.83,
        },
        surgery: {
          deductible: 49.56,
          regular: 58.99,
        },
      },
    },
  },
];

const catPriceGroup = {
  ageGroups: {
    0: {
      standard: {
        deductible: 26.21,
        regular: 30.02,
      },
      surgery: {
        deductible: 9.07,
        regular: 10.67,
      },
    },
    1: {
      standard: {
        deductible: 26.21,
        regular: 30.02,
      },
      surgery: {
        deductible: 9.07,
        regular: 10.67,
      },
    },
    3: {
      standard: {
        deductible: 30.39,
        regular: 34.99,
      },
      surgery: {
        deductible: 10.12,
        regular: 12.05,
      },
    },
    5: {
      standard: {
        deductible: 35.52,
        regular: 41.1,
      },
      surgery: {
        deductible: 13.26,
        regular: 15.79,
      },
    },
    7: {
      standard: {
        deductible: 55.32,
        regular: 64.31,
      },
      surgery: {
        deductible: 21.63,
        regular: 25.76,
      },
    },
  },
};

type Grouped = {
  standard: {
    deductible: number;
    regular: number;
  };
  surgery: {
    deductible: number;
    regular: number;
  };
};

const getGroupedValues = (group: Grouped, isDeductible = false) => {
  return [
    calculateFinalPrice(group.surgery[isDeductible ? 'deductible' : 'regular']),
    calculateFinalPrice(
      group.standard[isDeductible ? 'deductible' : 'regular']
    ),
  ];
};

export const getPrices = (
  answers: SubmittablePetHealthQuestionnaire,
  isDeductible = false
): number[] => {
  let priceGroup;

  if (!answers.species) {
    throw new Error('[PET_HEALTH - getPrices]: Species is undefined');
  }

  if (answers.species === 'DOG') {
    priceGroup = dogPriceGroups.find(
      (group) => group.groupId === answers.raceGroup
    );
  }

  if (answers.species === 'CAT') {
    priceGroup = catPriceGroup;
  }

  const age = dayjs().diff(dayjs(answers.dateOfBirth), 'years');

  if (!priceGroup) {
    throw new Error('[PET_HEALTH - getPrices]: Price group is undefined');
  }

  if (age >= 9) {
    throw new Error('[PET_HEALTH - getPrices]: Age is out of range');
  }

  switch (age) {
    case 0:
      return getGroupedValues(priceGroup.ageGroups[0], isDeductible);
    case 1:
    case 2:
      return getGroupedValues(priceGroup.ageGroups[1], isDeductible);
    case 3:
    case 4:
      return getGroupedValues(priceGroup.ageGroups[3], isDeductible);
    case 5:
    case 6:
      return getGroupedValues(priceGroup.ageGroups[5], isDeductible);
    case 7:
    case 8:
      return getGroupedValues(priceGroup.ageGroups[7], isDeductible);
    default:
      throw new Error('Invalid age supplied');
  }
};

export const getPrice = (
  answers: SubmittablePetHealthQuestionnaire,
  plan: Quote['plan'],
  isDeductible = false
) => {
  const [surgeryPrice, standardPrice] = getPrices(answers, isDeductible);
  return plan === 'SURGERY' ? surgeryPrice : standardPrice;
};

const calculateFinalPrice = (basePrice: number) => {
  return Math.ceil(basePrice * 1.32 * 1.19 * 100) / 100;
};
