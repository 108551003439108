import { useTranslation } from '@getpopsure/i18n-react';
import { Button } from '@popsure/dirty-swan';
import classNames from 'classnames';
import { APIResponseError } from 'models/error';
import { InsuranceTypes } from 'models/insurances/types';
import {
  getTitleMapping,
  imageTypeMapping,
} from 'models/insurances/types/mapping';
import AnimateHeight from 'react-animate-height';

import styles from './styles.module.scss';

interface HealthWelcomePageProps {
  onClick(): void;
  error?: APIResponseError;
  loading: boolean;
}

export const HealthWelcomePage = ({
  onClick,
  loading,
  error,
}: HealthWelcomePageProps) => {
  const { t } = useTranslation();
  const titleMapping = getTitleMapping(t);
  const insuranceTypesCards: InsuranceTypes[] = [
    'PUBLIC_HEALTH',
    'PRIVATE_HEALTH',
    'EXPAT_V2',
  ];
  const description: Partial<Record<InsuranceTypes, string>> = {
    PUBLIC_HEALTH: t(
      'recommendationtool.welcome.description.publicHealth',
      'State insurance where provider and public funds offer a standard coverage to all of their members.'
    ),
    PRIVATE_HEALTH: t(
      'recommendationtool.welcome.description.privateHealth',
      'More comprehensive than public health insurance and generally less expensive, but eligibility depends on your health.'
    ),
    EXPAT_V2: t(
      'recommendationtool.welcome.description.expatHealth',
      'Covers the basics until you can get on a private or public plan, but does not include pre-existing health conditions.'
    ),
  };

  return (
    <div className="p-body" data-testid="recommendation-tool-intro-page">
      <div
        className={classNames(
          styles.container,
          'd-flex fd-column ai-start jc-center py24'
        )}
      >
        <h1 className="p-h1 mb24" data-testid="welcome-page-title">
          {t(
            'recommendationtool.welcome.title.health',
            "Let's compare health insurances"
          )}
        </h1>

        <div className={classNames(styles.content, 'd-flex gap24 mb24')}>
          {insuranceTypesCards.map((type) => (
            <div key={type} className="bg-white br8 p24">
              <img
                src={imageTypeMapping?.[type]}
                className={classNames(styles.cardIcon, 'br8 mb16')}
                alt=""
              />
              <h3 className={classNames(styles.cardTitle, 'p-h4')}>
                {titleMapping?.[type]}
              </h3>
              <p className="p-p p-p--small tc-grey-600">
                {description?.[type]}
              </p>
            </div>
          ))}
        </div>

        <Button
          data-cy="button-get-started"
          className="wmn3 mb24"
          onClick={onClick}
          loading={loading}
          disabled={loading}
        >
          {t('recommendationtool.welcome.action', 'Get started')}
        </Button>

        <p className="p-p tc-grey-600">
          {t(
            'recommendationtool.welcome.disclaimer',
            'This recommendation doesn’t replace expert insurance advice.'
          )}
        </p>

        <div className="d-flex jc-start mt24 w100">
          <AnimateHeight duration={300} height={error ? 'auto' : 0}>
            <div className="p-notice p-notice--danger p-p">
              {error?.message}
            </div>
          </AnimateHeight>
        </div>
      </div>
    </div>
  );
};
