import type {
  LiabilityClaims,
  Questionnaire as LiabilityQuestionnaire,
} from 'features/liabilityClaims/models';
import Questionnaire from 'features/questionnaireFramework';

interface ViewProps {
  questionnaire: LiabilityQuestionnaire;
  questionnaireAnswers: Partial<LiabilityClaims>;
  handleAnswerQuestion: (answer: Partial<LiabilityClaims>) => void;
}

const LiabilityClaimsView = ({
  questionnaireAnswers,
  handleAnswerQuestion,
  questionnaire,
}: ViewProps) => {
  return (
    <Questionnaire
      featureName="Liability Claims"
      questionnaire={questionnaire}
      questionnaireAnswers={questionnaireAnswers}
      handleAnswerQuestion={handleAnswerQuestion}
    />
  );
};

export default LiabilityClaimsView;
