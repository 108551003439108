import { email } from '@getpopsure/private-constants';
import { BottomOrRegularModal } from '@popsure/dirty-swan';
import * as Sentry from '@sentry/browser';
import classnames from 'classnames';
import { createPortal } from 'react-dom';
import { useChat } from 'react-live-chat-loader';
import { useSelector } from 'react-redux';
import { getAccountInfo } from 'selectors/user';
import { Trans, useSafeTranslation } from 'shared/i18n';
import { isMobileApp } from 'shared/util/isMobileApp';

import styles from './IntercomLoaderWrapper.module.scss';
import { bootIntercomWithUserData, shutdownIntercom } from './utils';
import { useWidgetTimeout } from './utils/useWidgetTimeout';

const onError = () => {
  Sentry.captureException(
    '[INTERCOM] chat widget timed out and failed to load'
  );
};

interface RenderArgs {
  error: boolean;
  loading: boolean;
  handleClick: () => void;
}

interface IntercomLoaderWrapperProps {
  render: (args: RenderArgs) => JSX.Element;
}

export const IntercomLoaderWrapper = ({
  render,
}: IntercomLoaderWrapperProps) => {
  const [chat, loadChat] = useChat({ loadWhenIdle: true });
  const user = useSelector(getAccountInfo);
  const { t } = useSafeTranslation();
  /**
   * Stop the timeout when the messenger successfully loads.
   * onShow is triggered when the messenger is opened.
   */
  const onLoadStarted = async (stopTimeout: () => void) => {
    await bootIntercomWithUserData(user);
    window.Intercom &&
      window.Intercom('onShow', () => {
        stopTimeout();

        if (isMobileApp) {
          window?.webkit?.messageHandlers?.toggleIntercomHandler?.postMessage({
            intercomStatus: 'SHOW',
          });

          window?.ReactNativeWebView?.postMessage(
            JSON.stringify({ intercomStatus: 'SHOW' })
          );
        }
      });

    if (isMobileApp) {
      window?.Intercom?.('onHide', () => {
        window?.webkit?.messageHandlers?.toggleIntercomHandler?.postMessage({
          intercomStatus: 'HIDE',
        });

        window?.ReactNativeWebView?.postMessage(
          JSON.stringify({ intercomStatus: 'HIDE' })
        );
      });
    }
  };

  const {
    setWidgetTimeout,
    resetTimeout,
    status: timeoutStatus,
  } = useWidgetTimeout({
    onLoadStarted,
    onError,
  });

  const handleClick = async () => {
    if (chat === 'initial' || !window.intercomSettings) {
      loadChat({ open: true });
      await setWidgetTimeout();
    }
  };

  const handleResetIntercom = async () => {
    resetTimeout();
    /**
     * Clean Intercom settings to force a new boot.
     */
    shutdownIntercom();
  };

  if (timeoutStatus === 'ERROR') {
    return (
      <>
        {createPortal(
          <BottomOrRegularModal
            isOpen
            onClose={handleResetIntercom}
            title={t('intercomLauncher.error.modal.title', 'Contact us')}
          >
            <p className="p-p pt16 pr24 pl24 pb24">
              <Trans i18nKey="intercomLauncher.error.modal.content">
                Have questions about your insurance or need help?
                <br />
                Reach out to us at{' '}
                <a href={`mailto:${email.hello}`} className="p-a">
                  {{ email: email.hello }}
                </a>
              </Trans>
            </p>
            <p className="p-p pb24 pl24 pr24">
              <a
                href={`mailto:${email.hello}`}
                className="p-btn p-btn--primary"
              >
                {t(
                  'intercomLauncher.error.modal.button.title',
                  'Send us an email'
                )}
              </a>
            </p>
          </BottomOrRegularModal>,
          document.body
        )}
        <div className={classnames(styles.container, styles.disabled)}>
          {render({ error: true, loading: false, handleClick })}
        </div>
      </>
    );
  }

  if (timeoutStatus === 'LOADING')
    return (
      <div className={classnames(styles.container, styles.disabled)}>
        {render({ error: false, loading: true, handleClick })}
      </div>
    );

  return (
    <div className={styles.container} id="intercom_custom_button">
      {render({ error: false, loading: false, handleClick })}
    </div>
  );
};
