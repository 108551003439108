import { website } from '@getpopsure/private-constants';
import {
  Header,
  QuestionnaireFormProps,
  QuestionnaireQuestions,
} from '@getpopsure/qnr-framework';
import Session from '@getpopsure/session';
import { Input } from '@popsure/dirty-swan';
import { fetchAccountInfo } from 'actions/user';
import { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GenericQuestionnaireState } from 'reducers/genericQuestionnaire';
import { getAccountInfo } from 'selectors/user';
import { Trans, useSafeTranslation } from 'shared/i18n';

import { validateAccount } from '../../actions/account';
import styles from './Email.module.scss';
import LoginModal from './LoginModal';

export const Email = <Questionnaire extends QuestionnaireQuestions>({
  value,
  onSubmitValue,
  verticalId,
  setValidForSubmission,
}: {
  verticalId: keyof GenericQuestionnaireState;
} & QuestionnaireFormProps<Questionnaire>) => {
  const account = useSelector(getAccountInfo);

  // @TODO: prepare the error and loading handling in the parent components
  // const { loading, error } = useRequestStatus('VALIDATE_ACCOUNT');

  const dispatch = useDispatch();

  const { isAuthenticated } = Session;

  const { t } = useSafeTranslation();

  const answer = (value as string) ?? account?.email ?? '';

  const [email, setEmail] = useState(answer);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [requiresLoginCode, setRequiresLoginCode] = useState(false);

  const valid = email.length > 0 && termsAccepted;

  useEffect(() => {
    setValidForSubmission(email.length > 0 && termsAccepted);
  }, [email, termsAccepted, setValidForSubmission]);

  const handleModalClose = () => {
    setRequiresLoginCode(false);
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleSetTermsAccepted = () => {
    setTermsAccepted((accepted) => !accepted);
  };

  const handleSubmit = () => {
    if (valid) {
      handleValidateAccount(email, (userExists: boolean) => {
        if (userExists) {
          setRequiresLoginCode(true);
        } else {
          onSubmitValue(email);
        }
      });
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(fetchAccountInfo());
    }
  }, [dispatch, isAuthenticated]);

  const handleValidateAccount = (
    emailToValidate: string,
    onUserExists: (userExists: boolean) => void
  ) => {
    if (emailToValidate === account?.email && isAuthenticated) {
      onSubmitValue(emailToValidate);
    } else {
      dispatch(validateAccount(emailToValidate, onUserExists));
    }
  };

  const onLogin = (loginEmail?: string) => {
    setRequiresLoginCode(false);
    if (loginEmail) {
      onSubmitValue(loginEmail);
    }
  };

  return (
    <>
      <LoginModal
        email={email}
        isOpen={requiresLoginCode}
        onClose={handleModalClose}
        onLogin={onLogin}
        verticalId={verticalId}
      />
      <Header onSubmit={handleSubmit}>
        <Input
          className="mt16 wmx6"
          type="email"
          value={email}
          placeholder={t('components.email.placeholder', 'Email address')}
          onChange={handleInputChange}
          required
          data-cy="input-email"
        />
        <div className="mt16 p-label-container">
          <input
            id="terms"
            className="p-checkbox"
            type="checkbox"
            data-testid="email-tnc"
            onChange={handleSetTermsAccepted}
          />
          <label
            htmlFor="terms"
            className={`p-label ${styles.label}`}
            data-cy="email-tnc"
          >
            <div className="p-p">
              <Trans i18nKey="components.email.agreement">
                I have read and understood the{' '}
                <a
                  className="p-a"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={website.privacyPolicy}
                >
                  privacy policy
                </a>{' '}
                and{' '}
                <a
                  className="p-a"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={website.termsAndConditions}
                >
                  T&Cs
                </a>
                .
              </Trans>
            </div>
          </label>
        </div>
      </Header>
    </>
  );
};
