import {
  fetchMailingPreferences,
  updateMailingPreferences,
} from 'actions/mailingPreferences';
import CheckoutConfirmation from 'components/checkoutConfirmation';
import { useQueryParamValue } from 'hooks/useQueryParamValue';
import { useRequestStatus } from 'hooks/useRequestStatus';
import { ValidVerticalUrlParams } from 'models/insurances/types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getNewsletterSubscription } from 'selectors/mailingPreferences';
import { TFunction, useSafeTranslation } from 'shared/i18n';

const getMapSignupSuccessVertical = (
  t: TFunction,
  key: ValidVerticalUrlParams,
  showDental: boolean
): string => {
  if (showDental) {
    return '';
  }

  const descriptions: Partial<Record<ValidVerticalUrlParams, string>> & {
    default: string;
  } = {
    publicHealth: t(
      'myPolicies.signupSuccess.publicHealth.message',
      'We’re processing your application now. You will be receiving a confirmation email shortly.'
    ),
    default: t(
      'myPolicies.signupSuccess.default.message',
      "We've added the policy to your account. You can view the details below."
    ),
  };

  return descriptions[key] ?? descriptions.default;
};

export const CheckoutConfirmationWrapper = () => {
  const { t } = useSafeTranslation();
  const dispatch = useDispatch();
  const [newsletterFeedback, setNewsletterFeedback] = useState<string | null>(
    null
  );

  const showDental = useQueryParamValue('showDental') === '1';
  const signupSuccessParam = useQueryParamValue(
    'signupSuccess'
  ) as ValidVerticalUrlParams;

  const newsletterSubscription = useSelector(getNewsletterSubscription);
  const { error, loading } = useRequestStatus('UPDATE_MAILING_PREFERENCES');

  useEffect(() => {
    if (signupSuccessParam) {
      dispatch(fetchMailingPreferences());
    }
  }, [dispatch, signupSuccessParam]);

  if (!signupSuccessParam) {
    return null;
  }

  const showNewsletterFeedback = () => {
    setTimeout(() => setNewsletterFeedback(null), 3000);

    setNewsletterFeedback(
      !newsletterSubscription
        ? t('newsletter.subscribed.message', '🎉 Subscribed!')
        : t('newsletter.unsubscribed.message', '👋 Unsubscribed!')
    );
  };

  const onUpdateMailingPreferences = async () => {
    await dispatch(
      updateMailingPreferences({
        mailingListId: 'NEWSLETTER',
        isActive: !newsletterSubscription,
      })
    );

    if (!error?.message) {
      showNewsletterFeedback();
    }
  };

  return (
    <div className="mb56">
      <CheckoutConfirmation
        description={getMapSignupSuccessVertical(
          t,
          signupSuccessParam,
          showDental
        )}
        showFeatherInfoCard={
          signupSuccessParam === 'publicHealth' && !showDental
        }
        newsletterSubscription={newsletterSubscription}
        onUpdateMailingPreferences={onUpdateMailingPreferences}
        feedback={newsletterFeedback}
        newsletterLoading={loading}
        newsletterError={error?.message}
        title={
          showDental
            ? t('myPolicies.signupSuccess.tk.title', 'Application submitted')
            : undefined
        }
        showDentalCard={showDental}
        insuranceType={signupSuccessParam}
      />
    </div>
  );
};
