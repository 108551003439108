import type { ClaimsAction, RequestAction } from 'constants/actions';
import { Plan } from 'models/bike';
import { InsuranceTypes } from 'models/insurances/types';
import { PartialQuestionnaire } from 'models/questionnaireFramework';
import type { AppState } from 'reducers';
import { ThunkDispatch } from 'redux-thunk';
import type { TFunction } from 'shared/i18n';
import { UploadedFile } from 'shared/models/types';
import { ZUploadedDocumentsSchema } from 'shared/models/validations';
import { z } from 'zod';

export type Questionnaire = PartialQuestionnaire<BikeClaims, GroupId>;
export type QuestionId = keyof BikeClaims;

export type GroupId = 'questionnaire';

export const bikeBasicPlanClaimTypes = ['STOLEN_PARTS', 'STOLEN'] as const;
export const claimTypesAvailableAfterSixMonths = ['WEAR_AND_TEAR'] as const;
export const bikeComfortPlanClaimTypes = [
  'PARTIALLY_DAMAGED',
  'FULLY_DAMAGED',
] as const;

const BikeClaimTypeArray = [
  ...bikeBasicPlanClaimTypes,
  ...bikeComfortPlanClaimTypes,
  ...claimTypesAvailableAfterSixMonths,
] as const;
export type BikeClaimType = typeof BikeClaimTypeArray[number];

export const claimTypeMapping = (
  t: TFunction
): Record<BikeClaimType, string | { title: string; description: string }> => ({
  WEAR_AND_TEAR: t(
    'claims.bike.questionnaire.claimType.wearAndTear.title',
    'Wear and tear'
  ),
  STOLEN_PARTS: t(
    'claims.bike.questionnaire.claimType.stolenParts.title',
    'Stolen part(s)'
  ),
  PARTIALLY_DAMAGED: {
    title: t(
      'claims.bike.questionnaire.claimType.partiallyDamaged.title',
      'Partially damaged'
    ),
    description: t(
      'claims.bike.questionnaire.claimType.partiallyDamaged.description',
      'Your bike is still working but needs repair'
    ),
  },
  STOLEN: t('claims.bike.questionnaire.claimType.stolen.title', 'Stolen'),
  FULLY_DAMAGED: {
    title: t(
      'claims.bike.questionnaire.claimType.fullyDamaged.title',
      'Fully damaged'
    ),
    description: t(
      'claims.bike.questionnaire.claimType.fullyDamaged.description',
      'Your bike is not functional and you need a brand new one'
    ),
  },
});

// eslint-disable-next-line
export const isBikeClaimType = (value: any): value is BikeClaimType =>
  BikeClaimTypeArray.includes(value);

export interface BikeClaims {
  intro: boolean;
  frameNumber: string;
  blockerFrameNumber: boolean;
  purchaseReceipts: UploadedFile[];
  blockerPurchaseReceipts: boolean;
  claimType: BikeClaimType;
  details: string;
  documents: UploadedFile[];
  payoutDetails: { amount: number; iban: string };
  review: boolean;
  processing: boolean;
  submitted: boolean;
}

export interface BikeClaimsMetaData {
  policyStartDate: string;
  policyId: string;
  planId: Plan;
  questionnaireId: string;
}

export type BikeClaimsDispatch = ThunkDispatch<
  AppState,
  unknown,
  RequestAction | ClaimsAction
>;

export interface BikeClaimsSubmitInfo {
  type: InsuranceTypes;
  userPolicyId: string;
  claimType: BikeClaimType;
  details: string;
  uploadedDocumentTokens: string[];
  amount: number;
  iban: string;
}

export const ZBikeClaimsSubmissionSchema = z.object({
  claimType: z.enum(BikeClaimTypeArray),
  details: z.string(),
  documents: z.array(ZUploadedDocumentsSchema),
  payoutDetails: z.object({
    amount: z.number(),
    iban: z.string(),
  }),
});

export type BikeClaimsReviewQuestionId =
  | Extract<
      QuestionId,
      'frameNumber' | 'purchaseReceipts' | 'claimType' | 'details' | 'documents'
    >
  | 'amount'
  | 'iban';

export const bikeClaimsReviewQuestions: BikeClaimsReviewQuestionId[] = [
  'frameNumber',
  'purchaseReceipts',
  'claimType',
  'details',
  'documents',
  'amount',
  'iban',
];

export const bikeClaimsReviewQuestionsTitleMapping = (
  t: TFunction
): Record<BikeClaimsReviewQuestionId, string> => ({
  frameNumber: t(
    'claims.bike.review.question.frameNumber.title',
    'Frame number'
  ),
  purchaseReceipts: t(
    'claims.bike.review.question.purchaseReceipts.title',
    'Receipts'
  ),
  claimType: t('claims.bike.review.question.claimType.title', 'Claim type'),
  details: t('claims.bike.review.question.details.title', 'Event description'),
  documents: t('claims.bike.review.question.documents.title', 'Documents'),
  amount: t(
    'claims.bike.review.question.payoutDetails.amount.title',
    'Claim amount'
  ),
  iban: t('claims.bike.review.question.payoutDetails.iban.title', 'IBAN'),
});
