import { TFunction } from '@getpopsure/i18n-react';
import { Validation } from '@getpopsure/qnr-framework';
import dayjs from 'dayjs';

export const coverageOneYearInTheFutureValidator = (
  t?: TFunction
): Validation => ({
  op: 'Custom',
  fn: (answer: unknown) => {
    return !dayjs()
      .add(1, 'years')
      .subtract(1, 'day')
      .isBefore(dayjs(answer as string));
  },
  msg: () => {
    return {
      msg: t
        ? t(
            'generic.qnr.startDate.validation.errorInTheFutureMessage',
            'Coverage start date should not be more than 1 year in the future. Please adjust the start date.'
          )
        : 'Coverage start date should not be more than 1 year in the future. Please adjust the start date.',
      type: 'Error',
    };
  },
});

export const arrivalDateOneYearInTheFutureValidator = (
  t?: TFunction
): Validation => ({
  op: 'Custom',
  fn: (answer: unknown) => {
    return !dayjs()
      .add(1, 'years')
      .subtract(2, 'day')
      .isBefore(dayjs(answer as string));
  },
  msg: () => {
    return {
      msg: t
        ? t(
            'generic.qnr.arrivalDate.validation.errorInTheFutureMessage',
            'Arrival date should not be more than 1 year in the future. Please adjust the arrival date.'
          )
        : 'Arrival date should not be more than 1 year in the future. Please adjust the arrival date.',
      type: 'Error',
    };
  },
});

export const arrivalDateMaxFiveYearsInThePastValidator = (
  t?: TFunction
): Validation => ({
  op: 'Custom',
  fn: (answer: unknown) => {
    return dayjs()
      .subtract(5, 'years')
      .isBefore(dayjs(answer as string));
  },
  msg: () => {
    return {
      msg: t
        ? t(
            'generic.qnr.arrivalDate.validation.errorInThePastMessage',
            'Arrival date should not be more than 5 years in the past. Please adjust the arrival date.'
          )
        : 'Arrival date should not be more than 5 years in the past. Please adjust the arrival date.',
      type: 'Error',
    };
  },
});
