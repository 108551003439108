import { Checkbox } from '@popsure/dirty-swan';
import { PaymentElement, useElements } from '@stripe/react-stripe-js';
import { StripePaymentElementChangeEvent } from '@stripe/stripe-js';
import { StripeElementSetupData } from 'features/paymentMethodSelector/paymentMethodSelector.models';
import { useEffect, useState } from 'react';
import { useSafeTranslation } from 'shared/i18n';

import styles from './styles.module.scss';

export interface PaymentFormProps {
  accountHolderEmail: string;
  forceMakeDefault: boolean;
  onSetupDataChange: (setupData: StripeElementSetupData | null) => void;
  onPaymentMethodTypeChange: (type: string) => void;
}

export const StripeElementForm = ({
  accountHolderEmail,
  forceMakeDefault,
  onSetupDataChange,
  onPaymentMethodTypeChange,
}: PaymentFormProps) => {
  const { t } = useSafeTranslation();

  const [hasEnteredPaymentInfo, setHasEnteredPaymentInfo] = useState(false);
  const [makeDefault, setMakeDefault] = useState<boolean>(forceMakeDefault);

  const elements = useElements();

  useEffect(() => {
    let setupData: StripeElementSetupData | null;

    if (hasEnteredPaymentInfo) {
      setupData = { makeDefault };
    } else {
      setupData = null;
    }

    onSetupDataChange(setupData);
  }, [
    elements,
    hasEnteredPaymentInfo,
    forceMakeDefault,
    makeDefault,
    onSetupDataChange,
  ]);

  const onPaymentElementChange = ({
    complete,
    value,
  }: StripePaymentElementChangeEvent) => {
    setHasEnteredPaymentInfo(complete);
    onPaymentMethodTypeChange(value.type);
  };

  return (
    <div className="mt16" data-testid="stripe-payment-method-form">
      <PaymentElement
        onChange={onPaymentElementChange}
        options={{
          defaultValues: { billingDetails: { email: accountHolderEmail } },
        }}
      />

      {!forceMakeDefault && (
        <Checkbox
          wide
          bordered={false}
          options={{
            SET_AS_DEFAULT: t(
              'paymentMethodSelector.stripeElementForm.makeDefault.label',
              'Set as default payment method'
            ),
          }}
          classNames={{ container: 'mt16', label: styles.checkboxLabel }}
          value={makeDefault ? ['SET_AS_DEFAULT'] : []}
          onChange={(values) =>
            setMakeDefault(values.includes('SET_AS_DEFAULT'))
          }
        />
      )}
      <p className="p-p--small tc-grey-600 mt16">
        {t(
          'paymentMethodSelector.stripeElementForm.makeDefault.hint',
          'Recurring payments are always taken from the default payment method in your account.'
        )}
      </p>
    </div>
  );
};
