import { Rule } from '@getpopsure/qnr-framework';
import {
  ArrowRightIcon,
  Button,
  Input,
  PenToolIcon,
  PlusIcon,
  TrashIcon,
} from '@popsure/dirty-swan';
import { useState } from 'react';

import { BuilderQuestionnaire } from './models';

const entryClass = 'd-flex fd-row my8 gap16';
const titleClass = 'p-h3 mt24 mb8 mb8';

type EditRule = {
  idx?: number;
  id: string | null;
  ifCondition: string | null;
  comparisonValue: string | number | string[] | number[] | null;
  thenCondition: string | null;
  elseCondition: string | null;
};

type RulesProps = {
  rules: Rule<BuilderQuestionnaire>[];
  questions: string[];
  updateRules: React.Dispatch<
    React.SetStateAction<Rule<Record<string, unknown>>[]>
  >;
};

const isValidRule = (rule: EditRule) => {
  return rule.id && rule.ifCondition && rule.thenCondition;
};

export const Rules = ({ rules, questions, updateRules }: RulesProps) => {
  const [addRule, setAddRule] = useState<EditRule | null>(null);
  const onSave = () => {
    if (addRule === null || !isValidRule(addRule)) {
      return;
    }
    if (addRule.idx !== undefined && addRule.idx !== null) {
      updateRules((prevRules) => {
        return [
          ...prevRules.slice(0, addRule.idx),
          {
            id: addRule.id as string,
            if: {
              op: addRule.ifCondition,
              ...(comparisonOperators.includes(addRule.ifCondition as string)
                ? {
                    variable: {
                      type: 'static',
                      value: addRule.comparisonValue,
                    },
                  }
                : {}),
            },
            then: {
              goTo: addRule.thenCondition,
            },
          } as Rule<Record<string, unknown>>,
          ...prevRules.slice((addRule.idx ?? 0) + 1),
        ];
      });
    } else {
      updateRules((prevRules) => {
        return [
          ...prevRules,
          {
            id: addRule.id as string,
            if: {
              op: addRule.ifCondition,
              ...(comparisonOperators.includes(addRule.ifCondition as string)
                ? {
                    variable: {
                      type: 'static',
                      value: addRule.comparisonValue,
                    },
                  }
                : {}),
            },
            then: {
              goTo: addRule.thenCondition,
            },
          } as Rule<Record<string, unknown>>,
        ];
      });
    }
    setAddRule(null);
  };

  const onRemoveRule = (idx: number) => {
    updateRules((prevRules) => {
      return prevRules.filter((_rule, index) => index !== idx);
    });
  };

  return (
    <div className="mt32">
      <div className="d-flex w100 jc-center ai-center">
        <h3 className={titleClass}>Rules</h3>
      </div>
      <div className="p-container mt24">
        {rules.map((rule, idx) => {
          return (
            <div
              className="d-flex w100 fd-column mt24"
              // eslint-disable-next-line react/no-array-index-key
              key={`${rule.id}-${idx}`}
            >
              <div className="d-flex w100 fd-row jc-between">
                <div className="d-flex fd-row ai-center jc-between w90">
                  <div className="fw-bold">{rule.id}</div>
                  {typeof rule.if !== 'function' && (
                    <div>
                      if: {rule.if.op}{' '}
                      {rule.if.op !== 'dateDiff' && rule.if.op !== 'always' && (
                        <span> {rule.if.variable.value}</span>
                      )}
                    </div>
                  )}
                  <div>
                    <ArrowRightIcon /> {rule.then.goTo}
                  </div>
                  {rule.else && (
                    <div>
                      else <ArrowRightIcon /> {rule.else.goTo}
                    </div>
                  )}
                </div>
                <button
                  type="button"
                  className="c-pointer tc-grey-500 bg-transparent"
                  onClick={() =>
                    setAddRule({
                      idx,
                      id: rule.id,
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      ifCondition: rule.if?.op,
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      comparisonValue: rule.if.variable?.value ?? null,
                      thenCondition: rule.then.goTo,
                      elseCondition: rule.else?.goTo ?? null,
                    })
                  }
                >
                  <PenToolIcon />
                </button>
                <button
                  type="button"
                  className="c-pointer tc-grey-500 bg-transparent"
                  onClick={() => onRemoveRule(idx)}
                >
                  <TrashIcon />
                </button>
              </div>
              {addRule?.idx === idx && (
                <RuleComponent
                  rule={addRule}
                  onUpdate={setAddRule}
                  questions={questions}
                  onSave={onSave}
                  onCancel={() => {
                    setAddRule(null);
                  }}
                />
              )}
              <hr />
            </div>
          );
        })}{' '}
      </div>
      {addRule && (addRule.idx === null || addRule.idx === undefined) && (
        <RuleComponent
          rule={addRule}
          onUpdate={setAddRule}
          questions={questions}
          onSave={onSave}
          onCancel={() => {
            setAddRule(null);
          }}
        />
      )}
      {!addRule && (
        <div className="d-flex w100 jc-center ai-center">
          <Button
            type="button"
            onClick={() =>
              setAddRule({
                id: null,
                ifCondition: null,
                comparisonValue: null,
                thenCondition: null,
                elseCondition: null,
              })
            }
            className="mt8 ws4 p-btn--secondary"
          >
            <PlusIcon /> Add Rule
          </Button>
        </div>
      )}
    </div>
  );
};

const comparisonOperators = [
  'equals',
  'isIn',
  'greaterThan',
  'same',
  'contains',
];

const operators = [...comparisonOperators, 'always', 'dateDiff'];

type RuleComponentProps = {
  rule: EditRule;
  questions: string[];
  onUpdate: (rule: EditRule) => void;
  onSave: () => void;
  onCancel: () => void;
};

const RuleComponent = ({
  rule,
  questions,
  onUpdate,
  onSave,
  onCancel,
}: RuleComponentProps) => {
  const { id, ifCondition, comparisonValue, thenCondition, elseCondition } =
    rule;
  const selectableQuestions = ['-', ...questions];
  const isValid = isValidRule(rule);
  return (
    <div className="p-container d-flex fd-column ai-center">
      <div className="d-flex fd-column ws4">
        <div className={entryClass}>
          <span className="w20">Id</span>
          <select
            onChange={(e) => {
              onUpdate({
                ...rule,
                id: e.target.value,
              });
            }}
            value={id ?? ''}
          >
            {selectableQuestions.map((component) => {
              return (
                <option key={component} value={component}>
                  {component}
                </option>
              );
            })}
          </select>
        </div>
        <div className={entryClass}>
          <span className="w20">If</span>
          <select
            onChange={(e) => {
              onUpdate({ ...rule, ifCondition: e.target.value });
            }}
            value={ifCondition ?? '-'}
          >
            {['-', ...operators].map((component) => {
              return (
                <option key={component} value={component}>
                  {component}
                </option>
              );
            })}
          </select>
          {ifCondition && comparisonOperators.includes(ifCondition) && (
            <Input
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              value={comparisonValue ?? ''}
              onChange={(e) => {
                onUpdate({ ...rule, comparisonValue: e.target.value });
              }}
            />
          )}
        </div>
        <div className={entryClass}>
          <span className="w20">Then</span>
          <select
            onChange={(e) => {
              onUpdate({
                ...rule,
                thenCondition: e.target.value,
              });
            }}
            value={thenCondition ?? '-'}
          >
            {selectableQuestions
              .filter((questionId) => questionId !== id)
              .map((component) => {
                return (
                  <option key={component} value={component}>
                    {component}
                  </option>
                );
              })}
          </select>
        </div>
        <div className={entryClass}>
          <span className="w20">Else</span>
          <select
            onChange={(e) => {
              onUpdate({
                ...rule,
                elseCondition: e.target.value,
              });
            }}
            value={elseCondition ?? '-'}
          >
            {selectableQuestions.map((component) => {
              return (
                <option key={component} value={component}>
                  {component}
                </option>
              );
            })}
          </select>
        </div>
      </div>

      <div className="d-flex w100 jc-center ai-center">
        <Button
          type="button"
          onClick={onSave}
          className="mt8 ws4 mt16"
          disabled={!isValid}
        >
          Save
        </Button>
        <Button
          type="button"
          onClick={onCancel}
          className="mt8 ws4 p-btn--secondary"
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};
